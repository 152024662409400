import Vue from 'vue'
import { MutationTree } from 'vuex'

import * as QUESTIONS from './types'
import { QuestionsState } from '@/@next/store/questions'

export const mutations: MutationTree<QuestionsState> =  {

  [QUESTIONS.ADD_QUESTION_FINISHED] (state, question: any) {
    const index = state.finishedQuestions.findIndex((q: any) => q.id === question.id)
    if (index !== -1) {
      Vue.set(state.finishedQuestions, index, question)
    } else {
      state.finishedQuestions.push(question)
    }
  }

}
