
import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { MediaAudio, Question, QuestionAnswer } from '@/@types/graphql'
import _shuffle from 'lodash/shuffle'
import { messages } from '@/views/single-poi/i18n'
import CQuestionImage from '@/components/question/QuestionImage.vue'
import CQuestionClue from './QuestionClue.vue'
import CQuestionComment from './QuestionComment.vue'
import CQCMAnswer from './QCMAnswer.vue'
import Quizzes from '@/@next/services/Quiz'
import { QuestionData } from '@/@types/question'
import Medias from '@/@next/services/Medias'
import addProtocol from '@/utils/addProtocol'
import Audio from '@/views/single-poi/contents/Audio.vue'

@Component({
    name: 'c-question',
    i18n: { messages },
    components: { CQuestionImage, CQuestionClue, CQCMAnswer, CQuestionComment, Audio }
  })
  export default class CQuestion extends Mixins(Quizzes, Medias) {

    @Prop({ type: Object}) question!: Question

    isFinished: boolean = false
    isCompleted: boolean = false
    isSubmittable: boolean = true
    success: boolean = false
    showClue: boolean = false
    showQuestion: boolean = true
    selectedItems: any[] = []
    audio: MediaAudio | null = null
    audioIsPlaying: boolean = false

    @Watch('image')
    @Watch('statement')
    onImageUpdate () {
      this.init()
      this.showQuestion = false
      setTimeout(() => this.showQuestion = true, 200)
    }

    async init () {
      this.isCompleted = false
      this.isFinished = false
      this.isSubmittable = true
      this.success = false
      this.showClue = false
    }

    update () {
      this.init()
    }

    async mounted () {
      if (this.audioId) {
        this.audio = await this.GET_SINGLE_AUDIO(this.audioId)
      }
    }

    get image () {
      return this.question.image
    }

    get statement () {
      return this.question?.i18n.title?.value.html || ''
    }

    get answerItems () {
      const items = this.question?.data.answer.items || []
      const isRandomDisplayItem = this.question?.data.answer.randomDisplay || false
      return isRandomDisplayItem ? _shuffle(items) : items
    }

    async sendAnswer () {
      try {
        const questionAnswer: Partial<QuestionAnswer> = {
          userId: this.$cookies.get('WIVI_UUID'),
          questionId: this.question.id,
          data: {
            correct: this.success,
            selectedAnswers: this.selectedItems.map((items) => items.id || '')
          }
        }
        await this.STORE_QUESTION_ANSWER(questionAnswer)
      } catch (e) {
        console.warn('Error during saving question', e)
      }

    }

    async onQuestionSubmit () {
      this.isFinished = true
      await this.sendAnswer()
    }

    scrollOnComment () {
      // TODO : refaire avec un handler sur le chargement de l'image de commentaire
      Vue.nextTick(() => {
        const comment = document.getElementById('question-comment')
        if (!comment) return
        comment.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        setTimeout(() => {
          const comment = document.getElementById('question-comment')
          if (!comment) return
          comment.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }, 250)
      })

    }

    get comment () {
      if (!this.isFinished || !this.question) return null
      const unique = this.question.data.comment.unique || false
      if (!this.success && !unique) return this.question.data.comment.wrong
      return this.question.data.comment.right
    }

    get clue () {
      if (!this.question) return null
      const clue = this.question.data.clue
      if (!clue) return null
      const body = clue.body.find((el: any) => el.language === this.$i18n.locale)
      if (clue.imageRefs.length === 0 && (!body || body.value.html.length < 3)) return null
      return this.question.data.clue
    }

    toggleClue () {
      this.showClue = !this.showClue
    }

    get audioId () {
      // TODO : add target filter
      const { audioRefs } = this.question.data as QuestionData
      if (!audioRefs) return
      const audioRef = audioRefs.find((el: any) => el.language === this.$i18n.locale)
      return audioRef ? audioRef.id : audioRefs[0]?.id || null
    }

    get audioUrl () {
      if (!this.audio || !this.audio.file) return null
      return addProtocol(this.audio.file.url)
    }

    get audioElement () {
      // if (!this.audioId) return
      return document.getElementById(`question-statement-player-${this.audioId}`) as HTMLAudioElement
    }

    onAudioPlayPauseClick (e: Event) {
      e.stopImmediatePropagation()
      e.stopPropagation()
      this.audioElement.paused ? this.audioElement.play() : this.audioElement.pause()
      if (!this.audioElement.paused) {
        this.audioElement.currentTime = 0
        this.audioElement.addEventListener('ended', () => {
          this.audioIsPlaying = false
          this.audioElement.removeEventListener('ended', () => { return })
        })
      } else {
        this.audioElement.removeEventListener('ended', () => { return })
      }
      this.audioIsPlaying = !this.audioElement.paused
      this.$emit('playPause')
    }
  }
