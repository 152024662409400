



































import { Vue, Component } from 'vue-property-decorator';

const config = {
  name: 'icon-grey-scale',
  components: {}
};

@Component(config)
export default class IconGreyScale extends Vue {}
