import Vue from 'vue'
import { GetterTree, Module } from 'vuex'
import { RootState } from '@/@next/store'
import i18n from '@/i18n'

import * as AUDIOGUIDE from './types'
import { actions } from './actions'
import { mutations } from './mutations'

export class AudioguideState {
  poi: any = null
  currentPoi: any = null
  playlist: any[] = []
  activated: boolean = false
}

const state = new AudioguideState()

const getters: GetterTree<AudioguideState, RootState> = {
  [AUDIOGUIDE.GET_STATUS]: (state): any => state.activated,
  [AUDIOGUIDE.GET_PLAYLIST]: (state): any => state.playlist,
  [AUDIOGUIDE.GET_POI]: (state): any => state.poi,
  [AUDIOGUIDE.GET_CURRENT_POI]: (state): any => state.currentPoi
}

export const audioguide: Module<AudioguideState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
