
import { Mixins, Component } from 'vue-property-decorator'
import { messages } from './i18n'
import { Maybe, QuizList } from '@/@types/graphql'

import { SurveyService } from '@/@next/services'
import { LanguagesMixin } from '@/@next/mixins'

import { CAsyncTask } from '@/@next/components/async-task'
import { CLoader } from '@/components/loader'
import { CInfiniteScroll } from '@/@next/components/infinite-scroll'
import { CImage } from '@/@next/components/image'
import CCollectionCard from '@/components/cards/collection-card'
import { CTopbarTitle } from '@/@next/components/topbar-title'

const config = {
  name: 'v-survey-list',
  components: {
    ...CCollectionCard,
    CAsyncTask,
    CInfiniteScroll,
    CImage,
    CLoader,
    CTopbarTitle
  },
  i18n: { messages },
  head () {
    return {
      title: this.$t('surveyListTitle'),
    }
  }
}

@Component(config)
export default class SurveyList extends Mixins!(
  SurveyService,
  LanguagesMixin
) {

  surveyList: Maybe<QuizList> = null

  async init () {
    this.surveyList = await this.GET_ALL_SURVEY()
  }

  async fetch () {
    return
  }

  get pageTitle () {
    if (this.$t('surveyListTitle')) {
      return this.$t('surveyListTitle')
    }
  }

}
