import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'

import { Scalars, MediaImage, MediaVideo, MediaAudio } from '@/@types/graphql'

import {
  MediaImageFragment,
  MediaVideoFragment,
  MediaAudioFragment
} from './fragments'

@Component({
  name: 'media-service'
})
export default class Medias extends Vue {

  async GET_SINGLE_IMAGE (id: Scalars['UUID']): Promise<MediaImage> {
    const variables = { id }
    const query = gql`

        ${ MediaImageFragment }

        query image($id: UUID!) {
            image (id: $id) {
                ...MediaImageFragment
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.image
  }

  async GET_SINGLE_VIDEO (id: Scalars['UUID']): Promise<MediaVideo> {
    const variables = { id }
    const query = gql`

      ${ MediaVideoFragment }

      query video($id: UUID!) {
        video (id: $id) {
          ...MediaVideoFragment
        }
      }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.video
  }

  async GET_SINGLE_AUDIO (id: Scalars['UUID']): Promise<MediaAudio> {
    const variables = { id }
    const query = gql`

      ${ MediaAudioFragment }
      ${ MediaImageFragment }
      query audio($id: UUID!) {
        audio (id: $id) {
          ...MediaAudioFragment
        }
      }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.audio
  }
}
