import { Vue, Component } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

import * as SETTINGS from '@/@next/store/settings2/types'

@Component({ name: 'settings-mixin' })
export default class Settings extends Vue {

  @Getter(SETTINGS.GET_MENU)
  _settingsMenu!: any

  @Mutation(SETTINGS.SET_MENU)
  _setSettingsMenu!: any

  @Getter(SETTINGS.GET_FEATURES)
  _settingsFeatures!: any

  @Mutation(SETTINGS.SET_FEATURES)
  _setSettingsFeatures!: any

  @Getter(SETTINGS.GET_QRCODES)
  _settingsQRCodes!: any

  @Mutation(SETTINGS.SET_QRCODES)
  _setSettingsQRCodes!: any

  @Getter(SETTINGS.GET_HOMEPAGE)
  _settingsHomepage!: any

  @Mutation(SETTINGS.SET_HOMEPAGE)
  _setSettingsHomepage!: any

  @Getter(SETTINGS.GET_MOVIES)
  _settingsMovies!: any

  @Mutation(SETTINGS.SET_MOVIES)
  _setSettingsMovies!: any

}
