import { Mixins, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'

import { Language } from '@/@types/graphql'
// import { Getter, Mutation } from 'vuex-class'
// import * as LANGUAGES from '@/@next/store/languages/types'
// import { LanguageCode } from '@/@next/@types'
// import { DatasetService } from '@/@next/services'
import { LanguagesMixin } from '../mixins'

@Component({ name: 'languages-service' })
export default class Languages extends Mixins(LanguagesMixin) {

  // @Getter(LANGUAGES.GET_CURRENT) currentLanguage!: LanguageCode
  // @Getter(LANGUAGES.GET_TRANSLATABLE_TARGETS) translatableTargets!: any[]
  // @Getter(LANGUAGES.GET_ALL) languages!: Language[]
  // @Mutation(LANGUAGES.SET_CURRENT) setSelectedLanguage!: (iso: string) => void
  // @Mutation(LANGUAGES.SET_TARGET) setTarget!: (target: string | null) => void

  async GET_ALL_LANGUAGES (isEnabled: boolean = true): Promise<Language[]> {
    const variables = { isEnabled }
    const query = gql`
      query languages ($isEnabled: Boolean) {
        languages (isEnabled: $isEnabled) {
          id,
          iso,
          code,
          name,
          flag
        }
      }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.languages
  }

  async FETCH_AVAILABLE_TARGETS () {
    const variables = { key: 'translatableTargets' }
    const query = gql`      
        query dataset ($key: String!) {
            dataset (key: $key) {
                value
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    const tt = data?.dataset?.value || []
    this._setTranslatableTarget(tt)
    return tt
  }

}
