import { Mixins, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'

import { Maybe, User } from '@/@types/graphql'
import { LanguagesMixin } from '@/@next/mixins'
import * as AUTHORIZATION from '@/@next/store/authorization'
import * as USER from '@/@next/store/user/types'
import isLocalStorageAvailable from '@/@next/helpers/isLocalStorageAvailable'
import { Mutation } from 'vuex-class'

@Component({ name: 'users-service' })
export default class Users extends Mixins(LanguagesMixin) {

  @Mutation(AUTHORIZATION.SET_USER_ID)
  storeUserId!: (userId: string) => void
  @Mutation(USER.SET_SIGN_LANGUAGE_ENABLED) setSignLanguageEnabled!: (enable: boolean) => void

  async CREATE_USER (): Promise<User> {
    const variables = {
      userAgent: navigator.userAgent || 'unknow',
      language: this._currentLanguage
    }
    const mutation = gql`
        mutation createUser ($language: LanguageCode!, $userAgent: String!) {
            createUser (language: $language, userAgent: $userAgent) {
                id
                createdAt
                updatedAt
                language
                userAgent
            }
        }
    `
    const { data } = await this.$apollo.mutate({ mutation, variables, client: 'writableApolloClient' })
    return data.createUser
  }

  READ_USER_COOKIE () {
    const uuid = this.$cookies.get('WIVI_UUID')
    return uuid
  }

  async GET_USER_ID (clear: boolean = false): Promise<Maybe<string>> {
    try {
      const uuid = this.READ_USER_COOKIE()
      if (uuid && !clear) {
        console.info(`[Info][User] Welcome back user ${uuid}`)
        this.storeUserId(uuid)
        return uuid
      }
      const user = await this.CREATE_USER()
      if (!user) return null
      this.$cookies.set('WIVI_UUID', user.id)
      this.storeUserId(user.id)
      console.info(`[Info][User] Clear and set new user ${user.id}`)
      return user.id
    } catch (e) {
      console.warn('Error during init User Id with api', e)
      return null
    }
  }

  SAVE_CURRENT_USER_PREFERENCES () {
    try {
      const config = {
        isoLanguage: this._currentLanguage,
        target: this._languageTarget,
        accessibility: {
          textSize: this.textSize,
          wordSpacing: this.wordSpacing,
          greyScale: this.greyScale,
          highContrast: this.highContrast,
          dyslexicFont: this.dyslexicFont
        }        
      }
      if (isLocalStorageAvailable()) {
        localStorage.setItem('WIVI_PREFERENCES', JSON.stringify(config))
      } else {
        this.$cookies.set('WIVI_PREFERENCES', JSON.stringify(config))
      }
    } catch (e) {
      console.warn('Error during Saving User Preferences', e)
      return null
    }
  }

  GET_USER_PREFERENCES (clear: boolean = false) {
    try {
      if (isLocalStorageAvailable()) {
        if (!clear) {
          const preferences = localStorage.getItem('WIVI_PREFERENCES')
          if (!preferences) return null          
          return JSON.parse(preferences)
        }
        localStorage.removeItem('WIVI_PREFERENCES')
        return null
      }
      if (!clear) return this.$cookies.get('WIVI_PREFERENCES') || null
      this.$cookies.remove('WIVI_PREFERENCES')
      return null
    } catch (e) {
      console.warn('Error during Getting User Preferences', e)
      return null
    }
  }
  
  GET_USER_SIGN_LANGUAGE_ENABLED (enable: boolean = false) {
    try {
      this.setSignLanguageEnabled(enable)
      const signLanguageEnabled = this.$cookies.set('WIVI_SIGN_LANGUAGE_ENABLE', enable)
    } catch (e) {
      console.warn('Error during set User config', e)
      return null
    }
  }

}
