/* tslint:disable */
export const messages = {
  fr: {
    ok: 'J\'ai compris',
    moreInfo: 'Plus d\'informations',
    midiTitle: 'Découvrez le Midi Solaire',
    messageFiveMinuteBefore: 'Saviez-vous que le midi solaire sera visible sur la Méridienne dans 5 Min ? Dirigez-vous dans la salle du Méridien pour l\'observer.',
    messageMidiSolaire: 'Votre attention s\'il vous plaît ! C\'est l\'heure du midi solaire ! Dirigez-vous dans la salle du Méridien pour l\'observer.'
  },
  ef: {
    ok: 'J\'ai compris',
    moreInfo: 'Plus d\'informations',
    midiTitle: 'Découvrez le Midi Solaire',
    messageFiveMinuteBefore: 'Hey ! Connais-tu le Midi Solaire ? Il sera visible dans 5 Min, si tu le souhaites tu peux aller dans la salle du Méridien pour l\'observer.',
    messageMidiSolaire: 'C\'est l\'heure de découvrir la tâche du midi solaire ! Va vite voir la méridienne au sol dans la salle du Méridien !'
  },
  en: {
    ok: 'ok',
    moreInfo: 'More information',
    midiTitle: 'Discover the Solar noon',
    messageFiveMinuteBefore: 'The Solar noon will be visible in 5 minutes. Please head to the Greenwich Meridian room to discover it\'s point on the noon mark.',
    messageMidiSolaire: 'It\'s time ! The Solar noon is at it\'s right place now ! Head to the Greenwich Meridian room to discover it\'s point on the noon mark.'
  }
}
