import addProtocol, { addSocketProtocol } from '@/utils/addProtocol'
const CONFIG = (window as any).__CONFIG__
const {
  client,
  target,
  apiUrl,
  writableApiUrl,
  mapUrl,
  syncUrl,
  matomo,
  redirectUrl,
  cache,
  features,
  socketAuthorizationServiceUrl,
  domain,
  cacheStrategy
} = CONFIG

/**** Get App Domain ***/
const appUrl = window.location.host
const split = appUrl.split('.')
const appDomain = split.length > 1 ? split[split.length - 2] + '.' + split[split.length - 1] : domain
/***************/

export const CLIENT = client || 'default'
export const CACHE_STRATEGY = cacheStrategy || 'no-cache'
export const TARGET = target || null
export const API_URL = addProtocol(apiUrl)
export const WRITABLE_API_URL = writableApiUrl || apiUrl ? addProtocol(writableApiUrl || apiUrl) : null
export const DOMAIN = appDomain
export const MAP_URL = mapUrl ? addProtocol(CONFIG.mapUrl) : null
export const SYNC_URL = syncUrl
export const MATOMO_URL = addProtocol(matomo && matomo.url ? matomo.url : 'matomo.wivi.site')
export const MATOMO_SITE_ID = matomo && matomo.siteId || 3
export const REDIRECT_URL = redirectUrl || 'redirect.wivi.fr'
export const CACHE = cache || null
export const DEV = typeof process !== 'undefined' && process.env.NODE_ENV !== 'production'
export const PROD = !DEV
export const DEBUG = !PROD
export const FEATURES = features || []
export const SOCKET_AUTHORIZATION_SERVICE_URL = socketAuthorizationServiceUrl ? 
                                                addSocketProtocol(socketAuthorizationServiceUrl ) : null

/*** GEOLOCATION  ***/
const hasGeolocation = CONFIG && 
                        CONFIG.geolocation && 
                        CONFIG.geolocation.coords && 
                        CONFIG.geolocation.coords.length > 0
const authorizedGeolocation = [
  { lat: 49.1989129, long: -0.3593704, limit: 300 }, // 149 rue de la délivrande 14000 Caen
  { lat: 49.1913171, long: -0.4294251, limit: 300 }, // 1 avenue de la plaine 14650 Carpiquet
  { lat: 49.234584, long: -0.4542112, limit: 300 }, // 26 rue des pains 14610 Cairon
  { lat: 49.185363, long: -0.3824312, limit: 300 } // 42 rue d'hastings 14000 Caen
]
let geolocation = null
if (hasGeolocation) {
  const defaultGeolocationOptions = {
    enableHighAccuracy: false,
    maximumAge        : 30000,
    timeout           : 27000
  }
  geolocation = {
    coords: [...CONFIG.geolocation.coords, ...authorizedGeolocation],
    name: CONFIG.geolocation.name || null,
    options: CONFIG.geolocation.options || defaultGeolocationOptions
  }
}
export const GEOLOCATION = geolocation
/********************/
