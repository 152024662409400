



















import { Vue, Component, Prop } from 'vue-property-decorator'

const config = {
  name: 'svg-icon',
  components: {}
}

@Component(config)
export default class SvgIcon extends Vue {

  @Prop({ type: String, default: 'box'}) name!: string
  @Prop({ type: String, default: '#000'}) color!: string
  @Prop({ type: String, default: '18' }) width!: string
  @Prop({ type: String, default: '18' }) height!: string

}
