// getters
export const GET_STATUS = 'game/GET_STATUS'
export const GET_QUESTIONS = 'game/GET_QUESTIONS'
export const IS_WINNED = 'game/IS_WINNED'
// actions
export const SET_GAME_COMPLETED = 'game/SET_GAME_COMPLETED'
export const SET_GAME_USER_ANSWERS = 'game/SET_GAME_USER_ANSWERS'
export const SET_GAME_RESETED = 'game/SET_GAME_RESETED'
// mutations
export const COMPLETE_GAME = 'game/COMPLETE_GAME'
export const CHANGE_USER_ANSWERS = 'game/CHANGE_USER_ANSWERS'
export const RESET_GAME = 'game/RESET_GAME'
