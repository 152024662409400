
import { Component, Vue, Prop } from 'vue-property-decorator'
import format from 'date-fns/format'

import { CPersonCard } from '@/@next/components/person-card'
import { CLazyLoadingImage } from '@/components/images'
import { CLoader } from '@/components/loader'
import CModal from '@/components/modal'
import { CImage } from '@/@next/components/image'

const config = {
  name: 'c-content-person',
  components: {
    CPersonCard,
    CLazyLoadingImage,
    CLoader,
    CImage,
    ...CModal
  }
}

@Component(config)
export default class CContentPerson extends Vue {

  @Prop({ type: Object, required: true }) content!: any
  @Prop({ type: String, required: false, default: '' }) contextId!: string

  showPersonModal: boolean = false

  get person () {
    return this.content.data.person
  }

  get name () {
    if (!this.person.i18n.name) return null
    return this.person.i18n.name.value
  }

  get bio () {
    if (!this.person.i18n.bio) return null
    return this.person.i18n.bio.value
  }

  get bornAt () {
    if (!this.person.bornAt) return null
    return format(new Date(this.person.bornAt), 'DD/MM/YYYY')
  }

  get diedAt () {
    if (!this.person.diedAt) return null
    return format(new Date(this.person.diedAt), 'DD/MM/YYYY')
  }

  get datesString () {
    if (this.bornAt && this.diedAt) return `${this.bornAt} — ${this.diedAt}`
    if (this.bornAt && !this.diedAt) return `${this.bornAt}`
    if (!this.bornAt && this.diedAt) return ` * - ${this.diedAt}`
    if (!this.bornAt && !this.diedAt) return ``
  }

  get imageUrl () {
    if (!this.person.image) return null
    return this.person.image.value.file.url
  }
}
