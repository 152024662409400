
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Nullable } from '@/shared'
import { Action, Getter } from 'vuex-class'

import * as BOT from '@/store/bot/types'

import { CLazyLoadingImage } from '@/components/images'
import { CLoader } from '@/components/loader'
import CBotPoiCard from '@/components/bot-conversation/BotPoiCard.vue'

@Component({
  name: 'c-bot-conversation',
  components: {
    CBotPoiCard,
    CLazyLoadingImage,
    CLoader
  }
})
export default class CBotConversation extends Vue {

  contentDOMNode: Nullable<HTMLElement> = null

  @Getter(BOT.GET_MESSAGES)
  conversation!: any[]
  @Getter(BOT.IS_WRITING_MESSAGE)
  botIsWriting!: boolean
  @Getter(BOT.GET_USER_CHOICES)
  userChoices!: Nullable<any>
  @Action(BOT.RECEIVE_USER_CHOICE)
  setUserChoice!: (choice: any[]) => void

  // when the store current language change
  @Watch('botIsWriting')
  async onBotIsWritingChange () {
    this.updateScroll()
    setTimeout(() => { this.updateScroll() }, 10)
  }

  setMessageClass (message: any) {
    return message.isBotMessage ? '--Recipient' : '--Sender'
  }

  selectUserChoice (choice: any[]) {
    this.setUserChoice(choice)
  }

  updateScroll () {
    this.contentDOMNode = this.$refs.content as HTMLElement
    this.contentDOMNode.scrollTop = this.contentDOMNode.scrollHeight
  }

  mounted () {
    this.updateScroll()
  }
}
