export const messages = {
  fr: {
    pageName: 'Accueil',
    artwork: 'point d\'intérêt | points d\'intérêt',
    follow: 'Suivez nos actualités !',
    settingsLinkLabel: {
      title: 'Paramètres',
      subtitle: 'Langues & +'
    },
    languageLinkLabel: {
      title: 'Langue'
    },
    planLinkLabel: {
      title: 'Plan Audioguide',
      subtitle: ''
    },
    jurassicQuest: {
      title: 'Jurassic Quest',
      subtitle: '...'
    },
    surveyLinkLabel: {
      title: 'Votre avis',
      subtitle: ''
    },
    aboutLinkLabel: {
      title: 'À propos',
      subtitle: 'Crédits'
    },
    searchLinkLabel: {
      title: 'Rechercher',
      subtitle: 'Points d\'intérêt, collection & +'
    },
    newsletterSection: 'Newsletter',
    newsletterLabel: 'Veuillez saisir votre adresse email',
    newsletterPlaceholder: 'moi@example.com',
    newsletterSend: 'S\'abonner',
    newsletterConfirmation: 'Merci'
  },
  en: {
    pageName: 'Home',
    artwork: 'point of interest | points of interest',
    follow: 'Stay updated!',
    settingsLinkLabel: {
      title: 'Settings',
      subtitle: 'Languages'
    },
    languageLinkLabel: {
      title: 'Language'
    },
    planLinkLabel: {
      title: 'Audioguide Map',
      subtitle: ''
    },
    jurassicQuest: {
      title: 'Jurassic Quest',
      subtitle: '...'
    },
    surveyLinkLabel: {
      title: 'Survey',
      subtitle: ''
    },
    aboutLinkLabel: {
      title: 'About',
      subtitle: 'Credits'
    },
    searchLinkLabel: {
      title: 'Search',
      subtitle: 'Points of interest, collection & more'
    },
    newsletterSection: 'Newsletter',
    newsletterLabel: 'Please enter your email address',
    newsletterPlaceholder: 'me@example.com',
    newsletterSend: 'Subscribe',
    newsletterConfirmation: 'Thank you'
  },
  us: {
    pageName: 'Home',
    artwork: 'point of interest | points of interest',
    follow: 'Stay updated!',
    settingsLinkLabel: {
      title: 'Settings',
      subtitle: 'Languages'
    },
    languageLinkLabel: {
      title: 'Language'
    },
    aboutLinkLabel: {
      title: 'About',
      subtitle: 'Credits'
    },
    searchLinkLabel: {
      title: 'Search',
      subtitle: 'Points of interest, collection & more'
    },
    newsletterSection: 'Newsletter',
    newsletterLabel: 'Please enter your email address',
    newsletterPlaceholder: 'me@example.com',
    newsletterSend: 'Subscribe',
    newsletterConfirmation: 'Thank you'
  },
  da: {
    pageName: 'Home',
    artwork: 'point of interest | points of interest',
    follow: 'Stay updated!',
    settingsLinkLabel: {
      title: 'Settings',
      subtitle: 'Languages'
    },
    languageLinkLabel: {
      title: 'Sprog'
    },
    aboutLinkLabel: {
      title: 'About',
      subtitle: 'Credits'
    },
    searchLinkLabel: {
      title: 'Search',
      subtitle: 'Points of interest, collection & more'
    },
    newsletterSection: 'Newsletter',
    newsletterLabel: 'Please enter your email address',
    newsletterPlaceholder: 'me@example.com',
    newsletterSend: 'Subscribe',
    newsletterConfirmation: 'Thank you'
  },
  es: {
    pageName: 'Home',
    artwork: 'point of interest | points of interest',
    follow: 'Stay updated!',
    settingsLinkLabel: {
      title: 'Settings',
      subtitle: 'Languages'
    },
    languageLinkLabel: {
      title: 'Idioma'
    },
    aboutLinkLabel: {
      title: 'About',
      subtitle: 'Credits'
    },
    searchLinkLabel: {
      title: 'Search',
      subtitle: 'Points of interest, collection & more'
    },
    newsletterSection: 'Newsletter',
    newsletterLabel: 'Please enter your email address',
    newsletterPlaceholder: 'me@example.com',
    newsletterSend: 'Subscribe',
    newsletterConfirmation: 'Thank you'
  },
  nl: {
    pageName: 'Home',
    artwork: 'point of interest | points of interest',
    follow: 'Stay updated!',
    settingsLinkLabel: {
      title: 'Settings',
      subtitle: 'Languages'
    },
    languageLinkLabel: {
      title: 'Taal'
    },
    aboutLinkLabel: {
      title: 'About',
      subtitle: 'Credits'
    },
    searchLinkLabel: {
      title: 'Search',
      subtitle: 'Points of interest, collection & more'
    },
    planLinkLabel: {
      title: 'Audioguide Map',
      subtitle: ''
    },
    surveyLinkLabel: {
      title: 'Survey',
      subtitle: ''
    },
    newsletterSection: 'Newsletter',
    newsletterLabel: 'Please enter your email address',
    newsletterPlaceholder: 'me@example.com',
    newsletterSend: 'Subscribe',
    newsletterConfirmation: 'Thank you'
  },
  de: {
    pageName: 'Home',
    artwork: 'point of interest | points of interest',
    follow: 'Stay updated!',
    settingsLinkLabel: {
      title: 'Settings',
      subtitle: 'Languages'
    },
    languageLinkLabel: {
      title: 'Sprache'
    },
    aboutLinkLabel: {
      title: 'About',
      subtitle: 'Credits'
    },
    searchLinkLabel: {
      title: 'Search',
      subtitle: 'Points of interest, collection & more'
    },
    planLinkLabel: {
      title: 'Audioguide Map',
      subtitle: ''
    },
    surveyLinkLabel: {
      title: 'Survey',
      subtitle: ''
    },
    newsletterSection: 'Newsletter',
    newsletterLabel: 'Please enter your email address',
    newsletterPlaceholder: 'me@example.com',
    newsletterSend: 'Subscribe',
    newsletterConfirmation: 'Thank you'
  },
  it: {
    pageName: 'Home',
    artwork: 'point of interest | points of interest',
    follow: 'Stay updated!',
    settingsLinkLabel: {
      title: 'Settings',
      subtitle: 'Languages'
    },
    languageLinkLabel: {
      title: 'Lingua'
    },
    aboutLinkLabel: {
      title: 'About',
      subtitle: 'Credits'
    },
    searchLinkLabel: {
      title: 'Search',
      subtitle: 'Points of interest, collection & more'
    },
    newsletterSection: 'Newsletter',
    newsletterLabel: 'Please enter your email address',
    newsletterPlaceholder: 'me@example.com',
    newsletterSend: 'Subscribe',
    newsletterConfirmation: 'Thank you'
  },
  pt: {
    pageName: 'Home',
    artwork: 'point of interest | points of interest',
    follow: 'Stay updated!',
    settingsLinkLabel: {
      title: 'Settings',
      subtitle: 'Languages'
    },
    languageLinkLabel: {
      title: 'Língua'
    },
    aboutLinkLabel: {
      title: 'About',
      subtitle: 'Credits'
    },
    searchLinkLabel: {
      title: 'Search',
      subtitle: 'Points of interest, collection & more'
    },
    newsletterSection: 'Newsletter',
    newsletterLabel: 'Please enter your email address',
    newsletterPlaceholder: 'me@example.com',
    newsletterSend: 'Subscribe',
    newsletterConfirmation: 'Thank you'
  },
  zh: {
    pageName: 'Home',
    artwork: 'point of interest | points of interest',
    follow: 'Stay updated!',
    settingsLinkLabel: {
      title: 'Settings',
      subtitle: 'Languages'
    },
    languageLinkLabel: {
      title: '语言'
    },
    aboutLinkLabel: {
      title: 'About',
      subtitle: 'Credits'
    },
    searchLinkLabel: {
      title: 'Search',
      subtitle: 'Points of interest, collection & more'
    },
    newsletterSection: 'Newsletter',
    newsletterLabel: 'Please enter your email address',
    newsletterPlaceholder: 'me@example.com',
    newsletterSend: 'Subscribe',
    newsletterConfirmation: 'Thank you'
  }
}
