
import { Vue, Component } from 'vue-property-decorator'

const config = {
  name: 'c-topbar-title'
}

@Component(config)
export default class TopbarTitle extends Vue {

  enabled: boolean = true

  activated () {
    this.enabled = true
  }
  deactivated () {
    this.enabled = false
  }
}
