import { Mixins, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'

import { Realm } from '@/@types/graphql'
import { LanguagesMixin } from '@/@next/mixins'
import { MediaImageFragment } from './fragments'

@Component({ name: 'realms-service' })
export default class Realms extends Mixins(LanguagesMixin) {

  async GET_ALL_REALMS (isEnabled: boolean = true): Promise<Realm[]> {
    const variables = { isEnabled }
    const query = gql`
        ${ MediaImageFragment }
        query realms {
          realms {
            id
            languages
            i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                title { value { text html } language  }
                abstract { value { text html } language  }
                description { value { text html } language  }
            }
            slug
            name
            shortCode
            collections {
                itemsCount
                totalCount
                sort { field order}
                items {
                  id
                  slug
                }
            }
            pointsOfInterest {
                itemsCount
                totalCount
                sort { field order}
                items {
                    id
                    slug
                    i18n (languages: ${ JSON.stringify(this._allLanguageCodes)}) {
                      title { value { text html } language  }
                      abstract { value { text html } language  }
                      description { value { text html } language  }
                    }
                    image { ...MediaImageFragment }
                }
            }
          }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.realms
  }

}
