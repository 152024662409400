








import { Vue, Component } from 'vue-property-decorator'

const config = {
  name: 'icon-check',
  components: {}
}

@Component(config)
export default class IconCheck extends Vue {}
