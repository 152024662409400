// getters
export const GET_ALL = 'languages/GET_ALL'
export const GET_ALL_ISO = 'languages/GET_ALL_ISO'
export const GET_CURRENT = 'languages/GET_CURRENT'
export const GET_DEFAULT = 'languages/GET_DEFAULT'
export const GET_TARGET = 'languages/GET_TARGET'
export const GET_TRANSLATABLE_TARGETS = 'languages/GET_TRANSLATABLE_TARGETS'
export const GET_AVAILABLE_TARGETS = 'languages/GET_AVAILABLE_TARGETS'
export const GET_ACCESSIBILITY = 'languages/GET_ACCESSIBILITY'
// mutations
export const SET_ALL = 'languages/SET_ALL'
export const SET_CURRENT = 'languages/SET_CURRENT'
export const SET_TARGET = 'languages/SET_TARGET'
export const SET_DEFAULT = 'languages/SET_DEFAULT'
export const SET_TRANSLATABLE_TARGETS = 'languages/SET_TRANSLATABLE_TARGETS'
export const SET_ACCESSIBILITY = 'languages/SET_ACCESSIBILITY'
