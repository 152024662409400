import CRadio from './Radio.vue'
import CRadioButton from './RadioButton.vue'
import CRadioButtonIcon from './RadioButtonIcon.vue'
import CRadioButtonLabel from './RadioButtonLabel.vue'

export {
  CRadio,
  CRadioButton,
  CRadioButtonIcon,
  CRadioButtonLabel
}

export default {
  CRadio,
  CRadioButton,
  CRadioButtonIcon,
  CRadioButtonLabel
}
