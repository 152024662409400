
import { Vue, Component, Prop } from 'vue-property-decorator'

const config = {
  name: 'c-button'
}

@Component(config)
export default class CButton extends Vue {

  @Prop({ type: Boolean, default: false }) primary!: boolean
  @Prop({ type: Boolean, default: false }) textAlignLeft!: boolean
  @Prop({ type: Boolean, default: false }) textAlignRight!: boolean
  @Prop({ type: Boolean, default: false }) text!: boolean

  get CSSClasses () {
    return {
      '--Primary': this.primary,
      '--Text': this.text,
      '--TextAlignLeft': this.textAlignLeft,
      '--TextAlignRight': this.textAlignRight,
    }
  }
}
