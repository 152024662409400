
import {Vue, Component, Prop} from 'vue-property-decorator'
import CustomWrapper from '@/components/CustomWrapper.vue'

@Component({
  name: 'c-simple-card-title',
  components: { CustomWrapper }
})
export default class CSimpleCardTitle extends Vue {
  @Prop({ type: String, default: 'h2' }) tag!: string
}
