
import { Component, Mixins, Prop } from 'vue-property-decorator'

import CustomTagMixin from '@/@next/mixins/CustomTag'

const config = {
  name: 'c-collection-card-small'
}

@Component(config)
export default class CollectionCardSmall extends Mixins(CustomTagMixin) {

  @Prop({ type: [String, Object], required: false }) to!: any

}
