
import {Vue, Component, Watch, Mixins} from 'vue-property-decorator'
import { Carousel, Slide } from 'vue-carousel'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

import 'swiper/dist/css/swiper.css'

import { PointsOfInterestService } from '@/@next/services'

import { CImage } from '@/@next/components/image'
import { CLoader } from '@/components/loader'

import * as UI from '@/event-bus'

import IMAGES from '@/graphql/medias/images'
import POIS from '@/graphql/pois'

import { Nullable } from '@/shared'
import Translatable from '@/mixins/Translatable'

const config = {
  name: 'v-image-gallery',
  components: {
    'carousel': swiper,
    'carousel-item': swiperSlide,
    CImage,
    CLoader
  }
}

@Component(config)
export default class VImageGallery extends Mixins!(PointsOfInterestService, Translatable) {

  pointOfInterestIsLoading = 0
  pointOfInterestFetchFailed = false
  pointOfInterest: any = null

  containerDOMNode: Nullable<HTMLElement> = null
  imageSummaryDOMNode: Nullable<HTMLElement> = null

  scale: number = 1
  lastScale: number = 0
  maxScale: number = 5
  animate: boolean = false
  translateX: number = 0
  lastTranslateX: number = 0
  translateY: number = 0
  lastTranslateY: number = 0
  dX: number = 0
  dY: number = 0
  enableTransition: boolean = false

  carouselGestureEnabled: boolean = true
  currentImage: Nullable<string> = null
  currentIndex: number = 0

  carouselOptions: any = {
    initialSlide: 0,
    setWrapperSize: true,
    grabCursor: true,
    noSwiping: false,
    slidesPerView: 1,
    preloadImages: false,
    updateOnImagesReady: false
  }

  showHeading = true
  contentTextExpanded = false
  displayReadMore: boolean = false

  gallery: any = []

  async created () {
    if (!this.$route.params.uuid) return
    if (!this.$route.query.context) return
    if (!this.$route.query.context_id) return
    try {
      const contents = await this.getGalleryByContext(this.$route.query.context as string)
      if (!contents) return
      // @ts-ignore
      this.gallery = contents.contents.find((item) => item.id === this.$route.params.uuid)
    } catch (e) {
      console.error('Fail to fetch gallery', e)
    }
  }

  get items () {
    if (!this.gallery || !this.gallery.data || !this.gallery.data.i18n.images.length) return null
    return this.gallery.data.i18n.images
  }

  async getGalleryByContext (context: string) {
    switch (context) {
      case 'poi':
        return await this.GET_SINGLE_POI(this.$route.query.context_id as string)
    }
  }

  async mounted () {
    this.containerDOMNode = this.$refs.container as HTMLElement
    this.initCarousel()
    this.imageSummaryDOMNode = this.$refs.summary as HTMLElement
    this.initSummaryAccordion()
    this.$nextTick(() => {
      this.initCarousel()
      this.imageSummaryDOMNode = this.$refs.summary as HTMLElement
      this.initSummaryAccordion()
    })
  }

  initSummaryAccordion () {
    if (this.imageSummaryDOMNode) {
      const wrapper = this.imageSummaryDOMNode.firstElementChild
      if (wrapper) {
        const firstChild = wrapper.firstElementChild
        const childCount = wrapper.childElementCount
        if (childCount > 1) {
          this.displayReadMore = true
        }
      }
    } else {
      this.imageSummaryDOMNode = this.$refs.summary as HTMLElement
    }
  }

  initCarousel () {
    if (!this.$refs.carousel ) return
    if (!this.items) this.currentIndex = 0
    // @ts-ignore
    const carousel = this.$refs.carousel.swiper
    carousel.slideTo(this.getSlideIndexById(this.$route.query.selected))
    this.currentIndex = carousel.activeIndex
    this.currentImage = this.items[carousel.activeIndex].image.value.id
    carousel.on('slideChange', () => {
      this.currentIndex = carousel.activeIndex
    })
  }

  onSlideChange () {
    if (this.carousel) {
      this.currentIndex = this.carousel.activeIndex
    }
  }

  get headingCSSClasses () {
    return {
      'is-Hidden': !this.showHeading,
      'is-Expanded': this.contentTextExpanded
    }
  }

  get carousel () {
    if (this.$refs.carousel) {
      // @ts-ignore
      return this.$refs.carousel.swiper
    }
  }

  get activeSlide () {
    return this.currentIndex
  }

  get activeItem () {
    // @ts-ignore
    const item = this.items[this.currentIndex]
    if (item) {
      return item
    }
  }

  get imgInlineStyles () {
    return {
      'transform': `scale(${this.scale}) translateX(${this.translateX}px) translateY(${this.translateY}px)`,
      'filter': this.contentTextExpanded ? 'blur(12px)' : '',
      // 'transition': this.animate ? 'all 250ms ease' : ''
    }
  }

  @Watch('scale')
  onScaleChange () {
    if (this.carousel) {
      if (this.scale > 1) {
        this.carousel.detachEvents()
        UI.EventBus.$emit(UI.HIDE_DEFAULT_TOPBAR)
        this.showHeading = false
      } else {
        this.carousel.attachEvents()
        UI.EventBus.$emit(UI.SHOW_DEFAULT_TOPBAR)
        this.showHeading = true
      }
    }
  }

  @Watch('currentIndex')
  onCurrentPageChange (val: number) {
    if (!this.items) return
    this.currentImage = this.items[val].image.value.id
    this.carousel.slideTo(this.currentIndex)
  }

  getImgDOMNodes () {
    if (!this.$refs || !this.items)
    return this.items.map((item: any) => {
      return this.$refs[`img-${item.image.value.id}`]
    })
  }

  getImgDOMNode (id: string): any {
    return this.$refs[`img-${id}`] as any
  }

  onSingleTap () {
    if (this.scale === 1) {
      UI.EventBus.$emit(UI.TOGGLE_DEFAULT_TOPBAR)
      this.showHeading = !this.showHeading
    }
  }

  getSlideIndexById (id: any) {
    if (!this.items) return 0
    const index = this.items.findIndex((item: any) => item.image && item.image.value.id === id)
    if (index && index >= -1) return index
  }

  onDoubleTap () {
    this.animate = true
    this.translateX = 0
    this.translateY = 0
    switch (true) {
      case this.scale > 1:
        this.scale = 1
        break
      case this.scale === 1:
        this.scale = 2
        break
    }
    setTimeout(() => {
      this.animate = false
    }, 250)
  }

  onPinchStart () {
    this.lastScale = this.scale
  }

  onPinchMove (e: any) {
    this.scale = this.lastScale * e.scale
  }

  onPinchEnd (e: any) {
    if (this.scale < 1) {
      this.animate = true
      this.scale = 1
      this.translateX = 0
      this.translateY = 0
      setTimeout(() => {
        this.animate = false
      }, 250)
    }
  }

  onPanStart () {
    this.lastTranslateX = this.translateX
    this.lastTranslateY = this.translateY
  }

  onPanMove (e: any) {
    if (this.scale > 1) {
      if (this.containerDOMNode && this.currentImage && this.getImgDOMNode(this.currentImage)) {
        // we get the container and image size
        const containerSizes = {
          width: Math.round(this.containerDOMNode.offsetWidth),
          height: Math.round(this.containerDOMNode.offsetHeight)
        }
        const imageSizes = {
          width: Math.round(this.getImgDOMNode(this.currentImage)[0].offsetWidth * this.scale),
          height: Math.round(this.getImgDOMNode(this.currentImage)[0].offsetHeight * this.scale)
        }

        // move the image only if width or height overfill the container
        this.translateX = this.lastTranslateX + (e.deltaX / this.scale)
        if (imageSizes.height >= containerSizes.height) {
          this.translateY = this.lastTranslateY + (e.deltaY / this.scale)
        }

        // set the distance to limit the move
        this.dX = ((imageSizes.width - containerSizes.width) / 2) / this.scale
        this.dY = ((imageSizes.height - containerSizes.height) / 2) / this.scale
      }
    }
  }

  onPanEnd () {
    this.animate = true
    // Left and right
    if (this.dX > 0) {
      if (this.translateX > this.dX) {
        this.translateX = this.dX
      }
      if (this.translateX < (-this.dX)) {
        this.translateX = (-this.dX)
      }
    }
    // Up and down
    if (this.dY > 0) {
      if (this.translateY > this.dY) {
        this.translateY = this.dY
      }
      if (this.translateY < (-this.dY)) {
        this.translateY = (-this.dY)
      }
    }
    setTimeout(() => {
      this.animate = false
    }, 250)
  }

}
