


























import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { CModal } from '@/components/modal'
import { messages } from '@/@next/i18n'
import * as UI from '@/event-bus'
import { CInput } from '@/components/form-controls/input'
import { CButton, CButtonLabel, CButtonIcon } from '@/components/form-controls/button'
import { AuthorizationMixin } from '@/@next/mixins'
import * as AUTHORIZATION from '@/@next/store/authorization'
import { Getter } from 'vuex-class'
import { Maybe } from '@/@types/graphql'
import { TicketStatus } from '@/@next/store/authorization'
import { CLoader } from '@/components/loader'
import format from 'date-fns/format'

const config = {
  name: 'c-ticket-input',
  i18n: { messages },
  components: {
    CModal,
    CInput,
    CButton,
    CLoader
  }
}

@Component(config)
export default class CTicketInput extends Mixins(AuthorizationMixin) {

  // @Prop({ type: String, required: true}) lockId!: string
  @Getter(AUTHORIZATION.GET_TICKET_STATUS)
  getTicketStatus!: TicketStatus
  @Getter(AUTHORIZATION.GET_TICKET_DATA)
  getTicketData!: any
  
  messages = messages
  // ticket: string | null = null

  @Watch('getTicketStatus')
  onTicketStatusChanged () {
    if (this.getTicketStatus === TicketStatus.valid) {
      setTimeout(() => this.closeTicketInput(), 1000)
    }
  }

  @Watch('ticket')
  onTicketUpdated () {
    // this.setTicketStatus(TicketStatus.none)
  }
  
  mounted () {
    console.log('Ticket input', this.getCurrentLock, this.$i18n.locale)
    this.ticket = this.getTicketCookie()
    console.log('Ticket', this.ticket)
  }

  buy () {
    if (!this.buyAction) return
    switch (this.buyAction.__typename) {
      case 'externalRedirection': return window.open(this.buyAction.url)
      // case 'internalRedirection': return window.open(this.$router.push(this.buyAction.route))
      default: return
    }
  }

  get buyAction () {
    return this.lockData.buyAction
  }

  statusClass () {
    return this.ticketStatus === TicketStatus.valid ? 'valid' : 'invalid'
  }

  get explanationBeforeInput () {
    if (this.$i18n.locale === 'en-US') {
      return this.messages.en.enterTiketNumber
    }
    return this.$t('enterTiketNumber')
    // return this.lockData.explanationBeforeInput?.value.html || this.$t('enterTiketNumber')
  }

  get validateButton () {
    if (this.$i18n.locale === 'en-US') {
      return this.lockData.validateButton?.value.html || this.messages.en.validate || null
    }
    return this.lockData.validateButton?.value.html || this.$t('validate')
  }

  get buyButton () {
    if (this.$i18n.locale === 'en-US') {
      return this.lockData.buyButton?.value.html || this.messages.en.buyTicket || null
    }
    return this.lockData.buyButton?.value.html || this.$t('buyTicket')
  }
  
  get lockData () {
    
    const defaultData = {
      explanationBeforeInput: { language: 'fr', value: { text: this.$t('enterTiketNumber'), html: this.$t('enterTiketNumber') } },
      validateButton: { language: 'fr', value: { text: this.$t('validate'), html: this.$t('validate') } },
      // buyButton: { language: 'fr', value: { text: this.$t('buyTicket'), html: this.$t('buyTicket') } },
      // buyAction: {
      //   url: 'https://billetterie.memorial-caen.fr/',
      //   __typename: 'externalRedirection'
      // }
    }
    return this.getCurrentLock?.data || defaultData
    // return this.defaultTicketData // this.getCurrentLock?.i18n.data || this.defaultTicketData
  }

  get ticketStatus () {
    console.log('ticketStatus', this.getTicketStatus, this.$i18n)
    if (!this.getTicketStatus) return null
    const customTranslations = this.lockData?.ticketStatus?.find((el: any) => el.status === this.getTicketStatus)
    if (this.$i18n.locale === 'en-US') {
      return customTranslations?.value.html || this.messages.en.ticketStatus[this.getTicketStatus] || null
    }
    return customTranslations?.value.html || this.$t(`ticketStatus.${this.getTicketStatus}`) || null
  }

  get ticketCharacterLimits () {
    return {
      min: this.lockData?.ticketCharacterLimits?.min || 0,
      max: this.lockData?.ticketCharacterLimits?.max || 255
    }
  }

  get disableValidateButton () {
    return !this.ticket || 
          this.ticket.length < this.ticketCharacterLimits.min ||
          this.ticket.length > this.ticketCharacterLimits.max
  }

  get isLoading () {
    return this.getTicketStatus === TicketStatus.pending
  }

  async send () {
    console.log('send ticket button pushed', this.ticket)
    if (!this.ticket) return
    this.setTicketStatus(TicketStatus.pending)
    if (this.$route.name === 'ticket') {
      await this.sendTicket(this.ticket)
    } else {
      this.$emit('ticket', this.ticket)
    }
  }

  closeTicketInput () {
    if (this.$route.name === 'ticket') return this.$router.go(-1)
    this.setShowTicketInput(false)
  }

  activated () {
    // UI.EventBus.$emit(UI.HIDE_DEFAULT_TOPBAR)
    // UI.EventBus.$emit(UI.DISABLE_DEFAULT_TOPBAR_TRANSPARENT)
    UI.EventBus.$emit(UI.HIDE_DEFAULT_BOT)
    this.ticket = this.getTicketCookie()
    // console.log('Ticket', this.ticket)
    // this.ticket = null
  }

  deactivated () {
    // UI.EventBus.$emit(UI.SHOW_DEFAULT_TOPBAR)
    UI.EventBus.$emit(UI.SHOW_DEFAULT_BOT)
  }

  get validity () {
    if (this.getTicketStatus !== TicketStatus.tooEarly && this.getTicketStatus !== TicketStatus.tooLate) {
      return null
    }
    const stopAt = this.getTicketData?.stopAt
    if (!stopAt) return null
    if (this.$i18n.locale === 'en-US' || this.$i18n.locale === 'en') {
      const date = format(new Date(stopAt), 'MM-DD-YYYY')
      return this.messages.en.ticketAvailableOn + date
    }
    const date = format(new Date(stopAt), 'DD-MM-YYYY')
    return this.$t('ticketAvailableOn') + date
  }
}
