import CPoiMiniCard from './PoiMiniCard.vue'
import CPoiMiniCardContent from './PoiMiniCardContent.vue'
import CPoiMiniCardTitle from './PoiMiniCardTitle.vue'
import CPoiMiniCardSummary from './PoiMiniCardSummary.vue'
import CPoiMiniCardThumbnail from './PoiMiniCardThumbnail.vue'

export {
  CPoiMiniCard,
  CPoiMiniCardContent,
  CPoiMiniCardTitle,
  CPoiMiniCardSummary,
  CPoiMiniCardThumbnail
}
export default {
  CPoiMiniCard,
  CPoiMiniCardContent,
  CPoiMiniCardTitle,
  CPoiMiniCardSummary,
  CPoiMiniCardThumbnail
}
