
import { Vue, Component, Watch, Mixins } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { Route } from 'vue-router'

import * as UI from '@/event-bus'
import * as BOT from '@/store/bot/types'
import * as LANGUAGES from '@/@next/store/languages/types'
import * as AUDIOGUIDE from '@/store/audioguide/types'
import * as SETTINGS from '@/store/settings/types'

import LDefaultMenu from './DefaultMenu.vue'
import LDefaultTopbar from './DefaultTopbar.vue'
import LDefaultContent from './DefaultContent.vue'
import LDefaultAudioguide from './DefaultAudioguide.vue'
import LDefaultQrScan from './DefaultQrScan.vue'

import CModal from '@/components/modal'
import CBotIcon from '@/components/bot-icon/BotIcon.vue'
import CBotConversation from '@/components/bot-conversation/BotConversation.vue'
import CMidiSolaire from '@/components/midiSolaire/midiSolaire.vue'

import { CLazyLoadingImage } from '@/components/images'
import { CLoader } from '@/components/loader'
import { LanguagesMixin, SettingsMixin } from '@/@next/mixins'
import { UsersService } from '@/@next/services'

const config = {
  name: 'l-default',
  components: {
    LDefaultMenu,
    LDefaultTopbar,
    LDefaultContent,
    LDefaultAudioguide,
    LDefaultQrScan,
    ...CModal,
    CBotConversation,
    CBotIcon,
    CMidiSolaire,
    CLazyLoadingImage,
    CLoader
  }
}

@Component(config)
export default class LDefault extends Mixins(SettingsMixin, LanguagesMixin, UsersService) {

  @Getter(LANGUAGES.GET_CURRENT)
  getCurrentLanguage!: string

  @Action(BOT.OPENING_BOT)
  openBot!: () => void
  @Action(BOT.CLOSING_BOT)
  closeBot!: () => void

  @Getter(BOT.IS_ENABLE)
  isBotEnable!: any
  @Getter(BOT.SETTINGS)
  botSettings!: any
  @Getter(SETTINGS.GET_ALL)
  allSettings!: any[]
  @Getter(AUDIOGUIDE.GET_STATUS)
  audioguideStatus!: boolean
  @Getter(BOT.IS_OPEN)
  botIsOpen!: boolean

  routerTransitionDirection = 'is-AnimatingToNext'
  isTopbarTransparent: boolean = false
  isTopbarHidden: boolean = false
  isTopbarHiddenOnScroll: boolean = false
  isMenuHidden: boolean = true
  isQrScanHidden: boolean = true
  isBotHidden: boolean = false

  selectorsToScale = ['html', 'body', 'div', 'span', 'applet', 'object', 'iframe', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']

  created () {
    UI.EventBus.$on(UI.ENABLE_DEFAULT_TOPBAR_TRANSPARENT, this.enableTopbarTransparent)
    UI.EventBus.$on(UI.DISABLE_DEFAULT_TOPBAR_TRANSPARENT, this.disableTopbarTransparent)
    UI.EventBus.$on(UI.SHOW_DEFAULT_TOPBAR, this.showTopbar)
    UI.EventBus.$on(UI.HIDE_DEFAULT_TOPBAR, this.hideTopbar)
    UI.EventBus.$on(UI.TOGGLE_DEFAULT_TOPBAR, this.toggleTopbar)
    UI.EventBus.$on(UI.SHOW_DEFAULT_TOPBAR_ON_SCROLL, this.showTopbarOnScroll)
    UI.EventBus.$on(UI.HIDE_DEFAULT_TOPBAR_ON_SCROLL, this.hideTopbarOnScroll)

    UI.EventBus.$on(UI.SHOW_DEFAULT_MENU, this.showMenu)
    UI.EventBus.$on(UI.HIDE_DEFAULT_MENU, this.hideMenu)
    UI.EventBus.$on(UI.TOGGLE_DEFAULT_MENU, this.toggleMenu)

    UI.EventBus.$on(UI.SHOW_DEFAULT_QR_SCAN, this.showQrScan)
    UI.EventBus.$on(UI.HIDE_DEFAULT_QR_SCAN, this.hideQrScan)

    UI.EventBus.$on(UI.SHOW_DEFAULT_BOT, this.showBot)
    UI.EventBus.$on(UI.HIDE_DEFAULT_BOT, this.hideBot)
  }

  beforeDestroy () {
    UI.EventBus.$off(UI.ENABLE_DEFAULT_TOPBAR_TRANSPARENT, this.enableTopbarTransparent)
    UI.EventBus.$off(UI.DISABLE_DEFAULT_TOPBAR_TRANSPARENT, this.disableTopbarTransparent)
    UI.EventBus.$off(UI.SHOW_DEFAULT_TOPBAR, this.showTopbar)
    UI.EventBus.$off(UI.HIDE_DEFAULT_TOPBAR, this.hideTopbar)
    UI.EventBus.$off(UI.TOGGLE_DEFAULT_TOPBAR, this.toggleTopbar)
    UI.EventBus.$off(UI.SHOW_DEFAULT_TOPBAR_ON_SCROLL, this.showTopbarOnScroll)
    UI.EventBus.$off(UI.HIDE_DEFAULT_TOPBAR_ON_SCROLL, this.hideTopbarOnScroll)

    UI.EventBus.$off(UI.SHOW_DEFAULT_MENU, this.showMenu)
    UI.EventBus.$off(UI.HIDE_DEFAULT_MENU, this.hideMenu)
    UI.EventBus.$off(UI.TOGGLE_DEFAULT_MENU, this.toggleMenu)

    UI.EventBus.$off(UI.SHOW_DEFAULT_QR_SCAN, this.showQrScan)
    UI.EventBus.$off(UI.HIDE_DEFAULT_QR_SCAN, this.hideQrScan)

    UI.EventBus.$off(UI.SHOW_DEFAULT_BOT, this.showBot)
    UI.EventBus.$off(UI.HIDE_DEFAULT_BOT, this.hideBot)
  }

  @Watch('$route')
  onRouteChange (to: Route, from: Route) {
    this.defineRouterTransitionDirection(to, from)
  }

  /*
  get isAudioGuideOpen () {
    return this.getAudioguideStatus === AudioguideStatus.enabled
  }
  */

  get isBotEnableIsCurrentLanguage () {
    // console.log('isBotEnableIsCurrentLanguage', this.isBotEnable, this.getCurrentLanguage)
    if (this.isBotEnable && this.getCurrentLanguage) {
      const isBotEnable = this.isBotEnable[this.getCurrentLanguage]
      return !!isBotEnable
    }
    return false
  }

  get botAvatar () {
    const avatarFile = this.botSettings.avatar[this.getCurrentLanguage]
    // console.log('Bot avatar ', avatarFile, `/img/bots/${avatarFile || 'colette.png'}`)
    return `/img/bots/${avatarFile || 'colette.png'}`
  }

  get botName () {
    const name = this.botSettings.name
    if (name && name[this.getCurrentLanguage]) {
      return name[this.getCurrentLanguage]
    }
    return 'assistant'
  }

  get botDescription () {
    const description = this.botSettings.description
    if (description && description[this.getCurrentLanguage]) {
      return description[this.getCurrentLanguage]
    }
    return ''
  }

  get computedData () {
    return {
      isTopbarTransparent: this.isTopbarTransparent,
      isTopbarHidden: this.isTopbarHidden,
      isTopbarHiddenOnScroll: this.isTopbarHiddenOnScroll,
      isMenuHidden: this.isMenuHidden,
      isQrScanHidden: this.isQrScanHidden,
      isBotHidden: this.isBotHidden
    }
  }

  get computedMenuCSSClasses () {
    return {
      'is-Visible': !this.isMenuHidden
    }
  }

  get computedBodyCSSClasses () {
    return {
      'is-Hidden': !this.isMenuHidden
      // 'greyScale': this.greyScale
    }
  }

  get computedTopbarCSSClasses () {
    return {
      'is-Hidden': this.isTopbarHidden,
      '--Transparent': this.isTopbarTransparent
    }
  }

  get computedQrScanCSSClasses () {
    return {
      'is-Visible': !this.isQrScanHidden
    }
  }

  get computedBotIconCSSClasses () {
    return {
      'is-Hidden': this.isBotHidden
    }
  }

  defineRouterTransitionDirection (to: Route, from: Route) {
    const toOrder = to.meta.order
    const fromOrder = from.meta.order
    if (to.name === from.name) {
      this.routerTransitionDirection = 'is-AnimatingToPrev'
    } else {
      this.routerTransitionDirection = fromOrder < toOrder ?
        'is-AnimatingToPrev' :
        'is-AnimatingToNext'
    }
  }

  enableTopbarTransparent () {
    this.isTopbarTransparent = true
  }

  disableTopbarTransparent () {
    this.isTopbarTransparent = false
  }

  showTopbar () {
    this.isTopbarHidden = false
  }

  hideTopbar () {
    this.isTopbarHidden = true
  }

  toggleTopbar () {
    this.isTopbarHidden = !this.isTopbarHidden
  }

  showTopbarOnScroll () {
    this.isTopbarHiddenOnScroll = false
  }

  hideTopbarOnScroll () {
    this.isTopbarHiddenOnScroll = true
  }

  showMenu () {
    this.isMenuHidden = false
  }

  hideMenu () {
    this.isMenuHidden = true
  }

  toggleMenu () {
    this.isMenuHidden = !this.isMenuHidden
  }

  showBot () {
    this.isBotHidden = false
  }

  hideBot () {
    this.isBotHidden = true
  }

  toggleBot () {
    this.isBotHidden = !this.isBotHidden
  }

  showQrScan () {
    this.isQrScanHidden = false
  }

  hideQrScan () {
    this.isQrScanHidden = true
  }

  get enableMidiSolaire () {
    // @ts-ignore
    if (this.CLIENT && this.CLIENT === 'paleospace') {
      return true
    }
    return false
  }

}
