
import { Vue, Component, Prop } from 'vue-property-decorator'
import CustomWrapper from '@/components/CustomWrapper.vue'

@Component({
  name: 'c-carousel',
  components: { CustomWrapper }
})
export default class CCarousel extends Vue {
  @Prop({ default: 'div'}) tag!: string
  @Prop({ default: 0 }) offset!: number

  get rootInlineStyles () {
    return {
      padding: this.offset ? `0 ${this.offset / 2}px` : null
    }
  }

}
