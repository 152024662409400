import imageFormat from '@/@next/helpers/imageFormat'
import addProtocol from '@/@next/helpers/addProtocol'
import { Maybe, ImageFormat, Scalars } from '@/@types/graphql'

// export default function srcSet (formats: any[], keys: string[] = ['xs', 'sm', 'md', 'lg']) {
//   if (!keys.length) return
//   const images = keys.map((key) => {
//     const image = imageFormat(formats, key)
//     return `${addProtocol(image!.url)} ${image!.width}w`
//   })
//   return images.join(', ')
// }

export default function srcSet (formats: Maybe<ImageFormat[]>, keys: Array<Scalars['String']> = []) {
  if (!formats || !formats.length) return
  const keyFilter = (f: ImageFormat) => !keys.length || keys.includes(f.name)
  const formatToString = (f: ImageFormat) =>  `${addProtocol(f.file.url)} ${f.file.width}w`
  return formats.filter(keyFilter).map(formatToString).join(', ')
}
