








import { Vue, Component } from 'vue-property-decorator'

const config = {
  name: 'icon-accessibility',
  components: {}
}

@Component(config)
export default class IconWordAccessibility extends Vue {}
