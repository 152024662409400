import CSimpleCard from './SimpleCard.vue'
import CSimpleCardIcon from './SimpleCardIcon.vue'
import CSimpleCardContent from './SimpleCardContent.vue'
import CSimpleCardTitle from './SimpleCardTitle.vue'
import CSimpleCardSummary from './SimpleCardSummary.vue'

export {
  CSimpleCard,
  CSimpleCardIcon,
  CSimpleCardContent,
  CSimpleCardTitle,
  CSimpleCardSummary
}
export default {
  CSimpleCard,
  CSimpleCardIcon,
  CSimpleCardContent,
  CSimpleCardTitle,
  CSimpleCardSummary
}
