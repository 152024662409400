import { CLIENT } from '@/constants'

export default (() => {
  console.log(`%c
                 .:-
                 ./:
     \`--         ./:         .-.
      -/:\`       ./:        -/:\`
       .//.       .\`      \`:/-
        \`--               .-.
.--\`          \`--::::::-.\`         \`.--
.-:/:-    \`://///////////:.    .:/:-.\`
   \`.\`   -/////////////////:\`  \`.\`
        -//////-\`   \`-//////:\`
       \`/////:\`    \`:////////-
       -/////-     ./////////:
       \`//////\`     \`.-://///-
        ://///:-\`   \`.://////\`
         :////////://///////.
          .://///////////:-
            \`.--:://::-.\`


` +
    `%cEt vos visites prennent vie !

` +
    `%cSi vous avez vraiment besoin de plus d'informations,
ce n'est pas ici que vous les trouverez ! 😉

Allez plutôt voir ici : https://wivi.io/?utm_source=console&client=${CLIENT}

—
` + `
%cVersion ${process.env.VUE_APP_VERSION}
` + `
%cMade with 💜 by Twelve Solutions
https://twelve.solutions/?utm_source=console&client=${CLIENT}

—
` + `
%cVous souhaitez créer le futur des musées ?
` + `
%cRejoignez notre équipe !
https://twelve.solutions/jobs/?utm_source=console&client=${CLIENT}
`

    ,
    'color: #6c3169; font-weight: bold;',
    'color: #6c3169; font-weight: normal; font-family: sans-serif; font-size:16px',
    'color: #aaa; font-weight: 100; font-family: sans-serif; font-size:12px',
    'color: #aaa; font-weight: bold; font-family: sans-serif; font-size:12px',
    'color: #aaa; font-weight: 100; font-family: sans-serif; font-size:12px',
    'color: #aaa; font-weight: bold; font-family: sans-serif; font-size:12px',
    'color: #aaa; font-weight: 100; font-family: sans-serif; font-size:12px'
  )
})()
