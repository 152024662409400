
import { Component, Vue, Prop } from 'vue-property-decorator'
import CAudioCard from '@/components/cards/audio-card'
import { CImage } from '@/@next/components/image'
import { CLoader } from '@/components/loader'

const config = {
  name: 'c-content-audio',
  components: {
    ...CAudioCard,
    CImage,
    CLoader
  }
}

@Component(config)
export default class CContentAudio extends Vue {
  @Prop({ type: Object, required: true }) content!: any

  get audio () {
    if (!this.content) return null
    if (!this.content.data.i18n.audio) return null
    return this.content.data.i18n.audio.value
  }

  get title () {
    if (!this.content) return null
    return this.content.data.i18n.title.value
  }

  get description () {
    if (!this.content) return null
    return this.content.data.i18n.description.value
  }

  get image () {
    if (!this.content) return null
    return this.content.data.i18n.audio.value.image
  }

}
