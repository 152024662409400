import Vue from 'vue'
import { MutationTree } from 'vuex'

import * as HISTORY from './types'
import { HistoryState } from '@/store/history'
import {HistoryEntry} from '@/shared/history'

export const mutations: MutationTree<HistoryState> =  {

  [HISTORY.ADD_ENTRIE] (state, entry: HistoryEntry) {
    const match = state.history.findIndex((storeEntry: HistoryEntry) => storeEntry.route.path === entry.route.path)
    if (match === -1) {
      state.history = [entry, ...state.history]
    } else {
      Vue.set(state.history, match, entry)
    }
  }
}
