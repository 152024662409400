
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const config = {
  name: 'c-AuthorModalHeader'
}

@Component(config)
export default class CAuthorModalHeader extends Vue {
}
