// actions
export const FETCH = 'bot/FETCH'
export const OPENING_BOT = 'bot/OPENING_BOT'
export const CLOSING_BOT = 'bot/CLOSING_BOT'
export const READ_BUFFER = 'bot/READ_BUFFER'
export const DISPLAY_NEW_MESSAGE_FROM_BUFFER = 'bot/DISPLAY_NEW_MESSAGE_FROM_BUFFER'
export const ASK_USER_CHOICE = 'bot/ASK_USER_CHOICE'
export const RECEIVE_USER_CHOICE = 'bot/RECEIVE_USER_CHOICE'
export const ENABLE_BOT_NOTIFICATION = 'bot/ENABLE_BOT_NOTIFICATION'
export const ADD_POI_CONTENT = 'bot/ADD_POI_CONTENT'
export const DISPLAY_ADDITIONAL_CONTENT = 'bot/DISPLAY_ADDITIONAL_CONTENT'
// getters
export const IS_ENABLE = 'bot/IS_ENABLE'
export const SETTINGS = 'bot/SETTINGS'
export const GET_MESSAGES = 'bot/GET_MESSAGES'
export const GET_UNREAD_MESSAGE_COUNT = 'bot/GET_UNREAD_MESSAGE_COUNT'
export const IS_WRITING_MESSAGE = 'bot/IS_WRITING_MESSAGE'
export const GET_USER_CHOICES = 'bot/GET_USER_CHOICES'
// Mutations
export const IS_OPEN = 'bot/IS_OPEN'
export const SET_BOT_STATE = 'bot/SET_BOT_STATE'
export const SET_ALL_MESSAGES_ARE_READ = 'bot/SET_ALL_MESSAGES_ARE_READ'
export const ADD_MESSAGES_TO_BUFFER = 'bot/ADD_MESSAGES_TO_BUFFER'
export const ADD_NEW_MESSAGE = 'bot/ADD_NEW_MESSAGE'
export const REMOVE_FIRST_BUFFER_MESSAGE = 'bot/REMOVE_FIRST_BUFFER_MESSAGE'
export const CLEAR_BUFFER = 'bot/CLEAR_BUFFER'
export const SET_USER_CHOICES = 'bot/SET_USER_CHOICES'
export const SET_CURRENT_POI_CONTENT = 'bot/SET_CURRENT_POI_CONTENT'
