import CCollectionCard from './CollectionCard.vue'
import CCollectionCardContent from './CollectionCardContent.vue'
import CCollectionCardSummary from './CollectionCardSummary.vue'
import CCollectionCardThumbnail from './CollectionCardThumbnail.vue'
import CCollectionCardTitle from './CollectionCardTitle.vue'

export {
  CCollectionCard,
  CCollectionCardContent,
  CCollectionCardSummary,
  CCollectionCardThumbnail,
  CCollectionCardTitle
}

export default {
  CCollectionCard,
  CCollectionCardContent,
  CCollectionCardSummary,
  CCollectionCardThumbnail,
  CCollectionCardTitle
}
