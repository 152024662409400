
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import CustomTagMixin from '@/@next/mixins/CustomTag'
import POI from '@/graphql/pois'
import { PointsOfInterestService } from '@/@next/services'
import CPoiMiniCard from '@/components/cards/poi-mini-card'
import { CLazyLoadingImage } from '@/components/images'
import { CLoader } from '@/components/loader'

const config = {
  name: 'c-content-poi',
  components: {
    ...CPoiMiniCard,
    CLazyLoadingImage,
    CLoader
  }
}

@Component(config)
export default class Poi extends Mixins(CustomTagMixin, PointsOfInterestService) {
  @Prop({ type: Object, required: true }) content!: any
  pointOfInterest: any = null

  @Watch('content')
  updateContent () {
    if (this.content && this.content.data.pointOfInterest) {
      this.pointOfInterest = this.content.data.pointOfInterest
    }
  }

  get imageUrl () {
    if (!this.pointOfInterest) return null
    return this.pointOfInterest.image.file.url
  }

  get title () {
    if (!this.pointOfInterest) return null
    return this.pointOfInterest.i18n.title.value
  }

  mounted () {
    this.updateContent()
  }

}
