import { ActionTree } from 'vuex'

import * as QUESTIONS from './types'
import { RootState } from '@/@next/store'
import { QuestionsState } from '@/@next/store/questions'

export const actions: ActionTree<QuestionsState, RootState> =  {

  async [QUESTIONS.SET_QUESTION_FINISHED] ({ state, commit, dispatch }, question: any) {
    commit(QUESTIONS.ADD_QUESTION_FINISHED, question)
  }

}
