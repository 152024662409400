import CPoiCard from './PoiCard.vue'
import CPoiCardContent from './PoiCardContent.vue'
import CPoiCardMedia from './PoiCardMedia.vue'
import CPoiCardSummary from './PoiCardSummary.vue'
import CPoiCardTitle from './PoiCardTitle.vue'

export {
  CPoiCard,
  CPoiCardContent,
  CPoiCardMedia,
  CPoiCardSummary,
  CPoiCardTitle
}
export default {
  CPoiCard,
  CPoiCardContent,
  CPoiCardMedia,
  CPoiCardSummary,
  CPoiCardTitle
}
