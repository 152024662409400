export interface Coord {
  long: number;
  lat: number;
  limit?: number;
}
export const calcLatLongDistance = (lat1: number, long1: number, lat2: number, long2: number) => {
  const p = 0.017453292519943295
  const c = Math.cos
  const a = 0.5 - c((lat2 - lat1) * p) / 2 + c(lat1 * p) * c(lat2 * p)
    * (1 - c((long2 - long1) * p)) / 2
  return (12742 * Math.asin(Math.sqrt(a)) * 1000)
}

export const isGeolocationAuthorized = (authorizedList: Coord[], measured: any): boolean => {
  let isAuthorized = false
  authorizedList.forEach((authorized: Coord) => {
    const d = calcLatLongDistance(authorized.lat, authorized.long, measured.latitude, measured.longitude)
    const limit = (authorized.limit || 300) + measured.accuracy
    if (d < limit) isAuthorized = true
  })
  return isAuthorized
}
