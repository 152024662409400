import { GetterTree, Module } from 'vuex'
import { RootState } from '@/@next/store'

import { Realm, UUID, Nullable } from '@/@next/@types'

import * as REALMS from './types'
import { mutations } from './mutations'

export class RealmsState {
  all: Realm[] = []
  current: Nullable<Realm> = null
  defined: UUID = ''
}

const state: RealmsState = new RealmsState()

const getters: GetterTree<RealmsState, RootState> = {
  [REALMS.GET_ALL]: (state) => state.all,
  [REALMS.GET_CURRENT]: (state) => state.current
}

export const realms: Module<RealmsState, RootState> = {
  state,
  getters,
  mutations
}
