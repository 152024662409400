
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const config = {
  name: 'c-AuthorModalMedia'
}

@Component(config)
export default class CAuthorModalMedia extends Vue {
}
