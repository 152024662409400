import { GetterTree, Module } from 'vuex'
import { RootState } from '@/@next/store'

import * as SETTINGS from './types'
import { Maybe } from '@/@types/graphql'

import { mutations } from './mutations'
import { MenuAction, RouteWithoutParameterItem } from '@/@types/menu'
import * as realms from '@/@next/store/realms/types'

export class SettingsState {
  menu: Maybe<SETTINGS.SettingsDataset> = null
  features: Maybe<string[]> = null
  qrcodes: Maybe<any> = null
  homepage: Maybe<any> = null
  movies: Maybe<any> = null
}

const defaultMenu = {
  items: [
    {
      id: 'home',
      deletable: false,
      action: {
        __typename: MenuAction.internalRedirection,
        route: {
          __typename: 'RouteWithoutParameter',
          name: RouteWithoutParameterItem.home
        }
      },
      text: [
        {
          __typename: 'HTMLTranslation',
          language: 'fr',
          target: null,
          value: {
            text: 'Accueil',
            html: 'Accueil'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'en',
          target: null,
          value: {
            text: 'Home',
            html: 'Home'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'de',
          target: null,
          value: {
            text: 'Home',
            html: 'Home'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'nl',
          target: null,
          value: {
            text: 'Huis',
            html: 'Huis'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'es',
          target: null,
          value: {
            text: 'Inicio',
            html: 'Inicio'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'pt',
          target: null,
          value: {
            text: 'Início',
            html: 'Início'
          }
        }
      ]
    },
    {
      id: 'map',
      deletable: false,
      action: {
        __typename: MenuAction.internalRedirection,
        route: {
          __typename: 'RouteWithoutParameter',
          name: RouteWithoutParameterItem.fiberMap
        }
      },
      text: [
        {
          __typename: 'HTMLTranslation',
          language: 'fr',
          target: null,
          value: {
            text: 'Plan',
            html: 'Plan'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'en',
          target: null,
          value: {
            text: 'Map',
            html: 'Map'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'de',
          target: null,
          value: {
            text: 'Map',
            html: 'Map'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'nl',
          target: null,
          value: {
            text: 'Map',
            html: 'Map'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'es',
          target: null,
          value: {
            text: 'Plano',
            html: 'Plano'
          }
        }
      ]
    },
    {
      id: 'settings',
      deletable: false,
      action: {
        __typename: MenuAction.internalRedirection,
        route: {
          __typename: 'RouteWithoutParameter',
          name: RouteWithoutParameterItem.settings
        }
      },
      text: [
        {
          __typename: 'HTMLTranslation',
          language: 'fr',
          target: null,
          value: {
            text: 'Paramètres',
            html: 'Paramètres'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'en',
          target: null,
          value: {
            text: 'Settings',
            html: 'Settings'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'de',
          target: null,
          value: {
            text: 'Settings',
            html: 'Settings'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'nl',
          target: null,
          value: {
            text: 'Parameters',
            html: 'Parameters'
          }
        },
        {
          __typename: 'HTMLTranslation',
          language: 'es',
          target: null,
          value: {
            text: 'Configuración',
            html: 'Configuración'
          }
        }
      ]
    }
  ]
}

const state = new SettingsState()

const getters: GetterTree<SettingsState, RootState> = {
  // TODO Update why menu selection in menu list depend on menu options (languages, targets, realms)
  [SETTINGS.GET_MENU]: (state): any => state.menu && state.menu.value[0] ? state.menu.value[0] : defaultMenu,
  [SETTINGS.GET_FEATURES]: (state): any => state.features,
  [SETTINGS.GET_QRCODES]: (state): any => state.qrcodes,
  // TODO Update why homepage selection in menu list depend on menu options (languages, targets, realms)
  [SETTINGS.GET_HOMEPAGE]: (state, getters): any => {
    if (!state.homepage) return null
    const realm = getters[realms.GET_CURRENT]
    if (!realm || !realm.slug) return state.homepage[0]
    const homepage = state.homepage.find((item: any) => {
      if (!item.realms || !item.realms.length) return false
      return item.realms.find((r: string) => r === realm.slug )
    })
    if (homepage) return homepage
    return state.homepage[0]
  },
  [SETTINGS.GET_MOVIES]: (state): any => state.movies
}

export const settings2: Module<SettingsState, RootState> = {
  state,
  getters,
  mutations
}
