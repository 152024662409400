
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'

import { LanguagesService, RealmsService, DatasetService, UsersService, PreloadService } from '@/@next/services'
import { LanguagesMixin, RealmsMixin, SettingsMixin, GeolocationMixin, AuthorizationMixin } from '@/@next/mixins'
// import { io } from 'socket.io-client'
import removeArrayDuplicate from '@/@next/helpers/removeArrayDuplicate'
import formatedISO from '@/@next/helpers/formatedISO'
import CTicketInput from '@/@next/components/TicketInput.vue'
import { CAsyncTask} from '@/@next/components/async-task'
import { CButton, CButtonLabel, CButtonIcon } from '@/components/form-controls/button'
import { CModal, CModalContent } from '@/components/modal'
import { CLoader } from '@/components/loader'
import LDefault from '@/layouts/default/Default.vue'
import { CLIENT, FEATURES, MAP_URL, TARGET, CACHE_STRATEGY } from '@/constants'
import { Action } from 'vuex-class'
import * as BOT from '@/store/bot/types'
import * as FIBERMAP from '@/@next/store/fibermap/types'
import { messages } from '@/@next/i18n'
import i18n from '@/i18n'
import CWelcome from '@/@next/components/Welcome.vue'
import CConfiguration from '@/@next/components/Configuration.vue'
import CNotification from '@/@next/components/notifications/Notification.vue'

@Component
class AppServices extends Mixins(
  LanguagesService,
  RealmsService,
  DatasetService,
  UsersService,
  PreloadService
) {}

@Component
class AppMixins extends Mixins(
  LanguagesMixin,
  RealmsMixin,
  SettingsMixin,
  GeolocationMixin,
  AuthorizationMixin
) {}

const PERMANENT_ROUTE_QUERIES_WHITELIST = ['realm', 'geo', 'utm_source', 'cna']

const config = {
  name: 'c-app',
  i18n: { messages },
  components: {
    CAsyncTask,
    LDefault,
    CButton,
    CButtonLabel,
    CButtonIcon,
    CModal,
    CModalContent,
    CLoader,
    CWelcome,
    CConfiguration,
    CTicketInput,
    CNotification
  }
}

@Component(config)
export default class App extends Mixins(AppServices, AppMixins) {

  @Action(BOT.FETCH)
  fetchBot!: (data: any) => void
  @Action(FIBERMAP.SET_DATA)
  storeFiberMapData!: (data: any) => void
  retryInit: boolean = false
  showAnimation: boolean = false
  failCount: number = 0
  showWelcomeModal: boolean = false
  showConfigurationOverlay: boolean = false
  socket: any = undefined
  updatingRouter: boolean = false
  isInitializing: boolean = false

  @Watch('showConfigurationOverlay') 
  onShowConfigurationOverlayUpdate () {
    if (!this.showConfigurationOverlay) this.enableWelcomeModal()
  }

  get appIsReady () {
    return !this.isInitializing && !this.showConfigurationOverlay
  }

  enableWelcomeModal () {
    if (CLIENT === 'tatihou' ) {
      setTimeout(() => this.showWelcomeModal = true, 4000)       
    }
  }

  created () {
    this.initHistory()
  }

  preloadWiviData () {
    if (CACHE_STRATEGY === 'sw-and-preload') {
      console.info('[Info][Cache] Start to preload Wivi Collections and related Point Of Interests in 5s')
      setTimeout(() => this.PRELOAD_COLLECTIONS(), 5000)       
    }
  }

  get disableConfigurationPage () {
    return this._settingsFeatures?.includes('disableConfigurationPage')
  }

  clearUserSession () {
    try {
      const clear = this.$route.query?.clearSession === 'true'
      if (clear) {
        // Remove clearSession query from router history
        const { clearSession, ...queries } = this.$route.query
        this.$router.replace({ query: queries })
      }
      return clear
    } catch (e) {
      console.error(`Error during clear user session text ${e}`)
      return false
    }
   
  }

  async init () {
    this.isInitializing = true
    await this.initSettings()
    const clear = this.clearUserSession()
     // Set language and target depending on user and app settings
    const { isForced, ...i18n} = await this.initI18n(clear)
    // Update User configuration
    const userId = await this.GET_USER_ID(clear)
    if (!isForced && !this.disableConfigurationPage) this.showConfigurationOverlay = true 
    else {
      this.enableWelcomeModal()
      this.SAVE_CURRENT_USER_PREFERENCES()
    }
    await this.initAuthorization()
    await this.initRealms()
    // if (CLIENT === 'chfb' || CLIENT === 'dev') {
    //   if (!user.isNewUser) this.showConfigurationOverlay = true         
    // }
    await this.initBot()
    this.initMap()
    this.preloadWiviData()
    this.isInitializing = false
  }  

  // verifyAccess () {
  //   console.log('verifyAccess : ', ACCESS_URL)
  //   if (!ACCESS_URL) return
  //   this.socket = io.connect(`ws://${ACCESS_URL}`)
  //   this.socket.on('connect', () => {
  //     console.log('WS client connect')
  //     this.socket.send('message', 'coucou')
  //     // this.socket.on(`start:${this.movie.screen}`, this.startLiveStream)
  //   })
  //   this.socket.on('disconnect', () => {
  //     console.log('WS client disconnect')
  //   })
  // }

  async initMap () {
    const mapFeature = FEATURES.find((f: any) => f.id === 'map')
    const mapIsEnable = mapFeature && (mapFeature.data.disable === 'undefined' || !mapFeature.data.disable )
    if (!mapIsEnable) return
    const mapDataset = await this.GET_DATASET('fibermap')
    if (!mapDataset) return
    this.storeFiberMapData(mapDataset.value)
  }

  joinQueries (queries: any) {
    if (typeof queries === 'object') {
      return Object.entries(queries).map((i: any) => '&' + i[0] + '=' + i[1]).join('')
    }
    return ''
  }

  async changeHomePageToFiberMap (queries: any) {
    console.log('changeHomePageToFiberMap')
    if (!MAP_URL) return
    const url = `/fiber-map?hl=${this.$i18n.locale}` + this.joinQueries(queries)
    const route = this.$route
    console.log(url, route.name)
    if (route.name === 'home') {
      return await this.$router.replace(url)
    }
    window.history.replaceState({}, '', url)
    window.history.pushState({}, '', route.fullPath)
  }

  initHistory () {
    // Dans le cas ou l'utilisateur arrive par une url différente de la homepage
    // on créer une entrée homepage dans l'historique afin que l'utilisateur puisse naviguer
    const route = this.$route
    if (route.name === 'home') return
    window.history.replaceState({}, '', '/')
    window.history.pushState({}, '', route.fullPath)
  }

  async initI18n (clear: boolean) {
    // ******* ACCESSIBILITE ***********/
    const user = this.GET_USER_PREFERENCES(clear)
    if (user?.accessibility) this.setAccessibility(user.accessibility)

    // ******* CHOIX DE LA LANGUE ***********/
    // On lance une requête afin récupérer les langues disponibles de l'application
    const languages = await this.GET_ALL_LANGUAGES()
    this._setAllLanguages(languages)
    const availableLanguages = this._allLanguageCodes
    // On définit la langue à utiliser par défaut selon cet ordre :
    // 1) la paramètre "hl" dans l'url si la langue visée est présente dans la liste des langues disponibles.
    const hrefIso = this.$route.query.hl as string
    const hrefParamLang = availableLanguages.find((iso) => iso === hrefIso)
    // 2) La langue de configuration choisie est stockée dans les Coockies du navigateur
    const userConfigLang = user?.isoLanguage && availableLanguages.find((iso) => iso === user.isoLanguage) || null
    // 3) la première langue présente dans la liste des langues du navigateur
    const navigatorLanguages = removeArrayDuplicate(navigator.languages.map((iso) => formatedISO(iso)))
    const availableNavigatorLanguage = navigatorLanguages.find((iso) => availableLanguages.includes(iso)) || null
    // 4) la langue par défaut de l'application.
    const defaultLanguage = this._defaultLanguage
    // Sélection par ordre d'utilité pour le visiteur.
    const isoLanguage = hrefParamLang
                || userConfigLang 
                || availableNavigatorLanguage 
                || defaultLanguage 
    // if (hrefParamLang) i18nIsForceByQueryParam = true
    // else if (userConfigLang) i18nIsSetByOldUserConfig = true

    // Store Selection iso language
    this._setCurrentLanguage(isoLanguage)
    // Set i18n locale
    i18n.locale = isoLanguage
    const isForced = hrefParamLang || userConfigLang

    // ******* CHOIX DE LA TARGET ***********/
    // On lance une requête afin récupérer les langues disponibles de l'application
    const targets = await this.FETCH_AVAILABLE_TARGETS()
    const availableTargets = targets.filter((t: any) => t.languages.includes(isoLanguage))
    // On définit la target à utiliser par défaut selon cet ordre :
    // 1) la paramètre "target" dans l'url
    const targetQueryParam = this.$route.query.target as string
    const targetFromQuery = targetQueryParam === 'null' 
                            ? null
                            : targets.find((t: any) => t.target === targetQueryParam)?.target
    // 2) La target de configuration choisie est stockée dans les Coockies du navigateur
    const userConfigTarget = user?.target && availableTargets.find((t: any) => t.target === user.target)?.target
    // 3) App config force to start with specific target
    const targetFromAppConfiguration = targets.find((t: any) => t.target === TARGET)
    // Sélection par ordre d'utilité pour le visiteur.
    const target = targetFromQuery !== undefined
                    ? targetFromQuery
                    : (userConfigTarget ||  targetFromAppConfiguration || null)
     // Define current target
    this._setTarget(target)
    
    // const configurationIsForced = hrefParamLang || userConfigLang
    return { isoLanguage, target, isForced}
  }

  async initUser () {
    const clear = this.$route.query?.clearSession === 'true'
     // Update User configuration
    const userId = await this.GET_USER_ID(clear)
    // const userId = clear && this.READ_USER_COOKIE() || null
    if (clear) {
      // Remove clearSession query from router history
      const { clearSession, ...queries } = this.$route.query
      this.$router.replace({ query: queries })
    }
    return { userId, clear }
  }

  updateUser (user: any, i18n: any) {
    if (user?.isNewUser) return 
    if (user.isoLanguage === i18n.isoLanguage && user.target === i18n.target) return
    this.SAVE_CURRENT_USER_PREFERENCES()
    // this.SET_USER_CONFIG(i18n)
  }

  async initRealms () {
    // On lance une requête afin récupérer les royaumes disponib  les de l'application
    // et on les sauvegarde dans le magasin de données.
    const realms = await this.GET_ALL_REALMS()
    this._setAllRealms(realms)
    const realmParam = this.$route.query.realm as string
    if (realmParam) this._setCurrentRealm(realmParam)
  }

  async initSettings () {
    try {
      const menu = await this.GET_DATASET('menus')
      if (menu?.value) this._setSettingsMenu(Object.assign(menu, { value: menu.value }))
    } catch (e) {
      console.warn(e)
    }
    try {
      const features = await this.GET_DATASET('features')
      if (features?.value) this._setSettingsFeatures(features.value)
    } catch (e) {
      console.warn(e)
    }
    try {
      const homepages = await this.GET_DATASET('homepages')
      if (homepages?.value) this._setSettingsHomepage(homepages.value)
      if (homepages?.value && homepages.value.length && homepages.value[0]?.page === 'fiber-map' ) {
        await this.changeHomePageToFiberMap(homepages.value[0]?.queries)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  async initBot () {
    try {
      const botDataset = await this.GET_DATASET('bot')
      if (!botDataset || !botDataset.value) return
      this.fetchBot(botDataset.value)
    } catch (e) {
      console.warn(e)
    }
  }

  async setPermanentRouteQueries (from: Route, forceNewTarget = false) {
    if (this.updatingRouter) return
    this.updatingRouter = true
    setTimeout(async () => {
      // On filtre les paramètres qui ne sont pas disponible dans la liste blanche
      const permantentQueries = Object.keys(from.query)
        .filter((key) => PERMANENT_ROUTE_QUERIES_WHITELIST.includes(key))
        .map((key) => ([key, from.query[key] ]))
      // Puis nous les replaçons dans la route
      const targetQuery = () => {
        return this._languageTarget
        console.log('forceNewTarget', forceNewTarget)
        if (forceNewTarget) return this._languageTarget
        const queryTarget = this.$route.query.target as string
        console.log('Current queryTarget', queryTarget, this.$route.query.target)
        return queryTarget || this._languageTarget || null
        const newTarget = this._languageTarget
        console.log('newTarget newTarget', newTarget)
        return this._languageTarget !==  undefined ? this._languageTarget : (queryTarget || null)
        // if (newTarget) return { target: newTarget}
        // else if (queryTarget) return { target: queryTarget }
        // return null
      }
      const { target, ...queries } = this.$route.query
      const query = Object.assign({}, this.$route.query, {
        hl: this._currentLanguage,
        ...Object.fromEntries(permantentQueries)
      })
      if (target !== undefined || this._languageTarget !== null) {
        query.target = targetQuery()
      }
      await this.$router
      .replace({ query })
      .catch(() => undefined)
      this.updatingRouter = false
    }, 200)
    
  }

  @Watch('_languageTarget')
  async onCurrentTargetChange () {
    await this.setPermanentRouteQueries(this.$route, true)
    document.documentElement.lang = this._currentLanguage
    this.$i18n.locale = this._currentLanguage
    this.$i18n.fallbackLocale = this._currentLanguage
  }

  @Watch('_currentLanguage')
  async onCurrentLanguageChange () {
    await this.setPermanentRouteQueries(this.$route)
    document.documentElement.lang = this._currentLanguage
    this.$i18n.locale = this._currentLanguage
    this.$i18n.fallbackLocale = this._currentLanguage
  }

  @Watch('$route')
  async onRouteChange (to: Route, from: Route) {
    await this.setPermanentRouteQueries(from)
  }

  get definedLayout () {
    return this.$route.meta.layout || LDefault
  }

  get loadingText () {
    return this.$t('homePageLoading') || ''
  }

  loadingError () {
    this.retryInit = false
    this.showAnimation = true
    this.failCount++
    if (this.failCount > 10) return
    setTimeout(async () => {
      this.retryInit = true
    }, 15000)
  }

  reload () {
    window.location.reload()
  }

  get appUrl () {
    return window.location.host
  }

  setStyle (property: string, value: string) {
    const root = document.documentElement
    root.style.setProperty(property, value)
  }

  @Watch('textSize')
  @Watch('wordSpacing')
  @Watch('greyScale')
  @Watch('dyslexicFont')
  @Watch('highContrast')
  onAccessibilityUpdate () {
    this.SAVE_CURRENT_USER_PREFERENCES()
  }

  @Watch('textSize')
  onTextSizeUpdate () {
    document.body.style.fontSize = `${this.textSize}%`
  }

  @Watch('greyScale')
  onGreyScaleUpdate () {
    // this.setStyle('--greyScale', this.greyScale ? '1' : '0')
    document.body.style.filter = this.greyScale ? 'grayscale(100%)' : 'none'
  }
  @Watch('wordSpacing')
  onWordSpacingUpdate () {
    document.body.style.wordSpacing = `${this.wordSpacing}px`
  }
  
  @Watch('dyslexicFont')
  onDyslexicFontUpdate () {
    this.setStyle('--sansSerifFontStack', 
      this.dyslexicFont ? 'OpenDyslexic' : this.defaultCssValues.sansSerifFontStack)
    this.setStyle('--serifFontStack', 
      this.dyslexicFont ? 'OpenDyslexic' : this.defaultCssValues.serifFontStack)
  }
  
  @Watch('highContrast')
  onHighContrastUpdate () {
    // Update High Contrast
    const backgroundContrastColor: string = '#fff'
    const onBackgroundContrastColor: string = '#000'
    this.setStyle('--onBackgroundColor', 
      this.highContrast ? backgroundContrastColor : this.defaultCssValues.onBackgroundColor)
    this.setStyle('--backgroundColor', 
      this.highContrast ? onBackgroundContrastColor : this.defaultCssValues.backgroundColor)
    this.setStyle('--primaryColor', 
      this.highContrast ? backgroundContrastColor : this.defaultCssValues.primaryColor)
    this.setStyle('--onPrimaryColor', 
      this.highContrast ? onBackgroundContrastColor : this.defaultCssValues.onPrimaryColor)
    this.setStyle('--foregroundColor', 
      this.highContrast ? onBackgroundContrastColor : this.defaultCssValues.foregroundColor)
    this.setStyle('--textOnForegroundColor', 
      this.highContrast ? backgroundContrastColor : this.defaultCssValues.textOnForegroundColor)
    this.setStyle('--menuBackgroundColor', 
      this.highContrast ? backgroundContrastColor : this.defaultCssValues.menuBackgroundColor)
    this.setStyle('--menuColor', 
      this.highContrast ? onBackgroundContrastColor : this.defaultCssValues.menuColor)
    this.setStyle('--cardTitleColor', 
      this.highContrast ? onBackgroundContrastColor : this.defaultCssValues.cardTitleTextColor)
    this.setStyle('--cardSummaryTextColor', 
      this.highContrast ? onBackgroundContrastColor : this.defaultCssValues.cardSummaryTextColor)
    this.setStyle('--cardTitleBackgroundColor', 
      this.highContrast ? backgroundContrastColor : this.defaultCssValues.cardTitleBackgroundColor)
  }

  get appComponentKey () {
    return this._currentLanguage + '-' + (this._languageTarget || 'standard')
  }

}
