
import { Component, Mixins, Prop } from 'vue-property-decorator'

import CustomTagMixin from '@/@next/mixins/CustomTag'
import { QuizContent } from '@/@types/graphql'
import CModal from '@/components/modal'
import { messages } from '@/views/single-poi/i18n'
import { CButton, CButtonLabel, CButtonIcon } from '@/components/form-controls/button'
import CQuestion from '@/components/question/Question.vue'
import { CImage } from '@/@next/components/image'
import { CLoader } from '@/components/loader'

const config = {
  name: 'c-content-quiz',
  i18n: { messages },
  components: {
    ...CModal,
    CButton,
    CButtonLabel,
    CButtonIcon,
    CQuestion,
    CImage,
    CLoader
  }
}

@Component(config)
export default class Quiz extends Mixins(CustomTagMixin) {

  @Prop({ type: Object, required: true }) content!: QuizContent
  @Prop({ type: String, required: false, default: '' }) contextId!: string

  quizModalIsOpen: boolean = false
  currentQuestionIndex: number = 0

  get title () {
    return this.content?.data.quiz?.i18n.title?.value.html || ''
  }

  get image () {
    return this.content?.data.quiz?.image
  }

  get abstract () {
    return this.content?.data.quiz?.i18n.abstract?.value.html || ''
  }

  get description () {
    return this.content?.data.quiz?.i18n.description?.value.html || ''
  }

  get questions () {
    return this.content?.data.quiz?.questions
  }

  get question () {
    if (!this.questions) return
    return this.questions[this.currentQuestionIndex]
  }

  nextQuestion () {
    if (!this.questions) return
    this.currentQuestionIndex = this.currentQuestionIndex + 1
  }

  previousQuestion () {
    if (!this.questions) return
    this.currentQuestionIndex = this.currentQuestionIndex - 1
  }

}
