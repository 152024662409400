import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import gql from 'graphql-tag'

import * as LANGUAGES from '@/@next/store/languages/types'
import { LanguageCode, SearchContentType } from '@/@next/@types'
import { MediaImageFragment, MediaAudioFragment } from './fragments'

@Component({
  name: 'search-service'
})
export default class Search extends Vue {

  @Getter(LANGUAGES.GET_ALL_ISO) getAllLanguagesISO!: LanguageCode[]
  @Getter(LANGUAGES.GET_CURRENT) getCurrentLanguageISO!: LanguageCode

  async GET_SEARCH_RESULT (text: string, contentType: SearchContentType = SearchContentType.ALL): Promise<any> {
    switch (contentType) {
      case SearchContentType.ALL:
        return await this.GET_ALL_SEARCH_RESULT(text)
      case SearchContentType.AUDIOGUIDES:
        return await this.GET_AUDIOGUIDE_SEARCH_RESULT(text)
      case SearchContentType.COLLECTIONS:
        return await this.GET_COLLECTIONS_SEARCH_RESULT(text)
      case SearchContentType.PEOPLE:
        return await this.GET_PEOPLE_SEARCH_RESULT(text)
      case SearchContentType.POIS:
        return await this.GET_POIS_SEARCH_RESULT(text)
    }
  }

  async GET_ALL_SEARCH_RESULT (text: string) {
    // const audioGuideTracks = await this.GET_AUDIOGUIDE_SEARCH_RESULT(text)
    const collections = await this.GET_COLLECTIONS_SEARCH_RESULT(text)
    const people = await this.GET_PEOPLE_SEARCH_RESULT(text)
    const pointsOfInterest = await this.GET_POIS_SEARCH_RESULT(text)
    return { ...collections, ...people, ...pointsOfInterest }
  }

  async GET_AUDIOGUIDE_SEARCH_RESULT (text: string) {
    const variables = { text }
    const query = gql`

        ${ MediaImageFragment }
        ${ MediaAudioFragment }

        query search ($text: String!) {
            search (language: "${ this.getCurrentLanguageISO }", text: $text) {
                audioGuideTracks {
                    id
                    slug
                    shortCode
                    image { ...MediaImageFragment }
                    i18n (languages: ${ JSON.stringify(this.getAllLanguagesISO) }) {
                        title { value { text html } language  }
                        description { value { text html } language  }
                        abstract { value { text html } language  }
                        text { language value}
                        audio { language value { ...MediaAudioFragment } }
                    }
                }
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.search
  }

  async GET_COLLECTIONS_SEARCH_RESULT (text: string) {
    const variables = { text }
    const query = gql`

        ${ MediaImageFragment }

        query search ($text: String!) {
            search (language: "${ this.getCurrentLanguageISO }", text: $text) {
                collections {
                    id
                    slug
                    name
                    shortCode
                    languages
                    publishedAt
                    i18n (languages: ${ JSON.stringify(this.getAllLanguagesISO) }) {
                        title { value { text html } language  }
                        abstract { value { text html } language  }
                        description { value { text html } language  }
                    }
                    image { ...MediaImageFragment }
                }
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.search
  }

  async GET_PEOPLE_SEARCH_RESULT (text: string) {
    const variables = { text }
    const query = gql`

        ${ MediaImageFragment }

        query search ($text: String!) {
            search (language: "${ this.getCurrentLanguageISO }", text: $text) {
                people {
                    id
                    i18n (languages: ${ JSON.stringify(this.getAllLanguagesISO) }) {
                        name {
                            language
                            value {
                                text
                                html
                            }
                        }
                    }
                    bornAt
                    diedAt
                    image { ...MediaImageFragment }
                }
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.search
  }

  async GET_POIS_SEARCH_RESULT (text: string) {
    const variables = { text }
    const query = gql`

        ${MediaImageFragment}

        query search ($text: String!) {
            search (language: "${ this.getCurrentLanguageISO }", text: $text) {
                pointsOfInterest {
                    id
                    slug
                    name
                    shortCode
                    languages
                    publishedAt
                    audioGuideTracks {
                        id
                        shortCode
                    }
                    i18n (languages: ${ JSON.stringify(this.getAllLanguagesISO) }) {
                        title { value { text html } language  }
                        abstract { value { text html } language  }
                        description { value { text html } language  }
                    }
                    image { ...MediaImageFragment }
                    people {
                        id
                        i18n (languages: ${ JSON.stringify(this.getAllLanguagesISO) }) {
                            name {
                                language
                                value {
                                    text
                                    html
                                }
                            }
                        }
                        bornAt
                        diedAt
                        role {
                            id
                            slug
                            i18n (languages: ${ JSON.stringify(this.getAllLanguagesISO) }) {
                                name {
                                    language
                                    value
                                }
                            }
                        }
                        image { ...MediaImageFragment }
                    }

                }
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.search
  }
}
