
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Carousel, Slide } from 'vue-carousel'
import { CLazyLoadingImage } from '@/components/images'
import { CLoader } from '@/components/loader'
import CCarouselItem from '@/components/carousel/CarouselItem.vue'
import { CImage } from '@/@next/components/image'

const config = {
  name: 'c-content-image-gallery',
  components: {
    CCarousel: Carousel,
    CCarouselItem: Slide,
    CLazyLoadingImage,
    CLoader,
    CImage
  }
}
@Component(config)
export default class CContentImageGallery extends Vue {
  @Prop({ type: Object, required: true }) content!: any
  @Prop({ type: String, required: true }) contextId!: string
  navPrevLabel: string = `
    <svg version="1.1" style="width: 48px; height: 48px; fill: black" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 370.814 370.814" xml:space="preserve">
      <g>
        <g>
          <polygon points="292.92,24.848 268.781,0 77.895,185.401 268.781,370.814 292.92,345.961 127.638,185.401 \t\t"/>
        </g>
      </g>
  </svg>
  `
  nextPrevLabel: string = `
    <svg version="1.1" style="width: 48px; height: 48px; fill: black" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"  viewBox="0 0 478.448 478.448" xml:space="preserve">
        <g>
          <polygon points="131.659,0 100.494,32.035 313.804,239.232 100.494,446.373 131.65,478.448
            377.954,239.232 "/>
        </g>
  </svg>
  `

  get gallery () {
    return this.content.data.i18n
  }

  get title () {
    if (!this.gallery.title) return null
    return this.gallery.title.value
  }

  get images () {
    if (!this.gallery.images || !this.gallery.images.length) return null
    return this.gallery.images
  }

}
