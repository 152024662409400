
import { Component, Mixins } from 'vue-property-decorator'
// import data from './data.js'
import CModal from '@/components/modal'
import { messages } from './i18n'
import { NotificationService, MediasService } from '@/@next/services'
import { cloneDeep } from 'lodash'
import parser from 'cron-parser'
import { Maybe, MediaImage } from '@/@types/graphql'
import { CLazyLoadingImage } from '@/components/images'
import { CLoader } from '@/components/loader'
import { CButton } from '@/components/form-controls/button'

const config = {
  name: 'c-notification',
  components: { ...CModal, CLazyLoadingImage, CLoader, CButton },
  i18n: { messages }
}

@Component(config)
export default class CNotification extends Mixins(NotificationService, MediasService) {

  notifications: any = []
  notificationTimer: any
  current: any = null
  showNotification: boolean = false
  startTime = Date.now()
  image: Maybe<MediaImage> = null

  notify () {
    this.showNotification = true
    window.navigator.vibrate(200)
  }

  closeNotification () {
    this.showNotification = false 
    this.resetTimer()
  }

  resetTimer () {
    if (this.notificationTimer) clearTimeout(this.notificationTimer)
    this.findCurrent()
    if (!this.current) return
    const currentTime = new Date().getTime()
    const date = new Date(this.current.date)  
    const deltaMs = date.getTime() - currentTime
    if (deltaMs > 0) {
      this.notificationTimer = setTimeout(() => {
        this.notify()
      }, deltaMs)
    }
  }

  computeTrigger (notification: any) {
    switch (notification.type) {
      case 'delay': const date = new Date(this.startTime + notification.delay * 1000);
                    notification.deltaMs = date.getTime() - Date.now()
                    notification.date = date.toISOString()
                    break
      case 'date': const event = new Date(notification.date)  
                   notification.deltaMs = event.getTime() - this.startTime
                   break
      case 'cron': const interval = parser.parseExpression(notification.expression, { currentDate: new Date() })
                   const next = interval.next()
                   notification.deltaMs = next.getTime() - this.startTime
                   notification.date = next.toISOString()
                   break
      default: break
    }
    return notification
  }

  findCurrent () {
    if (!this.notifications || !Array.isArray(this.notifications)) return console.info('No Notifications')
    const removeOldNotifications = (n: any) => n.deltaMs && n.deltaMs > -1
    const sortByTime = (a: any, b: any) => a.deltaMs > b.deltaMs ? 1 : -1
    const ordered = this.notifications
                          .map(this.computeTrigger)
                          .filter(removeOldNotifications)
                          .sort(sortByTime)
    if (ordered.length) this.current = cloneDeep(ordered[0])
    else this.current = null
    this.fetchImage()
  }

  async init () {
    this.notifications = await this.GET_NOTIFICATION()
    this.resetTimer()      
  }

  async mounted () {
    await this.init()
  }

  beforeDestroy () {
    this.notifications = []
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer)
    }
  }

  findTranslation (text: any) {
      if (!text?.length) return null
      const translation = text.find((el: any) => el.language === this.$i18n.locale)
      if (translation) return translation.value.html
      const french = text.find((el: any) => el.language === 'fr')
      if (french) return french?.value.html
      return null
  }

  async fetchImage () {
    if (!this.current?.imageId) return
    this.image = await this.GET_SINGLE_IMAGE(this.current?.imageId)
  }
}
