
import { Vue, Component } from 'vue-property-decorator'

const config = {
  name: 'l-default-content',
  components: {}
}

@Component(config)
export default class LDefaultContent extends Vue {}
