import { Mixins, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'

import { Scalars, PointOfInterest, PointOfInterestList, PaginationOptions, SortArgs } from '@/@types/graphql'
import { LanguagesMixin } from '@/@next/mixins'

import { MediaImageFragment, MediaAudioFragment, MediaVideoFragment } from './fragments'

@Component({ name: 'point-of-interest-service' })
export default class PointsOfInterest extends Mixins(LanguagesMixin) {

  async GET_ALL_POIS (pagination: PaginationOptions = {}, sort: SortArgs = {}): Promise<PointOfInterestList> {
    const languages = this._allLanguageCodes
    const target = this._languageTarget || null
    const variables = { pagination, sort, languages, target }
    const query = gql`

        ${ MediaImageFragment }
        ${ MediaAudioFragment }

        query pointsOfInterest ($pagination: PaginationArgs, $sort: SortArgs, $languages: [LanguageCode!]!, $target: I18nTarget ) {
            pointsOfInterest (pagination: $pagination, sort: $sort) {
                itemsCount
                totalCount
                pagination { skip take }
                sort { field order }
                items {
                    id
                    slug
                    name
                    shortCode
                    languages
                    publishedAt
                    i18n (languages: $languages, target: $target) {
                        title { value { text html } language  }
                        abstract { value { text html } language  }
                        description { value { text html } language  }
                    }
                    image { ...MediaImageFragment }
                    locks { 
                        slug 
                        data
                    }
                    collections {
                        id
                        slug
                        name
                        shortCode
                        languages
                        i18n (languages: $languages, target: $target) {
                            title { value { text html } language  }
                            abstract { value { text html } language  }
                            description { value { text html } language  }
                        }
                        image { ...MediaImageFragment }
                    }
                    audioGuideTracks {
                        id
                        slug
                        shortCode
                        image { ...MediaImageFragment }
                        i18n (languages: $languages, target: $target) {
                            title { value { text html } language  }
                            description { value { text html } language  }
                            abstract { value { text html } language  }
                            text { language value}
                            audio { language target value { ...MediaAudioFragment } }
                            video { language target value { ...MediaVideoFragment } }
                        }
                    }
                    people {
                        id
                        i18n (languages: $languages, target: $target) {
                            name {
                                language
                                value {
                                    text
                                    html
                                }
                            }
                        }
                        bornAt
                        diedAt
                        role {
                            id
                            slug
                            i18n (languages: $languages, target: $target) {
                                name {
                                    language
                                    value
                                }
                            }
                        }
                        image { ...MediaImageFragment }
                    }
                }
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.pointsOfInterest
  }

  async GET_SINGLE_POI (id: Scalars['UUID']): Promise<PointOfInterest> {
    const languages = this._allLanguageCodes
    const target = this._languageTarget || null
    const variables = { id, languages, target }
    const query = gql`

        ${ MediaImageFragment }
        ${ MediaVideoFragment }
        ${ MediaAudioFragment }

        query pointOfInterest($id: UUID!, $languages: [LanguageCode!]!, $target: I18nTarget) {
            pointOfInterest (id: $id) {
                id
                slug
                name
                shortCode
                languages
                publishedAt
                i18n (languages: $languages, target: $target) {
                    title { value { text html } language  }
                    abstract { value { text html } language  }
                    description { value { text html } language  }
                }
                image { ...MediaImageFragment }
                placeholders {
                    id
                    floor { 
                        id
                        name 
                        i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                            title { value { text html } language  }
                            abstract { value { text html } language  }
                            description { value { text html } language  }
                        }
                    }
                    map {
                        id
                        name 
                        i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                            title { value { text html } language  }
                            abstract { value { text html } language  }
                            description { value { text html } language  }
                        }
                    }
                }
                locks { 
                    slug 
                    data
                }
                collections {
                    id
                    slug
                    name
                    shortCode
                    languages
                    i18n (languages: $languages, target: $target) {
                        title { value { text html } language  }
                        abstract { value { text html } language  }
                        description { value { text html } language  }
                    }
                    image { ...MediaImageFragment }
                }
                people {
                    id
                    i18n (languages: $languages, target: $target) {
                        name {
                            language
                            value {
                                text
                                html
                            }
                        }
                        bio {
                          language
                          value {
                            text
                            html
                          }
                        }
                    }
                    bornAt
                    diedAt
                    role {
                        id
                        slug
                        i18n (languages: $languages, target: $target) {
                            name {
                                language
                                value
                            }
                        }
                    }
                    image { ...MediaImageFragment }
                }
                audioGuideTracks {
                    id
                    slug
                    shortCode
                    image { ...MediaImageFragment }
                    i18n (languages: $languages, target: $target) {
                        title { value { text html } language  }
                        description { value { text html } language  }
                        abstract { value { text html } language  }
                        text { language value }
                        audio { language target value { ...MediaAudioFragment } }
                        video { language target value { ...MediaVideoFragment } }
                    }
                }
                contents {
                    ...on AudioContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                description { value { text html } language  }
                                audio { language value { ...MediaAudioFragment } }
                            }

                        }
                    }
                    ...on AudioGuideContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            audioGuide {
                                id
                                slug
                                name
                                shortCode
                                languages
                                i18n (languages: $languages, target: $target) {
                                    title { value { text html } language  }
                                    description { value { text html } language  }
                                    abstract { value { text html } language  }
                                }
                                image { ...MediaImageFragment }
                                tracks {
                                    i18n (languages: $languages, target: $target) {
                                        title { value { text html } language  }
                                        description { value { text html } language  }
                                        abstract { value { text html } language  }
                                    }
                                }
                            }
                        }
                    }
                    ...on AudioGuideTrackContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            audioGuideTrack {
                                id
                                slug
                                image { ...MediaImageFragment }
                                i18n (languages: $languages, target: $target) {
                                    title { value { text html } language  }
                                    description { value { text html } language  }
                                    abstract { value { text html } language  }
                                    text { language value }
                                    audio { language target value { ...MediaAudioFragment } }
                                    video { language target value { ...MediaVideoFragment } }
                                }
                            }
                        }
                    }
                    ...on CollectionContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            collection {
                                id
                                slug
                                name
                                shortCode
                                languages
                                i18n (languages: $languages, target: $target) {
                                    title { value { text html } language  }
                                    description { value { text html } language  }
                                    abstract { value { text html } language  }
                                }
                              pointsOfInterest (pagination: {skip: 0, take: 12}) {
                                totalCount
                                items {
                                  id
                                  image { ...MediaImageFragment }
                                }
                              }
                                image { ...MediaImageFragment }
                            }
                        }
                    }
                    ...on ImageContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                description { value { text html } language  }
                                image {
                                    value { ...MediaImageFragment }
                                    language
                                }
                            }
                        }
                    }
                    ...on ImageGalleryContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                images {
                                    title { value { text html } language }
                                    description { value { text html } language }
                                    image { value { ...MediaImageFragment   } language }
                                }
                            }
                        }
                    }
                    ...on PersonContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            person {
                                id
                                bornAt
                                diedAt
                                image { ...MediaImageFragment }
                                i18n (languages: $languages, target: $target) {
                                    name { value { html text } language }
                                    bio { value { html text } language }
                                }
                            }
                        }
                    }
                    ...on PointOfInterestContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            pointOfInterest {
                                id
                                slug
                                name
                                shortCode
                                languages
                                i18n (languages: $languages, target: $target) {
                                    title { value { text html } language  }
                                    abstract { value { text html } language  }
                                    description { value { text html } language  }
                                }
                                image { ...MediaImageFragment }
                                collections { id }
                                people {
                                    id
                                    bornAt
                                    diedAt
                                    role {
                                        id
                                        slug
                                        i18n (languages: $languages, target: $target) {
                                            name {
                                                language
                                                value
                                            }
                                        }
                                    }
                                    image { ...MediaImageFragment }
                                    i18n (languages: $languages, target: $target) {
                                        name { value { text html } language  }
                                    }
                                }
                            }
                        }
                    }
                    ...on PuzzleContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            xPieces
                            yPieces
                            timer
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                description { value { text html } language  }
                            }
                        }
                    }
                    ...on TextContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            i18n (languages: $languages, target: $target) {
                                body {
                                    language
                                    value { text html }
                                }
                            }
                        }
                    }
                    ...on VideoContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                          i18n (languages: $languages, target: $target) {
                              title { value { text html } language  }
                              description { value { text html } language  }
                              video {
                                  language
                                  value { ...MediaVideoFragment }
                              }
                          }
                        }
                    }
                    ...on QuestionContent {
                      id
                      slug
                      name
                      shortCode
                      languages
                      options
                      data {
                        id
                        question {
                          id
                          image { ...MediaImageFragment }
                          data
                          i18n (languages: $languages, target: $target) {
                            title { value { text html } language }
                          }
                        }
                      }
                    }
                    ...on QuizContent {
                          id
                          slug
                          name
                          shortCode
                          languages
                          options
                          data {
                            id
                            quiz {
                              image { ...MediaImageFragment }
                              questions {
                                id
                                data
                                image { ...MediaImageFragment }
                                i18n (languages: $languages, target: $target) {
                                  title { value { text html } language }
                                }
                              }
                              i18n (languages: $languages, target: $target) {
                                title { value { text html } language }
                                abstract { value { text html } language }
                                description { value { text html } language }
                              }
                            }
                          }
                        }
                    ...on SurveyContent {
                          id
                          slug
                          name
                          shortCode
                          languages
                          options
                          data {
                            id
                            survey {
                              image { ...MediaImageFragment }
                              i18n (languages: $languages, target: $target) {
                                title { value { text html } language }
                                abstract { value { text html } language }
                                description { value { text html } language }
                              }
                            }
                          }
                        }
                    ...on PopupContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            timer
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                text { value { text html } language  }
                                button { value { text html } language  }
                            }
                        }
                    }
                    ...on MemoryContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                text { value { text html } language  }
                            }
                            options
                            versoImage { ...MediaImageFragment }
                            cards {
                                image { ...MediaImageFragment }
                                options
                            }
                        }
                    }
                    ...on TimelineContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                text { value { text html } language  }
                                instruction { value { html text } language }
                            }
                            options
                            events {
                                image { ...MediaImageFragment }
                                id
                                i18n (languages: $languages, target: $target) {
                                    date { value { html text } language }
                                    text { value { html text } language }
                                }
                            }
                        }
                    }
                }
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.pointOfInterest
  }

  async GET_SINGLE_POI_CONTENT (id: Scalars['UUID']): Promise<PointOfInterest> {
    const languages = this._allLanguageCodes
    const target = this._languageTarget || null
    const variables = { id, languages, target }
    const query = gql`

        ${ MediaImageFragment }
        ${ MediaVideoFragment }
        ${ MediaAudioFragment }

        query pointOfInterest($id: UUID!, $languages: [LanguageCode!]!, $target: I18nTarget) {
            pointOfInterest (id: $id) {
                contents {
                    ...on AudioContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                description { value { text html } language  }
                                audio { language value { ...MediaAudioFragment } }
                            }
                        }
                    }
                    ...on AudioGuideContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            audioGuide {
                                id
                                slug
                                name
                                shortCode
                                languages
                                i18n (languages: $languages, target: $target) {
                                    title { value { text html } language  }
                                    description { value { text html } language  }
                                    abstract { value { text html } language  }
                                }
                                image { ...MediaImageFragment }
                                tracks {
                                    i18n (languages: $languages, target: $target) {
                                        title { value { text html } language  }
                                        description { value { text html } language  }
                                        abstract { value { text html } language  }
                                    }
                                }
                            }
                        }
                    }
                    ...on AudioGuideTrackContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            audioGuideTrack {
                                id
                                slug
                                image { ...MediaImageFragment }
                                i18n (languages: $languages, target: $target) {
                                    title { value { text html } language  }
                                    description { value { text html } language  }
                                    abstract { value { text html } language  }
                                    text { language value }
                                    audio { language value { ...MediaAudioFragment } }
                                }
                            }
                        }
                    }
                    ...on CollectionContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            collection {
                                id
                                slug
                                name
                                shortCode
                                languages
                                i18n (languages: $languages, target: $target) {
                                    title { value { text html } language  }
                                    description { value { text html } language  }
                                    abstract { value { text html } language  }
                                }
                                image { ...MediaImageFragment }
                                pointsOfInterest {
                                    totalCount
                                    items {
                                        id
                                        image { ...MediaImageFragment }
                                    }
                                }
                            }
                        }
                    }
                    ...on ImageContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                description { value { text html } language  }
                                image {
                                    value { ...MediaImageFragment }
                                    language
                                }
                            }
                        }
                    }
                    ...on ImageGalleryContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                description { value { text html } language }
                                images {
                                    title { value { text html } language }
                                    description { value { text html } language }
                                    image { value { ...MediaImageFragment   } language }
                                }
                            }
                        }
                    }
                    ...on PersonContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            person {
                                id
                                bornAt
                                diedAt
                                image { ...MediaImageFragment }
                                i18n (languages: $languages, target: $target) {
                                    name { value { html text } language }
                                    bio { value { html text } language }
                                }
                            }
                        }
                    }
                    ...on PointOfInterestContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            pointOfInterest {
                                id
                                slug
                                name
                                shortCode
                                languages
                                i18n (languages: $languages, target: $target) {
                                    title { value { text html } language  }
                                    abstract { value { text html } language  }
                                    description { value { text html } language  }
                                }
                                image { ...MediaImageFragment }
                                collections { id }
                                people {
                                    id
                                    bornAt
                                    diedAt
                                    role {
                                        id
                                        slug
                                        i18n (languages: $languages, target: $target) {
                                            name {
                                                language
                                                value
                                            }
                                        }
                                    }
                                    image { ...MediaImageFragment }
                                    i18n (languages: $languages, target: $target) {
                                        name { value { text html } language  }
                                    }
                                }
                            }
                        }
                    }
                    ...on PuzzleContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            xPieces
                            yPieces
                            timer
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                description { value { text html } language  }
                            }
                        }
                    }
                    ...on TextContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            i18n (languages: $languages, target: $target) {
                                body {
                                    language
                                    value { text html }
                                }
                            }
                        }
                    }
                    ...on VideoContent {
                            id
                            slug
                            name
                            shortCode
                            languages
                      options
                            people {
                                id
                                bornAt
                                diedAt
                                role {
                                    id
                                    slug
                                    i18n (languages: $languages, target: $target) {
                                        name {
                                            language
                                            value
                                        }
                                    }
                                }
                                image { ...MediaImageFragment }
                                i18n (languages: $languages, target: $target) {
                                    name { value { html text } language }
                                    bio { value { html text } language }
                                }
                            }
                            data {
                                i18n (languages: $languages, target: $target) {
                                    title { value { text html } language  }
                                    description { value { text html } language  }
                                    video {
                                        language
                                        value { ...MediaVideoFragment }
                                    }
                                }
                            }
                        }
                    ...on PopupContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            timer
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                text { value { text html } language  }
                                button { value { text html } language  }
                            }
                        }
                    }
                    ...on TimelineContent {
                        id
                        slug
                        name
                        shortCode
                        languages
                        options
                        people {
                            id
                            bornAt
                            diedAt
                            role {
                                id
                                slug
                                i18n (languages: $languages, target: $target) {
                                    name {
                                        language
                                        value
                                    }
                                }
                            }
                            image { ...MediaImageFragment }
                            i18n (languages: $languages, target: $target) {
                                name { value { html text } language }
                                bio { value { html text } language }
                            }
                        }
                        data {
                            i18n (languages: $languages, target: $target) {
                                title { value { text html } language  }
                                text { value { text html } language  }
                                instruction { value { html text } language }
                            }
                            options
                            events {
                                image { ...MediaImageFragment }
                                id
                                i18n (languages: $languages, target: $target) {
                                    date { value { html text } language }
                                    text { value { html text } language }
                                }
                            }
                        }
                    }
                }
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.pointOfInterest
  }

}
