
import { Vue, Component, Mixins } from 'vue-property-decorator'

import Input from '@/mixins/Input'

const config = {
  name: 'c-checkbox'
}

@Component(config)
export default class CCheckbox extends Mixins!(Input) {}
