
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CustomWrapper from '@/components/CustomWrapper.vue'

const config = {
  name: 'c-modal-content',
  components: { CustomWrapper }
}

@Component(config)
export default class CModalContent extends Vue {
  @Prop({ default: 'div'}) tag!: string
}
