
import { Component, Vue, Prop, Mixins, Watch } from 'vue-property-decorator';
import CustomTagMixin from '@/@next/mixins/CustomTag';
import { CLazyLoadingImage } from '@/components/images';
import { CLoader } from '@/components/loader';
import CModal from '@/components/modal';
import { CImage } from '@/@next/components/image';
import { CButton } from '@/components/form-controls/button';
import _shuffle from 'lodash/shuffle';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import Medias from '@/@next/services/Medias';
import { messages } from '@/@next/i18n';
import { TimelineContentData } from '@/@types/graphql';

const config = {
  name: 'c-content-timeline',
  i18n: { messages },
  components: {
    CLazyLoadingImage,
    CLoader,
    CImage,
    ...CModal,
    CButton
  }
};

@Component(config)
export default class CContentTimeline extends Mixins(CustomTagMixin, Medias) {
  @Prop({ type: Object, required: true }) content!: any;
  @Prop({ type: String, required: false, default: '' }) contextId!: string;

  openTimeline: boolean = false;
  showResult: boolean = false;
  cards = [];
  started = false;
  startTime: any = 0;
  turns = 0;
  flipBackTimer: any = null;
  timer: any = null;
  time = 0;
  score = 0;
  paused: boolean = false;
  selectedEvent: any | null = null;
  selectedPlaceholderId: string | null = null;
  eventPlaced: Array<any | null> = [];
  showDescription: boolean = false;
  showCongratulations: boolean = false;
  showInstruction: boolean = false;

  @Watch('openTimeline')
  onOpenTimelineUpdate () {
    if (this.openTimeline) {
      // this.resetGame();
    } else {
      this.started = false;
    }
  }

  @Watch('selectedEvent')
  @Watch('selectedPlaceholderId')
  onSelectionUpdate () {
    if (!this.selectedPlaceholderId || !this.selectedEvent) return;
    const placeholderIndex = this.events.findIndex(
      ({ id }: any) => id === this.selectedPlaceholderId
    );
    const eventPlacedIndex = this.eventPlaced.findIndex(
      (event: any) => event?.id === this.selectedEvent.id
    );
    if (eventPlacedIndex >= 0) {
      this.eventPlaced[eventPlacedIndex] = null;
    }
    if (placeholderIndex >= 0) {
      this.eventPlaced[placeholderIndex] = _cloneDeep(this.selectedEvent);
    }
    this.selectedEvent = null;
    this.selectedPlaceholderId = null;
    this.turns++;
    if (!this.started) this.startGame();
    setTimeout(() => this.showCorrectEvent(), 500);
  }

  @Watch('gameCompleted')
  onGameCompleted () {
    if (this.gameCompleted) {
      this.pause();
      setTimeout(() => this.showCongratulations = true, 200)
    }
  }

  showCorrectEvent () {
    if (this.eventPlaced.filter((event: any) => event === null).length > 0)
      return;
    this.eventPlaced.forEach((event: any, index: number) => {
      Vue.set(this.eventPlaced, index, {
        ...event,
        correct: event.id === this.events[index].id
      });
    });
    setTimeout(() => this.cleanGame(), 1000);
  }

  cleanGame () {
    this.eventPlaced.forEach((event: any, index: number) => {
      if (!event.correct)
        Vue.nextTick(() => Vue.set(this.eventPlaced, index, null));
    });
  }

  get hasInstruction () {
    return this.timeline.options.isGame 
          && this.timeline.i18n.instruction 
          && this.timeline.i18n.instruction.value.text.length > 1
  }

  mounted () {
    this.eventPlaced = this.events.map(() => null);
    if (this.hasInstruction) this.showInstruction = true;
  }

  get timeline (): TimelineContentData {
    return this.content.data
  }

  get title () {
    return (
      this.timeline?.i18n?.title?.value || {
        text: 'Timeline',
        html: 'Timeline'
      }
    );
  }

  get text () {
    return this.timeline?.i18n?.text?.value || null;
  }

  get events () {
    return this.timeline?.events || [];
  }

  get suffledEvents () {
    return _shuffle(this.events);
  }

  get eventFound () {
    return this.eventPlaced.filter((event: any) => event?.correct).length;
  }

  async resetGame () {
    this.eventPlaced = this.events.map(() => null);
    this.turns = 0;
    this.started = false;
    this.startTime = 0;
    this.time = 0;
    this.timer = null;
    this.showCongratulations = false;
    if (this.hasInstruction) this.showInstruction = true;
  }

  startTimer () {
    if (!this.timer)
      this.timer = setInterval(() => {
        this.time++;
      }, 1000);
  }

  startGame () {
    this.started = true;
    this.paused = false;
    this.time = 0;
    this.startTimer();
  }

  pause () {
    this.paused = true;
    clearInterval(this.timer);
  }

  resume () {
    this.paused = false;
    this.startTimer();
  }

  getFormattedTime () {
    return moment(this.time * 1000).format('mm:ss');
  }

  finishGame () {
    this.started = false;
    clearInterval(this.timer);
    const score =
      1000 -
      (moment().diff(this.startTime, 'seconds') - this.events.length * 5) * 3 -
      (this.turns - this.events.length) * 5;
    this.score = Math.max(score, 0);
    this.showResult = true;
  }

  stopTimeline () {
    this.pause();
    this.openTimeline = false;
  }

  selectEvent (event: any | null) {
    if (!event) return (this.selectedEvent = null);
    if (this.selectedEvent?.id === event.id) this.selectedEvent = null;
    else this.selectedEvent = event;
  }

  selectPlaceholder (id: string) {
    if (this.selectedPlaceholderId === id) this.selectedPlaceholderId = null;
    else this.selectedPlaceholderId = id;
  }

  isPlaced (event: any) {
    if (!event) return null;
    return this.eventPlaced.find((e: any) => e?.id === event.id) || null;
  }

  isCorrect (event: any) {
    if (!event) return null;
    return this.isPlaced(event)?.correct || false;
  }

  get gameCompleted () {
    return this.eventFound === this.events.length;
  }

  startDrag (evt: any, itemId: any) {
    // console.log('startDrag', evt, itemId);
    // element.style.left = evt.gesture.touches[0].pageX;
    // element.style.top = evt.gesture.touches[0].pageY;
    if (!evt || !evt.dataTransfer || !itemId) return;
    const item = this.events.find(({ id }: any) => id === itemId);
    if (!item) return;
    this.selectEvent(item);
    // if (!evt?.dataTransfer) evt.dataTransfer = {}
    evt.dataTransfer.dropEffect = 'move';
    evt.dataTransfer.effectAllowed = 'move';
    evt.dataTransfer?.setData('itemId', itemId);
  }

  stopDrag = () => {
    this.selectEvent(null);
  };

  dragEnter = (evt: any) => {
    if (!evt?.target?.id?.includes('drop-area')) return;
    evt.target.style.backgroundColor = 'var(--primaryColor)';
  };

  dragLeave = (evt: any) => {
    if (!evt?.target?.id?.includes('drop-area')) return;
    evt.target.style.backgroundColor = '';
  };

  onDrop (evt: any, placeholderId: any) {
    if (!evt?.dataTransfer) return;
    evt.target.style.backgroundColor = '';
    this.selectPlaceholder(placeholderId);
  }

  restart () {
    this.resetGame();
    this.openTimeline = true;
  }
}
