// getters
export const GET_ALL = 'settings/GET_ALL'
export const GET_AVAILABLE = 'settings/GET_AVAILABLE'
export const GET_MENU_ITEMS = 'settings/GET_MENU_ITEMS'
export const GET_REALM = 'settings/GET_REALM'
// actions
export const FETCH = 'settings/FETCH'
export const FETCH_AVAILABLE = 'settings/FETCH_AVAILABLE'
export const SET_REALM = 'settings/SET_REALM'
// mutations
export const ADD = 'settings/ADD'
export const ADD_AVAILABLE = 'settings/ADD_AVAILABLE'
export const ADD_LOGO = 'settings/ADD_LOGO'
