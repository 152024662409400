
import { Component, Vue, Prop, Mixins, Watch } from 'vue-property-decorator'
import CustomTagMixin from '@/@next/mixins/CustomTag'
import { CLazyLoadingImage } from '@/components/images'
import { CLoader } from '@/components/loader'
import CModal from '@/components/modal'
import { CImage } from '@/@next/components/image'
import { CButton } from '@/components/form-controls/button'
import _shuffle from 'lodash/shuffle'
import _cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import Medias from '@/@next/services/Medias'
import { MediaImage } from '@/@types/graphql'
import addProtocol from '@/utils/addProtocol'
import { messages } from '@/@next/i18n'

const config = {
  name: 'c-content-memory',
  i18n: { messages },
  components: {
    CLazyLoadingImage,
    CLoader,
    CImage,
    ...CModal,
    CButton
  }
}

@Component(config)
export default class CContentMemory extends Mixins(CustomTagMixin, Medias) {

  @Prop({ type: Object, required: true }) content!: any
  @Prop({ type: String, required: false, default: '' }) contextId!: string

  openMemory: boolean = false
  showResult = false
  cards = []
  started = false
  startTime: any = 0
  turns = 0
  flipBackTimer: any = null
  timer: any = null
  time = 0
  score = 0
  paused: boolean = false
  // images: any = []
  // options: any = {
  //     versoType: 'favicon',
  //     versoImageSize: 'contain',
  //     versoBorder: true
  // }

  @Watch('openMemory')
  onOpenMemoryUpdate () {
      if (this.openMemory) {
          this.resetGame()
      } else {
          this.started = false
      }
  }

  get memory () {
    return this.content.data
  }

  get title () {
      return this.memory?.i18n?.title?.value || { text: 'Memory', html: 'Memory' }
  }

  get text () {
    return this.memory?.i18n?.text?.value || null
  }

  get images () {
    return this.memory?.cards.map((c: any) => {
        return {
            ...c.image,
            ...c.options
        }
    })
  }

  get versoImage () {
    return this.memory?.versoImage
  }

  get options () {
      return this.memory?.options
  }

  get versoStyle () {
      const backgroundImage =
          (this.options.versoType === 'image' && this.versoImage?.formats[0].file.url && `url(${addProtocol(this.versoImage.formats[0].file.url)})`)
          || (this.options.versoType === 'favicon' && 'url(/favicon-512x512.png)')
          || 'url(/img/games/memory/card_backside.jpg)'
      return {
          'backgroundImage': backgroundImage, 
          'backgroundSize': this.options.versoType !== 'default' && this.options.versoImageSize || 'cover'
      }
  }

  shuffleCards () {
      const cards = this.initCards(this.images)
      const double = [].concat(_cloneDeep(cards), _cloneDeep(cards));
      this.cards = _shuffle(double);
      this.paused = false;
  }

  initCards = (cards: any = []) => {
      cards.forEach((card: any) => {
          card.size = 'cover'
          card.flipped = false
          card.found = false
      })
      return cards
  }

  showImages () {
      this.cards = this.images.map((i: any) => {
          return { ...i, flipped: true, found: false } 
      })
      this.paused = true
  }

  flipCards () {
      this.cards = this.images.map((i: any) => {
          return { ...i, flipped: false, found: false } 
      })
  }

  async resetGame () {
      this.showResult = false;
      this.turns = 0;
      this.score = 0;
      this.started = false;
      this.startTime = 0;
      this.time = 0;
      this.showImages()
      setTimeout(() => { this.flipCards()}, 2000)
      setTimeout(() => { this.shuffleCards() }, 2400)
             
  }
  
  get flippedCards (): any {
      return this.cards.filter(({ flipped }: any) => flipped) || []
  }

  get foundCardNumber () {
      return this.cards.filter((c: any) => c.found).length
  }
  
  get isSameFlippedCards () {
      return this.flippedCards.length === 2 && this.flippedCards[0].id === this.flippedCards[1].id
  }
  
  setCardFounds () {
      this.cards.forEach((c: any) => {
          if (c.flipped) c.found = true
      })
  }
  
  get isAllCardFound () {
      return this.cards.length === this.cards.filter((c: any) => c.found).length
  }
  
  startTimer () {
      if (!this.timer)
      this.timer = setInterval(() => {
          this.time ++
      }, 1000);
  }

  startGame () {
      this.started = true;
      this.paused = false
      this.time = 0
      this.startTimer()
  }

  pause () {
      this.paused = true
      clearInterval(this.timer)
  }

  resume () {
      this.paused = false
      this.startTimer()
  }

  getFormattedTime () {
      return moment(this.time * 1000).format('mm:ss');
  }
  
  finishGame () {
      this.started = false;
      clearInterval(this.timer);
      const score = 1000 - 
          (moment().diff(this.startTime, 'seconds') - this.images.length * 5) * 3 - 
          (this.turns - this.images.length) * 5
      this.score = Math.max(score, 0);
      this.showResult = true;
  }
  
  flipCard (card: any) {
      if (this.paused) return
      if (card.found || card.flipped) return;
      
      if (!this.started) {
          this.startGame();
      }
      
      const flipCount = this.flippedCards.length;
      if (flipCount === 0) {
          card.flipped = !card.flipped;
      } else if (flipCount === 1) {
          card.flipped = !card.flipped;
          this.turns += 1;

          if (this.isSameFlippedCards) {
              // Match!
              this.flipBackTimer = setTimeout( () => {
                  this.clearFlipBackTimer();
                  this.setCardFounds();
                  this.clearFlips();

                  if (this.isAllCardFound) {
                      setTimeout(() => this.finishGame(), 1000)
                  }	

              }, 200);
          } else {
              // Wrong match
              this.flipBackTimer = setTimeout( () => {
                  this.clearFlipBackTimer();
                  this.clearFlips();
              }, 1000);
          }
      }
  }
  
  clearFlips () {
      this.cards.forEach((c: any) => c.flipped = false)
  }
  
  clearFlipBackTimer () {
      clearTimeout(this.flipBackTimer);
      this.flipBackTimer = null;
  }

  stopMemory () {
      this.pause()
      this.openMemory = false
  }
}
