export const MediaImageFragment = `
  fragment MediaImageFragment on MediaImage {
    id
    originalImageId
    slug
    credits
    file {
      url
      size
      width
      height
    }
    formats {
      name
      file {
        url
        size
        width
        height
      }
    }
  }
`

export const MediaAudioFragment = `
  fragment MediaAudioFragment on MediaAudio {
    id
    slug
    credits
    image { ...MediaImageFragment }
    file {
      url
      contentType
      size
      duration
    }
  }
`

export const MediaVideoFragment = `
  fragment MediaVideoFragment on MediaVideo {
    id
    slug
    credits
    screenshots { 
      url
      contentType
      size
      time
    }
    file {
      url
      contentType
      size
      duration
    }
  }
`
