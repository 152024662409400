import { GetterTree, Module } from 'vuex'
import { RootState } from '@/@next/store'

import { mutations } from './mutations'

import * as APP from '@/@next/store/app/types'

import { NetworkStatus } from '@/@next/@types'

export class AppState {
  network: NetworkStatus = {}
  modals: any[] = []  
}

const state: AppState = new AppState()

const getters: GetterTree<AppState, RootState> = {
  [APP.GET_NETWORK]: (state) => state.network,
  [APP.GET_MODALS]: (state) => state.modals,
}

export const app: Module<AppState, RootState> = {
  state,
  getters,
  mutations
}
