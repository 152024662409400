
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const config = {
  name: 'c-AuthorModalMediaFigure'
}

@Component(config)
export default class CAuthorModalMediaFigure extends Vue {
}
