
import { Vue, Component, Prop } from 'vue-property-decorator'
import { MediaImage } from '@/@types/graphql'
import { CImage } from '@/@next/components/image'
import { CLoader } from '@/components/loader'

@Component({
  name: 'c-question-image',
  components: { CImage, CLoader }
})
export default class CQuestionImage extends Vue {

  @Prop({ type: Object }) image!: MediaImage

}
