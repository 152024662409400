
import { Vue, Component, Prop } from 'vue-property-decorator'
import CustomWrapper from '@/components/CustomWrapper.vue'

@Component({
  name: 'c-section-content',
  components: { CustomWrapper }
})
export default class CSectionContent extends Vue {

  @Prop({ default: 'div'}) tag!: string

}
