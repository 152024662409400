import { GetterTree, Module } from 'vuex'
import { RootState } from '@/@next/store'

import * as GAME from './types'
import { actions } from './actions'
import { mutations } from './mutations'

export class GameState {
  games: any[] = [
    { id: 'fa762bb7-52ca-4b55-bb90-6e54f53959d6', completed: false, userAnswer: null, fail: null },
    { id: '5593d66b-1747-480d-8626-a2e3d201fa19', completed: false, userAnswer: null, fail: null },
    { id: '662c6afd-c0af-4eec-b13e-098b351d8841', completed: false, userAnswer: null, fail: null },
    { id: '446a5efe-9af3-4d63-b885-7dfbed08d584', completed: false, userAnswer: null, fail: null },
    { id: '7a5cad57-6c53-4103-9295-05ec7f16fa5c', completed: false, userAnswer: null, fail: null },
    { id: 'd40fe267-2d3f-45e1-aec3-56cb6be625fd', completed: false, userAnswer: null, fail: null },
    { id: '21f3625e-ef11-43d6-ac49-f0a8ec0d58f9', completed: false, userAnswer: null, fail: null }
  ]
}

const state = new GameState()

const getters: GetterTree<GameState, RootState> = {
  [GAME.GET_STATUS]: (state): any => {
    const gamesFinished = state.games.filter((game: any) => game.completed).length
    const gamesCount = state.games.length
    return gamesFinished === gamesCount
  },
  [GAME.GET_QUESTIONS]: (state): any => state.games,
  [GAME.IS_WINNED]: (state): any => {
    return state.games.length === state.games.filter((game: any) => !game.fail).length
  },

}

export const game: Module<GameState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
