
import {Component, Mixins, Vue, Watch} from 'vue-property-decorator'
import {Action, Getter} from 'vuex-class'

import * as UI from '@/event-bus'
import * as SETTINGS from '@/store/settings/types'

import _debounce from 'lodash/debounce'

import {messages} from './i18n'

import SEARCH from '@/graphql/search'
import POIS from '@/graphql/pois'

import {Person} from '@/shared/people'
import {Collection} from '@/shared/collections'

import {CSection, CSectionContent, CSectionTitle} from '@/components/section'
import {CInput} from '@/components/form-controls/input'
import {CCarousel, CCarouselItem} from '@/components/carousel'
import {CImage} from '@/@next/components/image'
import {CLoader} from '@/components/loader'
import {CAudioGuideCount} from '@/components/audioguide'
import { CTopbarTitle } from '@/@next/components/topbar-title'
import CPoiCard from '@/components/cards/poi-card'
import CCollectionCard from '@/components/cards/collection-card'
import Translatable from '@/mixins/Translatable'
import addProtocol from '@/utils/addProtocol';
import {API_URL} from '@/constants';
import Axios from 'axios';
import { SearchService } from '@/@next/services'
import { Nullable, SearchContentType } from '@/@next/@types'
import * as AUDIOGUIDE from '@/@next/store/audioguide/types'
import { filterPublishableItem } from '@/@next/helpers/publishable'
import CModal from '@/components/modal'
import format from 'date-fns/format'

const config = {
  name: 'v-search',
  components: {
    ...CCollectionCard,
    ...CPoiCard,
    CLoader,
    CImage,
    CAudioGuideCount,
    CInput,
    CSection,
    CSectionTitle,
    CSectionContent,
    CCarousel,
    CCarouselItem,
    CTopbarTitle,
    ...CModal
  },
  i18n: { messages },
  head () {
    return {
      title: this.$t('pageName'),
      titleTemplate: '%s – Wivi'
    }
  }
}

@Component(config)
export default class Search extends Mixins!(SearchService) {

  @Action(AUDIOGUIDE.SET_STATUS) setAudioguideStatus!: any
  @Action(AUDIOGUIDE.SET_PLAYLIST) setAudioguidePlaylist!: any
  @Action(AUDIOGUIDE.SET_POI) setAudioguidePoi!: any

  debounce: Nullable<any> = null
  result: Nullable<any> = null
  loading: boolean = false
  showPersonModal: boolean = false
  person: Person | null = null
  query: string = ''
  contentType: SearchContentType = SearchContentType.ALL

  async onFormChanged (e: Event) {
    this.query = (e.target as HTMLInputElement).value
    if (!this.query || !this.query.length) return
    this.loading = true
    await this.$router.replace({ query: { q: this.query }})
    clearTimeout(this.debounce)
    this.debounce = setTimeout(async () => {
      const result = this.query.length ?
        await this.GET_ALL_SEARCH_RESULT(this.query) :
        null
      this.result = {
        ...result,
        pointsOfInterest: filterPublishableItem(result.pointsOfInterest),
        collections: filterPublishableItem(result.collections),
      }
      this.loading = false
    }, 600)
  }

  get searchFail () {
    return this.result &&
           !(this.result.pointsOfInterest && this.result.pointsOfInterest.length) &&
           !(this.result.audioGuideTracks && this.result.audioGuideTracks.length) &&
           !(this.result.collections && this.result.collections.length) &&
           !(this.result.people && this.result.people.length)
  }

  onStartAudioTrackBtnClick (track: any) {
    this.setAudioguidePlaylist([track]).then(() => {
      this.setAudioguideStatus(true)
    })
  }

  datesString (bornAt: Date | null, diedAt: Date | null) {
    const formatDate = (d: Date) => format(new Date(d), 'DD/MM/YYYY')
    if (bornAt && diedAt) return `${formatDate(bornAt)} — ${formatDate(diedAt)}`
    if (bornAt && !diedAt) return `${formatDate(bornAt)}`
    if (!bornAt && diedAt) return ` * - ${formatDate(diedAt)}`
    if (!bornAt && !diedAt) return ``
  }

  showPerson (person: Person) {
    this.person = person
    this.showPersonModal = true
  }

}
