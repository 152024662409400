
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import * as AUDIOGUIDE from '@/store/audioguide/types'
import * as USER from '@/@next/store/user/types'

import { Nullable } from '../../shared'

import { CImage } from '@/@next/components/image'
import { CLoader } from '@/components/loader'
import { CButton } from '@/components/form-controls/button'
import {Route} from 'vue-router';
import { LanguagesMixin } from '@/@next/mixins'

const config = {
  name: 'l-default-audioguide',
  components: {
    CLoader,
    CImage,
    CButton
  }
}

@Component(config)
export default class LDefaultAudioguide extends Mixins(LanguagesMixin) {

  @Getter(AUDIOGUIDE.GET_STATUS) audioguideStatus!: boolean
  @Getter(AUDIOGUIDE.GET_PLAYLIST) audioguidePlaylist!: any
  @Action(AUDIOGUIDE.SET_STATUS) setAudioguideStatus!: any
  @Getter(AUDIOGUIDE.GET_POI) audioguidePoi!: any
  @Getter(AUDIOGUIDE.GET_CURRENT_POI) audioguideCurrentPoi!: any
  @Action(AUDIOGUIDE.SET_PLAYLIST) setAudioguidePlaylist!: any
  @Action(AUDIOGUIDE.SET_POI) setAudioguidePoi!: any
  // @Getter(USER.IS_SIGN_LANGUAGE_ENABLED) isSignLanguageEnabled!: boolean

  expand: boolean = false
  audioTag: Nullable<HTMLMediaElement> = null
  isPlayed: boolean = false
  currentTime: number = 0
  currentTrack: number = 0
  forceHideWarn: boolean = false
  videoReduiced: boolean = false

  mounted () {
    this.onTrackUpdate()
  }

  @Watch('currentTrack')
  @Watch('audioguidePlaylist')
  @Watch('isSignLanguageEnabled')
  onTrackUpdate () {
    this.resetMediaElement()
    Vue.nextTick(() => this.playCurrentTrack())
  }

  get isSignLanguageEnabled () {
    return this._languageTarget === 'signLanguage'
  }
  resetMediaElement () {
    if (!this.audioTag) return
    this.audioTag.pause()
    this.isPlayed = false
  }

  playCurrentTrack () {
    if (this.videoTrack) this.audioTag = this.$refs.video as HTMLVideoElement
    else this.audioTag = this.$refs.audio as HTMLAudioElement
    if (!this.audioTag) return
    this.audioTag.load()
    this.play()
  }

  get CSSClasses () {
    return {
      'is-Open': this.audioguideStatus,
      'is-FullScreen': this.expand
    }
  }

  get videoCssClasses () {
    return {
      'is-FullScreen': !this.expand
    }
  }

  get playIcon () {
    return !this.isPlayed ? 'Play' : 'Pause'
  }

  play () {
    if (!this.audioTag) return
    this.audioTag.play().then(() => { this.isPlayed = true })
    this.videoReduiced = false
  }
  onReduceBtnClick () {
    this.expand = false
  }

  onExpandBtnClick () {
    this.expand = !this.expand
  }

  onTrackItemClick (index: number) {
    if (!this.audioTag) return
    this.currentTrack = index
    if (this.audioTag.paused) this.play()
    
  }

  onPlayBtnClick () {
    if (!this.audioTag) return
    if (this.audioTag.paused) return this.play()
    this.isPlayed = false
    this.audioTag.pause()
    // this.videoReduiced = true
  }

  onCloseBtnClick () {
    this.setAudioguideStatus(false)
  }

  onAudioProgress () {
    if (this.audioTag) {
      this.currentTime = this.audioTag.currentTime
    }
  }

  onProgressBarClick (e: any) {
    window.addEventListener('mousemove', this.onProgressBarMove)
    window.addEventListener('touchmove', this.onProgressBarMove)
    window.addEventListener('mouseup', this.onProgressBarMouseUp)
    window.addEventListener('touchend', this.onProgressBarMouseUp)
    const clickPosX = e.clientX || e.touches[0].clientX
    const progressBarWidth = (this.$refs.progressBar as HTMLElement).offsetWidth
    const clickPosXPercent =  clickPosX * 100 / progressBarWidth
    if (this.audioTag) {
      const audioDuration = this.audioTag.duration
      const newCurrentTime = audioDuration * clickPosXPercent / 100
      this.audioTag.pause()
      this.audioTag.currentTime = newCurrentTime
      if (this.isPlayed) {
        this.audioTag.play()
      }
    }
  }

  onProgressBarMove (e: any) {
    const clickPosX = e.clientX || e.touches[0].clientX
    const progressBarWidth = (this.$refs.progressBar as HTMLElement).offsetWidth
    const clickPosXPercent =  clickPosX * 100 / progressBarWidth
    if (this.audioTag) {
      const audioDuration = this.audioTag.duration
      const newCurrentTime = audioDuration * clickPosXPercent / 100
      this.audioTag.currentTime = newCurrentTime
    }
  }

  onProgressBarMouseUp (e: Event) {
    window.removeEventListener('mousemove', this.onProgressBarMove)
    window.removeEventListener('touchmove', this.onProgressBarMove)     
    window.removeEventListener('mouseup', this.onProgressBarMouseUp)
    window.removeEventListener('touchend', this.onProgressBarMouseUp)
  }

  onTrackEnd () {
    if (this.audioguidePlaylist[this.currentTrack + 1]) {
      this.expand = true
      this.currentTrack++
    } else {
      this.isPlayed = false
      this.currentTime = 0
    }
  }

  goToTrack (index: number) {
    this.currentTrack += index
  }

  onStartAudioGuideBtnClick () {
    this.setAudioguidePlaylist([
      ...this.audioguideCurrentPoi.audioGuideTracks
    ]).then(() => {
      this.setAudioguidePoi(this.audioguideCurrentPoi)
      this.setAudioguideStatus(true)
      if (this.audioTag) {
        this.isPlayed = false
        this.currentTrack = 0
        this.audioTag.pause()
        this.audioTag.load()
        this.audioTag.play()
        this.isPlayed = true
      }
    })
  }

  get progressBarWitdth () {
    if (this.audioTag && this.currentTime) {
      return {
        'width': `${(this.currentTime * 100) / this.audioTag.duration}%`
      }
    }
  }

  @Watch('$route')
  onRouteChange () {
    this.forceHideWarn = false
    this.expand = false
    this.videoReduiced = this.isPlayed
  }

  @Watch('expand')
  onExpand () {
    this.videoReduiced = false
  }

  get showWarning () {
    if (!this.forceHideWarn && this.$route.name === 'single-poi' &&
         this.audioguideCurrentPoi &&
         this.audioguidePlaylist) {
      const poiTrackIds = this.audioguideCurrentPoi.audioGuideTracks.map((item: any) => item.id)
      const playlistTrackIds = this.audioguidePlaylist.map((item: any) => item.id)
      if (JSON.stringify(poiTrackIds) === JSON.stringify(playlistTrackIds)) return false
      else return true
    } else return false
    return false
  }

  // get signLanguageVideo () {
  //   if (!this.isSignLanguageEnabled) return null
  //   return this.audioguidePlaylist[this.currentTrack].i18n.audio.value.signLanguageVideo || null
  // }

  get audioTrack () {
    return this.audioguidePlaylist[this.currentTrack].i18n.audio?.value || null
  }

  get videoTrack () {
    return this.audioguidePlaylist[this.currentTrack].i18n.video?.value || null
  }

  get mediaType () {
    return this.isSignLanguageEnabled && this.videoTrack ? 'video' : 'audio'
  }

  get isAudioTrack () {
    return !this.isSignLanguageEnabled && !this.videoTrack && !!this.audioTrack
  }

  get trackDuration () {
    if (this.audioTrack) return this.audioTrack.file.duration
    if  (this.videoTrack) return this.videoTrack.file.duration
    return 0
  }
}
