import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

import * as LANGUAGES from '@/@next/store/languages/types'

import { Language, Scalars } from '@/@types/graphql'

@Component({ name: 'languages-mixin' })
export default class Languages extends Vue {

  @Getter(LANGUAGES.GET_ALL)
  _allLanguages!: Language[]
  @Getter(LANGUAGES.GET_CURRENT)
  _currentLanguage!: Scalars['String']
  @Getter(LANGUAGES.GET_DEFAULT)
  _defaultLanguage!: Scalars['String']
  @Getter(LANGUAGES.GET_ALL_ISO)
  _allLanguageCodes!: Array<Scalars['String']>
  @Getter(LANGUAGES.GET_TARGET)
  _languageTarget!: Scalars['String']
  @Getter(LANGUAGES.GET_TRANSLATABLE_TARGETS)
  _translatableTargets!: any[]

  @Getter(LANGUAGES.GET_ACCESSIBILITY)
  accessibility!: any

  @Mutation(LANGUAGES.SET_ALL)
  _setAllLanguages!: (languages: Language[]) => void
  @Mutation(LANGUAGES.SET_CURRENT)
  _setCurrentLanguage!: (code: Scalars['String']) => void
  @Mutation(LANGUAGES.SET_DEFAULT)
  _setDefaultLanguage!: (code: Scalars['String']) => void
  @Mutation(LANGUAGES.SET_TARGET)
  _setTarget!: (code: Scalars['String'] | null) => void
  @Mutation(LANGUAGES.SET_TRANSLATABLE_TARGETS)
  _setTranslatableTarget!: (translatableTargets: any[]) => void
  @Mutation(LANGUAGES.SET_ACCESSIBILITY) setAccessibility!: (props: any) => any[]

  @Watch('_currentLanguage')
  onCurrentLanguageChanged (iso: string) {
    const at = this.getAvailableTargetsForLanguage(iso)
    // if (!at.includes(this._languageTarget)) this._setTarget(null)
  }

  getAvailableTargetsForLanguage (iso: string | null = null) {
    const language = iso || this._currentLanguage
    return this._translatableTargets.filter((t: any) => t.languages.includes(language)).map((t: any) => t.target)
  }

  get availableTargets () {
    return this._translatableTargets
      .filter((t: any) => t.languages.includes(this._currentLanguage)).map((t: any) => t.target)
  }

  get textSize ()  {
    return this.accessibility.textSize
  }

  get wordSpacing () {
    return this.accessibility.wordSpacing
  }

  get greyScale () {
    return this.accessibility.greyScale
  }

  get highContrast () {
    return this.accessibility.highContrast
  }

  get dyslexicFont () {
    return this.accessibility.dyslexicFont
  }

  get defaultCssValues () {
    return this.accessibility.defaultCssValues
  }

  scaleText (direction: string) {
    if (direction === 'up' && this.textSize < 140) this.setAccessibility({ textSize: this.textSize + 20 })
    if (direction === 'down' && this.textSize > 100) this.setAccessibility({ textSize: this.textSize - 20 })
  }

  scaleWordSpacing (direction: string) {
    if (direction === 'up' && this.wordSpacing < 10) this.setAccessibility({ wordSpacing: this.wordSpacing + 5 })
    if (direction === 'down' && this.wordSpacing > 0) this.setAccessibility({ wordSpacing: this.wordSpacing - 5 })
  }

  setGreyScale (value: boolean) {
    this.setAccessibility({ greyScale: value })
  }

  setDyslexicFont (v: boolean) {
    this.setAccessibility({ dyslexicFont: v })
  }

  setHighContrast (v: boolean) {
    this.setAccessibility({ highContrast: v })
    const backgroundContrastColor: string = '#fff'
    const onBackgroundContrastColor: string = '#000'    
  }

}
