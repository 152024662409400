//
// Global
// ---------------------------
export type Nullable<T> = T | null
export type UUID = string
export type Slug = string

//
// Network
// ---------------------------
export interface NetworkStatus {
  status?: string
  duration?: number
}

//
// Medias
// ---------------------------
export enum MediaType {
  AUDIO = 'audio',
  GALLERY = 'gallery',
  IMAGE = 'image',
  VIDEO = 'video',
}

export interface MediaAudio {
  id: UUID
  slug: Nullable<Slug>
  credits: Nullable<string>
  file: AudioFile
  image: Nullable<MediaImage>
}

export interface MediaImage {
  id: UUID
  slug: Nullable<Slug>
  credits: Nullable<string>
  file: ImageFile
  formats: ImageFormat[]
}

export interface MediaVideo {
  id: UUID
  slug: Nullable<string>
  credits: Nullable<string>
  video: VideoFile
  screenshots: VideoScreenshotFile[]
}

// TODO
export interface MediaGallery {
  id: UUID
  slug: Nullable<Slug>
}

//
// Files
// ---------------------------
export interface AudioFile {
  url: string
  contentType: string
  size: number
  duration: number
}

export interface ImageFile {
  url: string
  contentType: string
  size: number
  width: number
  height: number
}

export interface ImageFormat {
  name: string
  file: ImageFile
}

export interface VideoFile {
  url: string
  contentType: string
  size: number
  duration: number
}

export interface VideoScreenshotFile {
  url: string
  contentType: string
  size: number
  time: number
}

//
// Language
// ---------------------------
export type LanguageCode = string

export interface Language {
  id: string
  name: string
  iso: LanguageCode
  code: string
  flag: string
}

//
// Translations
// ---------------------------
export interface HTMLTranslation {
  language?: LanguageCode
  value: {
    text: string
    html: string
  }
}

export interface StringTranslation {
  language?: LanguageCode
  value: string
}

export interface MediaAudioTranslation {
  language?: LanguageCode
  value: MediaAudio
}

export interface MediaImageTranslation {
  language?: LanguageCode
  value: MediaImage
}

export interface MediaVideoTranslation {
  language?: LanguageCode
  value: MediaVideo
}

//
// List
// ---------------------------
export type SortDirection = 'ascending' | 'descending'

export interface List<T> {
  itemsCount: number
  totalCount: number
  pagination: Nullable<PaginationOptions>
  sort: SortOptions
  items: T[]
}

export interface PaginationOptions {
  skip?: number
  take?: number
}

export interface SortOptions {
  field?: string
  direction?: SortDirection
}

//
// Realm
// ---------------------------
export interface Realm {
  id: UUID
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  i18n: {
    title: Nullable<HTMLTranslation>
    description: Nullable<HTMLTranslation>
    abstract: Nullable<HTMLTranslation>
  }
  collections: Collection[]
  pointsOfInterest: PointOfInterest[]
}

//
// PointOfInterest
// ---------------------------
export type PointOfInterestList = List<PointOfInterest>

export interface PointOfInterest {
  id: UUID
  slug: Nullable<Slug>
  shortcode: Nullable<string>
  languages: LanguageCode[]
  i18n: {
    title: Nullable<HTMLTranslation>
    description: Nullable<HTMLTranslation>
    abstract: Nullable<HTMLTranslation>
  }
  image: Nullable<MediaImage>
  collections: Collection[]
  contents: [Content]
  people: Person[]
  audioGuideTracks: AudioGuideTrack[]
}

//
// Collection
// ---------------------------
export type CollectionList = List<Collection>

export interface Collection {
  id: UUID
  slug: Nullable<Slug>
  shortcode: Nullable<string>
  languages: LanguageCode[]
  i18n: {
    title: Nullable<HTMLTranslation>
    description: Nullable<HTMLTranslation>
    abstract: Nullable<HTMLTranslation>
  }
  image: Nullable<MediaImage>
  pointsOfInterest: List<PointOfInterest>
}

//
// AudioGuideTrack
// ---------------------------
export interface AudioGuideTrack {
  id: UUID
  slug: Nullable<Slug>
  i18n: {
    title: Nullable<HTMLTranslation>
    description: Nullable<HTMLTranslation>
    abstract: Nullable<HTMLTranslation>
    text: Nullable<StringTranslation>
    audio: Nullable<MediaAudioTranslation>
  }
  image: Nullable<MediaImage>
}

//
// Person
// ---------------------------
export type PersonList = List<Person>

export interface Person {
  id: UUID
  i18n: {
    name: Nullable<HTMLTranslation>
    bio?: Nullable<HTMLTranslation>
  }
  bornAt: string
  diedAt: Nullable<string>
  image: Nullable<MediaImage>
  role?: PersonRole
}

export interface PersonRole {
  id: UUID
  slug: Nullable<Slug>
  i18n: {
    name: Nullable<StringTranslation>
  }
}

//
// Contents
// ---------------------------
export enum ContentType {
  AUDIO = 'AudioContent',
  AUDIO_GUIDE = 'AudioGuideContent',
  AUDIO_GUIDE_TRACK = 'AudioGuideTrackContent',
  COLLECTION = 'CollectionContent',
  IMAGE = 'ImageContent',
  IMAGE_GALLERY = 'ImageGalleryContent',
  PERSON = 'PersonContent',
  POINT_OF_INTEREST = 'PointOfInterestContent',
  PUZZLE = 'PuzzleContent',
  TEXT = 'TextContent',
  VIDEO = 'VideoContent'
}

export type Content =
  AudioContent | AudioGuideContent | AudioGuideTrackContent |
  CollectionContent | ImageContent | ImageGalleryContent |
  PersonContent | PointOfInterestContent | PuzzleContent |
  TextContent | VideoContent

interface AudioContent {
  id: UUID
  type: ContentType
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  people: Person[]
  data: {
    i18n: {
      title: Nullable<HTMLTranslation>
      description: Nullable<HTMLTranslation>
      audio: Nullable<MediaAudioTranslation>
    }
  }
}

interface AudioGuideContent {
  id: UUID
  type: ContentType
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  people: Person[]
  data: {
    id: UUID
    audioGuide: {
      id: UUID
      slug: Nullable<Slug>
      shortCode: Nullable<string>
      languages: LanguageCode[]
      i18n: {
        title: Nullable<HTMLTranslation>
        description: Nullable<HTMLTranslation>
        abstract: Nullable<HTMLTranslation>
      }
    }
  }
}

interface AudioGuideTrackContent {
  id: UUID
  type: ContentType
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  people: Person[]
  data: {
    id: UUID
    audioGuideTrack: {
      id: UUID
      slug: Nullable<Slug>
      shortCode: Nullable<string>
      languages: LanguageCode[]
      i18n: {
        title: Nullable<HTMLTranslation>
        description: Nullable<HTMLTranslation>
        abstract: Nullable<HTMLTranslation>
        text: Nullable<StringTranslation>
        audio: Nullable<MediaAudioTranslation>
      }
    }
  }
}

interface CollectionContent {
  id: UUID
  type: ContentType
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  people: Person[]
  data: {
    id: UUID
    collection: {
      id: UUID
      slug: Nullable<Slug>
      shortCode: Nullable<string>
      languages: LanguageCode[]
      i18n: {
        title: Nullable<HTMLTranslation>
        description: Nullable<HTMLTranslation>
        abstract: Nullable<HTMLTranslation>
      }
    }
  }
}

interface ImageContent {
  id: UUID
  type: ContentType
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  people: Person[]
  data: {
    i18n: {
      title: Nullable<HTMLTranslation>
      description: Nullable<HTMLTranslation>
      image: Nullable<MediaImageTranslation>
    }
  }
}

interface ImageGalleryContent {
  id: UUID
  type: ContentType
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  people: Person[]
  data: {
    i18n: {
      title: Nullable<HTMLTranslation>
      description: Nullable<HTMLTranslation>
      images: any[]
    }
  }
}

interface PersonContent {
  id: UUID
  type: ContentType
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  people: Person[]
  data: {
    id: UUID
    person: {
      i18n: {
        name: Nullable<HTMLTranslation>
        bio: Nullable<HTMLTranslation>
      }
    }
  }
}

interface PointOfInterestContent {
  id: UUID
  type: ContentType
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  people: Person[]
  data: {
    id: UUID
    pointOfInterest: {
      id: UUID
      slug: Nullable<Slug>
      shortCode: Nullable<string>
      languages: LanguageCode[]
      people: Person[]
      i18n: {
        title: Nullable<HTMLTranslation>
        description: Nullable<HTMLTranslation>
        abstract: Nullable<HTMLTranslation>
      }
    }
  }
}

interface PuzzleContent {
  id: UUID
  type: ContentType
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  people: Person[]
  data: {
    xPieces: number
    yPieces: number
    timer: number
    image: MediaImage
    i18n: {
      title: Nullable<HTMLTranslation>
      description: Nullable<HTMLTranslation>
    }
  }

}

interface TextContent {
  id: UUID
  type: ContentType
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  people: Person[]
  data: {
    i18n: {
      body: Nullable<HTMLTranslation>
    }
  }

}

interface VideoContent {
  id: UUID
  type: ContentType
  slug: Nullable<Slug>
  shortCode: Nullable<string>
  languages: LanguageCode[]
  people: Person[]
  data: {
    i18n: {
      title: Nullable<HTMLTranslation>
      description: Nullable<HTMLTranslation>
      video: Nullable<MediaVideoTranslation>
    }
  }

}

//
// Search
// ---------------------------
export enum SearchContentType {
  ALL = 'ALL',
  AUDIOGUIDES = 'AUDIOGUIDES',
  COLLECTIONS = 'COLLECTIONS',
  PEOPLE = 'PEOPLE',
  POIS = 'POIS'
}
