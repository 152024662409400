export const messages = {
  fr: {
    pageTitle: {
      settings: 'Paramètres',
    },
    homePageLoading: 'Chargement de la page d\'accueil...',
    dataFrom: 'Les contenus de l`application :',
    available: 'sont disponibles uniquement sur le site suivant : ',
    needGeolocation: 'Afin de pouvoir accéder à l\'application, vous devez être géolocalisé.',
    enableGeolocation: 'Activer la géolocalisation',
    thankToAccept: 'Merci d\'accepter la demande d\'autorisation faite par votre navigateur afin que cette application puisse accèder à vos données GPS',
    locationLoading: 'Localisation en cours...',
    geolocationDisabled: 'Humm... La géolocalisation ne semble pas autorisé par votre navigateur.',
    authorizedBlockGeolocation: 'Merci de modifier les droits d\'accès aux données de géolocalisation pour l\'application sur ce navigateur.',
    wrongLocation: 'Vous n\'avez pas accès hors site.',
    geolocationNotAvailable: 'Votre navigateur ne prend pas en charge la géolocalisation',
    language: {
      selectedLanguage: 'Langue d\'utilisation',
      selectedTarget: 'Spécialisation / Mode de lecture',
      standard: 'Standard',
      childMode: 'Niveau enfant',
      signLanguage: 'Langue des signes'
    },
    accessibility: {
      title: 'Accessibilité',
      textSize: 'Taille du texte',
      wordSpacing: 'Espacement des mots',
      greyScale: 'Niveau de gris',
      dyslexicFont: 'Police dyslexique',
      highContrast: 'Contraste élevé',
    },
    configuration: {
      welcome: '👋 Bienvenue !',
      start: 'Valider',
      skip: 'Passer',
      next: 'Suivant',
      title: 'Configuration',
      subtitle: `Pour que votre visite soit la plus confortable possible :`,
      languageStep: '1️⃣ Sélectionnez votre langue',
      accessibilityStep: '2️⃣ Ajustez l\'accessibilité',
      congratulation: '👍 Félicitations !',
      congratulationText: 'Votre visite est prête.<br><br>Modifiez vos préférences à tout moment dans les paramètres de l\'application.',
      congratulationEnd: 'Bonne découverte !',
    },
    game: {
      memory: {
        memory: 'Jeu de mémoire',
        start: 'Jouer',
        restart: 'Rejouer',
        congrats: 'Félicitations'
      },
      timeline: {
        timeline: 'Frise Chronologique',
        start: 'Jouer',
        info: 'en savoir plus',
        restart: 'Refaire la frise chronologique',
        congrats: 'Félicitations',
        showTimeline: 'Voir le resultat',
        showInformation: 'Revoir les félicitations'
      }
    },
    enterTiketNumber: 'Numéro de billet Audioguide',
    validate: 'Valider mon billet',
    buyTicket: 'Acheter un billet',
    ticketAvailableOn: 'Date limite de validité : ',
    lockText: 'Un billet audioguide est nécessaire pour accéder à ce contenu',
    unLock: 'Déverrouiller',
    ticketStatus: {
      tooEarly: 'Votre Billet n\'est pas encore valide',
      tooLate: 'Votre Billet n\'est plus valide ',
      alreadyUsedByAnother: 'Ce billet est en cours d\'utilisation sur un autre appareil',
      alreadyUsedByMany: 'Le nombre d\'utilisation maximale de ce billet pour votre groupe est atteint.',
      none: 'Vous n\'avez aucun billet enregistré',
      pending: 'Demande de validation en attente',
      notExist: 'Ce billet n\'existe pas.',
      invalidDate: 'Votre billet n\'est actuellement pas valide',
      alreadyUsed: 'Votre billet est déjà utilisé',
      valid: 'Votre billet est valide',
      error: 'Un erreur est survenu avec ce billet'
    }
  },
  en: {
    pageTitle: {
      settings: 'Settings',
    },
    homePageLoading: 'Home page loading...',
    dataFrom: 'The contents of the application:',
    available: 'are available only on the following site: ',
    needGeolocation: 'In order to access the application, you must be geolocated.',
    enableGeolocation: 'Activate geolocation',
    thankToAccept: 'Please accept the authorization request made by your browser so that this application can access your GPS data',
    locationLoading: 'Location in progress...',
    geolocationDisabled: 'Hmmm... Geolocation does not seem to be allowed by your browser.',
    authorizedBlockGeolocation: 'Please modify the access rights to the geolocation data for the application on this browser.',
    wrongLocation: 'You do not have off-site access.',
    geolocationNotAvailable: 'Your browser does not support geolocation',
    language: {
      selectedLanguage: 'Language',
      selectedTarget: 'Specialization / Reading mode',
      standard: 'Standard',
      childMode: 'Child level',
      signLanguage: 'Sign language'
    },
    accessibility: {
      title: 'Accessibility',
      textSize: 'Text size',
      wordSpacing: 'Word spacing',
      greyScale: ' Grey scale',
      dyslexicFont: 'Dyslexic font',
      highContrast: 'High contrast',
    },
    configuration: {
      welcome: '👋 Welcome',
      selectedLanguage: 'Selected language',
      selectedTarget: 'Reading mode',
      start: 'Start',
      standard: 'Standard',
      childMode: 'Child Level',
      signLanguage: 'Sign language',
      skip: 'Skip',
      next: 'Next',
      title: 'Configuration',
      subtitle: `In order to have the best possible experience:`,
      languageStep: '1️⃣ Select your language',
      accessibilityStep: '2️⃣ Adjust the accessibility',
      congratulation: '👍 Congratulations!',
      congratulationText: 'Your visit is ready.<br><br>Modify your preferences at any time in the application settings. ',
      congratulationEnd: 'Enjoy your visit!',
    },
    game: {
      memory: {
        memory: 'Memory game',
        start: 'Start',
        restart: 'Restart',
        congrats: 'Congratulation'
      },
      timeline: {
        timeline: 'Timeline',
        start: 'Play',
        info: 'more information',
        restart: 'Make again',
        congrats: 'Congrats',
        showTimeline: 'Show completed timeline',
        showInformation: 'Show congratulations'
      }
    },
    enterTiketNumber: 'Audioguide ticket number',
    validate: 'Validate my ticket',
    buyTicket: 'Buy a ticket',
    ticketAvailableOn: 'Expiration date: ',
    lockText: 'Valid audioguide ticket is required to access this content',
    unLock: 'Unlock',
    ticketStatus: {
      tooEarly: 'Your ticket is not valid yet',
      tooLate: 'Your ticket is no longer valid',
      alreadyUsedByAnother: 'This ticket is being used on another device',
      alreadyUsedByMany: 'The maximum number of uses of this ticket for your group has been reached',
      none: 'You do not have any registered tickets',
      pending: 'Pending validation request',
      notExist: 'This ticket does not exist.',
      invalidDate: 'Your ticket is currently not valid',
      alreadyUsed: 'Your ticket is already used',
      valid: 'Your ticket is valid',
      error: 'An error occurred with this post'
    }
  },
  de: {
    homePageLoading: 'Home page loading...',
    dataFrom: 'The contents of the application:',
    available: 'are available only on the following site: ',
    needGeolocation: 'In order to access the application, you must be geolocated.',
    enableGeolocation: 'Activate geolocation',
    thankToAccept: 'Please accept the authorization request made by your browser so that this application can access your GPS data',
    locationLoading: 'Location in progress...',
    geolocationDisabled: 'Hmmm... Geolocation does not seem to be allowed by your browser.',
    authorizedBlockGeolocation: 'Please modify the access rights to the geolocation data for the application on this browser.',
    wrongLocation: 'You do not have off-site access.',
    geolocationNotAvailable: 'Your browser does not support geolocation',
    enterTiketNumber: 'Audioguide-Ticketnummer',
    validate: 'Mein Ticket validieren',
    buyTicket: 'Ein Ticket kaufen',
    ticketAvailableOn: 'Verfallsdatum: ',
    lockText: 'Für den Zugriff auf diesen Inhalt ist ein Audioguide-Ticket erforderlich',
    unLock: 'freischalten',
    ticketStatus: {
      tooEarly: 'Ihr Ticket ist noch nicht gültig',
      tooLate: 'Ihr Ticket ist nicht mehr gültig',
      alreadyUsedByAnother: 'Dieses Ticket wird auf einem anderen Gerät verwendet',
      alreadyUsedByMany: 'Die maximale Anzahl der Nutzungen dieses Tickets für Ihre Gruppe wurde erreicht',
      none: 'Sie haben keine registrierten Tickets',
      pending: 'Anstehende Validierungsanfrage',
      notExist: 'Dieses Ticket existiert nicht.',
      invalidDate: 'Ihr Ticket ist derzeit nicht gültig',
      alreadyUsed: 'Ihr Ticket ist bereits verwendet',
      valid: 'Ihr Ticket ist gültig',
      error: 'Bei diesem Beitrag ist ein Fehler aufgetreten'
    }
  },
  nl: {
    homePageLoading: 'Home page loading...',
    dataFrom: 'The contents of the application:',
    available: 'are available only on the following site: ',
    needGeolocation: 'In order to access the application, you must be geolocated.',
    enableGeolocation: 'Activate geolocation',
    thankToAccept: 'Please accept the authorization request made by your browser so that this application can access your GPS data',
    locationLoading: 'Location in progress...',
    geolocationDisabled: 'Hmmm... Geolocation does not seem to be allowed by your browser.',
    authorizedBlockGeolocation: 'Please modify the access rights to the geolocation data for the application on this browser.',
    wrongLocation: 'You do not have off-site access.',
    geolocationNotAvailable: 'Your browser does not support geolocation',
    enterTiketNumber: 'Audiogids ticketnummer',
    validate: 'Valideer mijn ticket',
    buyTicket: 'Koop een ticket',
    ticketAvailableOn: 'Vervaldatum: ',
    lockText: 'Een audioguide ticket is vereist om toegang te krijgen tot deze inhoud',
    unLock: 'Ontgrendelen',
    ticketStatus: {
      tooEarly: 'Uw ticket is nog niet geldig',
      tooLate: 'Uw ticket is niet langer geldig',
      alreadyUsedByAnother: 'Dit ticket wordt op een ander apparaat gebruikt',
      alreadyUsedByMany: 'Het maximale aantal keren dat uw groep dit ticket kan gebruiken is bereikt',
      none: 'U heeft geen geregistreerde tickets',
      pending: 'In afwachting van validatieverzoek',
      notExist: 'Dit ticket bestaat niet..',
      invalidDate: 'Uw ticket is momenteel niet geldig',
      alreadyUsed: 'Uw ticket is al gebruikt',
      valid: 'Uw ticket is geldig',
      error: 'Er is een fout opgetreden bij dit bericht'
    }
  },
  es: {
    homePageLoading: 'Home page loading...',
    dataFrom: 'The contents of the application:',
    available: 'are available only on the following site: ',
    needGeolocation: 'In order to access the application, you must be geolocated.',
    enableGeolocation: 'Activate geolocation',
    thankToAccept: 'Please accept the authorization request made by your browser so that this application can access your GPS data',
    locationLoading: 'Location in progress...',
    geolocationDisabled: 'Hmmm... Geolocation does not seem to be allowed by your browser.',
    authorizedBlockGeolocation: 'Please modify the access rights to the geolocation data for the application on this browser.',
    wrongLocation: 'You do not have off-site access.',
    geolocationNotAvailable: 'Your browser does not support geolocation',
    enterTiketNumber: 'Número de billete de la audioguía',
    validate: 'Validar mi billete',
    buyTicket: 'Comprar un billete',
    ticketAvailableOn: 'Fecha de caducidad : ',
    lockText: 'Para acceder a este contenido se necesita un billete de audioguía',
    unLock: 'Desbloquear',
    ticketStatus: {
      tooEarly: 'Su billete aún no es válido',
      tooLate: 'Su billete ya no es válido',
      alreadyUsedByAnother: 'Este billete se está utilizando en otro dispositivo',
      alreadyUsedByMany: 'Se ha alcanzado el número máximo de usos de este billete para su grupo',
      none: 'No tiene ninguna entrada registrada',
      pending: 'Solicitud de validación pendiente',
      notExist: 'Este billete no existe',
      invalidDate: 'Su billete no es válido actualmente',
      alreadyUsed: 'Su billete ya está utilizado',
      valid: 'Su billete es válido',
      error: 'Se ha producido un error en este mensaje'
    }
  },
  it: {
    homePageLoading: 'Home page loading...',
    dataFrom: 'The contents of the application:',
    available: 'are available only on the following site: ',
    needGeolocation: 'In order to access the application, you must be geolocated.',
    enableGeolocation: 'Activate geolocation',
    thankToAccept: 'Please accept the authorization request made by your browser so that this application can access your GPS data',
    locationLoading: 'Location in progress...',
    geolocationDisabled: 'Hmmm... Geolocation does not seem to be allowed by your browser.',
    authorizedBlockGeolocation: 'Please modify the access rights to the geolocation data for the application on this browser.',
    wrongLocation: 'You do not have off-site access.',
    geolocationNotAvailable: 'Your browser does not support geolocation',
    enterTiketNumber: 'Numero del biglietto dell\'audioguida',
    validate: 'Convalidare il mio biglietto',
    buyTicket: 'Comprare un biglietto',
    ticketAvailableOn: 'Data di scadenza: ',
    lockText: 'Per accedere a questo contenuto è necessario un biglietto dell\'audioguida',
    unLock: 'Sblocca',
    ticketStatus: {
      tooEarly: 'Il suo biglietto non è ancora valido',
      tooLate: 'Il tuo biglietto non è più valido',
      alreadyUsedByAnother: 'Questo biglietto è utilizzato su un altro dispositivo',
      alreadyUsedByMany: 'Il numero massimo di usi di questo biglietto per il tuo gruppo è stato raggiunto',
      none: 'Non hai nessun biglietto registrato',
      pending: 'Richiesta di convalida in sospeso',
      notExist: 'Questo biglietto non esiste',
      invalidDate: 'Il suo biglietto non è attualmente valido',
      alreadyUsed: 'Il tuo biglietto è già usato',
      valid: 'Il tuo biglietto è valido',
      error: 'Si è verificato un errore con questo post'
    }
  }
}
