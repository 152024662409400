





















































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { LanguagesMixin } from '../mixins'
import { CModal } from '@/components/modal'
import { CButton, CButtonIcon } from '@/components/form-controls/button'
import { CRadio, CRadioButton, CRadioButtonIcon, CRadioButtonLabel } from '@/components/form-controls/radio'
import CLanguageSelector from './LanguageSelector.vue'
import { messages } from '@/@next/i18n'
import CLanguageList from '@/@next/components/LanguageList.vue'
import * as UI from '@/event-bus'
import { SvgIcon, IconCheck, IconTextSize, IconWordSpacing, IconContrast, IconGreyScale, IconDyslexic} from '@/@next/components/icons'

const config = {
  name: 'c-accessibility',
  i18n: { messages },
  components: {
    CModal,
    CRadioButton,
    CRadioButtonIcon,
    CRadioButtonLabel,
    CButton,
    CButtonIcon,
    CRadio,
    CLanguageSelector,
    CLanguageList,
    SvgIcon, IconCheck, IconTextSize, IconWordSpacing, IconContrast, IconGreyScale, IconDyslexic
  }
}

@Component(config)
export default class CAccessibility extends Mixins(LanguagesMixin) {
  @Prop({required: false, default: null}) title!: string | null
}
