
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'

import CustomTagMixin from '@/@next/mixins/CustomTag'
import { QuestionContent } from '@/@types/graphql'
import { messages } from '@/views/single-poi/i18n'
import _shuffle from 'lodash/shuffle'
import CModal from '@/components/modal'
import { CSection, CSectionTitle, CSectionContent } from '@/components/section'
import { CButton, CButtonLabel, CButtonIcon } from '@/components/form-controls/button'
import Medias from '@/@next/services/Medias'
import CQuestion from '@/components/question/Question.vue'

const config = {
  name: 'c-content-question',
  i18n: { messages },
  components: {
    ...CModal,
    CSection,
    CSectionTitle,
    CSectionContent,
    CButton,
    CButtonLabel,
    CButtonIcon,
    CQuestion,
  }
}
@Component(config)

export default class CContentQuestion extends Mixins(CustomTagMixin, Medias) {

  @Prop({ type: Object, required: true }) content!: QuestionContent
  @Prop({ type: String, required: false, default: '' }) contextId!: string

  questionModalIsOpen: boolean = false
  isFinished: boolean = false
  isCompleted: boolean = false
  isSubmittable: boolean = true
  success: boolean = false
  showClue: boolean = false

  get question () {
    return this.content.data.question
  }

  get statement () {
    return this.question?.i18n.title?.value.html || ''
  }

  get answerItems () {
    const items = this.question?.data.answer.items || []
    const isRandomDisplayItem = this.question?.data.answer.randomDisplay || false
    return isRandomDisplayItem ? _shuffle(items) : items
  }

  async onQuestionSubmit () {
    this.isFinished = true
  }

  scrollOnComment () {
    // TODO : refaire avec un handler sur le chargement de l'image de commentaire
    Vue.nextTick(() => {
      const comment = document.getElementById('question-comment')
      if (!comment) return
      comment.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      setTimeout(() => {
        const comment = document.getElementById('question-comment')
        if (!comment) return
        comment.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      }, 250)
    })

  }

  get comment () {
    if (!this.isFinished || !this.question) return null
    const unique = this.question.data.comment.unique
    if (!this.success && !unique) return this.question.data.comment.wrong
    return this.question.data.comment.right
  }

  get clue () {
    if (!this.question) return null
    return this.question.data.clue
  }

  toggleClue () {
    this.showClue = !this.showClue
  }
}
