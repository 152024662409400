
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Nullable} from '@/shared'
import { Getter } from 'vuex-class'
import * as BOT from '@/store/bot/types'

@Component({
  name: 'c-bot-icon'
})
export default class CBotIcon extends Vue {

  @Getter(BOT.GET_UNREAD_MESSAGE_COUNT)
  botUnReadMessages!: number

  @Prop({ type: Boolean, default: false }) draggable!: boolean
  pos1: number = 0
  pos2: number = 0
  pos3: number = 0
  pos4: number = 0
  iconDOMNode: Nullable<HTMLElement> = null

  mounted () {
    if (this.draggable) {
      this.iconDOMNode = this.$refs.icon as HTMLElement
      this.initDrag()
    }
  }

  initDrag () {
    if (this.iconDOMNode) {
      this.iconDOMNode.addEventListener('mousedown', this.onDragMouseDown)
      this.iconDOMNode.addEventListener('touchstart', this.onDragMouseDown)
    }
  }

  onDragMouseDown (e: any) {
    const touch = e.touches
    if (touch && touch[0]) {
      this.pos3 = touch[0].clientX
      this.pos4 = touch[0].clientY
    } else {
      e.preventDefault()
      e.stopPropagation()
      this.pos3 = e.clientX
      this.pos4 = e.clientY
    }

    document.addEventListener('mouseup', this.onDragMouseUp)
    document.addEventListener('touchend', this.onDragMouseUp)
    document.addEventListener('mousemove', this.onDragMouseMove, { passive: false })
    document.addEventListener('touchmove', this.onDragMouseMove, { passive: false })
  }

  onDragMouseUp (e: any) {
    if (e.cancelable) {
      e.preventDefault()
    }
    document.removeEventListener('mouseup', this.onDragMouseUp)
    document.removeEventListener('touchend', this.onDragMouseUp)
    document.removeEventListener('mousemove', this.onDragMouseMove)
    document.removeEventListener('touchmove', this.onDragMouseMove)

  }

  onDragMouseMove (e: any) {
    if (e.cancelable) {
      e.preventDefault()
    }
    const touch = e.touches
    if (touch && touch[0]) {
      this.pos1 = this.pos3 - touch[0].clientX
      this.pos2 = this.pos4 - touch[0].clientY
      this.pos3 = touch[0].clientX
      this.pos4 = touch[0].clientY
    } else {
      this.pos1 = this.pos3 - e.clientX
      this.pos2 = this.pos4 - e.clientY
      this.pos3 = e.clientX
      this.pos4 = e.clientY
    }
  }

  onSingleTap () {
    this.$emit('click')
  }

  get draggableIconStyle () {
    if (this.draggable && this.iconDOMNode) {
      return {
        top: `${ this.iconDOMNode.offsetTop - this.pos2 }px`,
        left: `${ this.iconDOMNode.offsetLeft - this.pos1 }px`
      }
    }
  }

  get botIconCSSClasses () {
    return {
      '--Bounce': this.botUnReadMessages > 0
    }
  }

}
