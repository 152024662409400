import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { Lock, Maybe } from '@/@types/graphql'

// import * as AUTHORIZATION from './types'

export enum TicketStatus {
  none = 'none',
  pending = 'pending',
  notExist = 'notExist',
  tooEarly= 'tooEarly',
  tooLate = 'tooLate',
  alreadyUsedByAnother = 'alreadyUsedByAnother',
  alreadyUsedByMany = 'alreadyUsedByMany',
  valid = 'valid',
  error = 'error'
}

export class AuthorizationState {
  positionWatcher: any
  authorizations: string[] = []
  externalAuthServiceSocket: any
  showTicketInput: boolean = false
  currentLock: Maybe<Lock> = null
  userId: Maybe<string> = null
  ticketStatus: TicketStatus = TicketStatus.none
  ticketData: any
}

export const GET_USER_AUTHORIZATIONS = 'authorization/GET_USER_AUTHORIZATIONS'
export const ADD_AUTHORIZATIONS = 'authorization/ADD_AUTHORIZATIONS'
export const REMOVE_AUTHORIZATIONS = 'authorization/REMOVE_AUTHORIZATIONS'
export const SET_EXTERNAL_AUTHORIZATION_SOCKET = 'authorization/SET_EXTERNAL_AUTHORIZATION_SOCKET'
export const CHECK_IF_AUTHORIZATION_EXIST = 'authorization/CHECK_IF_AUTHORIZATION_EXIST'
export const GET_EXTERNAL_AUTHORIZATION_SOCKET = 'authorization/GET_EXTERNAL_AUTHORIZATION_SOCKET'
export const HAS_AUTHORIZATIONS = 'authorization/HAS_AUTHORIZATIONS'
export const SHOW_TICKET_INPUT = 'authorization/SHOW_TICKET_INPUT'
export const SET_SHOW_TICKET_INPUT = 'authorization/SET_SHOW_TICKET_INPUT'
export const SET_TICKET_INPUT = 'authorization/SET_TICKET_INPUT'
export const RESSOURCE_IS_AUHTORIZED = 'authorization/RESSOURCE_IS_AUHTORIZED'
export const GET_CURRENT_LOCK = 'authorization/GET_CURRENT_LOCK'
export const SET_CURRENT_LOCK = 'authorization/SET_CURRENT_LOCK'
export const GET_TICKET_STATUS = 'authorization/GET_TICKET_STATUS'
export const SET_TICKET_STATUS = 'authorization/SET_TICKET_STATUS'
export const SET_TICKET_DATA = 'authorization/SET_TICKET_DATA'
export const GET_TICKET_DATA = 'authorization/GET_TICKET_DATA'
// User
export const SET_USER_ID = 'authorization/SET_USER_ID'
export const GET_USER_ID = 'authorization/GET_USER_ID'

const state = new AuthorizationState()

const getters: GetterTree<AuthorizationState, RootState> = {
  [GET_USER_AUTHORIZATIONS]: (state): any => state.authorizations,
  [GET_EXTERNAL_AUTHORIZATION_SOCKET]: (state): any => state.externalAuthServiceSocket,
  [SHOW_TICKET_INPUT]: (state): any => state.showTicketInput,
  [GET_CURRENT_LOCK]: (state): any => state.currentLock,
  [GET_USER_ID]: (state): Maybe<string> => state.userId,
  [GET_TICKET_STATUS]: (state): TicketStatus => state.ticketStatus,
  [GET_TICKET_DATA]: (state): any => state.ticketData
}

const actions: ActionTree<AuthorizationState, RootState> = {
  [ADD_AUTHORIZATIONS] ({ state, commit }, authorizations: string[]) {
    const arr = [...state.authorizations, ...authorizations]
    commit(ADD_AUTHORIZATIONS, [...new Set(arr)])
  },
  [REMOVE_AUTHORIZATIONS] ({ state, commit }, authorizations: string[]) {
    const arr = state.authorizations
    authorizations.forEach((a: string) => {
      const i = state.authorizations.indexOf(a)
      if (i !== -1) arr.splice(i, 1)
    })
    commit(ADD_AUTHORIZATIONS, [...new Set(arr)])
  },
  [HAS_AUTHORIZATIONS] ({ state }, authorizations: string[]): boolean {
    const commonAuthorizations = state.authorizations.filter((s: string) => authorizations.includes(s));
    return commonAuthorizations.length === authorizations.length
  },
  [SET_TICKET_INPUT] ({ commit }, lock: Lock) {
    commit(SET_CURRENT_LOCK, lock)
    commit(SET_SHOW_TICKET_INPUT, true)
  },
  [SET_TICKET_STATUS] ({ commit }, status: TicketStatus) {
    commit(SET_TICKET_STATUS, status)
  }
}

const mutations: MutationTree<AuthorizationState> = {
  [SET_EXTERNAL_AUTHORIZATION_SOCKET] (state, socket: any) {
    console.log('SET_EXTERNAL_AUTHORIZATION_SOCKET', socket)
    state.externalAuthServiceSocket = socket
  },
  [ADD_AUTHORIZATIONS] (state, authorizations: string[]) {
    state.authorizations = authorizations
  },
  [SET_SHOW_TICKET_INPUT] (state, show: boolean) {
    state.showTicketInput = show
    if (!show) state.currentLock = null
  },
  [SET_CURRENT_LOCK] (state, lock: Lock) {
    state.currentLock = lock
  },
  [SET_USER_ID] (state, userId: string) {
    state.userId = userId
  },
  [SET_TICKET_STATUS] (state, status: TicketStatus) {
    state.ticketStatus = status
  },
  [SET_TICKET_DATA] (state, data: any) {
    state.ticketData = data
  }
}

export const authorization: Module<AuthorizationState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
