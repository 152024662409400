
import { Mixins, Component } from 'vue-property-decorator'
import { messages } from './i18n'
import { CLIENT } from '@/constants'
import { Maybe, CollectionList, Collection, Lock } from '@/@types/graphql'
import sortByPositionKey from '@/utils/sortByPositionKey'
import { Mutation } from 'vuex-class'
import { CollectionsService } from '@/@next/services'
import { AuthorizationMixin, LanguagesMixin } from '@/@next/mixins'
import * as AUTHORIZATION from '@/@next/store/authorization'
import { CAsyncTask } from '@/@next/components/async-task'
import { CLoader } from '@/components/loader'
import { CInfiniteScroll } from '@/@next/components/infinite-scroll'
import { CImage } from '@/@next/components/image'
import CCollectionCard from '@/components/cards/collection-card'
import { CTopbarTitle } from '@/@next/components/topbar-title'

const config = {
  name: 'v-collections',
  components: {
    ...CCollectionCard,
    CAsyncTask,
    CInfiniteScroll,
    CImage,
    CLoader,
    CTopbarTitle
  },
  i18n: { messages },
  head () {
    return {
      title: this.$t('pageTitle'),
    }
  }
}

@Component(config)
export default class Collections extends Mixins!(
  CollectionsService,
  LanguagesMixin,
  AuthorizationMixin
) {

  collectionList: Maybe<CollectionList> = null

  get collections () {
    if (!this.collectionList) return null
    const auth = this.$route.query.auth

    // Filtrage temproraire en attendant la création de parcours
    // Permet d'utiliser la page toutes les collections avec un filtrage synamique par authorization
    const authSlug = (a: Lock) => a.slug
    const authFilter = (c: Collection) => {
      if (!c.publishedAt) return false
      if (!auth) return true
      return auth === 'free' ?
            c.locks.length === 0 :
            c.locks.map(authSlug).includes(auth)
    }
    const items = this.collectionList.items.filter(authFilter).sort((a: Collection, b: Collection) => {
      return new Date(a.publishedAt) < new Date(b.publishedAt) ? 1 : -1
    })
    return { ...this.collectionList, items}
  }

  async init () { 
    this.collectionList = await this.GET_ALL_COLLECTIONS({
      take: 200
    })
  }

  async fetch () {
    return
  }

  get pageTitle () {
    if (this.$t('pageTitle')) {
      return this.$t('pageTitle')
    }
  }

  hasLocks ({ locks }: Collection) {
    return locks.length > 0
  }

  getLockedId ({ id, locks }: Collection) {
    return this.isRessourceAuhtorized(locks) ? undefined : id
  }

}
