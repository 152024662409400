import CAudioCard from './AudioCard.vue'
import CAudioCardContent from './AudioCardContent.vue'
import CAudioCardSummary from './AudioCardSummary.vue'
import CAudioCardThumbnail from './AudioCardThumbnail.vue'
import CAudioCardTitle from './AudioCardTitle.vue'
import CAudioCardPlayer from './AudioCardPlayer.vue'

export {
  CAudioCard,
  CAudioCardContent,
  CAudioCardSummary,
  CAudioCardThumbnail,
  CAudioCardTitle,
  CAudioCardPlayer
}
export default {
  CAudioCard,
  CAudioCardContent,
  CAudioCardSummary,
  CAudioCardThumbnail,
  CAudioCardTitle,
  CAudioCardPlayer
}
