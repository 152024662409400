/* eslint-disable */
/* tslint-disable */
import isMobile from 'ismobilejs'

const OPTIONS = {
  xPieces: 4,
  yPieces: 3,
  overTint: '#713a76'
}

const _container = new WeakMap()
const _canvas = new WeakMap()
const _context = new WeakMap()
const _image = new WeakMap()
const _handlers = new WeakMap()

export default class Puzzle {
  static swapPieces (hovered, current) {
    const { x, y } = hovered
    hovered.x = current.x
    hovered.y = current.y
    current.x = x
    current.y = y
  }

  constructor (canvas, imagePath, options) {
    _container.set(this, canvas.parentNode)
    _canvas.set(this, canvas)
    _context.set(this, canvas.getContext('2d'))
    this.image = imagePath
    this.options = options || OPTIONS
    this.cursor = { x: 0, y: 0 }
    this.current = null
    this.hovered = null
    this.pieces = []
    this.info = {
      moves: 0
    }
    this.gameInProgress = false
    this.initializeHandlers()
  }

  initializeHandlers () {
    _handlers.set(this, {
      resize: () => this.resizeCanvas(),
      start: () => this.start(),
      onMove: event => this.updatePuzzle(event),
      onMoveStop: event => this.dropPiece(event),
      onMoveStart: event => this.selectPiece(event)
    })
  }

  get canvas () {
    return _canvas.get(this)
  }

  get container () {
    return _container.get(this)
  }

  get context () {
    return _context.get(this)
  }

  get handlers () {
    return _handlers.get(this)
  }

  get image () {
    return _image.get(this)
  }

  /**
   * Initialize image and redraw canvas on load
   * @param {String} imgPath
   */
  set image (imgPath) {
    _image.set(this, new Image())
    this.image.addEventListener('load', () => {
      this.createPieces()
      this.resizeCanvas()
      this.redrawCanvas()
    }, false)
    this.image.src = imgPath
  }

  /**
   * Register a callback to notify subscriber when player win
   * @param callback
   */
  registerEndGameCallback (callback) {
    this.endGameCallback = callback
  }

  /**
   * Register a callback to notify subscriber when player move a piece
   * @param callback
   */
  registerAfterMoveCallback (callback) {
    this.afterMoveCallback = callback
  }

  /**
   * Resize event handling callback
   * @param shouldHandleResize
   */
  handleResize (shouldHandleResize) {
    if (shouldHandleResize) {
      window.addEventListener('resize', this.handlers.resize, false)
    } else {
      window.removeEventListener('resize', this.handlers.resize)
    }
  }

  /**
   * Resize canvas and compute the scale for inner rendered image
   */
  resizeCanvas () {
    const horizontalRatio = this.container.clientWidth / this.image.width
    const verticalRatio = this.container.clientHeight / this.image.height
    const landscape = this.container.clientWidth > this.container.clientHeight
    this.scale = (landscape && verticalRatio < horizontalRatio)
      ? verticalRatio
      : horizontalRatio
    this.canvas.width = (this.pieceWidth * this.scale) * this.options.xPieces
    this.canvas.height = (this.pieceHeight * this.scale) * this.options.yPieces
    this.redrawCanvas()
  }

  /**
   * Redraw canvas
   */
  redrawCanvas () {
    if (this.gameInProgress) {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.width)
      this.pieces.map((piece) => {
        this.drawPiece(piece)
      })
    } else {
      this.drawOriginalImage()
    }
  }

  /**
   * Draw original image and add button with event listener to start the game
   */
  drawOriginalImage (withPlayButton = false) {
    // Draw image
    this.context.drawImage(this.image,
      0, 0, this.image.width, this.image.height,
      0, 0, this.image.width * this.scale, this.image.height * this.scale
    )
    if (withPlayButton) {
      this.addPlayButton()
    }
  }

  addPlayButton () {
    // Create a container for play button
    this.context.save()
    this.context.fillStyle = '#444444'
    this.context.globalAlpha = 0.7
    this.context.fillRect(
      this.canvas.width * 0.2,
      this.canvas.height * 0.6,
      this.canvas.width * 0.6,
      this.canvas.height * 0.2
    )
    // Add play text in button
    this.context.fillStyle = '#ffffff'
    this.context.font = isMobile.phone ? '20px sans-serif' : '40px sans-serif'
    this.context.textAlign = 'center'
    this.context.fillText('PLAY',
      this.canvas.width * 0.5,
      this.canvas.height * 0.73
    )
    this.context.restore()
    // On click start game
    this.canvas.addEventListener('click', this.handlers.start, false)
  }

  /**
   * Draw a piece
   * @param piece
   */
  drawPiece (piece) {
    // Draw piece given as parameter in canvas
    this.context.drawImage(this.image,
      piece.sx,
      piece.sy,
      this.pieceWidth,
      this.pieceHeight,
      piece.x * this.scale,
      piece.y * this.scale,
      this.pieceWidth * this.scale,
      this.pieceHeight * this.scale
    )
    // Draw piece border
    this.context.save()
    this.context.strokeStyle = '#bbbbbb'
    this.context.lineWidth = 0.5
    this.context.strokeRect(
      piece.x * this.scale,
      piece.y * this.scale,
      this.pieceWidth * this.scale,
      this.pieceHeight * this.scale
    )
    this.context.restore()
  }

  /**
   * ClearRect for the given piece coordinates
   * @param piece
   */
  clearPiece (piece) {
    this.context.clearRect(
      piece.x * this.scale,
      piece.y * this.scale,
      this.pieceWidth * this.scale,
      this.pieceHeight * this.scale
    )
  }

  /**
   * Initialize pieces array
   */
  createPieces () {
    // Compute pieces dimensions and create them
    this.pieceWidth = this.image.width / this.options.xPieces
    this.pieceHeight = this.image.height / this.options.yPieces
    // Create pieces
    this.pieces = []
    let x = 0
    let y = 0
    // Calculate pieces count
    const piecesCount = this.options.xPieces * this.options.yPieces
    // Create all pieces with their original coordinates
    for (let i = 0; i < piecesCount; i++) {
      this.pieces.push({ sx: x, sy: y })
      x += this.pieceWidth
      if (x >= this.image.width) {
        x = 0
        y += this.pieceHeight
      }
    }
  }

  /**
   * For each pieces, randomize a new position and swap it with the target
   */
  shufflePieces () {
    for (let i = this.pieces.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = this.pieces[i]
      this.pieces[i] = this.pieces[j]
      this.pieces[j] = temp
    }
    // Update pieces coordinates
    this.updatePiecesCoordinates()
    // Redraw canvas
    this.redrawCanvas()
  }

  /**
   * Update pieces coordinates
   */
  updatePiecesCoordinates () {
    // Base coordinates
    let x = 0
    let y = 0
    this.pieces.map((piece) => {
      piece.x = x
      piece.y = y
      // Increment horizontal offset
      x += this.pieceWidth
      // At end line, increment vertical offset
      if (x >= this.image.width) {
        x = 0
        y += this.pieceHeight
      }
    })
  }

  /**
   * Function called to start the game
   */
  start () {
    this.canvas.classList.add('is-started')
    this.info.startedAt = Date.now()
    // Remove game starter event
    this.canvas.removeEventListener('click', this.handlers.start)
    // Set game state to in progress
    this.gameInProgress = true
    this.shufflePieces()
    // Add event listener on canvas element for piece selection
    this.canvas.addEventListener('touchstart', this.handlers.onMoveStart, false)
    this.canvas.addEventListener('mousedown', this.handlers.onMoveStart, false)
  }

  /**
   * Piece selection handling callback
   * @param e
   */
  selectPiece (e) {
    // Catch the cursor position in the canvas layer
    this.cursor = this.getCursorPosition(e)
    // Select the piece whose coordinates correspond to those of the cursor
    this.current = this.getCurrentPiece()
    if (!this.current) {
      return
    }
    // Clean selected piece area in the canvas
    this.clearPiece(this.current)
    // Draw selected piece over the canvas
    this.drawSelected()
    // Set the move watcher events handlers for non touch events
    this.canvas.addEventListener('mousemove', this.handlers.onMove, false)
    document.addEventListener('mouseup', this.handlers.onMoveStop, false)
    // Set the piece drop events handlers
    this.canvas.addEventListener('touchmove', this.handlers.onMove, false)
    document.addEventListener('touchend', this.handlers.onMoveStop, false)
  }

  /**
   * Get cursor position in canvas layer from event
   * @param e event
   * @returns {Object} Cursor coordinates
   */
  getCursorPosition (event) {
    // Identify event source
    const e = event.touches ? event.touches[0] : event
    // Catch cursor coordinates
    const cursor = {}
    if (e.layerX || e.layerX === 0) {
      cursor.x = e.layerX
      cursor.y = e.layerY
    } else if (e.offsetX || e.offsetX === 0) {
      cursor.x = e.offsetX
      cursor.y = e.offsetY
    } else {
      const rect = this.canvas.getBoundingClientRect()
      cursor.x = e.pageX - rect.left
      cursor.y = e.pageY - rect.top
    }
    return cursor
  }

  /**
   * From cursor position, return piece when coordinates match
   * @returns {*}
   */
  getCurrentPiece () {
    let piece = null
    this.pieces.map((p) => {
      if (this.cursorIsOverPiece(p)) {
        piece = p
      }
    })
    return piece
  }

  /**
   * Check if cursor position is in the piece given as parameter
   * @param piece
   * @returns {boolean}
   */
  cursorIsOverPiece (piece) {
    return !(this.cursor.x < piece.x * this.scale ||
      this.cursor.x > (piece.x + this.pieceWidth) * this.scale ||
      this.cursor.y < piece.y * this.scale ||
      this.cursor.y > (piece.y + this.pieceHeight) * this.scale)
  }

  /**
   * Update puzzle
   * @param e
   */
  updatePuzzle (e) {
    e.preventDefault()
    this.hovered = null
    this.cursor = this.getCursorPosition(e)
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.pieces.map((piece) => {
      if (piece !== this.current) {
        this.drawPiece(piece)
      }
      if (this.hovered === null) {
        if (this.cursorIsOverPiece(piece)) {
          this.hovered = piece
          this.context.save()
          this.context.globalAlpha = 0.4
          this.context.fillStyle = this.options.overTint
          this.context.fillRect(
            this.hovered.x * this.scale,
            this.hovered.y * this.scale,
            this.pieceWidth * this.scale,
            this.pieceHeight * this.scale
          )
          this.context.restore()
        }
      }
    })
    this.drawSelected()
  }

  /**
   * update currently selected piece when it's moved
   */
  drawSelected () {
    this.context.save()
    this.context.globalAlpha = 0.6
    this.context.drawImage(this.image,
      this.current.sx, this.current.sy, this.pieceWidth, this.pieceHeight,
      this.cursor.x - ((this.pieceWidth * this.scale) / 2),
      this.cursor.y - ((this.pieceHeight * this.scale) / 2),
      this.pieceWidth * this.scale,
      this.pieceHeight * this.scale
    )
    this.context.restore()
    this.context.strokeRect(
      this.cursor.x - ((this.pieceWidth * this.scale) / 2),
      this.cursor.y - ((this.pieceHeight * this.scale) / 2),
      this.pieceWidth * this.scale,
      this.pieceHeight * this.scale
    )
  }

  /**
   * Function called when the current piece was hovered
   */
  dropPiece () {
    if (this.hovered !== null) {
      if (this.current !== this.hovered) {
        this.info.moves += 1
        this.afterMoveCallback(this.info)
      }
      Puzzle.swapPieces(this.hovered, this.current)
    }
    this.canvas.removeEventListener('mousemove', this.handlers.onMove)
    this.canvas.removeEventListener('touchmove', this.handlers.onMove)
    document.removeEventListener('mouseup', this.handlers.onMoveStop)
    document.removeEventListener('touchend', this.handlers.onMoveStop)
    this.redrawCanvas()
    this.checkWin()
    this.current = this.hovered = null
  }

  completeGame () {
    this.canvas.classList.remove('is-started')
    if (typeof this.endGameCallback === 'function') {
      this.endGameCallback({ correct: true })
    }
    // Remove event listener on canvas element for piece selection
    this.canvas.removeEventListener('touchstart', this.handlers.onMoveStart)
    this.canvas.removeEventListener('mousedown', this.handlers.onMoveStart)
    this.drawOriginalImage()
  }

  /**
   * Check if the puzzle is correctly organized and notify observer
   */
  checkWin () {
    let win = true
    // Check if all pieces are in place.
    this.pieces.map((p) => {
      if (p.x !== p.sx || p.y !== p.sy) {
        win = false
      }
    })
    if (win) {
      this.completeGame()
    }
    return win
  }
}
