import { Vue, Component, Prop } from 'vue-property-decorator'

import { CACHE, GEOLOCATION } from '@/constants'

import { isGeolocationAuthorized } from '@/utils/geolocation'

@Component({})
export default class GeoLocation extends Vue {

  geolocationRequired: boolean = false // True if geolocation object is given is project configuration
  geolocationLoading: boolean = false // True if app is waiting GPS data
  geolocationAvailable: boolean = false // True if device support geolocation
  geolocationPersmissionState: string = 'undefined' // Current geolocation permission
  watchId: number | null = null // Id of the current position measurement
  geolocationFailed: boolean = false // True if the last geolocation measure is failed 
  geolocationAuthorizedPosition: boolean = false // True if curent device position is in authorized area
  // geolocationUserConsent: boolean = true
  // geolocationLoaded: boolean = false

  // DEBUG Variables
  lastGeolocationUpdate: Date = new Date()
  geolocationUpdateCount = 0

  created () {
    this.geolocationRequired = this.isGeolocationRequired()
    this.geolocationAvailable = 'geolocation' in navigator
  }

  get geolocationName () {
    if (!GEOLOCATION) return null
    return GEOLOCATION.name
  }

  isWiviCachedVersion () {
    try {
      if ( CACHE === 'local') return true
      if (this.$cookies.get('WIVI_CACHE') === 'local') return true
      if (this.$route.query.cache as string === 'local') {
        this.$cookies.set('WIVI_CACHE', 'local', '12h')
        return true
      }
      return false
    } catch (e) {
      console.warn('Error during cache wivi estimation', e)
      return false
    }    
  }

  isGeolocationRequired () {
    try {
      const disabledGeolocation = this.$cookies.get('WIVI_DISABLE_GEOLOCATION') === 'true'
      const needGeolocation = !!GEOLOCATION && !this.isWiviCachedVersion() && !disabledGeolocation
      console.info(`[Info][Geolocation] ${needGeolocation ? 'Geolocation is required' : 'Geolocation is not required'}`)
      return !!GEOLOCATION && !this.isWiviCachedVersion() && !disabledGeolocation
    } catch (e) {
      console.warn('Error during check if geolocation is required', e)
      return !!GEOLOCATION
    }   
  }

  get geolocationUserConsent () {
    return this.geolocationPersmissionState === 'granted'
  }

  get geolocationUserReject () {
    return this.geolocationPersmissionState === 'denied'
  }

  async mounted () {
    if (this.geolocationRequired && this.geolocationAvailable) {
      this.geolocationPersmissionState = await this.getGeolocationPersmissionState()
      if (this.geolocationUserConsent) this.startGeoLocation()
    }
  }

  async getGeolocationPersmissionState () {
    const perm = await navigator.permissions.query({name: 'geolocation'})
    return perm.state
  }

  startGeoLocation () {
    if (!GEOLOCATION) return
    this.geolocationLoading = true
    this.watchId = navigator.geolocation.watchPosition(
      this.onGeoLocationSuccess,
      this.onGeoLocationFail
    )
  }

  onGeoLocationSuccess ({ coords, timestamp }: any) {
    this.geolocationLoading = false
    this.geolocationFailed = false
    this.geolocationPersmissionState = 'granted'
    if (!GEOLOCATION) return
    /* debug  */
    this.geolocationUpdateCount++
    this.lastGeolocationUpdate = new Date(timestamp)
    /* */
    this.geolocationAuthorizedPosition = isGeolocationAuthorized(GEOLOCATION.coords, coords)
  }

  // prompt (window: any, pref: any, message: any, callback: any) {
  //   // @ts-ignore
  //   const branch = Components.classes['@mozilla.org/preferences-service;1']
  //     // @ts-ignore
  //     .getService(Components.interfaces.nsIPrefBranch)

  //   if (branch.getPrefType(pref) === branch.PREF_STRING) {
  //       switch (branch.getCharPref(pref)) {
  //       case 'always':
  //           return callback(true)
  //       case 'never':
  //           return callback(false)
  //       }
  //   }

  //   let done = false;

  //   function remember (value: any, result: any) {
  //       return () => {
  //           done = true;
  //           branch.setCharPref(pref, value);
  //           callback(result);
  //       }
  //   }

  //   const self = window.PopupNotifications.show(
  //       window.gBrowser.selectedBrowser,
  //       'geolocation',
  //       message,
  //       'geo-notification-icon',
  //       {
  //           label: 'Share Location',
  //           accessKey: 'S',
  //           callback: (notification: any) => {
  //               done = true;
  //               callback(true);
  //           }
  //       }, [
  //           {
  //               label: 'Always Share',
  //               accessKey: 'A',
  //               callback: remember('always', true)
  //           },
  //           {
  //               label: 'Never Share',
  //               accessKey: 'N',
  //               callback: remember('never', false)
  //           }
  //       ], {
  //           eventCallback: (event: any) => {
  //               if (event === 'dismissed') {
  //                   if (!done) callback(false);
  //                   done = true;
  //                   window.PopupNotifications.remove(self);
  //               }
  //           },
  //           persistWhileVisible: true
  //       });
  // }

  async onGeoLocationFail ({ code }: any) {
    console.log('onGeoLocationFail', code)
    this.geolocationLoading = false
    this.geolocationFailed = true
    this.geolocationPersmissionState = await this.getGeolocationPersmissionState()
    // this.geoLocationUserConsent = code === 1
    // switch (code) {
    //   case 1:
    //     this.geoLocationUserConsent = false
    //     break
    // }
  }

}
