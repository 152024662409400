
import { Vue, Component, Prop } from 'vue-property-decorator'
import CAudioCard from '@/components/cards/audio-card'

const config = {
  name: 'v-Single-poi-content-audio',
  components: {
    ...CAudioCard
  }
}

@Component(config)
export default class VSinglePoiContentAudio extends Vue {
  @Prop({ type: Object, required: true }) content!: any
}
