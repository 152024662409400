
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import * as APP from '@/@next/store/app/types'
import uuidv4 from 'uuid/v4'

const config = {
  name: 'c-Modal'
}

@Component(config)
export default class CModal extends Vue {
  @Prop({ type: Boolean, default: false }) fillHeight!: boolean
  @Prop({ type: Boolean, default: true }) closable!: boolean

  @Getter(APP.GET_MODALS)  modals!: any[]
  @Mutation(APP.ADD_MODAL) addModal!: any
  @Mutation(APP.REMOVE_MODAL) removeModal!: any
  @Mutation(APP.REMOVE_CURRENT_MODAL) removeCurrentModal!: any

  uuid = uuidv4()
  show = false
  waitDelay: boolean = false

  @Watch('modals')
  onModalListUpdate () {
    if (this.modals[0] !== this.uuid) return
    setTimeout(() => { this.show = true }, this.waitDelay ? 500 : 0 )  
  }

  get cssClasses () {
    return {
      '--FillHeight': this.fillHeight
    }
  }

  created () {
    this.addModal(this.uuid)     
  }

  mounted () {
    if (this.modals.length > 1) this.waitDelay = true
  }

  beforeDestroy () {
    this.removeModal(this.uuid)
  }

  close () {
    this.removeModal(this.uuid)
    this.$emit('close')
  }
}
