import { MutationTree } from 'vuex'

import * as BOT from './types'
import { BotState } from '@/@next/store/bot'

export const mutations: MutationTree <BotState> =  {

  [BOT.FETCH] (state, data: any) {
    state.isEnable = data.botIsEnable
    state.settings = data.settings
    state.content = data.content
  },

  [BOT.IS_OPEN] (state, isOpen: boolean) {
    state.isOpen = isOpen
  },

  [BOT.ENABLE_BOT_NOTIFICATION] (state, notificationAreEnable) {
    state.notificationAreEnable = notificationAreEnable
  },

  [BOT.SET_BOT_STATE] (state, botState: string) {
    state.botState = botState
  },

  [BOT.IS_WRITING_MESSAGE] (state, isWritingMessage: boolean) {
    state.isWritingMessage = isWritingMessage
  },

  [BOT.SET_ALL_MESSAGES_ARE_READ] (state) {
    state.messages.filter((el: any) => el.read === false).forEach((el: any) => {
      el.read = true
    })
  },

  [BOT.ADD_MESSAGES_TO_BUFFER] (state, messages: any) {
    if (state.notificationAreEnable) {
      state.buffer = state.buffer.concat(messages)
    }
  },

  [BOT.ADD_NEW_MESSAGE] (state, message: any) {
    // console.log(message)
    state.messages.push(message)
  },

  [BOT.REMOVE_FIRST_BUFFER_MESSAGE] (state) {
    state.buffer.splice(0, 1)
  },

  [BOT.CLEAR_BUFFER] (state) {
    state.buffer = []
  },

  [BOT.SET_USER_CHOICES] (state, userChoices: any[]) {
    // console.log('SET_USER_CHOICES', userChoices)
    state.userChoices = userChoices
  },

  [BOT.SET_CURRENT_POI_CONTENT] (state, poi: any) {
    state.currentPoiContent = poi
  }
}
