// getters
export const GET_STATUS = 'audioguide/GET_STATUS'
export const GET_PLAYLIST = 'audioguide/GET_PLAYLIST'
export const GET_POI = 'audioguide/GET_POI'
export const GET_CURRENT_POI = 'audioguide/GET_CURRENT_POI'
// actions
export const SET_STATUS = 'audioguide/SET_STATUS'
export const SET_PLAYLIST = 'audioguide/SET_PLAYLIST'
export const SET_POI = 'audioguide/SET_POI'
export const SET_CURRENT_POI = 'audioguide/SET_CURRENT_POI'

// actions
export const CHANGE_STATUS = 'audioguide/CHANGE_STATUS'
export const CHANGE_PLAYLIST = 'audioguide/CHANGE_PLAYLIST'
export const CHANGE_POI = 'audioguide/CHANGE_POI'
export const CHANGE_CURRENT_POI = 'audioguide/CHANGE_CURRENT_POI'
