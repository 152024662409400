export const messages = {
  fr: {
    pageTitle: 'Points d\'intérêt'
  },
  en: {
    pageTitle: 'Points of interest'
  },
  us: {
    pageTitle: 'Points of interest'
  },
  da: {
    pageTitle: 'Points of interest'
  },
  es: {
    pageTitle: 'Points of interest'
  },
  nl: {
    pageTitle: 'Points of interest'
  },
  de: {
    pageTitle: 'Points of interest'
  },
  it: {
    pageTitle: 'Points of interest'
  },
  pt: {
    pageTitle: 'Points of interest'
  },
  zh: {
    pageTitle: 'Points of interest'
  }
}
