
import { Component, Mixins } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Matomo from 'matomo-ts'

import { FiberMapMixin, AuthorizationMixin, SaveScrollMixin } from '@/@next/mixins'
import { PointsOfInterestService, CollectionsService } from '@/@next/services'

import { messages } from './i18n'

import { MAP_URL, MATOMO_URL } from '@/constants'
import { Maybe, PointOfInterest, PointOfInterestList, Collection, Person } from '@/@types/graphql'

import * as UI from '@/event-bus'

import * as AUDIOGUIDE from '@/@next/store/audioguide/types'
import * as BOT from '@/@next/store/bot/types'
import * as SETTINGS from '@/@next/store/settings2/types'

import { CAsyncTask } from '@/@next/components/async-task'
import CustomWrapper from '@/components/CustomWrapper.vue'
import { CImage } from '@/@next/components/image'
import { CLoader } from '@/components/loader'
import { CAudioGuideButton, CAudioGuideCount } from '@/components/audioguide'
import { CTopbarTitle } from '@/@next/components/topbar-title'
import CModal from '@/components/modal'
import VSinglePoiContentPuzzle from '@/views/single-poi/contents/puzzle/Puzzle.vue'
import CAuthorModal from '@/components/modal/author-modal'
import { CPoiContent } from '@/@next/components/poi-content'
import format from 'date-fns/format'
import * as LANGUAGES from '@/@next/store/languages/types'
import * as FIBERMAP from '@/@next/store/fibermap/types'
import addProtocol from '@/utils/addProtocol'
import { CButton } from '@/components/form-controls/button'

const config = {
  name: 'v-single-poi',
  components: {
    CAsyncTask,
    CPoiContent,
    ...CModal,
    ...CAuthorModal,
    CustomWrapper,
    CAudioGuideButton,
    CAudioGuideCount,
    CLoader,
    CImage,
    VSinglePoiContentPuzzle,
    CTopbarTitle,
    CButton
  },
  i18n: { messages },
  head () {
    return {
      title: this.tabTitle,
      titleTemplate: '%s – Wivi'
    }
  }
}

@Component(config)
export default class SinglePoi extends Mixins!(
  SaveScrollMixin,
  PointsOfInterestService,
  CollectionsService,
  FiberMapMixin,
  AuthorizationMixin
) {

  @Getter(AUDIOGUIDE.GET_STATUS) audioguideStatus!: boolean
  @Action(AUDIOGUIDE.SET_STATUS) setAudioguideStatus!: any
  @Action(AUDIOGUIDE.SET_PLAYLIST) setAudioguidePlaylist!: any
  @Action(AUDIOGUIDE.SET_POI) setAudioguidePoi!: any
  @Action(AUDIOGUIDE.SET_CURRENT_POI) setAudioguideCurrentPoi!: (poi: any) => Promise<any>
  @Action(BOT.ADD_POI_CONTENT) addPoiContent!: any
  @Getter(BOT.IS_ENABLE) isBotEnable!: any
  @Getter(BOT.IS_OPEN) isBotOpen!: boolean
  @Action(BOT.CLOSING_BOT) closeBot!: any
  // @Getter(SETTINGS.GET_ALL) getAllSettings!: any
  @Getter(LANGUAGES.GET_CURRENT) getCurrentLanguage!: string
  @Getter(SETTINGS.GET_FEATURES)
  _settingsFeatures!: any
  // @Action(FIBERMAP.CONTENT_HAS_LINK)
  // isPresentOnTheMap!: ({type, uuid}: {type: string, uuid: string}) => boolean
  
  pointOfInterest: Maybe<PointOfInterest> = null
  pointsOfInterest: Maybe<PointOfInterestList> = null
  collection: Maybe<Collection> = null
  showPersonModal: boolean = false
  scrollPosY: number = 0
  mediaScale: number = 1
  person: Person | null = null
  speechSynthesisIsActive: boolean = false
  speechSynthesisIsPlayed: boolean = false
  speechSynthesisIsReady: boolean = false
  // displayMapLink: boolean = false

  async fetchNextPoi (currentId: PointOfInterest, list: PointOfInterest[]) {
    const index = list.findIndex((p: PointOfInterest) => p.id === currentId)
    const nextId = index < list.length - 1 && list[index + 1]?.id
    if (nextId) {
      await this.GET_SINGLE_POI(nextId)
      this.fetchNextPoi(nextId, list)
    }
  }

  async fetchPreviousPoi (currentId: PointOfInterest, list: PointOfInterest[]) {
    const index = list.findIndex((p: PointOfInterest) => p.id === currentId)
    const prevId = index > 0 && list[index - 1]?.id
    if (prevId) {
      await this.GET_SINGLE_POI(prevId)
      this.fetchPreviousPoi(prevId, list)
    }
  }
  async fetchAroundPointOfInterest (currentId: PointOfInterest, list: PointOfInterest[]) {
    if (list.length < 2) return
    this.fetchNextPoi(currentId, list)
    this.fetchPreviousPoi(currentId, list)
  }

  async init () {
    // On récupère le POI via son identifiant
    this.pointOfInterest = await this.GET_SINGLE_POI(this.$route.params.uuid)
    await this.setAudioguideCurrentPoi(this.pointOfInterest)

    this.sendMatomoInput()

    // Afin de créer les liens POI suivant et précédent nous devons connaître
    // le contexte lié à ce dernier (une collection, un parcours, aucun)
    if ('collection' in this.$route.query) {
      this.collection = await this.GET_SINGLE_COLLECTION(this.$route.query.collection as string, {
        take: 200
      })
      if (this.collection?.id)
        this.fetchAroundPointOfInterest(this.pointOfInterest.id , this.collection.pointsOfInterest.items)
    }
    if (this.isBotEnableIsCurrentLanguage) this.sendContentToBot()
    // this.displayMapLink = await this.isPresentOnTheMap({type: 'pointOfInterest', uuid: this.pointOfInterest.id})
  }

  get locks () {
    if (!this.pointOfInterest) return []
    return this.pointOfInterest.locks
  }

  get isLocked () {
    return !this.isRessourceAuhtorized(this.locks)
  }

  async activated () {
    await this.setAudioguideCurrentPoi(this.pointOfInterest)
    UI.EventBus.$emit(UI.ENABLE_DEFAULT_TOPBAR_TRANSPARENT)
    this.sendMatomoInput()
    this.initSpeechSynthesis()
  }

  sendMatomoInput () {
    if (!MATOMO_URL || !this.pointOfInterest || !this.pointOfInterest.i18n.title) return
    const title = this.pointOfInterest.i18n.title.value.text as string
    Matomo.default().trackPageView(title)
  }

  deactivated () {
    UI.EventBus.$emit(UI.DISABLE_DEFAULT_TOPBAR_TRANSPARENT)
    this.scrollPosY = 0
  }

  get tabTitle () {
    return (this.pointOfInterest && this.pointOfInterest.i18n.title) ?
      this.pointOfInterest.i18n.title.value.text :
      this.$t('global.loading')
  }

  get pageTitle () {
    return (this.pointOfInterest && this.pointOfInterest.i18n.title) ?
      this.pointOfInterest.i18n.title.value.html :
      this.$t('global.loading')
  }

  get pointsOfInterestAround () {
    if (!this.collection) return null
    const pointsOfInterest = this.collection.pointsOfInterest!.items!
    const currentPoiIndex = pointsOfInterest
      .findIndex((pointOfInterest: PointOfInterest) => pointOfInterest.id === this.pointOfInterest!.id)
    return { prev: pointsOfInterest[currentPoiIndex - 1], next: pointsOfInterest[currentPoiIndex + 1] }
  }

  get prevPointOfInterest () {
    if (this.pointsOfInterestAround)
      return this.pointsOfInterestAround.prev
  }

  get nextPointOfInterest () {
    if (this.pointsOfInterestAround)
      return this.pointsOfInterestAround.next
  }

  get mediaInlineStyles () {
    return {
      'transform': `translateY(-${this.scrollPosY / 5}px)`
    }
  }

  onViewScroll (e: any) {
    const mediaElem: HTMLElement = this.$refs.media as HTMLElement
    if ((mediaElem) && (e.target.scrollTop < mediaElem.offsetHeight)) {
      this.scrollPosY = e.target.scrollTop
      const percentage = (this.scrollPosY * 100) / mediaElem.offsetHeight
      this.mediaScale = 1 + (percentage / 500)
    }
  }

  onStartAudioGuideBtnClick () {
    this.setAudioguidePlaylist([
      ...this.playableAudioGuideTracks
    ]).then(() => {
      this.setAudioguidePoi(this.pointOfInterest)
      this.setAudioguideStatus(true)
    })
  }

  get playableAudioGuideTracks () {
    if (!this.pointOfInterest?.audioGuideTracks.length) return []
    return this.pointOfInterest.audioGuideTracks.filter((t) => t.i18n.audio || t.i18n.video)
  }

  get showAudioGuideButton () {
    if (!this.pointOfInterest) return []
    if (this.isLocked) return
    return this.playableAudioGuideTracks.length
  }

  datesString (bornAt: Date | null, diedAt: Date | null) {
    const formatDate = (d: Date) => format(new Date(d), 'DD/MM/YYYY')
    if (bornAt && diedAt) return `${formatDate(bornAt)} — ${formatDate(diedAt)}`
    if (bornAt && !diedAt) return `${formatDate(bornAt)}`
    if (!bornAt && diedAt) return ` * - ${formatDate(diedAt)}`
    if (!bornAt && !diedAt) return ``
  }

  showPerson (person: Person) {
    this.person = person
    this.showPersonModal = true
  }

  get isBotEnableIsCurrentLanguage () {
    if (this.isBotEnable && this.getCurrentLanguage) {
      const isBotEnable = this.isBotEnable[this.getCurrentLanguage]
      return !!isBotEnable
    }
    return false
  }

  sendContentToBot () {
    if (!this.pointOfInterest) return
    const botContent = this.pointOfInterest.contents.filter((content: any) => content.options.isAdditionalContent)
    if (!botContent || !botContent.length) return
    this.addPoiContent({ ...this.pointOfInterest, contents: botContent })
  }

  initSpeechSynthesis () {
    if (!this._settingsFeatures?.includes('textToSpeech')) return
    if (!('speechSynthesis' in window)) return
    this.speechSynthesisIsReady = true
  }

  getIOSVersion () {
    const agent = window.navigator.userAgent
    const start = agent.indexOf('OS')
    if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1 ) && start > -1) {
      // @ts-ignore
      return window.Number(agent.substr(start + 3, 3).replace('_', '.'))
    }
    return 0
  }

  playSpeechContent () {
    const textToSpeechWrapper = (this.$refs.textToSpeech as HTMLElement)
    const textToSpeechDomNode = [].slice.call(textToSpeechWrapper.querySelectorAll('[data-to-speech]'))
    const textToSpeech = textToSpeechDomNode.map((node: HTMLElement) => node.textContent)

    if (textToSpeech.length) {
      for (const text of textToSpeech) {
        const speechSynthesis = new SpeechSynthesisUtterance()
        speechSynthesis.lang = this.$i18n.locale
        speechSynthesis.volume = 1
        speechSynthesis.rate = 1
        speechSynthesis.pitch = 1
        speechSynthesis.text = text || ''
        window.speechSynthesis.speak(speechSynthesis)
        this.speechSynthesisIsActive = true
        this.speechSynthesisIsPlayed = true
      }
    }
  }

  togglePlaySpeechContent () {
    if (window.speechSynthesis.paused) {
      window.speechSynthesis.resume()
      this.speechSynthesisIsPlayed = true
    } else {
      window.speechSynthesis.pause()
      this.speechSynthesisIsPlayed = false
    }
  }

  // goToMap () {
  //   const mapUrl = MAP_URL
  //   const url = 
  // encodeURIComponent(`${mapUrl}?hl=${this.$i18n.locale}&type=pointOfInterest&uuid=${this.pointOfInterest?.id}`)
  //   this.$router.push(`/embeds/?bot=false&topbar=trans&url=${url}`)
  // }
}
