<script>
  import { Vue, Component } from 'vue-property-decorator'

  const config = {
    name: 'v-audio'
  }

  @Component(config)
  export default class VAudio extends Vue {

  }
</script>

<template>
  <div class="v-Audio">
    Audio
  </div>
</template>

<style lang="stylus">
  .v-Audio {
    position: relative
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
  }
</style>
