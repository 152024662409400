
import { Mixins, Component, Watch } from 'vue-property-decorator'
import * as UI from '@/event-bus'

import { messages } from './i18n'
import {Route} from 'vue-router';
import { CTopbarTitle } from '@/@next/components/topbar-title'
import { MATOMO_URL, DOMAIN, PROD, MAP_URL } from '@/constants'
import { FiberMapMixin } from '@/@next/mixins'
import Matomo from 'matomo-ts'

Component.registerHooks([
  'beforeRouteLeave'
])

const config = {
  name: 'v-fiber-map',
  components: {
    CTopbarTitle
  },
  i18n: { messages },
  head () {
    return {
      title: this.$t('pageName'),
    }
  }
}

@Component(config)
export default class VFiberMap extends Mixins(FiberMapMixin) {

  hideBot: boolean = false
  hideTopbar: boolean = false
  transTopbar: boolean = false
  isReady: boolean = false
  queries: string = ''

  @Watch('$route', { immediate: true })
  async onRouteChange () {
    if (this.$route.name !== 'fiber-map') return this.isReady = false
    await this.init()
  }

  async init () {
    this.isReady = false
    this.queries = ''
    if (!MAP_URL) return this.$router.go(-1)
    this.checkLogoTopBar()
    this.sendMatomoInput()
    this.initCallbackMethods()
    try {
      if (PROD) document.domain = DOMAIN
    } catch (e) {
      console.warn('error during domain definition', DOMAIN)
    }
    this.hideBot = this.$route.query.bot === 'false'
    this.hideTopbar = this.$route.query.topbar === 'false'
    this.transTopbar = this.$route.query.topbar === 'trans'

    switch (true) {
      case this.hideTopbar:
        UI.EventBus.$emit(UI.HIDE_DEFAULT_TOPBAR)
      case this.transTopbar:
        UI.EventBus.$emit(UI.ENABLE_DEFAULT_TOPBAR_TRANSPARENT)
      case this.hideBot:
        UI.EventBus.$emit(UI.HIDE_DEFAULT_BOT)
    }
    // Add queries to map url iframe to filter map content
    const { placeholderId, floorId, mapId } = this.$route.query
    this.queries += `?hl=${this.$i18n.locale}`
    if (placeholderId) this.queries += `&placeholderId=${placeholderId}`
    if (floorId) this.queries += `&floorId=${floorId}`
    if (mapId) this.queries += `&mapId=${mapId}`
    this.isReady = true
  }

  sendMatomoInput () {
    if (!MATOMO_URL || !this.mapUrl) return
    Matomo.default().trackPageView('map')
  }

  get mapUrl () {
    return MAP_URL + this.queries
  }

  get title () {
    return ''
  }
  
  get CSSClasses () {
    return {
      '--NoTopbar': this.hideTopbar || this.transTopbar
    }
  }

  beforeRouteLeave (to: Route, from: Route, next: any) {
    if (this.hideTopbar) UI.EventBus.$emit(UI.SHOW_DEFAULT_TOPBAR)
    if (this.hideBot) UI.EventBus.$emit(UI.SHOW_DEFAULT_BOT)
    if (this.transTopbar) UI.EventBus.$emit(UI.DISABLE_DEFAULT_TOPBAR_TRANSPARENT)
    next()
  }

  checkLogoTopBar () {
    try {
      const logoTopBar = new Image();
      logoTopBar.addEventListener('load', () => {
        const customElm = document.getElementById('logo-topbar-custom')
        const defaultElm = document.getElementById('logo-topbar-default')
        if (!customElm || !defaultElm) return
        customElm.style.display = 'block'
        defaultElm.style.display = 'none'
      }, false)
      logoTopBar.src = './logo-topbar.png';
    } catch (e) {
      console.warn('Error during logo topBar verification.', e)
    }
  }

}
