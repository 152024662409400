import CAuthorModal from './AuthorModal.vue'
import CAuthorModalContent from './AuthorModalContent.vue'
import CAuthorModalHeader from './AuthorModalHeader.vue'
import CAuthorModalMedia from './AuthorModalMedia.vue'
import CAuthorModalFigure from './AuthorModalFigure.vue'
import CAuthorModalFigcaption from './AuthorModalFigcaption.vue'

export {
  CAuthorModal,
  CAuthorModalContent,
  CAuthorModalHeader,
  CAuthorModalMedia,
  CAuthorModalFigure,
  CAuthorModalFigcaption
}

export default {
  CAuthorModal,
  CAuthorModalContent,
  CAuthorModalHeader,
  CAuthorModalMedia,
  CAuthorModalFigure,
  CAuthorModalFigcaption
}
