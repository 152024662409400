
import { Vue, Component, Prop } from 'vue-property-decorator'
import CustomWrapper from '@/components/CustomWrapper.vue'

@Component({
  name: 'c-quizz-choices',
  components: { CustomWrapper }
})
export default class CQuizzChoice extends Vue {

  @Prop({ default: 'ul'}) tag!: string

}
