
import { Component, Watch, Mixins } from 'vue-property-decorator'

import * as UI from '@/event-bus'

import { MediasService, PointsOfInterestService } from '@/@next/services'

import { Maybe, MediaImage, PointOfInterest } from '@/@types/graphql'

import { CTopbarTitle } from '@/@next/components/topbar-title'
import { CAsyncTask } from '@/@next/components/async-task'
import { CImage } from '@/@next/components/image'
import { CLoader } from '@/components/loader'

const config = {
  name: 'v-image',
  components: {
    CTopbarTitle,
    CAsyncTask,
    CImage,
    CLoader
  }
}

@Component(config)
export default class VImage extends Mixins!(MediasService, PointsOfInterestService) {

  showHeading = true
  contentTextExpanded = false
  displayReadMore: boolean = false

  image: Maybe<MediaImage> = null
  pointOfInterest: Maybe<PointOfInterest> = null

  scale: number = 1
  lastScale: number = 0
  maxScale: number = 5
  animate: boolean = false
  translateX: number = 0
  lastTranslateX: number = 0
  translateY: number = 0
  lastTranslateY: number = 0
  dX: number = 0
  dY: number = 0

  async init () {
    await this.fetchImage()
    if (this.$route.query.context_id) {
      await this.fetchContent()
    }
  }

  async fetchImage () {
    this.image = await this.GET_SINGLE_IMAGE(this.$route.params.uuid)
  }

  async fetchContent () {
    if (this.$route.query.context_id && this.$route.query.context) {
      switch (this.$route.query.context) {
        case 'poi':
          this.pointOfInterest = await this.GET_SINGLE_POI(this.$route.query.context_id as string)
          break
      }
    }
  }

  @Watch('scale')
  onScaleChange () {
    if (this.scale > 1) {
      UI.EventBus.$emit(UI.HIDE_DEFAULT_TOPBAR)
      this.showHeading = false
    } else {
      UI.EventBus.$emit(UI.SHOW_DEFAULT_TOPBAR)
      this.showHeading = true
    }
  }

  get imgInlineStyles () {
    return {
      'transform': `scale(${this.scale}) translateX(${this.translateX}px) translateY(${this.translateY}px)`,
      'transition': this.animate ? 'transform 250ms ease' : '',
      'filter': this.contentTextExpanded ? 'blur(12px)' : '',
    }
  }

  get headingCSSClasses () {
    return {
      'is-Hidden': !this.showHeading,
      'is-Expanded': this.contentTextExpanded
    }
  }

  get content () {
    if (this.pointOfInterest) {
      return this.pointOfInterest.contents.find((content: any) => {
        return content.id === this.$route.query.content_id
      })
    }
  }

  get containerDOMNode () {
    return this.$refs.container as HTMLElement
  }

  get imgDOMNode () {
    return this.$refs.img as HTMLElement
  }

  get imageSummaryDOMNode () {
    return this.$refs.summary as HTMLElement
  }

  onSingleTap () {
    if (this.scale === 1) {
      UI.EventBus.$emit(UI.TOGGLE_DEFAULT_TOPBAR)
      this.showHeading = !this.showHeading
    }
  }

  onDoubleTap () {
    this.animate = true
    this.translateX = 0
    this.translateY = 0
    switch (true) {
      case this.scale > 1:
        this.scale = 1
        break
      case this.scale === 1:
        this.scale = 2
        break
    }
    setTimeout(() => {
      this.animate = false
    }, 250)
  }

  onPinchStart () {
    this.lastScale = this.scale
  }

  onPinchMove (e: any) {
    this.scale = this.lastScale * e.scale
  }

  onPinchEnd (e: any) {
    if (this.scale < 1) {
      this.animate = true
      this.scale = 1
      this.translateX = 0
      this.translateY = 0
      setTimeout(() => {
        this.animate = false
      }, 250)
    }
  }

  onPanStart () {
    this.lastTranslateX = this.translateX
    this.lastTranslateY = this.translateY
  }

  onPanMove (e: any) {
    if (this.scale > 1) {
      if (this.containerDOMNode && this.imgDOMNode) {
        // we get the container and image size
        const containerSizes = {
          width: Math.round(this.containerDOMNode.offsetWidth),
          height: Math.round(this.containerDOMNode.offsetHeight)
        }
        const imageSizes = {
          width: Math.round(this.imgDOMNode.offsetWidth * this.scale),
          height: Math.round(this.imgDOMNode.offsetHeight * this.scale)
        }

        // move the image only if width or height overfill the container
        this.translateX = this.lastTranslateX + (e.deltaX / this.scale)
        if (imageSizes.height >= containerSizes.height) {
          this.translateY = this.lastTranslateY + (e.deltaY / this.scale)
        }

        // set the distance to limit the move
        this.dX = ((imageSizes.width - containerSizes.width) / 2) / this.scale
        this.dY = ((imageSizes.height - containerSizes.height) / 2) / this.scale
      }
    }
  }

  onPanEnd () {
    this.animate = true
    // Left and right
    if (this.dX > 0) {
      if (this.translateX > this.dX) {
        this.translateX = this.dX
      }
      if (this.translateX < (-this.dX)) {
        this.translateX = (-this.dX)
      }
    }
    // Up and down
    if (this.dY > 0) {
      if (this.translateY > this.dY) {
        this.translateY = this.dY
      }
      if (this.translateY < (-this.dY)) {
        this.translateY = (-this.dY)
      }
    }
    setTimeout(() => {
      this.animate = false
    }, 250)
  }

}
