/* tslint:disable */
export const messages = {
  fr: {
    global: {
      pointOfInterest: 'point d\'intérêt | points d\'intérêt',
      collection: 'collection | collections',
      person: 'personne | personnes',
      enlargeImage: 'Agrandir l\'image',
      forFurther: 'En savoir plus',
      contentNotAvailableInYourLanguage: 'Ce contenu n\'est pas disponible pour votre langue',
      startAudioGuide: 'Lancer l\'audioguide',
      audioguidePoiNotCurrentPoi: 'Attention, l\'audioguide ne correspond pas au point d\'intérêt actuel.',
      noQrCodeNum: 'Le numéro ne semble pas valide',
      scanQrCode: 'Scanner un QR-Code',
      scanQrCodeNotAllowedTitle: 'Accès à la caméra refusé',
      scanQrCodeNotAllowedSummary: 'Nous ne pouvons accèder à votre caméra… Vous pouvez néanmoins rechercher un contenu sur la page recherche !',
      scanQrCodeNotFoundTitle: 'Caméra introuvable !',
      scanQrCodeNotFoundSummary: 'Votre équipement ne semble pas être équipé d\'une caméra… Vous pouvez néanmoins rechercher un contenu dans la page recherche',
      setQrCodeNum: 'Page recherche',
      scanQrCodeSearchPage: 'Page recherche',
      scanQrCodeWarning: 'Ce QR-Code n\'est pas reconnu... Vous pouvez aussi rechercher un contenu via la page recherche.',
      next: 'Suivant',
      prev: 'Précédent',
      send: 'Envoyer',
      play: 'Jouer',
      setAudioguideContent: 'Jouer l\'audioguide correspondant au contenu',
      ignore: 'Ignorer',
      loading: 'Chargement…'
    }
  },
  en: {
    global: {
      pointOfInterest: 'point of interest | points of interest',
      collection: 'collection | collections',
      person: 'person | people',
      enlargeImage: 'Enlarge image',
      forFurther: 'Read more',
      contentNotAvailableInYourLanguage: 'This content is not available for your language',
      startAudioGuide: 'Start audioguide',
      audioguidePoiNotCurrentPoi: 'Warning, the audioguide doesn\'t match the current content.',
      noQrCodeNum: 'The number does not seem valid',
      scanQrCode: 'Scan a QRCode',
      scanQrCodeNotAllowedTitle: 'Accès à la caméra refusé',
      scanQrCodeNotAllowedSummary: 'Nous ne pouvons accèder à votre caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      scanQrCodeNotFoundTitle: 'Caméra introuvable !',
      scanQrCodeNotFoundSummary: 'Votre équipement ne semble pas être équipé d\'une caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      setQrCodeNum: 'Enter a number',
      next: 'Next',
      prev: 'Previous',
      send: 'Send',
      play: 'Play',
      setAudioguideContent: 'Play the corresponding audio track',
      ignore: 'Ignore',
      loading: 'Loading...'
    }
  },
  us: {
    global: {
      pointOfInterest: 'point of interest | points of interest',
      collection: 'collection | collections',
      person: 'person | people',
      enlargeImage: 'Enlarge image',
      forFurther: 'Read more',
      contentNotAvailableInYourLanguage: 'This content is not available for your language',
      startAudioGuide: 'Start audioguide',
      audioguidePoiNotCurrentPoi: 'Warning, the audioguide doesn\'t match the current content.',
      noQrCodeNum: 'The number does not seem valid',
      scanQrCode: 'Scan a QRCode',
      scanQrCodeNotAllowedTitle: 'Accès à la caméra refusé',
      scanQrCodeNotAllowedSummary: 'Nous ne pouvons accèder à votre caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      scanQrCodeNotFoundTitle: 'Caméra introuvable !',
      scanQrCodeNotFoundSummary: 'Votre équipement ne semble pas être équipé d\'une caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      setQrCodeNum: 'Enter a number',
      next: 'Next',
      prev: 'Previous',
      send: 'Send',
      play: 'Play',
      setAudioguideContent: 'Play the corresponding audio track',
      ignore: 'Ignore',
      loading: 'Loading...'
    }
  },
  da: {
    global: {
      pointOfInterest: 'point of interest | points of interest',
      collection: 'collection | collections',
      person: 'person | people',
      enlargeImage: 'Enlarge image',
      forFurther: 'Read more',
      contentNotAvailableInYourLanguage: 'This content is not available for your language',
      startAudioGuide: 'Start audioguide',
      audioguidePoiNotCurrentPoi: 'Warning, the audioguide doesn\'t match the current content.',
      noQrCodeNum: 'The number does not seem valid',
      scanQrCode: 'Scan a QRCode',
      scanQrCodeNotAllowedTitle: 'Accès à la caméra refusé',
      scanQrCodeNotAllowedSummary: 'Nous ne pouvons accèder à votre caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      scanQrCodeNotFoundTitle: 'Caméra introuvable !',
      scanQrCodeNotFoundSummary: 'Votre équipement ne semble pas être équipé d\'une caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      setQrCodeNum: 'Enter a number',
      next: 'Next',
      prev: 'Previous',
      send: 'Send',
      play: 'Play',
      setAudioguideContent: 'Play the corresponding audio track',
      ignore: 'Ignore',
      loading: 'Loading...'
    }
  },
  es: {
    global: {
      pointOfInterest: 'point of interest | points of interest',
      collection: 'collection | collections',
      person: 'person | people',
      enlargeImage: 'Ampliar la imagen',
      forFurther: 'Leer más',
      contentNotAvailableInYourLanguage: 'Este contenido no está disponible para su idioma',
      startAudioGuide: 'iniciar la audioguía',
      audioguidePoiNotCurrentPoi: 'Advertencia, la audioguía no coincide con el contenido actual',
      noQrCodeNum: 'El número no parece válido',
      scanQrCode: 'Escanear un QRCode',
      scanQrCodeNotAllowedTitle: 'Accès à la caméra refusé',
      scanQrCodeNotAllowedSummary: 'Nous ne pouvons accèder à votre caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      scanQrCodeNotFoundTitle: '¡Cámara no encontrada!',
      scanQrCodeNotFoundSummary: 'Votre équipement ne semble pas être équipé d\'une caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      setQrCodeNum: 'Introduzca un número',
      next: 'Siguiente',
      prev: 'Anteriormente',
      send: 'Enviar',
      play: 'Reproducir',
      setAudioguideContent: 'Reproducir la pista de audio correspondiente',
      ignore: 'Ignore',
      loading: 'Cargando...'
    }
  },
  nl: {
    global: {
      pointOfInterest: 'point of interest | points of interest',
      collection: 'collection | collections',
      person: 'person | people',
      enlargeImage: 'Enlarge image',
      forFurther: 'Read more',
      contentNotAvailableInYourLanguage: 'This content is not available for your language',
      startAudioGuide: 'Start audioguide',
      audioguidePoiNotCurrentPoi: 'Warning, the audioguide doesn\'t match the current content.',
      noQrCodeNum: 'The number does not seem valid',
      scanQrCode: 'Scan a QRCode',
      scanQrCodeNotAllowedTitle: 'Accès à la caméra refusé',
      scanQrCodeNotAllowedSummary: 'Nous ne pouvons accèder à votre caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      scanQrCodeNotFoundTitle: 'Caméra introuvable !',
      scanQrCodeNotFoundSummary: 'Votre équipement ne semble pas être équipé d\'une caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      setQrCodeNum: 'Enter a number',
      next: 'Next',
      prev: 'Previous',
      send: 'Send',
      play: 'Play',
      setAudioguideContent: 'Play the corresponding audio track',
      ignore: 'Ignore',
      loading: 'Loading...'
    }
  },
  de: {
    global: {
      pointOfInterest: 'point of interest | points of interest',
      collection: 'collection | collections',
      person: 'person | people',
      enlargeImage: 'Enlarge image',
      forFurther: 'Read more',
      contentNotAvailableInYourLanguage: 'This content is not available for your language',
      startAudioGuide: 'Start audioguide',
      audioguidePoiNotCurrentPoi: 'Warning, the audioguide doesn\'t match the current content.',
      noQrCodeNum: 'The number does not seem valid',
      scanQrCode: 'Scan a QRCode',
      scanQrCodeNotAllowedTitle: 'Accès à la caméra refusé',
      scanQrCodeNotAllowedSummary: 'Nous ne pouvons accèder à votre caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      scanQrCodeNotFoundTitle: 'Caméra introuvable !',
      scanQrCodeNotFoundSummary: 'Votre équipement ne semble pas être équipé d\'une caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      setQrCodeNum: 'Enter a number',
      next: 'Next',
      prev: 'Previous',
      send: 'Send',
      play: 'Play',
      setAudioguideContent: 'Play the corresponding audio track',
      ignore: 'Ignore',
      loading: 'Loading...'
    }
  },
  it: {
    global: {
      pointOfInterest: 'point of interest | points of interest',
      collection: 'collection | collections',
      person: 'person | people',
      enlargeImage: 'ingrandire l\'immagine',
      forFurther: 'Leggi di più',
      contentNotAvailableInYourLanguage: 'Questo contenuto non è disponibile per la tua lingua',
      startAudioGuide: 'Avviare l\'audioguida',
      audioguidePoiNotCurrentPoi: 'Attenzione, l\'audioguida non corrisponde al contenuto attuale.',
      noQrCodeNum: 'Il numero non sembra valido',
      scanQrCode: 'Scansione di un QRCode',
      scanQrCodeNotAllowedTitle: 'Accesso alla telecamera negato',
      scanQrCodeNotAllowedSummary: 'Non possiamo accedere alla tua telecamera... Puoi ancora inserire il numero sul QrCode manualmente',
      scanQrCodeNotFoundTitle: 'telecamera non trovata!',
      scanQrCodeNotFoundSummary: 'Il tuo apparecchio non sembra essere dotato di una telecamera... Puoi comunque inserire manualmente il numero sul QrCode',
      setQrCodeNum: 'Inserire un numero',
      next: 'Prossimo',
      prev: 'Precedente',
      send: 'Inviare',
      play: 'Riprodurre',
      setAudioguideContent: 'Riprodurre la traccia audio corrispondente',
      ignore: 'Ignorare',
      loading: 'Caricamento...'
    }
  },
  pt: {
    global: {
      pointOfInterest: 'point of interest | points of interest',
      collection: 'collection | collections',
      person: 'person | people',
      enlargeImage: 'Enlarge image',
      forFurther: 'Read more',
      contentNotAvailableInYourLanguage: 'This content is not available for your language',
      startAudioGuide: 'Start audioguide',
      audioguidePoiNotCurrentPoi: 'Warning, the audioguide doesn\'t match the current content.',
      noQrCodeNum: 'The number does not seem valid',
      scanQrCode: 'Scan a QRCode',
      scanQrCodeNotAllowedTitle: 'Accès à la caméra refusé',
      scanQrCodeNotAllowedSummary: 'Nous ne pouvons accèder à votre caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      scanQrCodeNotFoundTitle: 'Caméra introuvable !',
      scanQrCodeNotFoundSummary: 'Votre équipement ne semble pas être équipé d\'une caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      setQrCodeNum: 'Enter a number',
      next: 'Next',
      prev: 'Previous',
      send: 'Send',
      play: 'Play',
      setAudioguideContent: 'Play the corresponding audio track',
      ignore: 'Ignore',
      loading: 'Loading...'
    }
  },
  zh: {
    global: {
      pointOfInterest: 'point of interest | points of interest',
      collection: 'collection | collections',
      person: 'person | people',
      enlargeImage: 'Enlarge image',
      forFurther: 'Read more',
      contentNotAvailableInYourLanguage: 'This content is not available for your language',
      startAudioGuide: 'Start audioguide',
      audioguidePoiNotCurrentPoi: 'Warning, the audioguide doesn\'t match the current content.',
      noQrCodeNum: 'The number does not seem valid',
      scanQrCode: 'Scan a QRCode',
      scanQrCodeNotAllowedTitle: 'Accès à la caméra refusé',
      scanQrCodeNotAllowedSummary: 'Nous ne pouvons accèder à votre caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      scanQrCodeNotFoundTitle: 'Caméra introuvable !',
      scanQrCodeNotFoundSummary: 'Votre équipement ne semble pas être équipé d\'une caméra… Vous pouvez néanmoins saisir manuellement le numéro présent sur le QrCode',
      setQrCodeNum: 'Enter a number',
      next: 'Next',
      prev: 'Previous',
      send: 'Send',
      play: 'Play',
      setAudioguideContent: 'Play the corresponding audio track',
      ignore: 'Ignore',
      loading: 'Loading...'
    }
  }
}
