import CSection from './Section.vue'
import CSectionContent from './SectionContent.vue'
import CSectionTitle from './SectionTitle.vue'
import CSectionTitleAdditional from './SectionTitleAdditional.vue'

export {
  CSection,
  CSectionContent,
  CSectionTitle,
  CSectionTitleAdditional
}

export default {
  CSection,
  CSectionContent,
  CSectionTitle,
  CSectionTitleAdditional
}
