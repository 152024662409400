
import { Component, Mixins } from 'vue-property-decorator'

import Input from '@/mixins/Input'

const config = {
  name: 'c-radio'
}

@Component(config)
export default class CRadio extends Mixins!(Input) {}
