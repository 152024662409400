export const staticPath = (src: string) => src
/**
 * load image resource from src attribute
 * @param {String} src
 * @returns {Promise<Image>}
 */
export const loadImage = (src: string) => new Promise((resolve) => {
  const img = new Image()
  img.addEventListener('load', () => {
    resolve(img)
  }, false)
  img.src = src
})

/**
 * Load some images from src attributes list
 * @param {String[]} srcList
 * @returns {Promise<Image[]>}
 */
export const loadImages = (srcList: string[]) => Promise.all(srcList.map(loadImage))
