
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const config = {
  name: 'c-AuthorModalFigure'
}

@Component(config)
export default class CAuthorModalFigcaption extends Vue {
}
