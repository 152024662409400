
import { Mixins, Component } from 'vue-property-decorator'
import { messages } from './i18n'
import { CLIENT } from '@/constants'
import { Maybe, PointOfInterestList } from '@/@types/graphql'
import sortByPositionKey from '@/utils/sortByPositionKey'

import { PointsOfInterestService } from '@/@next/services'
import { LanguagesMixin } from '@/@next/mixins'

import { CAsyncTask } from '@/@next/components/async-task'
import { CLoader } from '@/components/loader'
import { CInfiniteScroll } from '@/@next/components/infinite-scroll'
import { CImage } from '@/@next/components/image'
import CPoiCard from '@/components/cards/poi-card'
import { CTopbarTitle } from '@/@next/components/topbar-title'
import { CAudioGuideCount } from '@/components/audioguide'

const config = {
  name: 'v-pois',
  components: {
    ...CPoiCard,
    CAsyncTask,
    CInfiniteScroll,
    CImage,
    CLoader,
    CTopbarTitle,
    CAudioGuideCount
  },
  i18n: { messages },
  head () {
    return {
      title: this.$t('pageTitle'),
    }
  }
}

@Component(config)
export default class Pois extends Mixins!(
  PointsOfInterestService,
  LanguagesMixin
) {

  pointsOfInterest: Maybe<PointOfInterestList> = null

  async init () {
    this.pointsOfInterest = await this.GET_ALL_POIS({
      take: 1000
    })
  }

  async fetch () {
    return
  }

  get pageTitle () {
    if (this.$t('pageTitle')) {
      return this.$t('pageTitle')
    }
  }

}
