
import { Vue, Component } from 'vue-property-decorator'
import * as data from './data.json'
import CModal from '@/components/modal'
import { Nullable } from '@/shared'
import { messages } from './i18n'

const config = {
  name: 'c-midi-solaire',
  components: { ...CModal },
  i18n: { messages }
}

@Component(config)
export default class CMidiSolaire extends Vue {

  // fiveMinuteBeforeNotificationTimer: Nullable<number> = null
  fiveMinuteBeforeNotificationTimer: any
  // notificationTimer: Nullable<number> = null
  notificationTimer: any
  poiIdMeridienne: string = 'b3245794-b8a6-43b7-9bea-50bfdd778f8b'
  midiSolaireFlag: boolean = false
  fiveMinuteBeforeMidiSolaireFlag: boolean = false

  get dateList () {
    return data.midiSolaire.map((el: any) => {
      const formattedDate = `${el.YYYY}-${el.MM}-${el.DD}`
      // Date Reelle
      const HSsUTC = el.HSsUTC
      const midiSolaireTimeMs = el.HSsUTC * 60 * 60 * 1000
      // TEST
      // const midiSolaireTimeMs = (((((15 * 60) + 50) * 60) + 0) * 1000) + 0
      // const HSsUTC = midiSolaireTimeMs / (60 * 60 * 1000)
      const h = HSsUTC
      const HH = Math.floor(h)
      const m = (h - HH) * 60
      const MM = Math.floor(m)
      const s = (m - MM) * 60
      const SS = Math.floor(s)
      const ms = (s - SS) * 1000
      const MMS = Math.floor(ms)
      // Test
      return { formattedDate, midiSolaireTimeMs, HSsUTC: el.HSsUTC, HH, MM, SS, MMS }
    })
  }

  getDayTimeInUTCMs (date: Date) {
    const UTC_Ms = date.getUTCMilliseconds()
    const UTC_S = date.getUTCSeconds()
    const UTC_M = date.getUTCMinutes()
    const UTC_H = date.getUTCHours()
    // console.log('getDayTimeInUTCMs', UTC_H, UTC_M, UTC_S, UTC_Ms)
    return (((((UTC_H * 60) + UTC_M) * 60) + UTC_S) * 1000) + UTC_Ms
  }

  formatDate (date: Date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  deltaMsToNextMidiSolaire () {
    const now = new Date()
    // Today Formatted Date YYY-MM-DD
    const todayFormattedDate = this.formatDate(now)
    // Get midi solaire of current day
    const todayData = this.dateList.find((el: any) => el.formattedDate === todayFormattedDate)
    // console.log('today data : ', todayData)
    if (todayData) {
      const todayMidiSolaireMs = todayData.midiSolaireTimeMs
      // console.log('today Midi Solaire : ', todayData.HH, todayData.MM, todayData.SS, todayData.MMS)
      // Current UTC MS
      const currentUTC_Ms = this.getDayTimeInUTCMs(now)
      // Computed difference in ms  of current day
      let deltaMs = Math.round(todayMidiSolaireMs) - currentUTC_Ms
      // console.log('deltaMs', deltaMs)
      if (deltaMs < 0) {
        // Le midi est dépassé, on prend celui du lendemain
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        // Today Formatted Date YYY-MM-DD
        const tomorrowFormattedDate = this.formatDate(tomorrow)
        // Get midi solaire of tomorrow
        const tomorrowData = this.dateList.find((el: any) => el.formattedDate === tomorrowFormattedDate)
        if (tomorrowData) {
          const tomorrowMidiSolaireMs = tomorrowData.midiSolaireTimeMs
          // Computed difference in ms of current day
          deltaMs = Math.round(tomorrowMidiSolaireMs) - currentUTC_Ms
        }
      }
      return deltaMs
    }
    return -1
  }

  midiSolaireNotification () {
    // console.log('midi solaire')
    this.midiSolaireFlag = true
    this.fiveMinuteBeforeMidiSolaireFlag = false
    window.navigator.vibrate(200)
  }

  fiveMinuteBeforeMidiSolaireNotification () {
    // console.log('5 minute before midi solaire')
    this.midiSolaireFlag = false
    this.fiveMinuteBeforeMidiSolaireFlag = true
    window.navigator.vibrate(200)
  }

  closeModal () {
    this.midiSolaireFlag = false
    this.fiveMinuteBeforeMidiSolaireFlag = false
  }

  mounted () {
    // console.log('Midi Solaire Component')
    const deltaMs = this.deltaMsToNextMidiSolaire()
    // console.log('delta ms avant Midi Solaire : ', deltaMs)
    const fiveMinuteBefore = deltaMs - (5 * 60 * 1000)
    // console.log('delta ms 5 avant Midi Solaire : ', fiveMinuteBefore)
    // console.log(deltaMs, fiveMinuteBefore)
    if (deltaMs > 0) {
      this.notificationTimer = setTimeout(() => {
        this.midiSolaireNotification()
      }, deltaMs)
    }
    if (fiveMinuteBefore > 0) {
      this.fiveMinuteBeforeNotificationTimer = setTimeout(() => {
        this.fiveMinuteBeforeMidiSolaireNotification()
      }, fiveMinuteBefore)
    }
  }

  beforeDestroy () {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer)
    }
    if (this.fiveMinuteBeforeNotificationTimer) {
      clearTimeout(this.fiveMinuteBeforeNotificationTimer)
    }
  }
}
