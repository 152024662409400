
import { Component, Mixins, Watch } from 'vue-property-decorator'

import * as UI from '@/event-bus'

import { SettingsMixin } from '@/@next/mixins'
import { MenuAction, MenuItem, RouteWithoutParameterItem } from '@/@types/menu'
import { LanguagesService } from '@/@next/services'
import { CACHE, MAP_URL, FEATURES } from '@/constants'
import addProtocol from '@/utils/addProtocol'

const config = {
  name: 'l-default-menu'
}

@Component(config)
export default class LDefaultMenu extends Mixins(SettingsMixin, LanguagesService) {

  isActiveClass (url: string) {
    return {
      'is-Active': this.$route.path === url
    }
  }

  @Watch('$route')
  onRouteChange () {
    setTimeout(() => {
      UI.EventBus.$emit(UI.HIDE_DEFAULT_MENU)
    }, 250)
  }

  get items () {
    const mapFeature = FEATURES.find((f: any) => f.id === 'map')
    const mapIsEnable = mapFeature && (mapFeature.data.disable === 'undefined' || !mapFeature.data.disable )
    const items = this._settingsMenu?.items || []
    return mapIsEnable ? items : items.filter((item: any) => item.id !== 'map')
  }

  itemPath (item: MenuItem) {
    if (item.action.__typename === MenuAction.externalRedirection) return item.action?.url
    else if (item.action.__typename === MenuAction.internalRedirection) {
      const url = `${item.action.route.name}`
      if (item.action.route.__typename === 'RouteWithoutParameter') {
        // if (item.action.route.name === RouteWithoutParameterItem.fiberMap) {
        //   const mapUrl = MAP_URL
        //   const url = encodeURI(`${mapUrl}?hl=${this.$i18n.locale}`)
        //   return `/embeds?url=${url}&bot=false&topbar=trans`
        // }
        return url
      }
      return `${url}/${item.action.route.param}`
    }
    return ''
  }

  goToExternalUrl (item: MenuItem) {
    if (item.action.__typename !== MenuAction.externalRedirection) return
    const url = item.action?.url
    window.location.href = /^http/.test(url) ? url : 'http://' + url
  }

  itemText (item: MenuItem) {
    const text = item.text.find((t) => t.language === this._currentLanguage)
    if (text) return text.value.text
    // TODO Change 'fr' with fallback language
    const fallback = item.text.find((t) => t.language === null) ||
      item.text.find((t) => t.language === 'fr')
    if (fallback) return fallback.value.text
    return ''
  }

  isInternalRedirection (item: MenuItem) {
    return item.action.__typename === MenuAction.internalRedirection
  }

  isExternalRedirection (item: MenuItem) {
    return item.action.__typename === MenuAction.externalRedirection
  }

  get version () {
    return this.$store.state.version
  }

  get cache () {
    return CACHE
  }

  checkLogoMenu () {
    try {
      const logoTopBar = new Image();
      logoTopBar.addEventListener('load', () => {
        const customElm = document.getElementById('logo-menu-custom')
        const defaultElm = document.getElementById('logo-menu-default')
        if (!customElm || !defaultElm) return
        customElm.style.display = 'block'
        defaultElm.style.display = 'none'
      }, false)
      logoTopBar.src = './logo-menu.png';
    } catch (e) {
      console.warn('Error during logo menu verification.', e)
    }
  }

  mounted () {
    this.checkLogoMenu()
  }

}
