/* tslint:disable */
import Marzipano from 'marzipano'

class Viewer {

  data: any
  options: any
  viewerOpts: any
  viewer: any
  panoElement: any
  autorotate: any
  autorotateToggleElement: any
  scenes: any
  viewer360: any
  sceneNameElement: any
  sceneListElement: any
  sceneElements: any
  sceneListToggleElement: any
  fullscreenToggleElement: any
  viewUpElement: any
  viewDownElement: any
  viewLeftElement: any
  viewRightElement: any
  viewInElement: any
  viewOutElement: any

  constructor (_data: any, _options: any) {
    // Init variables
    this.data = _data
    this.options = _options
    this.initDOMViewer()

    // Initialize viewer.
    this.viewer = new Marzipano.Viewer(this.panoElement, this.viewerOpts)

    // Setup autorotate.
    this.autorotate = Marzipano.autorotate({ yawSpeed: 0.1, targetPitch: 0, targetFov: (Math.PI) / 2 })
    if (this.data.settings.autorotateEnabled) {
      this.autorotateToggleElement.classList.add('enabled')
    }

    // Create scenes.
    this.createScenes()

    // Display the initial scene.
    this.switchScene(this.scenes[this.options.index])

    // DOM elements for view controls.
    this.initDOMControls()
  }

  initDOMViewer () {
    // Grab elements from DOM.
    this.viewer360 = document.querySelector('#viewer360')
    this.panoElement = document.querySelector('#pano')
    this.sceneNameElement = document.querySelector('#titleBar .sceneName')
    this.sceneListElement = document.querySelector('#sceneList')
    this.sceneElements = document.querySelectorAll('#sceneList .scene')
    this.sceneListToggleElement = document.querySelector('#sceneListToggle')
    this.autorotateToggleElement = document.querySelector('#autorotateToggle')
    this.fullscreenToggleElement = document.querySelector('#fullscreenToggle')

    if (!this.options.title) this.sceneNameElement.style.display = 'none'
    if (!this.options.autorotate) {
      this.data.settings.autorotateEnabled = false
      this.autorotateToggleElement.style.display = 'none'
    }
    if (!this.options.list) {
      this.sceneListToggleElement.style.display = 'none'
    }
  }

  initDOMControls () {
    this.viewUpElement = document.querySelector('#viewUp')
    this.viewDownElement = document.querySelector('#viewDown')
    this.viewLeftElement = document.querySelector('#viewLeft')
    this.viewRightElement = document.querySelector('#viewRight')
    this.viewInElement = document.querySelector('#viewIn')
    this.viewOutElement = document.querySelector('#viewOut')

    // Dynamic parameters for controls.
    const velocity = 0.7
    const friction = 3

    // Associate view controls with elements.
    const controls = this.viewer.controls()
    controls.registerMethod('upElement', new Marzipano.ElementPressControlMethod(this.viewUpElement, 'y', -velocity, friction), true)
    controls.registerMethod('downElement', new Marzipano.ElementPressControlMethod(this.viewDownElement, 'y', velocity, friction), true)
    controls.registerMethod('leftElement', new Marzipano.ElementPressControlMethod(this.viewLeftElement, 'x', -velocity, friction), true)
    controls.registerMethod('rightElement', new Marzipano.ElementPressControlMethod(this.viewRightElement, 'x', velocity, friction), true)
    controls.registerMethod('inElement', new Marzipano.ElementPressControlMethod(this.viewInElement, 'zoom', -velocity, friction), true)
    controls.registerMethod('outElement', new Marzipano.ElementPressControlMethod(this.viewOutElement, 'zoom', velocity, friction), true)
  }

  createScenes () {
    this.scenes = this.data.scenes.map((data: any) => {
      const source = Marzipano.ImageUrlSource.fromString(
        `${this.options.path}/tiles/${data.id}/{z}/{f}/{y}/{x}.jpg`,
        { cubeMapPreviewUrl: `${this.options.path}/tiles/${data.id}/preview.jpg` })
      const geometry = new Marzipano.CubeGeometry(data.levels)

      const limiter = Marzipano.RectilinearView.limit.traditional(data.faceSize, (100 * Math.PI) / 180, (120 * Math.PI) / 180)
      const view = new Marzipano.RectilinearView(data.initialViewParameters, limiter)

      const scene = this.viewer.createScene({ source, geometry, view, pinFirstLevel: true })

      // Create link hotspots.
      data.linkHotspots.forEach((hotspot: any) => {
        const element = this.createLinkHotspotElement(hotspot)
        scene.hotspotContainer().createHotspot(element, { yaw: hotspot.yaw, pitch: hotspot.pitch })
      });

      // Create info hotspots.
      data.infoHotspots.forEach((hotspot: any) => {
        const element = this.createInfoHotspotElement(hotspot)
        scene.hotspotContainer().createHotspot(element, { yaw: hotspot.yaw, pitch: hotspot.pitch })
      });

      return { data, scene, view }
    });
  }

  switchScene (scene: any) {
    this.stopAutorotate()
    scene.view.setParameters(scene.data.initialViewParameters)
    scene.scene.switchTo()
    this.startAutorotate()
    this.updateSceneName(scene)
    this.updateSceneList(scene)
  }

  /*
  sanitize(s) {
    return s.replace('&', '&amp;').replace('<', '&lt;').replace('>', '&gt;');
  } */

  updateSceneName (scene: any) {
    this.sceneNameElement.innerHTML = scene.data.name.replace('&', '&amp;').replace('<', '&lt;').replace('>', '&gt;')
  }

  updateSceneList (scene: any) {
    for (let i = 0; i < this.sceneElements.length; i++) {
      const el = this.sceneElements[i]
      if (el.getAttribute('data-id') === scene.data.id) {
        el.classList.add('current')
      } else {
        el.classList.remove('current')
      }
    }
  }

  startAutorotate () {
    if (!this.autorotateToggleElement.classList.contains('enabled')) {
      return
    }
    this.viewer.startMovement(this.autorotate)
    this.viewer.setIdleMovement(3000, this.autorotate)
  }

  stopAutorotate () {
    this.viewer.stopMovement()
    this.viewer.setIdleMovement(Infinity)
  }

  createLinkHotspotElement (hotspot: any) {
    // Create wrapper element to hold icon and tooltip.
    const wrapper = document.createElement('div')
    wrapper.classList.add('hotspot')
    wrapper.classList.add('link-hotspot')

    // Create image element.
    const icon = document.createElement('img')
    icon.src = '/img/link.png'
    icon.classList.add('link-hotspot-icon')

    // Set rotation transform.
    const transformProperties = ['-ms-transform', '-webkit-transform', 'transform']
    for (let i = 0; i < transformProperties.length; i++) {
      const property = transformProperties[i]
      // @ts-ignore
      icon.style[property] = `rotate('${hotspot.rotation}rad)`
    }

    // Add click event handler.
    wrapper.addEventListener('click', () => {
      this.switchScene(this.findSceneById(hotspot.target))
    });

    // Prevent touch and scroll events from reaching the parent element.
    // This prevents the view control logic from interfering with the hotspot.
    // @ts-ignore
    Viewer.stopTouchAndScrollEventPropagation(wrapper)

    // Create tooltip element.
    const tooltip = document.createElement('div')
    tooltip.classList.add('hotspot-tooltip')
    tooltip.classList.add('link-hotspot-tooltip')
    tooltip.innerHTML = this.findSceneDataById(hotspot.target).name

    wrapper.appendChild(icon)
    wrapper.appendChild(tooltip)

    return wrapper
  }

  createInfoHotspotElement (hotspot: any) {
    // Create wrapper element to hold icon and tooltip.
    const wrapper = document.createElement('div')
    wrapper.classList.add('hotspot')
    wrapper.classList.add('info-hotspot')

    // Create hotspot/tooltip header.
    const header = document.createElement('div')
    header.classList.add('info-hotspot-header')

    // Create image element.
    const iconWrapper = document.createElement('div')
    iconWrapper.classList.add('info-hotspot-icon-wrapper')
    const icon = document.createElement('img')
    icon.src = '/static/360/img/info.png'
    icon.classList.add('info-hotspot-icon')
    iconWrapper.appendChild(icon)

    // Create title element.
    const titleWrapper = document.createElement('div')
    titleWrapper.classList.add('info-hotspot-title-wrapper')
    const title = document.createElement('div')
    title.classList.add('info-hotspot-title')
    title.innerHTML = hotspot.title
    titleWrapper.appendChild(title)

    // Create close element.
    const closeWrapper = document.createElement('div')
    closeWrapper.classList.add('info-hotspot-close-wrapper')
    const closeIcon = document.createElement('img')
    closeIcon.src = '/static/360/img/close.png'
    closeIcon.classList.add('info-hotspot-close-icon')
    closeWrapper.appendChild(closeIcon)

    // Construct header element.
    header.appendChild(iconWrapper)
    header.appendChild(titleWrapper)
    header.appendChild(closeWrapper)

    // Create text element.
    const text = document.createElement('div')
    text.classList.add('info-hotspot-text')
    text.innerHTML = hotspot.text

    // Place header and text into wrapper element.
    wrapper.appendChild(header)
    wrapper.appendChild(text)

    // Create a modal for the hotspot content to appear on mobile mode.
    const modal = document.createElement('div')
    modal.innerHTML = wrapper.innerHTML
    modal.classList.add('info-hotspot-modal')
    this.viewer360.appendChild(modal)

    /* toggle = function() {
      wrapper.classList.toggle('visible');
      modal.classList.toggle('visible');
    }; */

    // Show content when hotspot is clicked.
    // @ts-ignore
    wrapper.querySelector('.info-hotspot-header').addEventListener('click', () => {
      wrapper.classList.toggle('visible')
      modal.classList.toggle('visible')
    })

    // Hide content when close icon is clicked.
    // @ts-ignore
    modal.querySelector('.info-hotspot-close-wrapper').addEventListener('click', () => {
      wrapper.classList.toggle('visible')
      modal.classList.toggle('visible')
    });

    // Prevent touch and scroll events from reaching the parent element.
    // This prevents the view control logic from interfering with the hotspot.
    // @ts-ignore
    Viewer.stopTouchAndScrollEventPropagation(wrapper)

    return wrapper
  }

  // Prevent touch and scroll events from reaching the parent element.
  static stopTouchAndScrollEventPropagation (element: any, eventList: any) {
    eventList = ['touchstart', 'touchmove', 'touchend', 'touchcancel', 'wheel', 'mousewheel']
    for (let i = 0; i < eventList.length; i++) {
      element.addEventListener(eventList[i], (event: any) => {
        event.stopPropagation()
      });
    }
  }

  findSceneById (id: any) {
    for (let i = 0; i < this.scenes.length; i++) {
      if (this.scenes[i].data.id === id) {
        return this.scenes[i]
      }
    }
    return null;
  }

  findSceneDataById (id: any) {
    for (let i = 0; i < this.data.scenes.length; i++) {
      if (this.data.scenes[i].id === id) {
        return this.data.scenes[i]
      }
    }
    return null;
  }

  toggleAutorotate () {
    if (this.autorotateToggleElement.classList.contains('enabled')) {
      this.autorotateToggleElement.classList.remove('enabled')
      this.stopAutorotate()
    } else {
      this.autorotateToggleElement.classList.add('enabled')
      this.startAutorotate()
    }
  }

  /* toggleFullscreen() {
    screenfull.toggle();
    if (screenfull.isFullscreen) {
      fullscreenToggleElement.classList.add('enabled');
    } else {
      fullscreenToggleElement.classList.remove('enabled');
    }
  } */

  toggleSceneList () {
    this.sceneListElement.classList.toggle('enabled')
    this.sceneListToggleElement.classList.toggle('enabled')
  }

}

export default Viewer
