
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Nullable } from '@/@next/@types'

import { CustomTagMixin } from '@/@next/mixins'

const config = {
  name: 'c-infinite-scroll'
}

@Component(config)
export default class InfiniteScroll extends Mixins(CustomTagMixin) {
  @Prop({ type: Boolean }) loading!: boolean

  observer: Nullable<IntersectionObserver> = null

  mounted () {
    this.initIntersectionObserver()
  }

  beforeDestroy () {
    this.observer!.disconnect()
  }

  initIntersectionObserver () {
    const options = { rootMargin: '0px', threshold: [0, 0, 0, 0] }
    this.observer = new IntersectionObserver(this.onEnterViewport as IntersectionObserverCallback,  options)
    this.observer.observe(this.$refs.spy as HTMLElement)
  }

  onEnterViewport (entries: IntersectionObserverEntry[]) {
    if (entries[0].isIntersecting && !this.loading) this.$emit('fetch')
  }

}
