import CLazyLoadingImage from './LazyLoadingImage.vue'
import CImage from './Image.vue'

export {
  CLazyLoadingImage,
  CImage
}

export default {
  CLazyLoadingImage,
  CImage
}
