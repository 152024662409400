
import { Component, Mixins, Prop } from 'vue-property-decorator'

import CustomTagMixin from '@/@next/mixins/CustomTag'

const config = {
  name: 'c-content-text'
}

@Component(config)
export default class Text extends Mixins(CustomTagMixin) {
}
