
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'c-loader'
})
export default class CLoader extends Vue {

  @Prop({ type: Boolean, default: false }) dark!: boolean
  @Prop({ type: Boolean, default: false }) light!: boolean
  @Prop({ type: Boolean, default: false }) small!: boolean

  get CSSClasses () {
    return {
      '--Small': this.small,
      '--Light': this.light,
      '--Dark': this.dark
    }
  }

}
