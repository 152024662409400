
import {Vue, Component, Mixins} from 'vue-property-decorator'
import { Route } from 'vue-router'
import Matomo from 'matomo-ts'

import { Maybe, Collection, PointOfInterest } from '@/@types/graphql'
import { CLIENT, MAP_URL, MATOMO_URL } from '@/constants'

import { messages } from './i18n'

import { CollectionsService } from '@/@next/services'
import { FiberMapMixin, AuthorizationMixin, SaveScrollMixin } from '@/@next/mixins'

import { CAsyncTask } from '@/@next/components/async-task'
import CModal from '@/components/modal'
import CPoiCard from '@/components/cards/poi-card'
import { CLoader } from '@/components/loader'
import { CImage } from '@/@next/components/image'
import { CAudioGuideCount } from '@/components/audioguide'
import { CButton } from '@/components/form-controls/button'
import { CInfiniteScroll } from '@/@next/components/infinite-scroll'
import { CTopbarTitle } from '@/@next/components/topbar-title'
import { filterPublishableItem } from '@/@next/helpers/publishable'
import { Action } from 'vuex-class'
import * as FIBERMAP from '@/@next/store/fibermap/types'
import addProtocol from '@/utils/addProtocol'

const config = {
  name: 'v-single-collection',
  components: {
    CAsyncTask,
    CInfiniteScroll,
    ...CPoiCard,
    CImage,
    CAudioGuideCount,
    CLoader,
    ...CModal,
    CButton,
    CTopbarTitle
  },
  i18n: { messages },
  head () {
    return {
      title: this.tabTitle,
      titleTemplate: '%s '
      // titleTemplate: '%s – Wivi'
    }
  }
}

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave'
])

@Component(config)
  export default  class SingleCollection extends Mixins!(
    SaveScrollMixin,
    CollectionsService,
    FiberMapMixin,
    AuthorizationMixin
  ) {

  // TODO: Remove with fiber map update v4
  // @Action(FIBERMAP.CONTENT_HAS_LINK)
  // isPresentOnTheMap!: ({type, uuid}: {type: string, uuid: string}) => boolean
  // displayMapLink: boolean = false

  collection: Maybe<Collection> = null
  skip: number = 0
  fetchLoading: boolean = false
  welcomeModalOpen: boolean = false
  needWelcomeModal: boolean = true
  welcomeModalTimeOut: any = null

  async init () {
    const collection = await this.GET_SINGLE_COLLECTION(this.$route.params.uuid, {
      take: 100
    })
    const { pointsOfInterest } = collection
    this.collection = {
      ...collection,
      pointsOfInterest: {
        ...pointsOfInterest,
        items: filterPublishableItem(pointsOfInterest.items)
      }
    }
    this.sendMatomoInput()
    // this.displayMapLink = await this.isPresentOnTheMap({type: 'collection', uuid: this.collection.id})
  }

  get locks () {
    if (!this.collection) return []
    return this.collection.locks
  }

  get isLocked () {
    return !this.isRessourceAuhtorized(this.locks)
  }

  sendMatomoInput () {
    if (!MATOMO_URL || !this.collection || !this.collection.i18n.title) return
    const title = this.collection.i18n.title.value.text as string
    Matomo.default().trackPageView(title)
  }

  async fetch () {
    if (!this.collection) return
    // On ne fait rien si il n'y plus d'élements à récupérer
    if (this.skip >= this.collection.pointsOfInterest.totalCount) return
    // Sinon on active le flag de chargement et on incrémente le nombre d'élement à passer
    this.fetchLoading = true
    this.skip += 100
    // On récup
    const { pointsOfInterest } = await this.GET_SINGLE_COLLECTION(this.$route.params.uuid, {
      skip: this.skip,
      take: 100
    })
    // pointsOfInterest.items = filterPublishableItem(pointsOfInterest.items)
    setTimeout(() => {
      this.collection!.pointsOfInterest.items = [
        ...this.collection!.pointsOfInterest.items,
        ...pointsOfInterest.items
      ]
      this.collection!.pointsOfInterest.itemsCount = pointsOfInterest.itemsCount
      this.fetchLoading = false
    }, 200)
  }

  get pointOfInterestList () {
    if (!this.collection || !this.collection.pointsOfInterest.items) return []
    if (CLIENT === 'juno' || CLIENT === 'webbvisit') {
      const hasTitle = (c: PointOfInterest) => c.i18n.title && c.i18n.title.value.text.length > 3
      const poiHasTitle = (p: PointOfInterest) => p.i18n.title && p.i18n.title.value.text.length > 3
      return this.collection.pointsOfInterest.items.filter(hasTitle)
    }
    return this.collection.pointsOfInterest.items
  }

  get tabTitle () {
    return (this.collection && this.collection.i18n.title) ?
      this.collection.i18n.title.value.text :
      this.$t('global.loading')
  }

  get pageTitle () {
    return this.collection?.i18n.title?.value?.html || this.$t('global.loading')
    // return (this.collection && this.collection.i18n.title) ?
    //   this.collection.i18n.title.value.html :
    //   this.$t('global.loading')
  }

  get title () {
    return this.collection?.i18n.title?.value?.html || ''
  }

  get abstract () {
     return this.collection?.i18n.abstract?.value?.html || ''
  }

  get description () {
     return this.collection?.i18n.description?.value?.html || ''
  }

  onWelcomeModalClose () {
    this.welcomeModalOpen = false
    this.needWelcomeModal = false
  }

  beforeRouteEnter (to: Route, from: Route, next: any) {
    next((vm: Vue) => {
      if (from.name === 'single-poi') {
        // @ts-ignore
        vm.welcomeModalOpen = false
      } else {
        // @ts-ignore
        if (vm.needWelcomeModal) {
          // @ts-ignore
          vm.welcomeModalTimeOut = setTimeout(() => vm.welcomeModalOpen = true, 2000)
        }
      }
    })
  }

  beforeRouteLeave (to: Route, from: Route, next: any) {
    clearTimeout(this.welcomeModalTimeOut)
    this.welcomeModalOpen = false
    next()
  }

  // seeOnMap () {
  //   if (!this.collection) return
  //   console.log('See collection on Map', this.collection.placeholders)
  //   this.showPlaceholder(this.collection?.placeholders[0])
  // }

  // goToMap () {
  //   const mapUrl = MAP_URL
  //   const url = encodeURIComponent(`${mapUrl}?hl=${this.$i18n.locale}&type=collection&uuid=${this.collection?.id}`)
  //   this.$router.push(`/embeds/?bot=false&topbar=trans&url=${url}`)
  // }

}
