
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import CustomWrapper from '@/components/CustomWrapper.vue'

const config = {
  name: 'c-collection-card-thumbnail',
  components: { CustomWrapper }
}

@Component(config)
export default class CCollectionCardThumbnail extends Vue {

  @Prop({ type: String, default: 'div' }) tag!: string
  @Prop({ type: Boolean, default: false }) cover!: boolean
  @Prop({ type: Boolean, default: false }) contain!: boolean

  get CSSClasses () {
    return {
      '--Cover': this.cover,
      '--Contain': this.contain,
    }
  }

}
