









































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { CModal } from '@/components/modal'
import { CButton } from '@/components/form-controls/button'
import { CRadio, CRadioButton, CRadioButtonIcon, CRadioButtonLabel } from '@/components/form-controls/radio'
import CLanguageSelector from './LanguageSelector.vue'
import * as LANGUAGES from '@/@next/store/languages/types'
import { Language } from '@/@types/graphql'
import { messages } from '@/@next/i18n'
import { LanguagesService, UsersService } from '../services'
import CLanguageAndTarget from '@/@next/components/LanguageAndTarget.vue'
import CAccessibility from '@/@next/components/Accessibility.vue'
import { SvgIcon, IconLanguage, IconAccessibility, IconCheck} from '@/@next/components/icons'

const config = {
  name: 'c-configuration',
  i18n: { messages },
  components: {
    CModal,
    CRadioButton,
    CRadioButtonIcon,
    CRadioButtonLabel,
    CButton,
    CRadio,
    CLanguageSelector,
    CLanguageAndTarget,
    CAccessibility,
    SvgIcon, IconLanguage, IconAccessibility, IconCheck
  }
}

@Component(config)
export default class CConfiguration extends Mixins (LanguagesService, UsersService) {

  // currentSection = 'language'
  currentStep = 0
  // sections = ['language', 'accessibility']
  canValidate: boolean = false

  @Watch('currentStep')
  onCurrentSessionUpdate () {
    this.canValidate = this.currentStep === 3
  }

  mounted () {
      this.checkLogoTopBar()
  }

  next () {
    if (this.canValidate) this.close()
    else this.currentStep++
  }

  close () {
    this.SAVE_CURRENT_USER_PREFERENCES()
    // this.SET_USER_CONFIG({ isoLanguage: this._currentLanguage, target: this._languageTarget })
    // this.$cookies.set('WIVI_CONFIG', ))
    this.$emit('close')
  }

  findTranslation (text: any) {
      if (!text?.length) return null
      const translation = text.find((el: any) => el.language === this.$i18n.locale)
      if (translation) return translation.value.html
      const french = text.find((el: any) => el.language === 'fr')
      if (french) return french?.value.html
      return null
  }

  checkLogoTopBar () {
      try {
      const logoTopBar = new Image();
      logoTopBar.addEventListener('load', () => {
          const customElm = document.getElementById('configuration-logo-custom')
          const defaultElm = document.getElementById('configuration-logo-default')
          if (!customElm || !defaultElm) return
          customElm.style.display = 'block'
          defaultElm.style.display = 'none'
      }, false)
      logoTopBar.src = './logo-topbar.png';
      } catch (e) {
      console.warn('Error during logo topBar verification.', e)
      }
  }

  get language () {
      return this._allLanguages.find((l: Language) => l.iso === this._currentLanguage)
  }

  setTarget (target: string) {
    this._setTarget(target)
    this.SAVE_CURRENT_USER_PREFERENCES()
    // const currentConfig = this.GET_USER_CONFIG()
    // this.SET_USER_CONFIG({...currentConfig, target})
  }

}
