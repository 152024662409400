import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class Sizable extends Vue {

  @Prop({ type: Boolean, default: false }) small!: boolean
  @Prop({ type: Boolean, default: false  }) medium!: boolean
  @Prop({ type: Boolean, default: false  }) large!: boolean

}
