import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'

import { Dataset as DatasetType } from '@/@types/graphql'

@Component({ name: 'dataset-service' })
export default class Dataset extends Vue {

    async GET_DATASET (key: string): Promise<DatasetType> {
    const variables = { key }
    const query = gql`      
        query dataset ($key: String!) {
            dataset (key: $key) {
                value
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.dataset
  }

}
