import { Vue } from 'vue-property-decorator'
import { ActionTree } from 'vuex'

import * as SETTINGS from './types'
import { RootState } from '@/@next/store'
import { SettingsState } from './'

export const ENDPOINT = 'data'

export const actions: ActionTree<SettingsState, RootState> =  {

  [SETTINGS.SET_REALM] ({ commit, state }, realm: string) {
    try {
      // TODO : Fetch all realm before and check if realm exist
      commit(SETTINGS.SET_REALM, realm)
    } catch (e) {
      throw new Error(`
        \nError in ${ SETTINGS.SET_REALM } action.
        \nError message : ${ e }
      `)
    }
  },

  async [SETTINGS.FETCH] ({ state, commit, dispatch }, keys = null) {
    try {
      if (!keys) {
        await dispatch(SETTINGS.FETCH_AVAILABLE)
        keys = state.availableSettings
      }
      keys = Array.isArray(keys) ? keys : [keys]
      const toQueryStringPart = (k: string, i: number) => `${i === 0 ? '?' : '&'}key=${k}`
      const queryStringParts = keys.map(toQueryStringPart)
      const url = ENDPOINT.concat(queryStringParts.join(''))
      const { data } = await Vue.axios.get(url)
      for (const [key, value] of Object.entries(data)) {
        commit(SETTINGS.ADD, { key, value })
        if (typeof value === 'object' && !(Array.isArray(value))) {
          if ('key' in value! && (value as any).key === 'menus') {
            commit(SETTINGS.ADD, { key, value: (value as any).value })
          }
        }
      }
      // for (const [key, value] of Object.entries(data)) {
      //   if (typeof value === 'object' && !(Array.isArray(value))) {
      //     if ('key' in value! && (value as any).key === 'menus') {
      //       commit(SETTINGS.ADD, { key, value: (value as any).value })
      //     } else {
      //       commit(SETTINGS.ADD, { key, value })
      //     }
      //   }
      // }
      // for (const [key, value] of Object.entries(data)) {
      //   if (key === 'menus') console.log('MENU ', key, value)
      //   commit(SETTINGS.ADD, { key, value })
      // }
    } catch (e) {
      throw new Error(`
        \nError in ${ SETTINGS.FETCH } action.
        \nError message : ${e}
      `)
    }
  },

  async [SETTINGS.FETCH_AVAILABLE] ({ state, commit }) {
    try {
      const { data } = await Vue.axios.get(ENDPOINT)
      commit(SETTINGS.ADD_AVAILABLE, data);
    } catch (e) {
      throw new Error(`
        \nError in ${SETTINGS.FETCH_AVAILABLE} action.
        \nError message : ${e}
      `)
    }
  }

}
