
import {Vue, Component, Prop} from 'vue-property-decorator'
import CustomWrapper from '@/components/CustomWrapper.vue'

@Component({
  name: 'c-simple-card-icon',
  components: { CustomWrapper }
})
export default class CSimpleCardIcon extends Vue {
  @Prop({ type: String, default: 'span' }) tag!: string
}
