
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const config = {
  name: 'c-async-task'
}

@Component(config)
export default class AsyncTask extends Vue {

  @Prop({ type: Function, default: () => undefined }) exec!: () => Promise<any>
  @Prop({ type: Number, default: 0 }) minDelay!: number
  @Prop({ type: Boolean, default: false }) manual!: boolean
  @Prop({ type: Boolean, default: false }) debug!: boolean

  pending: boolean = false
  fail: boolean = false
  done: boolean = false
  data: any = null

  async created () {
    if (!this.manual) await this.init()
  }

  async init () {
    this.$emit('start')
    await this.start()
  }

  @Watch('manual')
  async onManualPropChanged () {
    console.log('onManualPropChanged,', this.manual)
    if (this.manual) await this.start()
  }

  async start () {
    try {
      this.$emit('start')
      this.pending = true
      if (this.debug) console.info('Async task started...')
      const startTime = Date.now()
      const pendingTime = await (async (): Promise<number> => {
        this.data = await this.exec()
        return Date.now() - startTime
      })()
      if (this.debug) console.info('Async task done !')
      this.$emit('done', { duration: pendingTime })
      const delayDuration = Math.max(this.minDelay - pendingTime, 0)
      setTimeout(() => {
        this.fail = false
        this.pending = false
        this.done = true
      }, delayDuration)
    } catch (error) {
      if (this.debug) console.warn('Async task failed x__x')
      this.$emit('fail', { error })
      setTimeout(() => {
        this.fail = true
        this.pending = false
        this.done = false
      }, this.minDelay)
    }
  }

  render () {
    if (!(this.$scopedSlots && this.$scopedSlots.default)) return
    return this.$scopedSlots.default({
      retry: this.init,
      start: this.init,
      manual: this.manual,
      data: this.data,
      done: this.done,
      pending: this.pending,
      fail: this.fail
    })
  }

}
