import { Component, Vue } from 'vue-property-decorator'
import io from 'socket.io-client'
import { SOCKET_AUTHORIZATION_SERVICE_URL, CLIENT } from '@/constants'
import * as AUTHORIZATION from '@/@next/store/authorization'
import { Action, Getter, Mutation } from 'vuex-class'
import { Lock, Maybe } from '@/@types/graphql'
import { authorization, TicketStatus } from '@/@next/store/authorization'

@Component({ name: 'authorization-mixin' })
export default class Authorization extends Vue {

  @Getter(AUTHORIZATION.GET_USER_AUTHORIZATIONS)
  getCurrentAuthorizations!: string[]
  @Action(AUTHORIZATION.SET_TICKET_INPUT)
  setTicketInput!: (lock: Lock) => void
  @Mutation(AUTHORIZATION.SET_SHOW_TICKET_INPUT)
  setShowTicketInput!: (show: boolean) => void
  @Getter(AUTHORIZATION.SHOW_TICKET_INPUT)
  showTicketInput!: boolean
  @Action(AUTHORIZATION.ADD_AUTHORIZATIONS)
  addAuthorizations!: (authorizationArray: string[]) => void
  @Action(AUTHORIZATION.REMOVE_AUTHORIZATIONS)
  removeAuthorizations!: (authorizationArray: string[]) => void
  @Mutation(AUTHORIZATION.SET_EXTERNAL_AUTHORIZATION_SOCKET)
  setExternalAuthServiceSocket!: (socket: any) => void
  @Getter(AUTHORIZATION.GET_EXTERNAL_AUTHORIZATION_SOCKET)
  getExternalAuthServiceSocket!: any
  @Action(AUTHORIZATION.HAS_AUTHORIZATIONS)
  hasAuthorizations!: (authorizations: string[]) => boolean
  @Getter(AUTHORIZATION.GET_CURRENT_LOCK)
  getCurrentLock!: Maybe<Lock>
  @Action(AUTHORIZATION.SET_CURRENT_LOCK)
  setCurrentLock!: (lock: Lock) => void
  @Getter(AUTHORIZATION.GET_USER_ID)
  getUserId!: string | null
  @Action(AUTHORIZATION.SET_TICKET_STATUS)
  setTicketStatus!: (status: TicketStatus) => void
  @Mutation(AUTHORIZATION.SET_TICKET_DATA)
  setTicketData!: (ticket: any) => void

  authorizationCookieName = 'WIVI_AUTHORIZATION'
  ticketCookieName = 'WIVI_TICKET'

  socket: any
  authorizationCookie: string[] = []
  isAuthorized: boolean = false
  
  hasAccessControl: boolean = false
  userId: string | null = null
  ticket: string | null = null
  hasTicketQuery: boolean = false

  get hasSocketAuthorizationService () {
    return !!SOCKET_AUTHORIZATION_SERVICE_URL
  }
  
  async initAuthorization () {
    
    // Get ticket Cookie
    const ticketCookie = this.getTicketCookie()
    if (ticketCookie && typeof ticketCookie === 'string') this.ticket = ticketCookie
    // Get ticketQuery
    const { ticket, ...queries } = this.$route.query
    if (ticket && typeof ticket === 'string') {
      this.ticket = ticket
      this.$router.replace({ query: queries })
      this.hasTicketQuery = true
    }

    if (this.ticket) this.saveTicket(this.ticket)
    // Get Authorization Coockie
    this.authorizationCookie = this.getAuthorizationCookie()
    this.addAuthorizations(this.authorizationCookie)

    this.connectToExternalAuthorizationServiceSocket()
    // if (SOCKET_AUTHORIZATION_SERVICE_URL) {
    //   // this.hasSocketAuthorizationService = true
      
    // }
  }

  connectToExternalAuthorizationServiceSocket () {
    // Has Socket authorization service for this project ?
    if (!this.hasSocketAuthorizationService || !SOCKET_AUTHORIZATION_SERVICE_URL) return

    // Open Access Socket
    // this.socket = io.connect(`wss://${SOCKET_AUTHORIZATION_SERVICE_URL}`)
    this.socket = io.connect(SOCKET_AUTHORIZATION_SERVICE_URL)
    console.log(this.socket)
    // this.setExternalAuthServiceSocket(io.connect(this.socket ))

    this.socket.on('connect', () => {
      console.log('WS client connect')
      if (this.ticket) this.sendUserInfo(
        [{
          type: 'ticketNumber',
          value: this.ticket
        }]
      )
      else this.sendUserInfo()
    })
    this.socket.on('ticket_authorization', (response: any) => {
      console.log('WS client receive authorization ', response)
      if (response.isAuthorized) this.addAuthorizations(response.authorizations)
      else this.removeAuthorizations(response.authorizations)
      this.setAuthCookie()
      // TODO Faire une petite verification sur le ticket suatus si hors énumeration
      if (!response.ticketStatus) return this.setTicketStatus(TicketStatus.error)
      if (Object.values(TicketStatus).includes(response.ticketStatus)) {
        this.setTicketStatus(response.ticketStatus)
      } else {
        response.isAuthorized ? this.setTicketStatus(TicketStatus.valid) : this.setTicketStatus(TicketStatus.error)
      }
      this.setTicketData(response?.ticketData || {})
      if (!response.isAuthorized && this.hasTicketQuery) this.setShowTicketInput(true)
    })
    this.socket.on('disconnect', () => {
      console.log('WS client disconnect')
      // this.removeAuthorizations(this.getCurrentAuthorizations)
      // this.setDeleteAuthCookieTimeout()
    })
  }

  getAuthorizationCookie (): string[] {
    const auth = this.$cookies.get(this.authorizationCookieName)  
    if (!auth) return []
    const arr = JSON.parse(auth)
    if (!arr.isArray) return []
    return arr
  }

  getTicketCookie (): string | null {
    const ticket = this.$cookies.get(this.ticketCookieName)
    return ticket || null
  }

  setAuthCookie () {
    this.$cookies.set(this.authorizationCookieName, JSON.stringify(this.getCurrentAuthorizations))
  }

  saveTicket (value: string) {
    // Update Ticket in react native webview context 
    // @ts-ignore
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'SET_TICKET',
        value
      }))
    }
    this.setTicketCookie(value)
    // if (value === '1212121212') {
    //   this.addAuthorizations(['espace-permanent'])
    //   this.setAuthCookie()
    //   this.setShowTicketInput(false)
    // }
  }

  setTicketCookie (value: string) {
    this.$cookies.set(this.ticketCookieName, value)
  }

  deleteAuthCookie () {
    this.$cookies.remove(this.authorizationCookieName)
  }

  deleteTicketCookie () {
    this.$cookies.remove(this.ticketCookieName)
  }

  // get needAuthorization () {
  //     return this.hasSocketAuthorizationService && !this.isAuthorized
  // }

  // async verifyAuthorization () {
  //   // Has Access service for this project ?
  //   if (!this.hasAccessControl) return

  //   // get User Id
  //   this.userId = await this.GET_USER_ID()

  //   // Get Authorization Coockie
  //   // this.getAuthCoockie()

  //   if (this.authCookie) {
  //       this.isAuthorized = true
  //       this.setDeleteAuthCookieTimeout()
  //   }

  //   // Open Access Socket
  //   this.socket = io.connect(`ws://${ACCESS_URL}`)

  //   this.socket.on('connect', () => {
  //     console.log('WS client connect')
  //     this.sendUserInfo()
  //     // this.socket.send('user-info', {
  //     //   clientId: CLIENT,
  //     //   userId: this.userId,
  //     //   isAuthorized: this.isAuthorized,
  //     //   ticket: this.authCookie
  //     // })
  //   })
  //   this.socket.on('authorized', () => {
  //     console.log('WS client autorized')
  //     this.setAuthCoockie(this.ticket || this.authCookie)
  //     this.isAuthorized = true
  //   })
  //   this.socket.on('unauthorized', () => {
  //       console.log('WS client unautorized')
  //       this.deleteAuthCookie()
  //       this.isAuthorized = false
  //   })

  //   this.socket.on('disconnect', () => {
  //     console.log('WS client disconnect')
  //     this.setDeleteAuthCookieTimeout()
  //   })
  // }

  async sendUserInfo (data: any = []) {
    // Get current user ID
    // const userId = await this.GET_USER_ID()
    const userId: Maybe<string> = this.getUserId
    if (!userId) {
      return console.error('[Auth] Send user info needs userId')
    }
    
    const userInfo = {
      clientId: CLIENT,
      userId,
      authorizations: this.getCurrentAuthorizations,
      data
    }
    console.log('sendUserInfo in auth mixin', userInfo)
    this.socket.send('user-info', userInfo)

  }

  setDeleteAuthCookieTimeout () {
    // TODO: Set Timeout to delete auth Coockie
  }

  async sendTicket (value: string) {
    console.log('CICI', value)
    const tiket = {
      type: 'ticketNumber',
      value
    }
    this.saveTicket(value)
    // this.ticket = value
    // TODO: remove trick for memorial test
    await this.sendUserInfo([tiket])
    // this.socket.send('user-info', { userId: this.userId, isAuthorized: this.isAuthorized, ticket: value })
    
  }

  isRessourceAuhtorized (locks: Lock[]) {
    const lockSlugs = locks.map((a: Lock) => a.slug)
    const unlockSlugs = this.getCurrentAuthorizations.filter((s: string) => lockSlugs.includes(s))
    return unlockSlugs.length === lockSlugs.length
  }

  unlock (locks: Lock[]) {
    console.log('unlock authorization mixin', locks)
    // Show différents page defined on locks
    // On va considérer ici qu'il n'y a qu'un vérrou de type ticket
    const ticketLock = locks[0]
    // If only ticket input
    this.setTicketInput(ticketLock)
  }

}
