
import { Vue, Component, Prop } from 'vue-property-decorator'

import CustomWrapper from '@/components/CustomWrapper.vue'

const config = {
  name: 'c-image',
  components: {
    CustomWrapper
  }
}

export interface Srcset {
  src: any
  size: any
}

export interface Size {
  mediaQuery: string
  size: number
}

@Component(config)
export default class CImage extends Vue {

  @Prop({ type: String, default: 'div' }) tag!: string
  @Prop({ type: String, required: true }) src!: string
  @Prop({ type: Array }) srcset!: Srcset[]
  @Prop({ type: Array }) sizes!: Size[]
  @Prop({ type: String, required: true }) alt!: string
  @Prop({ type: Boolean, default: false }) cover!: boolean
  @Prop({ type: Boolean, default: false }) contain!: boolean
  @Prop({ type: String }) width!: string
  @Prop({ type: String }) height!: string
  @Prop({ type: Function, default: () => null }) onLoaded!: any

  get srcPropType () {
    switch (typeof this.src) {
      case 'string':
        return 'string'
      case 'object':
        if (Array.isArray(this.src)) {
          return 'array'
        }
        break
    }
  }

  get formatedSrcset () {
    if ((typeof this.srcset === 'object') &&
        (Array.isArray(this.srcset))) {
      return this.srcset.map((img) => {
        return `${ img.src } ${ img.size }w`
      }).join(', ')
    }
  }

  get formatedSizes () {
    if ((typeof this.sizes === 'object') &&
      (Array.isArray(this.sizes))) {
      return this.sizes.map((size: any) => {
        return `${ size.mediaQuery } ${ size.size }px`
      }).join(', ')
    }
  }

}
