import Vue from 'vue'
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import { RootState } from '@/@next/store'

import * as FIBERMAP from './types'
import { type } from 'os'

export class FiberMapState {
  data: any = null
  mapLinks = {
      pointsOfInterest: [],
      collections: []
  }
}

const state = new FiberMapState()

const getters: GetterTree<FiberMapState, RootState> = {
  [FIBERMAP.HAS_FIBER_MAP]: (state): any => state.data !== null
}

const actions: ActionTree<FiberMapState, RootState> =  {
  async [FIBERMAP.SET_DATA] ({ commit, dispatch }, data: any) {
    commit(FIBERMAP.SET_DATA, data)
    dispatch(FIBERMAP.COMPUTE_CONTENT_LINK, data)
  },
  async [FIBERMAP.COMPUTE_CONTENT_LINK] ({state, commit }, data: any) {
    const floors = data.floors
    if (!floors) return
    const mapLinks = floors.reduce((acc: any, floor: any) => {
        if (floor.placeholders.length === 0) return acc
        floor.placeholders.reduce((acc: any, placeholder: any) => {
          if (placeholder.type === 'pointOfInterest') acc.pointsOfInterest.push(placeholder.uuid)
          if (placeholder.type === 'collection') acc.collections.push(placeholder.uuid)
          return acc
        }, acc)
        return acc
    }, { pointsOfInterest: [], collections: [] })
    commit(FIBERMAP.COMPUTE_CONTENT_LINK, mapLinks)
  },
  [FIBERMAP.CONTENT_HAS_LINK] ({ state }, { type, uuid }: {type: string, uuid: string}) {
      console.log('CONTENT_HAS_LINK', type, uuid, state.mapLinks.pointsOfInterest)
      if (type === 'pointOfInterest') return state.mapLinks.pointsOfInterest.find((i: string) => i === uuid)
      if (type === 'collection') return state.mapLinks.collections.find((i: string) => i === uuid)
      return false
  }
}

export const mutations: MutationTree<FiberMapState> =  {
  [FIBERMAP.SET_DATA] (state, data: any) {
    state.data = status
  },
  [FIBERMAP.COMPUTE_CONTENT_LINK] (state, mapLinks: any) {
    state.mapLinks = mapLinks
  }
}

export const fibermap: Module<FiberMapState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
