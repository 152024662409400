
import { Vue, Component } from 'vue-property-decorator'
import { Route } from 'vue-router'

import * as UI from '@/event-bus'
import * as BOT from '@/store/bot/types'

import { messages } from './i18n'

import { Nullable } from '@/shared'
import { MediaTypes } from '@/shared/medias'

import VImage from '@/views/single-media/image/Image.vue'
import VImageGallery from '@/views/single-media/image-gallery/ImageGallery.vue'
import VAudio from '@/views/single-media/audio/Audio.vue'
import VVideo from '@/views/single-media/video/Video.vue'
import VPanorama from '@/views/single-media/panorama/Panorama.vue'
import { CTopbarTitle } from '@/@next/components/topbar-title'

import { Action, Getter } from 'vuex-class'

const config = {
  name: 'v-single-media',
  components: {
    CTopbarTitle
  },
  i18n: { messages },
  head () {
    return {
      title: this.$t('pageName'),
    }
  }
}

@Component(config)
export default class VSingleMedia extends Vue {

  @Getter(BOT.IS_OPEN) isBotOpen!: boolean

  @Action(BOT.OPENING_BOT) openBot!: any
  @Action(BOT.CLOSING_BOT) closeBot!: any

  type: Nullable <string> = null

  activated () {
    this.type = this.$route.params.type
    UI.EventBus.$emit(UI.ENABLE_DEFAULT_TOPBAR_TRANSPARENT)
    UI.EventBus.$emit(UI.HIDE_DEFAULT_BOT)
  }

  deactivated () {
    UI.EventBus.$emit(UI.SHOW_DEFAULT_TOPBAR)
    UI.EventBus.$emit(UI.DISABLE_DEFAULT_TOPBAR_TRANSPARENT)
    UI.EventBus.$emit(UI.SHOW_DEFAULT_BOT)
  }

  beforeRouteEnter (to: Route, from: Route, next: (cb: any) => void) {
    next((vm: any) => {
      if (to.query.context === 'bot' && vm.isBotOpen) {
        setTimeout(() => {
          vm.closeBot()
        }, 250)
      }
    })
  }

  beforeRouteLeave (to: Route, from: Route, next: () => void) {
    if (from.query.context === 'bot' && !this.isBotOpen) {
      this.openBot()
    }
    next()
  }

  get viewByType () {
    switch (this.type) {
      case MediaTypes.image:
        return VImage
      case MediaTypes.imageGallery:
        return VImageGallery
      case MediaTypes.video:
        return VVideo
      case MediaTypes.audio:
        return VAudio
      case MediaTypes.panorama:
        return VPanorama
    }
  }

}
