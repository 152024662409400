import { Vue, Component } from 'vue-property-decorator'
import { Route } from 'vue-router'

Component.registerHooks([
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

@Component({ name: 'save-scroll-mixin' })
export default class SaveScroll extends Vue {

  _scrollX: number = 0
  _scrollY: number = 0

  activated () {
    this.scroll()
  }

  scroll () {
    if (!this.$refs.saveScroll) return
    (this.$refs.saveScroll as HTMLElement).scroll(this._scrollX, this._scrollY)
  }

  scrollablePos () {
    if (this.$refs.saveScroll) {
      this._scrollX = (this.$refs.saveScroll as HTMLElement).scrollLeft
      this._scrollY = (this.$refs.saveScroll as HTMLElement).scrollTop
    }
  }

  beforeRouteUpdate (to: Route, from: Route, next: any) {
    this.scrollablePos()
    next()
  }

  beforeRouteLeave (to: Route, from: Route, next: any) {
    this.scrollablePos()
    next()
  }
}
