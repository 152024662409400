
import { Mixins, Component, Prop } from 'vue-property-decorator'
import CustomWrapper from '@/components/CustomWrapper.vue'

import Colorable from '@/mixins/Colorable'
import Sizable from '@/mixins/Sizable'

@Component({
  name: 'c-simple-card',
  components: { CustomWrapper }
})
export default class CSimpleCard extends Mixins!(Colorable, Sizable) {

  @Prop({ type: String, default: 'div' }) tag!: string
  @Prop({ type: Boolean, default: false }) rounded!: boolean
  @Prop({ type: Boolean, default: false }) shadow!: boolean

  get CSSClasses () {
    return {
      '--Small': this.small,
      '--Medium': this.medium,
      '--Large': this.large,
      '--Primary': this.primary,
      '--Secondary': this.secondary,
      '--Light': this.light,
      '--Dark': this.dark,
      '--Shadow': this.shadow,
      '--Rounded': this.rounded
    }
  }

}
