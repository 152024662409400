import { GetterTree, Module } from 'vuex'
import { RootState } from '@/store'

import * as HISTORY from './types'
import { actions } from './actions'
import { mutations } from './mutations'
import {HistoryEntry} from '@/shared/history'
import {Route} from 'vue-router'

export class HistoryState {
  history: HistoryEntry[] = []
}

const state = new HistoryState()

const getters: GetterTree<HistoryState, RootState> = {
  [HISTORY.GET_ALL_ENTRIES]: (state: HistoryState): HistoryEntry | HistoryEntry[] => state.history,
  [HISTORY.GET_ENTRY]: (state: HistoryState) => {
    return (route: Route) => {
      return state.history.find((entry: HistoryEntry) => {
        if (entry.route) {
          return entry.route.path === route.path
        } else {
          return false
        }
      })
    }
  },
}

export const history: Module<HistoryState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
