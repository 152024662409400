
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Medias from '@/@next/services/Medias'
import { QuestionCommentItem } from '@/@types/question'
import { MediaImage, MediaAudio, MediaVideo } from '@/@types/graphql'
import { CImage } from '@/@next/components/image'
import { CLoader } from '@/components/loader'

const config = {
  name: 'c-question-clue',
  components: {
    CImage,
    CLoader
  }
}

@Component(config)
export default class CQuestionClue extends Mixins(Medias) {

  @Prop({ type: Object }) clue!: QuestionCommentItem

  image: MediaImage | null = null

  async mounted () {
    if (this.imageId) this.image = await this.GET_SINGLE_IMAGE(this.imageId)
    this.$emit('ready')
  }

  get text () {
    // TODO : add target filter
    const body = this.clue.body.find((el: any) => el.language === this.$i18n.locale)
    if (!body) return ''
    return body.value.html
  }

  get imageId () {
    // TODO : add target filter
    const { imageRefs } = this.clue
    const imageRef = imageRefs.find((el: any) => el.language === this.$i18n.locale)
    return imageRef ? imageRef.id : imageRefs[0]?.id || null
  }

}
