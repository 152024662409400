import { MutationTree } from 'vuex'

import { Realm, UUID } from '@/@next/@types'

import * as REALMS from './types'
import { RealmsState } from '@/@next/store/realms'

export const mutations: MutationTree<RealmsState> =  {

  [REALMS.SET_ALL] (state, realms: Realm[]) {
    state.all = realms
  },

  [REALMS.SET_CURRENT] (state, slug: string) {
    state.current = state.all.find((realm) => realm.slug === slug) || null
  }
}
