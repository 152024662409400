import { MutationTree } from 'vuex'

import * as AUDIOGUIDE from './types'
import { AudioguideState } from '@/@next/store/audioguide'

export const mutations: MutationTree<AudioguideState> =  {

  [AUDIOGUIDE.CHANGE_STATUS] (state, status: boolean) {
    state.activated = status
  },

  [AUDIOGUIDE.CHANGE_PLAYLIST] (state, playlist: any) {
    state.playlist = playlist
  },

  [AUDIOGUIDE.CHANGE_POI] (state, poi: any) {
    state.poi = poi
  },

  [AUDIOGUIDE.CHANGE_CURRENT_POI] (state, poi: any) {
    state.currentPoi = poi
  }
}
