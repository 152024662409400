
import { Component, Prop, Watch, Mixins} from 'vue-property-decorator'

import Input from '@/mixins/Input'
import Sizable from '@/mixins/Sizable'

const typesCollection = [ String, Number, Boolean, Function, Object, Array, Symbol ]

const config = {
  name: 'c-input'
}

@Component(config)
export default class CInput extends Mixins!(Input, Sizable) {

  @Prop({ type: String, default: 'text' }) type!: string
  @Prop({ type: String, default: '' }) placeholder!: string
  @Prop({ type: Boolean, default: false }) autofocus!: boolean
  @Prop({ type: Number, default: 3}) rows!: number
  @Prop({ type: String }) pattern!: string

  get CSSClasses () {
    return {
      '--Small': this.small,
      '--Medium': this.medium,
      '--Large': this.large,
    }
  }

  @Watch('computedValue')
  onComputedValueUpdated () {
    this.$emit('update', this.computedValue)
  }
}
