
import { Component, Mixins } from 'vue-property-decorator'

import * as UI from '@/event-bus'

import { MediasService, PointsOfInterestService } from '@/@next/services'

import { Maybe, MediaVideo, PointOfInterest } from '@/@types/graphql'

import { CTopbarTitle } from '@/@next/components/topbar-title'
import { CAsyncTask } from '@/@next/components/async-task'
import { CLoader } from '@/components/loader'

const config = {
  name: 'v-video',
  components: {
    CTopbarTitle,
    CAsyncTask,
    CLoader
  },
  head () {
    return {
      title: this.pageTitle,
      titleTemplate: '%s – Wivi'
    }
  }
}

@Component(config)
export default class VVideo extends Mixins!(MediasService, PointsOfInterestService) {

  showHeading: boolean = true
  contentTextExpanded: boolean = false

  video: Maybe<MediaVideo> = null
  pointOfInterest: Maybe<PointOfInterest> = null

  uuid: Maybe<string> = null

  async init () {
    this.uuid = this.$route.params.uuid
    await this.fetchVideo()
    if (this.$route.query.content_id) {
      await this.fetchContent()
    }
  }

  async fetchVideo () {
    this.video = await this.GET_SINGLE_VIDEO(this.$route.params.uuid)
  }

  async fetchContent () {
    if (this.$route.query.context_id && this.$route.query.context) {
      switch (this.$route.query.context) {
        case 'poi':
          this.pointOfInterest = await this.GET_SINGLE_POI(this.$route.query.context_id as string)
          break
      }
    }
  }

  get containerDOMNode () {
    return this.$refs.container as HTMLElement
  }

  get imgDOMNode () {
    return this.$refs.img as HTMLElement
  }

  get imageSummaryDOMNode () {
    return this.$refs.summary as HTMLElement
  }

  get content () {
    if (this.pointOfInterest) {
      return this.pointOfInterest.contents.find((content: any) => {
        return content.id === this.$route.query.content_id
      })
    }
  }

  get pageTitle () {
    if (this.video && this.video.name) {
      return this.video.name
    }
  }

  get headingCSSClasses () {
    return {
      'is-Hidden': !this.showHeading,
      'is-Expanded': this.contentTextExpanded
    }
  }

  get videoInlineStyles () {
    return {
      'filter': this.contentTextExpanded ? 'blur(12px)' : ''
    }
  }

  onSingleTap () {
    UI.EventBus.$emit(UI.TOGGLE_DEFAULT_TOPBAR)
    this.showHeading = !this.showHeading
  }

}
