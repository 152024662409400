var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c(_vm.tag,{tag:"component",staticClass:"v-SinglePoi__ContentMemoryContainer",attrs:{"id":_vm.content.id}},[_c('div',{staticClass:"v-SinglePoi__ContentMemory",on:{"click":function($event){_vm.openMemory = true}}},[_c('div',{staticClass:"SinglePoi__ContentMemoryContent"},[_c('div',{staticClass:"SinglePoi__ContentMemoryContentTitle"},[_vm._v("\n            "+_vm._s(_vm._f("capitalizeFirstLetter")(_vm.$t('game.memory.memory')))+"\n            ")]),(_vm.title)?_c('div',{staticClass:"SinglePoi__ContentMemoryContentSubtitle",domProps:{"innerHTML":_vm._s(_vm.title.html)}}):_vm._e(),_c('div',{staticClass:"SinglePoi__ContentMemoryContentSubtitle"},[_c('c-button',{staticStyle:{"background":"var(--onPrimaryColor)","color":"var(--primaryColor)","padding":"4px 8px","border-radius":"4px","margin-top":"8px","font-weight":"bold"},attrs:{"text":""}},[_vm._v("\n                "+_vm._s(_vm._f("capitalizeFirstLetter")(_vm.$t('game.memory.start')))+"\n            ")])],1)]),_c('div',{staticClass:"v-SinglePoi__ContentMemoryContentCards"},_vm._l((_vm.images),function(image,index){return (index < 6)?_c('div',{key:("memory-image-" + index),staticClass:"v-SinglePoi__ContentMemoryContentCards--card-container "},[_c('div',{staticClass:"v-SinglePoi__ContentMemoryContentCards--card "},[(index === 0 || index === 4)?_c('div',{staticClass:"front",style:({
                                    backgroundImage: 'url(' + _vm.addProtocol(image.formats[0].file.url) + ')',
                                    backgroundSize: 'cover'
                                })}):_c('div',{staticClass:"back",style:({
                                    backgroundImage: 'url(/img/games/memory/card_backside.jpg)',
                                    backgroundSize: 'cover'
                                })})])]):_vm._e()}),0)]),(_vm.openMemory)?_c('c-modal',{attrs:{"fillHeight":""},on:{"close":_vm.stopMemory}},[_c('c-modal-content',[_c('div',{staticClass:"memory_container"},[_c('div',{staticClass:"info"},[_c('div',[_c('span',{staticClass:"value"},[_c('i',{staticClass:"essentials-icons-time",staticStyle:{"margin-right":"4px"}})]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.getFormattedTime())+" ")])]),_c('div',[_c('span',{staticClass:"value"},[_vm._v(_vm._s(this.foundCardNumber / 2)+" / ")]),_c('span',{staticClass:"label"},[_vm._v(_vm._s(this.images.length)+" ")]),_c('span',{staticClass:"label"},[_vm._v(" ("+_vm._s(_vm.turns)+")")])])]),(!_vm.showResult)?_c('div',{staticClass:"cards_container"},[_c('div',{staticClass:"cards"},_vm._l((_vm.cards),function(card,index){return _c('div',{key:index,staticClass:"card",class:{ flipped: card.flipped, found: card.found },on:{"click":function($event){return _vm.flipCard(card)}}},[_c('div',{staticClass:"back",style:(_vm.versoStyle)},[(_vm.options.versoBorder)?_c('div',{staticClass:"border"}):_vm._e()]),_c('div',{staticClass:"front",style:({
                                backgroundImage: 'url(' + _vm.addProtocol(card.formats[0].file.url) + ')',
                                backgroundSize: card.options && card.size || 'cover'
                            })})])}),0)]):_c('div',{staticClass:"result"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('game.memory.congrats'))+" ")]),_c('div',{staticClass:"result_images"},_vm._l((_vm.images),function(card,index){return _c('div',{key:index,staticClass:"result_images-cards"},[_c('div',{staticClass:"result_images-cards_card",style:({
                                    backgroundImage: 'url(' + _vm.addProtocol(card.formats[0].file.url) + ')',
                                    backgroundSize: card.size || 'cover'
                                })})])}),0),(_vm.text)?_c('div',{staticClass:"result_text"},[_c('span',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.text.html)}})]):_vm._e(),_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center"}},[_c('div',{staticStyle:{"min-width":"33%"}},[_c('c-button',{on:{"click":function($event){return _vm.resetGame()}}},[_vm._v("\n                                "+_vm._s(_vm.$t('game.memory.restart'))+"\n                            ")])],1)])])])])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }