import { ActionTree } from 'vuex'

import * as HISTORY from './types'
import { RootState } from '@/@next/store'
import { HistoryState } from '@/@next/store/history'
import {HistoryEntry} from '@/shared/history'

export const actions: ActionTree<HistoryState, RootState> =  {

  async [HISTORY.SET_ENTRY] ({ state, commit, dispatch }, entry: HistoryEntry) {
    commit(HISTORY.ADD_ENTRIE, entry)
  }

}
