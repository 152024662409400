var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-Content__ImageGallery"},[(_vm.title)?_c('h2',{staticClass:"c-Content__ImageGalleryTitle",domProps:{"innerHTML":_vm._s(_vm.title.html)}}):_vm._e(),(_vm.images)?_c('c-carousel',{staticClass:"c-Content__ImageGalleryCarousel",attrs:{"spacePadding":36,"perPage":1,"pagination":"c-Content__ImageGalleryCarouselPagination","paginationEnabled":true,"navigationEnabled":true,"navigationPrevLabel":_vm.navPrevLabel,"navigationNextLabel":_vm.nextPrevLabel,"centerMode":false}},_vm._l((_vm.images),function(ref,index){
var image = ref.image;
var title = ref.title;
var description = ref.description;
return _c('c-carousel-item',{key:index},[_c('div',{staticClass:"c-Content__ImageGalleryCarouselItem"},[_c('router-link',{staticClass:"c-Content__ImageGalleryCarouselLinkWrapper",attrs:{"to":{
                   name: 'single-media',
                   params: { type: 'image-gallery', uuid: _vm.content.id },
                   query: { selected: image.value.id, context: 'poi', context_id: _vm.contextId }
          }}},[_c('figure',[_c('div',{staticClass:"c-Content__ImageGalleryCarouselItemPicture"},[_c('div',{staticClass:"c-Content__ImageGalleryCarouselItemPictureWrap"},[(image && image.value)?_c('c-lazy-loading-image',{attrs:{"src":_vm._f("addProtocol")(image.value.file.url),"alt":title ? title.value.text : 'image',"cover":"","relative":""}},[_c('c-loader')],1):_vm._e()],1)]),_c('figcaption',{staticClass:"c-Content__ImageGalleryCarouselItemMeta"},[(title)?_c('div',{staticClass:"c-Content__ImageGalleryCarouselItemTitle",domProps:{"innerHTML":_vm._s(title.value.html)}}):_vm._e(),(description)?_c('div',{staticClass:"c-Content__ImageGalleryCarouselItemSummary",domProps:{"innerHTML":_vm._s(description.value.html)}}):_vm._e()])])])],1)])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }