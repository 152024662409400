import CButton from './Button.vue'
import CButtonIcon from './ButtonIcon.vue'
import CButtonLabel from './ButtonLabel.vue'

export {
  CButton,
  CButtonIcon,
  CButtonLabel
}

export default {
  CButton,
  CButtonIcon,
  CButtonLabel
}
