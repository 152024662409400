







import { Vue, Component, Prop } from 'vue-property-decorator'

const config = {
  name: 'icon-language',
  components: {}
}

@Component(config)
export default class IconLanguage extends Vue {}
