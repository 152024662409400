import { ActionTree } from 'vuex'

import * as GAME from './types'
import { RootState } from '@/@next/store'
import { GameState } from '@/@next/store/game'

export const actions: ActionTree<GameState, RootState> =  {

  async [GAME.SET_GAME_COMPLETED] ({ state, commit, dispatch }, id: string) {
    commit(GAME.COMPLETE_GAME, id)
  },

  async [GAME.SET_GAME_USER_ANSWERS] ({ state, commit, dispatch }, { id, answer, fail, completed}) {
    commit(GAME.CHANGE_USER_ANSWERS, { id, answer, fail, completed })
  },

  async [GAME.SET_GAME_RESETED] ({ state, commit, dispatch }) {
    commit(GAME.RESET_GAME)
  }

}
