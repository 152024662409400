export let available = false
export let tested = false

export default function () {
  if (tested) return available;
  else {
    try {
      const valueToStore = 'wivi';
      const mykey = 'twelve';
      localStorage.setItem(mykey, valueToStore);
      const recoveredValue = localStorage.getItem(mykey);
      localStorage.removeItem(mykey);
      return recoveredValue === valueToStore;
    } catch (e) {
        return false;
    }
  }
}
