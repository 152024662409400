
import { Vue, Component } from 'vue-property-decorator'
import Viewer from './Viewer'

const config = {
  name: 'v-panorama'
}

@Component(config)
export default class VPanorama extends Vue {

  data: any = null
  options: any = null
  viewer: any = null
  selectedPano: any = null

  mounted () {
    // Define Viewer Options
    this.options = {
      // @ts-ignore
      path: `/sites/${this.CLIENT}/panoramas/${this.$route.params.uuid}`,
      index: 0,
      autorotate: true,
      list: true,
      title: true
    }
    this.getData(this.$route.params.uuid)
  }

  close () {
    this.$emit('close')
  }

  getData (value: any) {
    // @ts-ignore
    this.axios({ url: `/sites/${this.CLIENT}/panoramas/${value}/data.json`, baseURL: '/' })
      .then((res: any) => {
        this.data = res.data
        // console.log(res);
        this.viewer = new Viewer(this.data, this.options)
      }, (res) => {
        this.close()
      })
  }

  switchScene (scene: any) {
    this.viewer.switchScene(this.viewer.scenes.find((elem: any) => elem.data.id === scene.id))
    this.viewer.toggleSceneList()
  }

  openViewer (pano: any) {
    this.selectedPano = pano
  }

  closeViewer () {
    this.selectedPano = null
  }

}
