
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import * as L from 'leaflet'
import { LMap, LMarker, LImageOverlay } from 'vue2-leaflet'
import { Carousel, Slide } from 'vue-carousel'
import Matomo from 'matomo-ts'

import { messages } from './i18n'
import * as UI from '@/event-bus'
import * as AUDIOGUIDE from '@/store/audioguide/types'
import { Maybe, Dataset, PointOfInterest } from '@/@types/graphql'

import { LanguagesMixin } from '@/@next/mixins'
import { DatasetService, PointsOfInterestService, MediasService } from '@/@next/services'

import { API_URL, MATOMO_URL } from '@/constants'
import { addProtocol, isUUIDV4 } from '@/@next/helpers'

import CPoiMiniCard from '@/components/cards/poi-mini-card'
import { CAsyncTask } from '@/@next/components/async-task'
import { CImage } from '@/@next/components/image'
import { CTopbarTitle } from '@/@next/components/topbar-title'
import { CLoader } from '@/components/loader'

interface MapDataset extends Omit<Dataset, 'value'> {
  value: MapFloor[]
}

interface MapTextTranslation {
  [key: string]: {
    html: string
    text: string
  }
}

interface MapBackgroundTranslation {
  [key: string]: {
    activeLevel: number
    backgroundColor: string
    bounds: number[][]
    id: string
    mapCenter: number[]
    mapOptions: any
    mapZoom: number
    type: string
  }
}

interface MapFloor {
  id: string
  label: string
  name: MapTextTranslation
  abstract: MapTextTranslation
  background: MapBackgroundTranslation
  description: MapTextTranslation
  markers: MapMarker[]
}

interface MapMarker {
  active?: boolean
  category: any
  contentId: string
  geoJson: any
  icon: any
  typeLabel: string
}

const config = {
  name: 'v-plan',
  components: {
    CMap: LMap,
    CMapImageOverlay: LImageOverlay,
    CMapMarker: LMarker,
    CCarousel: Carousel,
    CCarouselItem: Slide,
    ...CPoiMiniCard,
    CAsyncTask,
    CImage,
    CLoader,
    CTopbarTitle
  },
  i18n: { messages },
  head () {
    return {
      title: this.$t('pageName'),
    }
  }
}

@Component(config)
export default class VPlan extends Mixins!(
  DatasetService,
  MediasService,
  PointsOfInterestService,
  LanguagesMixin
) {

  @Getter(AUDIOGUIDE.GET_STATUS)
  _audioguideStatus!: boolean

  data: Maybe<MapDataset> = null
  currentFloorIndex: number = 0
  currentSlideIndex: number = 0

  crs = L.CRS.Simple
  mapCenter: number[] = [0, 0]
  mapZoom: number = 0
  backgroundImages: Array<(string | undefined)> = []
  pointsOfInterest: Array<Array<(PointOfInterest | undefined)>> = []

  activated () {
    if (MATOMO_URL) Matomo.default().trackPageView('map')
    UI.EventBus.$emit(UI.ENABLE_DEFAULT_TOPBAR_TRANSPARENT)
    UI.EventBus.$emit(UI.HIDE_DEFAULT_BOT)
  }

  deactivated () {
    UI.EventBus.$emit(UI.DISABLE_DEFAULT_TOPBAR_TRANSPARENT)
    UI.EventBus.$emit(UI.SHOW_DEFAULT_BOT)
  }

  async init () {
    const map = await this.GET_DATASET('map-indoor')
    if (typeof map.value === 'string') {
      this.data = Object.assign(map, { value: map.value })
      if (!Array.isArray(this.data!.value)) return
      this.backgroundImages = await this.setBackgroundImages()
      this.pointsOfInterest = await this.setPointsOfInterest()
      this.mapZoom = this.mapBackground!.mapZoom
      this.mapCenter = this.mapBackground!.mapCenter
    }
  }

  @Watch('currentSlideIndex')
  onCurrentSlideIndex () {
    const marker = this.mapMarkers[this.currentSlideIndex]
    if (marker) this.mapCenter = marker.geoJson.coordinates
  }

  get pageTitle () {
    if (!this.mapData || !this.mapData.name) return
    const translation = this.mapData.name[this._currentLanguage]
    if (translation) return translation.html
  }

  get floorCount () {
    if (!this.data!.value) return 0
    return this.data!.value.length
  }

  get mapNode () {
    const map = this.$refs.map as any
    if (map && map.mapObject) return map.mapObject
  }

  get floors () {
    if (this.data && Array.isArray(this.data.value))
      return this.data.value
  }

  get mapData () {
    if (this.data && Array.isArray(this.data.value))
      return this.data.value[this.currentFloorIndex]
  }

  get mapBackground () {
    if (!this.mapData || !this.mapData.background) return
    return this.mapData.background[this._currentLanguage] || this.mapData.background[this._defaultLanguage]
  }

  get mapBackgroundImage () {
    return this.backgroundImages[this.currentFloorIndex]
  }

  get mapMarkers () {
    if (!this.mapData || !this.mapData.markers) return []
    return this.mapData.markers.map((marker, i) => {
      const activeCSSClass = this.currentSlideIndex === i ? 'is-Active' : ''
      return Object.assign(marker, {
        active: i === this.currentSlideIndex,
        icon: this.createMapMarkerIcon(marker, activeCSSClass)
      })
    })
  }

  get mapPointsOfInterest () {
    return this.pointsOfInterest[this.currentFloorIndex]
  }

  get carouselCSSClasses () {
    return {
      '--SpaceBottom': this._audioguideStatus
    }
  }

  createMapMarkerIcon (marker: any, activeCSSClass: string) {
    return L.divIcon({
      className: `v-Plan__MapMarker ${activeCSSClass}`,
      iconSize: [36, 36],
      iconAnchor: [16, 32],
      html: `
        <div class='v-Map__MapMarkerIcon'>
          <svg xmlns='http://www.w3.org/2000/svg'
               xmlns:xlink='http://www.w3.org/1999/xlink'
               width='24pt'
               height='24pt'
               viewBox='0 0 24 24'>
          <g>
            <path style="fill:${marker.category.style.fillColor};
                         stroke:${marker.category.style.strokeColor};
                         stroke-width:${marker.category.style.strokeSize};"
                  d='M 11.878906 0 L 11.839844 0 C 7.746094 0 4.414062 3.332031 4.414062 7.425781 C 4.414062
                     10.109375 5.636719 13.652344 8.046875 17.953125 C 9.835938 21.144531 11.648438 23.585938
                     11.664062 23.613281 C 11.714844 23.679688 11.792969 23.71875 11.878906 23.71875C 11.882812
                     23.71875 11.886719 23.71875 11.886719 23.71875 C 11.972656 23.714844 12.050781 23.671875
                     12.097656 23.597656 C 12.121094 23.574219 13.917969 20.855469 15.695312 17.53125 C
                     18.089844 13.046875 19.308594 9.644531 19.308594 7.425781 C 19.308594 3.332031 15.972656
                     0 11.878906 0 Z M 15.304688 7.605469 C 15.304688 9.511719 13.761719 11.054688 11.859375
                     11.054688 C 9.957031 11.054688 8.414062 9.511719 8.414062 7.605469 C 8.414062 5.707031
                     9.957031 4.160156 11.859375 4.160156 C 13.761719 4.160156 15.304688 5.707031 15.304688
                     7.605469 Z M 15.304688 7.605469 '/>
          </g>
        </svg>
      </div>`
    })
  }

  async setBackgroundImages (): Promise<Array<(string | undefined)>> {
    return await Promise.all(this.data!.value.map(async (floor) => {
      const background = floor.background[this._currentLanguage] || floor.background[this._defaultLanguage]
      const { id, type } = background
      if (type !== 'image') return
      if (isUUIDV4(id)) {
        const image = await this.GET_SINGLE_IMAGE(id)
        return addProtocol(image.file.url)
      } else {
        return `${API_URL}/files/${id}.jpeg`
      }
    }))
  }

  async setPointsOfInterest (): Promise<Array<Array<(PointOfInterest | undefined)>>> {
    return await Promise.all(this.data!.value.map(async (floor) => {
      return await Promise.all(floor.markers.map(async (marker) => {
        if (marker.typeLabel === 'pointOfInterest')
          return await this.GET_SINGLE_POI(marker.contentId)
      }))
    }))
  }

  onMapMarkerClick (index: number) {
    this.currentSlideIndex = index
  }

}
