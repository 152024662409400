import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import VueApollo from 'vue-apollo'

import { API_URL, WRITABLE_API_URL } from '@/constants'

import introspectionQueryResultData from './introspection-result'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const httpLink = new HttpLink({
  uri: `${API_URL}/gql`
})

const writableHttpLink = new HttpLink({
  uri: `${WRITABLE_API_URL}/gql`
})

const cache = new InMemoryCache({ fragmentMatcher })

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[Wivi][GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }
  if (networkError) console.error(`[Wivi][Network error]: ${networkError}`)
})

// Create the apollo client
export const apolloClient = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, httpLink]),
  connectToDevTools: process.env.NODE_ENV !== 'production'
})

// Create the master apollo client for cache version
export const writableApolloClient = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, writableHttpLink])
})

export const apolloProvider = new VueApollo({
  clients: {
    'apolloClient': apolloClient,
    'writableApolloClient': writableApolloClient
  },
  defaultClient: apolloClient
})

// Install the Vue plugin
Vue.use(VueApollo)
