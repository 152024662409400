
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const config = {
  name: 'c-AuthorModal'
}

@Component(config)
export default class CAuthorModal extends Vue {
}
