import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import VuexPersistence from 'vuex-persist'

import { audioguide } from './audioguide'
import { languages } from './languages'
import { settings } from './settings'
import { settings2 } from './settings2'
import { bot } from './bot'
import { game } from './game'
import { questions } from './questions'
import { history } from './history'
import { app } from './app'
import { realms } from './realms'
import { fibermap } from './fibermap'
import { version } from '../../../package.json'
import { authorization } from './authorization'
import { user } from './user'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence<RootState>({
  key: 'WIVI_STORAGE',
  storage: window.localStorage,
  strictMode: process.env.NODE_ENV !== 'production',
  modules: ['game', 'questions']
})

/* tslint:disable: no-empty-interface */
export interface RootState {}

const storeConfig: StoreOptions<RootState> = {
  strict: process.env.NODE_ENV !== 'production',
  state: {
    version: process.env.VUE_APP_VERSION || version
  },
  modules: {
    app,
    audioguide,
    languages,
    settings,
    settings2,
    bot,
    game,
    questions,
    history,
    realms,
    fibermap,
    authorization,
    user
  },
  plugins: [vuexLocal.plugin],
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION
  }
}

const store = new Vuex.Store<RootState>(storeConfig)

export default store
