import CVideoCard from './VideoCard.vue'
import CVideoCardContent from './VideoCardContent.vue'
import CVideoCardSummary from './VideoCardSummary.vue'
import CVideoCardThumbnail from './VideoCardThumbnail.vue'
import CVideoCardTitle from './VideoCardTitle.vue'
import CVideoCardOverlay from './VideoCardOverlay.vue'

export {
  CVideoCard,
  CVideoCardContent,
  CVideoCardSummary,
  CVideoCardThumbnail,
  CVideoCardTitle,
  CVideoCardOverlay
}
export default {
  CVideoCard,
  CVideoCardContent,
  CVideoCardSummary,
  CVideoCardThumbnail,
  CVideoCardTitle,
  CVideoCardOverlay
}
