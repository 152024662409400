// tslint:disable:max-line-length

// Layouts
import LDefault from '@/layouts/default/Default.vue'

import Home from '@/views/home/Home.vue'
import Settings from '@/views/settings/Settings.vue'
import Search from '@/views/search/Search.vue'
import Collections from '@/views/collections/Collections.vue'
import SingleCollection from '@/views/single-collection/SingleCollection.vue'
import SingleMedia from '@/views/single-media/SingleMedia.vue'
import Pois from '@/views/pois/Pois.vue'
import SinglePoi from '@/views/single-poi/SinglePoi.vue'
import Embeds from '@/views/embeds/Embeds.vue'
import Plan from '@/views/plan/Plan.vue'
import SurveyList from '@/views/survey/SurveyList.vue'
import FiberMap from '@/views/fiber-map/FiberMap.vue'

// Routes
// const Collections = () => import('@/views/collections/Collections.vue'/* webpackChunkName: "page-collections" */)
// const SingleCollection = () => import('@/views/single-collection/SingleCollection.vue'/* webpackChunkName: "page-single-collection" */)
const Map = () => import('@/views/map/Map.vue'/* webpackChunkName: "page-map" */)
const About = () => import('@/views/about/About.vue'/* webpackChunkName: "page-about" */)
const Glossary = () => import('@/views/glossary/Glossary.vue'/* webpackChunkName: "page-glossary" */)
const Articles = () => import('@/views/articles/Articles.vue'/* webpackChunkName: "page-articles" */)
const SingleArticle = () => import('@/views/single-article/SingleArticle.vue'/* webpackChunkName: "page-single-article" */)
const Games = () => import('@/views/games/Games.vue'/* webpackChunkName: "page-games" */)
const SingleGame = () => import('@/views/single-game/SingleGame.vue'/* webpackChunkName: "page-single-games" */)
const Videos = () => import('@/views/videos/Videos.vue'/* webpackChunkName: "page-videos" */)
const Informations = () => import('@/views/informations/Informations.vue'/* webpackChunkName: "page-informations" */)
const Survey = () => import('@/views/survey/Survey.vue'/* webpackChunkName: "page-survey" */)
const AgendaTDN = () => import('@/views/agenda-tdn/AgendaTDN.vue'/* webpackChunkName: "page-agenda-tdn" */)
const SingleVideoSync = () => import('@/views/single-video-sync/SingleVideoSync.vue'/* webpackChunkName: "page-single-video-sync" */)
const ReserveUtah = () => import('@/views/reserve-utah/ReserveUtah.vue'/* webpackChunkName: "page-reserve-utah" */)
const Questions = () => import('@/views/questions/Questions.vue'/* webpackChunkName: "page-questions" */)
const Quiz = () => import('@/views/quiz/Quiz.vue'/* webpackChunkName: "page-questions" */)
const Ticket = () => import('@/@next/components/TicketInput.vue'/* webpackChunkName: "page-ticket" */)

export const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: LDefault,
      order: 0
    }
  },
  {
    path: '/survey',
    name: 'survey-list',
    component: SurveyList,
    meta: {
      layout: LDefault,
      order: 3
    }
  },
  {
    path: '/survey/:uuid',
    name: 'survey',
    component: Survey,
    meta: {
      layout: LDefault,
      order: 3
    }
  },
  {
    path: '/agenda-tdn',
    name: 'agenda-tdn',
    component: AgendaTDN,
    meta: {
      layout: LDefault,
      order: 3
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      layout: LDefault,
      order: 3
    }
  },
  {
    path: '/informations',
    name: 'informations',
    component: Informations,
    meta: {
      layout: LDefault,
      order: 3
    }
  },
  {
    path: '/map',
    name: 'map',
    component: Map,
    meta: {
      layout: LDefault,
      order: 1
    }
  },
  {
    path: '/fiber-map',
    name: 'fiber-map',
    component: FiberMap,
    meta: {
      layout: LDefault,
      order: 1
    }
  },
  {
    path: '/plan',
    name: 'plan',
    component: Plan,
    meta: {
      layout: LDefault,
      order: 1
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      layout: LDefault,
      order: 3
    }
  },
  {
    path: '/glossary',
    name: 'glossary',
    component: Glossary,
    meta: {
      layout: LDefault,
      order: 1
    }
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      layout: LDefault,
      order: 4
    }
  },
  {
    path: '/articles',
    name: 'articles',
    component: Articles,
    meta: {
      layout: LDefault,
      order: 4
    }
  },
  {
    path: '/articles/:uuid',
    name: 'single-article',
    component: SingleArticle,
    meta: {
      layout: LDefault,
      order: 4
    }
  },
  {
    path: '/collections',
    name: 'collections',
    component: Collections,
    meta: {
      layout: LDefault,
      order: 1
    }
  },
  {
    path: '/collections/:uuid',
    name: 'single-collection',
    component: SingleCollection,
    meta: {
      layout: LDefault,
      order: 1
    }
  },
  {
    path: '/pois',
    name: 'pois',
    component: Pois,
    meta: {
      layout: LDefault,
      order: 1
    }
  },
  {
    path: '/pois/:uuid',
    name: 'single-poi',
    component: SinglePoi,
    meta: {
      layout: LDefault,
      order: 2
    }
  },
  {
    path: '/games',
    name: 'games',
    component: Games,
    meta: {
      layout: LDefault,
      order: 4
    }
  },
  {
    path: '/game/:uuid',
    name: 'single-game',
    component: SingleGame,
    meta: {
      layout: LDefault,
      order: 4
    }
  },
  {
    path: '/medias/:type/:uuid',
    name: 'single-media',
    component: SingleMedia,
    meta: {
      layout: LDefault,
      order: 4
    }
  },
  {
    path: '/videos',
    name: 'videos',
    component: Videos,
    meta: {
      layout: LDefault,
      order: 2
    }
  },
  {
    path: '/video-sync/:uuid',
    name: 'single-video-sync',
    component: SingleVideoSync,
    meta: {
      layout: LDefault,
      order: 2
    }
  },
  {
    path: '/reserves',
    name: 'reserves-utah',
    component: ReserveUtah,
    meta: {
      layout: LDefault,
      order: 2
    }
  },
  {
    path: '/embeds',
    name: 'embeds',
    component: Embeds,
    meta: {
      layout: LDefault,
      order: 2
    }
  },
  {
    path: '/questions',
    name: 'questions',
    component: Questions,
    meta: {
      layout: LDefault,
      order: 2
    }
  },
  {
    path: '/quiz/:uuid',
    name: 'single-quiz',
    component: Quiz,
    meta: {
      layout: LDefault,
      order: 2
    }
  },
  {
    path: '/ticket',
    name: 'ticket',
    component: Ticket,
    meta: {
      layout: LDefault,
      order: 2
    }
  },
  {
    path: '*',
    redirect: { name: 'home' },
    meta: {
      layout: LDefault,
      order: 0
    }
  }
]
