export const messages = {
  fr: {
    pageName: 'Recherche',
    artwork: 'Œuvre | Œuvres',
    collection: 'Collection | Collections',
    audiotrack: 'Piste audio | Pistes audio',
    inputPlaceholder: 'Recherchez sur Wivi',
    formEmpty: 'Essayez de chercher des personnes, des sujets ou des mots-clés',
    formFail: 'Aucun résultat ne correspond à vos critères de recherche…',
    openQrCodeReader: 'Vous pouvez également retrouvez un point d\'intérêt',
    openQrCodeReaderNext: 'en flashant son QrCode',

  },
  en: {
    pageName: 'Search',
    artwork: 'Artwork | Artworks',
    collection: 'Collection | Collections',
    audiotrack: 'Audiotrack | Audiotracks',
    inputPlaceholder: 'Search on Wivi',
    formEmpty: 'Try searching for people, topics, or keywords',
    formFail: 'No results match your search criteria…',
    openQrCodeReader: 'Vous pouvez également retrouvez un point d\'intérêt',
    openQrCodeReaderNext: 'en flashant son QrCode',
  },
  us: {
    pageName: 'Search',
    artwork: 'Artwork | Artworks',
    collection: 'Collection | Collections',
    audiotrack: 'Audiotrack | Audiotracks',
    inputPlaceholder: 'Search on Wivi',
    formEmpty: 'Try searching for people, topics, or keywords',
    formFail: 'No results match your search criteria…',
    openQrCodeReader: 'Vous pouvez également retrouvez un point d\'intérêt',
    openQrCodeReaderNext: 'en flashant son QrCode',
  },
  da: {
    pageName: 'Search',
    artwork: 'Artwork | Artworks',
    collection: 'Collection | Collections',
    audiotrack: 'Audiotrack | Audiotracks',
    inputPlaceholder: 'Search on Wivi',
    formEmpty: 'Try searching for people, topics, or keywords',
    formFail: 'No results match your search criteria…',
    openQrCodeReader: 'Vous pouvez également retrouvez un point d\'intérêt',
    openQrCodeReaderNext: 'en flashant son QrCode',
  },
  es: {
    pageName: 'Search',
    artwork: 'Artwork | Artworks',
    collection: 'Collection | Collections',
    audiotrack: 'Audiotrack | Audiotracks',
    inputPlaceholder: 'Buscar',
    formEmpty: 'Intente buscar personas, temas o palabras clave',
    formFail: 'No hay resultados que coincidan con sus criterios de búsqueda...',
    openQrCodeReader: 'Vous pouvez également retrouvez un point d\'intérêt',
    openQrCodeReaderNext: 'en flashant son QrCode',
  },
  nl: {
    pageName: 'Zoekactie',
    artwork: 'Artwork | Artworks',
    collection: 'Collection | Collections',
    audiotrack: 'Audiotrack | Audiotracks',
    inputPlaceholder: 'Zoek op Wivi',
    formEmpty: 'Probeer te zoeken naar mensen, onderwerpen of trefwoorden',
    formFail: 'Geen resultaten komen overeen met uw zoekcriteri…',
    openQrCodeReader: 'Vous pouvez également retrouvez un point d\'intérêt',
    openQrCodeReaderNext: 'en flashant son QrCode',
  },
  de: {
    pageName: 'Fahnden',
    artwork: 'Artwork | Artworks',
    collection: 'Collection | Collections',
    audiotrack: 'Audiotrack | Audiotracks',
    inputPlaceholder: 'Suche nach Wivi',
    formEmpty: 'Versuche, nach Personen, Themen oder Stichworten zu suchen',
    formFail: 'Keine Ergebnisse entsprechen Ihren Suchkriterien…',
    openQrCodeReader: 'Vous pouvez également retrouvez un point d\'intérêt',
    openQrCodeReaderNext: 'en flashant son QrCode',
  },
  it: {
    pageName: 'Search',
    artwork: 'Artwork | Artworks',
    collection: 'Collection | Collections',
    audiotrack: 'Audiotrack | Audiotracks',
    inputPlaceholder: 'Search on Wivi',
    formEmpty: 'Try searching for people, topics, or keywords',
    formFail: 'No results match your search criteria…',
    openQrCodeReader: 'Vous pouvez également retrouvez un point d\'intérêt',
    openQrCodeReaderNext: 'en flashant son QrCode',
  },
  pt: {
    pageName: 'Search',
    artwork: 'Artwork | Artworks',
    collection: 'Collection | Collections',
    audiotrack: 'Audiotrack | Audiotracks',
    inputPlaceholder: 'Search on Wivi',
    formEmpty: 'Try searching for people, topics, or keywords',
    formFail: 'No results match your search criteria…',
    openQrCodeReader: 'Vous pouvez également retrouvez un point d\'intérêt',
    openQrCodeReaderNext: 'en flashant son QrCode',
  },
  zh: {
    pageName: 'Search',
    artwork: 'Artwork | Artworks',
    collection: 'Collection | Collections',
    audiotrack: 'Audiotrack | Audiotracks',
    inputPlaceholder: 'Search on Wivi',
    formEmpty: 'Try searching for people, topics, or keywords',
    formFail: 'No results match your search criteria…',
    openQrCodeReader: 'Vous pouvez également retrouvez un point d\'intérêt',
    openQrCodeReaderNext: 'en flashant son QrCode',
  }
}
