import { HtmlTranslation, I18nTarget, Maybe, Realm, Scalars } from '@/@types/graphql'

export enum MenuAction {
  internalRedirection = 'internalRedirection',
  externalRedirection = 'externalRedirection'
}

export enum RouteWithoutParameterItem {
  home = '/',
  settings = '/settings',
  pointsOfInterest = '/pois',
  collections = '/collections',
  fiberMap = '/fiber-map',
  map = '/map',
  image = 'media/image',
  audio = 'media/audio',
  video = 'media/video'
}

export interface RouteWithoutParameter {
  __typename: 'RouteWithoutParameter'
  name: RouteWithoutParameterItem
}

export enum RouteWithParameterItem {
  pointOfInterest = '/pois',
  collection = '/collections',
}

export interface RouteWithParameter {
  __typename: 'RouteWithParameter'
  name: RouteWithParameterItem,
  param: string
}

export interface InternalRedirection {
  __typename: MenuAction.internalRedirection
  route: RouteWithoutParameter | RouteWithParameter
}

export interface ExternalRedirection {
  __typename: MenuAction.externalRedirection
  url: string
}

export interface MenuItem {
  id: string
  deletable: boolean
  text: HtmlTranslation[]
  action: InternalRedirection | ExternalRedirection
}

export interface Menu {
  id: string
  name: string
  items: MenuItem[]
  realm?: Maybe<Realm>
  languages: Maybe<Array<Scalars['LanguageCode']>>
  targets?: Maybe<I18nTarget[]>
}
