import { Mixins, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import omitDeep from 'omit-deep-lodash'
import { PointOfInterestList, QuestionAnswer, Quiz, Scalars } from '@/@types/graphql'
import { LanguagesMixin } from '@/@next/mixins'
import { MediaImageFragment } from './fragments'

@Component({ name: 'realms-service' })
export default class Quizzes extends Mixins(LanguagesMixin) {

  async GET_QUIZ_WITH_QUESTIONS (id: Scalars['UUID']): Promise<Quiz> {
    const languages = this._allLanguageCodes
    const target = this._languageTarget || null
    const variables = { id, languages, target }
    const query = gql`

      ${ MediaImageFragment }

      query quiz($id: UUID!, $languages: [LanguageCode!]!, $target: I18nTarget) {
        quiz (id: $id) {
          id
          name
          shortCode
          languages
          i18n (languages: $languages, target: $target) {
            title { value { text html } language  }
            abstract { value { text html } language  }
            description { value { text html } language  }
          }
          image { ...MediaImageFragment }
          questions {
            id
            i18n (languages: $languages, target: $target) {
              title { value { text html } language }
            }
            image { ...MediaImageFragment }
          }
        }
      }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.quiz
  }

  async GET_POIS_WITH_CONTENT_QUESTION (): Promise<PointOfInterestList> {
    const query = gql`

      query pointsOfInterest {
        pointsOfInterest {
          items {
            id
            contents {
              ... on QuestionContent {
                id
                name
                languages
                data {
                  id
                }
              }
            }
          }
        }
      }
    `
    const { data } = await this.$apollo.query({ query })
    return data.pointsOfInterest
  }

  async STORE_QUESTION_ANSWER (answer: Partial<QuestionAnswer>): Promise<QuestionAnswer> {
    const variables = omitDeep(answer, '__typename')
    const mutation = gql`
      mutation storeQuestionAnswer (
        $userId: UUID!,
        $questionId: UUID!,
        $quizId: UUID,
        $contentId: UUID,
        $data: JSON!,
        $submissionId: UUID,
        $language: LanguageCode
      ) {
        questionAnswer: storeQuestionAnswer(
          userId: $userId
          questionId: $questionId,
          quizId: $quizId,
          contentId: $contentId,
          data: $data,
          submissionId: $submissionId,
          language: $language
        ) {
          id createdAt userId questionId data
        }
      }
    `
    const { data } = await this.$apollo.mutate({ mutation, variables, client: 'writableApolloClient' })

    // Return updated Question
    return data.questionAnswer
  }

}
