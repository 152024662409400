
import { Vue, Component, Prop } from 'vue-property-decorator'

import CustomWrapper from '@/components/CustomWrapper.vue'

const config = {
  name: 'c-poi-card-media',
  components: { CustomWrapper }
}

@Component(config)
export default class CPoiCardMedia extends Vue {

  @Prop({ type: String, default: 'div' }) tag!: string
  @Prop({ type: Boolean, default: false }) rounded!: boolean
  @Prop({ type: Boolean, default: false }) shadow!: boolean
  @Prop({ type: Boolean, default: false }) cover!: boolean
  @Prop({ type: Boolean, default: false }) contain!: boolean

  get CSSClasses () {
    return {
      '--Rounded': this.rounded,
      '--Shadow': this.shadow,
      '--Cover': this.cover,
      '--Contain': this.contain
    }
  }
}
