
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import CustomWrapper from '@/components/CustomWrapper.vue'

const config = {
  name: 'c-collection-card-summary',
  components: { CustomWrapper }
}

@Component(config)
export default class CCollectionCardSummary extends Vue {
  @Prop({ type: String, default: 'p' }) tag!: string
}
