import { GetterTree, Module } from 'vuex'
import _union from 'lodash/union'

import { RootState } from '@/@next/store'

import { Language, LanguageCode } from '@/@next/@types'

import * as LANGUAGE from './types'
import { mutations } from './mutations'

export class LanguagesState {
  all: Language[] = []
  current: LanguageCode = ''
  default: LanguageCode = 'fr'
  target: string | null = null
  translatableTargets: any[] = []
  accessibility: any = {
    textSize: 100,
    greyScale: 0,
    wordSpacing: 0,
    dyslexicFont: false,
    highContrast: false,
    defaultCssValues: {
      primaryColor: document.documentElement.style.getPropertyValue('--primaryColor'),
      onPrimaryColor: document.documentElement.style.getPropertyValue('--onPrimaryColor'),
      backgroundColor: document.documentElement.style.getPropertyValue('--backgroundColor'),
      onBackgroundColor: document.documentElement.style.getPropertyValue('--onBackgroundColor'),
      textOnForegroundColor: document.documentElement.style.getPropertyValue('--textOnForegroundColor'),
      foregroundColor: document.documentElement.style.getPropertyValue('--foregroundColor'),
      menuBackgroundColor: document.documentElement.style.getPropertyValue('--menuBackgroundColor'),
      menuColor: document.documentElement.style.getPropertyValue('--menuColor'),
      cardTitleBackgroundColor: document.documentElement.style.getPropertyValue('--cardTitleBackgroundColor'),
      cardTitleTextColor: document.documentElement.style.getPropertyValue('--cardTitleTextColor'),
      cardSummaryTextColor: document.documentElement.style.getPropertyValue('--cardSummaryTextColor'),
      sansSerifFontStack: document.documentElement.style.getPropertyValue('--sansSerifFont'),
      serifFontStack: document.documentElement.style.getPropertyValue('--serifFontStack'),
    }
  }
}

const state: LanguagesState = new LanguagesState()

const getters: GetterTree<LanguagesState, RootState> = {
  [LANGUAGE.GET_ALL]: (state) => state.all,
  [LANGUAGE.GET_ALL_ISO]: (state) => {
    const isos = state.all.map((language) => language.iso)
    isos.unshift(state.current)
    return _union(isos)
  },
  [LANGUAGE.GET_CURRENT]: (state) => state.current,
  [LANGUAGE.GET_DEFAULT]: (state) => state.default,
  [LANGUAGE.GET_TARGET]: (state) => state.target,
  [LANGUAGE.GET_TRANSLATABLE_TARGETS]: (state) => state.translatableTargets,
  [LANGUAGE.GET_AVAILABLE_TARGETS]: (state) => {
    state.translatableTargets.filter((t: any) => t.languages.includes(state.current)).map((t: any) => t.target)
  },
  [LANGUAGE.GET_ACCESSIBILITY]: (state) => state.accessibility,
}

export const languages: Module<LanguagesState, RootState> = {
  state,
  getters,
  mutations
}
