
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const typesCollection = [ String, Number, Boolean, Function, Object, Array, Symbol ]

const config = {
  name: 'c-RadioButton-button'
}

@Component(config)
export default class CRadioButton extends Vue {
  @Prop({ type: Boolean }) required!: boolean
  @Prop({ type: Boolean }) disabled!: boolean
  @Prop({ type: String }) name!: string
  @Prop({ type: typesCollection }) value!: any
  @Prop({ type: typesCollection }) nativeValue!: any

  newValue: any = this.value

  get computedValue () {
    return this.newValue
  }

  set computedValue (val: any) {
    this.newValue = val
    this.$emit('input', val)
  }

  @Watch('value')
  onValueChange (val: any) {
    this.newValue = val
  }
}
