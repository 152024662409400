
import { Vue, Component, Watch } from 'vue-property-decorator'
import * as UI from '@/event-bus'

import { messages } from './i18n'
import addProtocol from '@/utils/addProtocol'
import {Nullable} from '@/shared';
import {Route} from 'vue-router';
import { CTopbarTitle } from '@/@next/components/topbar-title'
import { MATOMO_URL, DOMAIN, PROD } from '@/constants'
import Matomo from 'matomo-ts'
import { Domain } from 'domain'

Component.registerHooks([
  'beforeRouteLeave'
])

const config = {
  name: 'v-embeds',
  components: {
    CTopbarTitle
  },
  i18n: { messages },
  head () {
    return {
      title: this.$t('pageName'),
    }
  }
}

@Component(config)
export default class VEmbeds extends Vue {

  // urlToLoad: Nullable<string | Array<string | null>> = null
  hideBot: boolean = false
  hideTopbar: boolean = false
  transTopbar: boolean = false

  @Watch('$route', { immediate: true })
  async onRouteChange () {
    if (this.$route.name !== 'embeds') return
    await this.init()
  }

  async init () {
    this.checkLogoTopBar()
    try {
      if (PROD) document.domain = DOMAIN
    } catch (e) {
      console.warn('error during domain definition', DOMAIN)
    }
    // this.urlToLoad = this.$route.query.url
    this.hideBot = this.$route.query.bot === 'false'
    this.hideTopbar = this.$route.query.topbar === 'false'
    this.transTopbar = this.$route.query.topbar === 'trans'

    switch (true) {
      case this.hideTopbar:
        UI.EventBus.$emit(UI.HIDE_DEFAULT_TOPBAR)
      case this.transTopbar:
        UI.EventBus.$emit(UI.ENABLE_DEFAULT_TOPBAR_TRANSPARENT)
      case this.hideBot:
        UI.EventBus.$emit(UI.HIDE_DEFAULT_BOT)
    }

  }

  get urlToLoad () {
    return this.$route.query.url || null
  }

  onLoad () {
    // console.log('load', this.urlToLoad)
  }

  onDocumentLoad () {
    // console.log('document load', this.urlToLoad)
  }

  get title () {
    return ''
  }
  get CSSClasses () {
    return {
      '--NoTopbar': this.hideTopbar || this.transTopbar
    }
  }

  beforeRouteLeave (to: Route, from: Route, next: any) {
    // console.log('BEFORE')
    if (this.hideTopbar) UI.EventBus.$emit(UI.SHOW_DEFAULT_TOPBAR)
    if (this.hideBot) UI.EventBus.$emit(UI.SHOW_DEFAULT_BOT)
    if (this.transTopbar) UI.EventBus.$emit(UI.DISABLE_DEFAULT_TOPBAR_TRANSPARENT)
    next()
  }

  checkLogoTopBar () {
    try {
      const logoTopBar = new Image();
      logoTopBar.addEventListener('load', () => {
        const customElm = document.getElementById('logo-topbar-custom')
        const defaultElm = document.getElementById('logo-topbar-default')
        if (!customElm || !defaultElm) return
        customElm.style.display = 'block'
        defaultElm.style.display = 'none'
      }, false)
      logoTopBar.src = './logo-topbar.png';
    } catch (e) {
      console.warn('Error during logo topBar verification.', e)
    }
  }

}
