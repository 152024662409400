import { MutationTree } from 'vuex'

import * as SETTINGS from './types'
import { SettingsState } from './'

export const mutations: MutationTree<SettingsState> =  {

  [SETTINGS.SET_MENU] (state, value) {
    state.menu = value
  },

  [SETTINGS.SET_FEATURES] (state, value) {
    state.features = value
  },

  [SETTINGS.SET_QRCODES] (state, value) {
    state.qrcodes = value
  },

  [SETTINGS.SET_HOMEPAGE] (state, value) {
    state.homepage = value
  },

  [SETTINGS.SET_MOVIES] (state, value) {
    state.movies = value
  }

}
