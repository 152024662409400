














import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CModal } from '@/components/modal'
import { CRadioButton, CRadioButtonIcon, CRadioButtonLabel } from '@/components/form-controls/radio'
import i18n from '@/i18n'
import { LanguagesService, UsersService } from '../services'

const config = {
  name: 'c-language-list',
  components: {
    CModal,
    CRadioButton, 
    CRadioButtonIcon,
    CRadioButtonLabel
  }
}

@Component(config)
export default class CLanguageList extends Mixins(LanguagesService, UsersService) {

  @Prop({ type: Boolean, required: false, default: false }) undisplayCurrent!: boolean

  get selectedLanguage (): string {
    return this._currentLanguage || this._defaultLanguage
  }

  set selectedLanguage (isoLanguage: string) {
    this._setCurrentLanguage(isoLanguage)
    i18n.locale = isoLanguage
    this.SAVE_CURRENT_USER_PREFERENCES()
    this.$emit('close')
  }

  get currentLanguage () {
    return this._allLanguages.find((language) => language.iso === this._currentLanguage)
  }

  get languages () {
    return this._allLanguages.map((language) => language).filter((language) => language.iso !== this._currentLanguage)
  }

}
