
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import CustomWrapper from '@/components/CustomWrapper.vue'

const config = {
  name: 'c-collection-card-content',
  components: { CustomWrapper }
}

@Component(config)
export default class CCollectionCardContent extends Vue {

  @Prop({ type: String, default: 'div' }) tag!: string
  @Prop({ type: Boolean, default: false }) rounded!: boolean
  @Prop({ type: Boolean, default: false }) shadow!: boolean
  @Prop({ type: Boolean, default: false }) locked!: boolean
  @Prop({ type: Boolean, default: false }) noBackground!: boolean
  @Prop({ type: Boolean, default: false }) center!: boolean
  @Prop({ type: String}) lockedId!: string

  startAnimation: boolean = false;
  animTimeout!: any;

  get CSSClasses () {
    return {
      '--Rounded': this.rounded,
      '--Shadow': this.shadow,
      '--NoBackground': this.noBackground,
      '--Centered': this.center
    }
  }

  clickOnLockedContent () {
    console.log('clickOnLockedContent')
    if (!this.startAnimation) {
      this.startAnimation = true
      this.animTimeout = setTimeout(() => this.startAnimation = false, 12000)
    } else {
      this.startAnimation = false
      if (this.animTimeout) clearTimeout(this.animTimeout)
    }
  }

  // enterTicket (e: any) {
  //   console.log('enterTicket')
  //   e.stopPropagation()
  //   e.preventDefault()
  // }

}
