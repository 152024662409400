
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ContentType } from '@/@next/@types'

import {
  CContentAudio,
  CContentAudioGuide,
  CContentAudioGuideTrack,
  CContentCollection,
  CContentImage,
  CContentImageGallery,
  CContentPerson,
  CContentPointOfInterest,
  CContentPuzzle,
  CContentText,
  CContentVideo,
  CContentQuestion,
  CContentQuiz,
  CContentSurvey,
  CContentNotFound,
  CContentPopup,
  CContentMemory,
  CContentTimeline
} from '@/@next/components/contents/index'

const config = {
  name: 'c-poi-content'
}

@Component(config)
export default class PoiContent extends Vue {

  @Prop() content!: any
  @Prop() poiId!: string

// <component class="v-SinglePoi__ContentPart"
//   v-for="(content, index) in pointOfInterest.contents"
// :key="content.id"
// :contextId="pointOfInterest.id"
// :content="content"
// :is="getContentPartComponent(content.typeId)" />
  get component () {
    switch (this.content.__typename) {
      case 'AudioContent':
        return {
          instance: CContentAudio,
          props: {
            content: this.content
          }
        }

      case 'AudioGuideContent':
        return {
          instance: CContentAudioGuide,
          props: {}
        }

      case 'AudioGuideTrackContent':
        return {
          instance: CContentAudio,
          props: {
            content: this.content
          }
        }

      case 'CollectionContent':
        return {
          instance: CContentCollection,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }

      case 'ImageContent':
        return {
          instance: CContentImage,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }

      case 'ImageGalleryContent':
        return {
          instance: CContentImageGallery,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }

      case 'PersonContent':
        return {
          instance: CContentPerson,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }

      case 'PointOfInterestContent':
        return {
          instance: CContentPointOfInterest,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }

      case 'PuzzleContent' :
        return {
          instance: CContentPuzzle,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }

      case 'TextContent' :
        return {
          instance: CContentText,
          props: {
            id: this.content.id,
            text: this.content.data.i18n.body.value.html,
            people: this.content.people
          }
        }

      case 'VideoContent' :
        return {
          instance: CContentVideo,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }

      case 'QuestionContent' :
        return {
          instance: CContentQuestion,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }

      case 'QuizContent' :
        return {
          instance: CContentQuiz,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }

      case 'SurveyContent' :
        return {
          instance: CContentSurvey,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }

      case 'PopupContent' :
        return {
          instance: CContentPopup,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }

      case 'MemoryContent' :
        return {
          instance: CContentMemory,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }
      case 'TimelineContent' :
        return {
          instance: CContentTimeline,
          props: {
            content: this.content,
            contextId: this.poiId
          }
        }
      default:
        return {
          instance: CContentNotFound,
          props: {
            content: this.content
          }
        }

    }
  }
  get isAdditionalContent () {
    return this.content.options && this.content.options.isAdditionalContent
  }
}
