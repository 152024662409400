export const messages = {
  fr: {
    surveyListTitle: 'Liste des questionnaires de satisfaction',
    pageName: 'Sondage',
    send: 'Envoyer',
    thank: 'Merci d\'avoir répondu à cette enquête !',
    error: 'Il y a eu une erreur, veuillez réessayer…',
    rgpdCondition: 'J\'accepte les conditions de confidentialité (Standard RGPD)',
    acceptNewsLetter: 'J\'accepte de recevoir la newsletter.',
    requiredFieldAreNotAnswered: 'Tous les champs obligatoires ne sont pas renseignés.'
  },
  en: {
    surveyListTitle: 'survey list',
    pageName: 'Survey',
    send: 'Send',
    thank: 'Thank you for responding to this survey !',
    error: 'This content is not available offline.',
    rgpdCondition: 'I accept the privacy policy (RGPD standard)',
    acceptNewsLetter: 'I agree to receive the newsletter.',
    requiredFieldAreNotAnswered: 'Not all required fields are filled in.'
  },
  us: {
    surveyListTitle: 'survey list',
    pageName: 'Survey',
    send: 'Send',
    thank: 'Thank you for responding to this survey !',
    error: 'This content is not available offline.',
    rgpdCondition: 'I accept the privacy policy (RGPD standard)',
    acceptNewsLetter: 'I agree to receive the newsletter.',
    requiredFieldAreNotAnswered: 'Not all required fields are filled in.'
  },
  da: {
    surveyListTitle: 'survey list',
    pageName: 'Survey',
    send: 'Send',
    thank: 'Thank you for responding to this survey !',
    error: 'This content is not available offline.',
    rgpdCondition: 'I accept the privacy policy (RGPD standard)',
    acceptNewsLetter: 'I agree to receive the newsletter.',
    requiredFieldAreNotAnswered: 'Not all required fields are filled in.'
  },
  es: {
    surveyListTitle: 'survey list',
    pageName: 'Survey',
    send: 'Send',
    thank: 'Thank you for responding to this survey !',
    error: 'This content is not available offline.',
    rgpdCondition: 'I accept the privacy policy (RGPD standard)',
    acceptNewsLetter: 'I agree to receive the newsletter.',
    requiredFieldAreNotAnswered: 'Not all required fields are filled in.'
  },
  nl: {
    surveyListTitle: 'survey list',
    pageName: 'Survey',
    send: 'Send',
    thank: 'Thank you for responding to this survey !',
    error: 'This content is not available offline.',
    rgpdCondition: 'I accept the privacy policy (RGPD standard)',
    acceptNewsLetter: 'I agree to receive the newsletter.',
    requiredFieldAreNotAnswered: 'Not all required fields are filled in.'
  },
  de: {
    surveyListTitle: 'survey list',
    pageName: 'Survey',
    send: 'Send',
    thank: 'Thank you for responding to this survey !',
    error: 'This content is not available offline.',
    rgpdCondition: 'I accept the privacy policy (RGPD standard)',
    acceptNewsLetter: 'I agree to receive the newsletter.',
    requiredFieldAreNotAnswered: 'Not all required fields are filled in.'
  },
  it: {
    surveyListTitle: 'survey list',
    pageName: 'Survey',
    send: 'Send',
    thank: 'Thank you for responding to this survey !',
    error: 'This content is not available offline.',
    rgpdCondition: 'I accept the privacy policy (RGPD standard)',
    acceptNewsLetter: 'I agree to receive the newsletter.',
    requiredFieldAreNotAnswered: 'Not all required fields are filled in.'
  },
  pt: {
    surveyListTitle: 'survey list',
    pageName: 'Survey',
    send: 'Send',
    thank: 'Thank you for responding to this survey !',
    error: 'This content is not available offline.',
    rgpdCondition: 'I accept the privacy policy (RGPD standard)',
    acceptNewsLetter: 'I agree to receive the newsletter.',
    requiredFieldAreNotAnswered: 'Not all required fields are filled in.'
  },
  zh: {
    surveyListTitle: 'survey list',
    pageName: 'Survey',
    send: 'Send',
    thank: 'Thank you for responding to this survey !',
    error: 'This content is not available offline.',
    rgpdCondition: 'I accept the privacy policy (RGPD standard)',
    acceptNewsLetter: 'I agree to receive the newsletter.',
    requiredFieldAreNotAnswered: 'Not all required fields are filled in.'
  }
}
