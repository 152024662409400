import { GetterTree, Module } from 'vuex'
import { RootState } from '@/@next/store'

import * as BOT from './types'
import { actions } from './actions'
import { mutations } from './mutations'

export class BotState {
  isEnable: any = {}
  notificationAreEnable: boolean = true
  isOpen: boolean = false
  botState: string = ''
  messages: any[] = []
  buffer: any[] = []
  settings: any = {}
  content: any = {}
  isWritingMessage: boolean = false
  userChoices: any = []
  currentPoiContent: any = {}
}

const state = new BotState()

const getters: GetterTree<BotState, RootState> = {
  [BOT.GET_MESSAGES]: (state): any => state.messages,
  [BOT.IS_ENABLE]: (state): any => {
    return state.isEnable
  },
  [BOT.SETTINGS]: (state): any => state.settings,
  [BOT.IS_OPEN]: (state): boolean => state.isOpen,
  [BOT.GET_UNREAD_MESSAGE_COUNT]: (state): number => {
    return state.messages.filter((el: any) => el.read === false).length
  },
  [BOT.IS_WRITING_MESSAGE]: (state): boolean => state.isWritingMessage,
  [BOT.GET_USER_CHOICES]: (state): any[] => state.userChoices,
}

export const bot: Module<BotState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
