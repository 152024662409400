import { GetterTree, MutationTree, Module } from 'vuex'
import { RootState } from '@/@next/store'
import * as USER from './types'

export class UserState {
  signLanguageEnabled: boolean = false
  fontSizeScale: number = 1
  highContrastEnabled: boolean = false
}

const state = new UserState()

const getters: GetterTree<UserState, RootState> = {
  [USER.IS_SIGN_LANGUAGE_ENABLED]: (state) => state.signLanguageEnabled
}

const mutations: MutationTree<UserState> = {
  [USER.ENABLE_SIGN_LANGUAGE] (state) {
    state.signLanguageEnabled = true
  },

  [USER.DISABLE_SIGN_LANGUAGE] (state) {
    state.signLanguageEnabled = false
  },

  [USER.SET_SIGN_LANGUAGE_ENABLED] (state, enabled: boolean) {
    state.signLanguageEnabled = enabled
  }
}

export const user: Module<UserState, RootState> = {
  state,
  getters,
  mutations
}
