import Matomo from 'matomo-ts'
import { MATOMO_SITE_ID, MATOMO_URL } from '@/constants'

Matomo.default().init(MATOMO_URL, MATOMO_SITE_ID)

// export const initMatomo = () => {
//   // Set Matomo
//   if (!MATOMO_URL || !MATOMO_SITE_ID) return
//   Matomo.default().init(MATOMO_URL, MATOMO_SITE_ID)
  // if (MATOMO_URL && (CLIENT || MATOMO_SITE_ID)) {
    // else if (CLIENT) {
    //   let siteId = 3
    //   if (process.env.NODE_ENV) {
    //     switch (CLIENT) {
    //       case 'utah':
    //         siteId = 2
    //         break
    //       case 'demo':
    //         siteId = 3
    //         break
    //       case 'graindorge':
    //         siteId = 4
    //         break
    //       case 'muma':
    //         siteId = 5
    //         break
    //       case 'nvm':
    //         siteId = 6
    //         break
    //       case 'overlord':
    //         siteId = 7
    //         break
    //       case 'nacre':
    //         siteId = 9
    //         break
    //       case 'atelier-conde':
    //         siteId = 10
    //         break
    //       case 'paleospace':
    //         siteId = 12
    //         break
    //       case 'bernieres':
    //         siteId = 13
    //         break
    //       case 'pegasus':
    //         siteId = 14
    //         break
    //       case 'argentan':
    //         siteId = 15
    //         break
    //       case 'juno':
    //         siteId = 20
    //         break
    //       default:
    //         siteId = 3
    //         break
    //     }
    //   } else {
    //     console.warn('Default Matomo (Demo)')
    //   }
    //   Matomo.default().init(MATOMO_URL, siteId.toString())
    // }
//   }
// }
