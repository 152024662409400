import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import gql from 'graphql-tag'

import * as LANGUAGES from '@/@next/store/languages/types'

import { PersonList, UUID, PaginationOptions, SortOptions, Person, LanguageCode } from '@/@next/@types'

import { MediaImageFragment } from './fragments'

@Component({
  name: 'people-service'
})
export default class People extends Vue {

  @Getter(LANGUAGES.GET_ALL_ISO) getAllLanguagesISO!: LanguageCode[]

  async GET_PEOPLE (pagination: PaginationOptions = {}, sort: SortOptions = {}): Promise<PersonList> {
    const variables = { pagination, sort }
    const query = gql`

        ${ MediaImageFragment }

        query people ($pagination: PaginationArgs, $sort: SortArgs) {
            people (pagination: $pagination, sort: $sort) {
                itemsCount
                totalCount
                pagination {
                    skip
                    take
                }
                sort {
                    field
                    order
                }
                items {
                    id
                    i18n (languages: ${ JSON.stringify(this.getAllLanguagesISO) }) {
                        name {
                            language
                            value {
                                text 
                                html 
                            } 
                        }
                    }
                    bornAt
                    diedAt
                    image { ...MediaImageFragment }
                }

            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.people
  }

  async GET_SINGLE_PERSON (id: UUID): Promise<Person> {
    const variables = { id }
    const query = gql`
      
        ${ MediaImageFragment }

        query person ($id: UUID!) {
            person (id: $id) {
                id
                i18n (languages: ${ JSON.stringify(this.getAllLanguagesISO) }) {
                    name {
                        language
                        value {
                            text
                            html
                        }
                    }
                    bio {
                        language
                        value {
                            text
                            html
                        }
                    }
                }
                bornAt
                diedAt
                image { ...MediaImageFragment }
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.person
  }
}
