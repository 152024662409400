import { Mixins, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import omitDeep from 'omit-deep-lodash'
import { PointOfInterestList, QuestionAnswer, Quiz, QuizList, Scalars, SortArgs } from '@/@types/graphql'
import { LanguagesMixin } from '@/@next/mixins'
import { MediaImageFragment } from './fragments'

@Component({ name: 'survey-service' })
export default class SurveyService extends Mixins(LanguagesMixin) {
  
  async GET_ALL_SURVEY (): Promise<QuizList> {
    const query = gql`

      ${ MediaImageFragment }

        query {
            quizzes {
              itemsCount
              totalCount,
              items {
                id,
                name,
                shortCode
                languages,
                scope { id slug }
                i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                    title { value { text html } language  }
                    abstract { value { text html } language  }
                    description { value { text html } language  }
                }
                image { ...MediaImageFragment }
                questions {
                    id
                    i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                        title { value { text html } language }
                    }
                    data
                }
              }
            }
        }
    `
    const { data } = await this.$apollo.query({ query })
    const items = data.quizzes.items.filter((q: Quiz) => q.scope?.slug === 'survey')   
    return { ...data.quizzes, items }
  }

  async GET_SURVEY (id: Scalars['UUID']): Promise<Quiz> {
    const query = gql`
      ${ MediaImageFragment }
      query quiz($id: UUID!) {
        quiz (id: $id) {
          id,
          name,
          shortCode
          languages,
          scope { id slug }
          i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
              title { value { text html } language  }
              abstract { value { text html } language  }
              description { value { text html } language  }
          }
          image { ...MediaImageFragment }
          questions {
              id
              i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                  title { value { text html } language }
                  abstract { value { text html } language }
              }
              data
          }
        }
      }
    `
    const { data } = await this.$apollo.query({ query, variables: { id }})
    return data.quiz
  }
}
