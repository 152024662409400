import { Floor, Placeholder, Map } from '@/@types/graphql';
import { MAP_URL } from '@/constants';
import { Vue, Component } from 'vue-property-decorator';

export interface FiberMapQueries {
  [key: string]: string;
}

@Component({ name: 'fiber-map-mixin' })
export default class FiberMap extends Vue {
  initCallbackMethods () {
    if (!window) return;
    (window as any).goTo = (target: any) => {
      switch (target.type) {
        case 'pointOfInterest':
          return this.$router.push({
            name: 'single-poi',
            params: { uuid: target.id },
            query: target.contextCollection
              ? { collection: target.contextCollection }
              : {}
          });
        case 'collection':
          this.$router.push({
            name: 'single-collection',
            params: { uuid: target.id }
          });
        default:
          console.warn('Map go To target undefined', target);
          break;
      }
    };
  }

  redirectToMap (queries: FiberMapQueries = {}) {
    if (!MAP_URL) return;
    this.$router.push({ name: 'fiber-map', query: queries });
  }

  showPlaceholder ({ id }: Placeholder) {
    return this.redirectToMap({ placeholderId: id });
  }

  showFloor (floor: Floor | null = null) {
    if (!floor) return this.redirectToMap();
    return this.redirectToMap({ floorId: floor.id });
  }

  showMap (map: Map | null = null) {
    if (!map) return this.redirectToMap();
    return this.redirectToMap({ mapId: map.id });
  }
}
