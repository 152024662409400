



















import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CModal } from '@/components/modal'
import { CRadioButton, CRadioButtonIcon, CRadioButtonLabel } from '@/components/form-controls/radio'
import i18n from '@/i18n'
import { LanguagesService, UsersService } from '../services'
import CLanguageAndTarget from '@/@next/components/LanguageAndTarget.vue'

const config = {
  name: 'c-Language-Selector',
  components: {
    CModal,
    CRadioButton, 
    CRadioButtonIcon,
    CRadioButtonLabel,
    CLanguageAndTarget
  }
}

@Component(config)
export default class CLanguageSelector extends Mixins(LanguagesService, UsersService) {

  @Prop({ type: Boolean, required: true }) isOpen!: boolean

  get selectedLanguage (): string {
  return this._currentLanguage || this._defaultLanguage
}

set selectedLanguage (isoLanguage: string) {
  this._setCurrentLanguage(isoLanguage)
  i18n.locale = isoLanguage
  this.SAVE_CURRENT_USER_PREFERENCES()
  // const currentConfig = this.GET_USER_CONFIG()
  // this.SET_USER_CONFIG({...currentConfig, isoLanguage})
  this.$emit('close')
}

get currentLanguage () {
  return this._allLanguages.find((language) => language.iso === this._currentLanguage)
}

get languages () {
  return this._allLanguages.map((language) => language)
}

}
