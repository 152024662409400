
import { Component, Prop, Mixins } from 'vue-property-decorator'

import Input from '@/mixins/Input'

const config = {
  name: 'c-switch'
}

@Component(config)
export default class CSwitch extends Mixins!(Input) {

  @Prop({ type: Boolean, default: false }) reverse!: boolean

  get CSSClasses () {
    return {
      '--Reverse': this.reverse
    }
  }
}
