/* tslint:disable */
export const messages = {
  fr: {
    pageName: 'Point d\'intérêt seul',
    quiz: 'Questionnaire',
    question: 'Question ',
    survey: 'Sondage',
    questionAvailableType: 'question | questions',
    questionAvailableLabel: 'disponible sur ce point d\'intérêt | disponibles sur ce point d\'intérêt',
    reply: 'répondre',
    nextQuestion: 'Question Suivante',
    next: 'Suiv.',
    previous: 'Préc.',
    terminate: 'Fermer le quiz',
    speechSynthesis : {
      start: 'Lire cette page',
      play: 'Reprendre la lecture',
      pause: 'Mettre en pause'
    },
    contest: {
      title: 'Dans leurs pas - Concours',
      realm: {
        ok: 'J\'ai compris',
        noRealm: 'Il semblerait que vous ne soyez pas actuellement près d\'un kiosque, rendez-vous dans une des 7 communes participantes pour tenter votre chance !'
      },
      welcome: {
        title: 'Participez au jeu concours !',
        text: `
          <p><strong>Vous avez + de 18 ans : tentez de gagner un voyage de rêve au Canada !</strong></p>
          <br />
          <p><strong>Vous avez – de 18 ans : tentez de gagner un petit cadeau souvenir !</strong></p>
          <h3>Comment participer ?</h3>
          <p>Rendez-vous devant chacun des 7 kiosques situés à :</p>
          <br />
          <ul style="margin: 0 0 0 12px; list-style: square;">
            <li style="padding: 6px 0 6px 6px;">Courseulles-sur-mer, quai des Alliés,</li>
            <li style="padding: 6px 0 6px 6px;">Bernières-sur-mer, rue Victor Tesnière,</li>
            <li style="padding: 6px 0 6px 6px;">Saint-Aubin-sur-mer, 1 rue Pasteur, devant l’office de tourisme, </li>
            <li style="padding: 6px 0 6px 6px;">Langrune-sur-mer, place du 6 juin,</li>
            <li style="padding: 6px 0 6px 6px;">Luc-sur-mer, square de l’Europe, à proximité du Carrefour,</li>
            <li style="padding: 6px 0 6px 6px;">Douvres-la-Délivrande, place Lesage,</li>
            <li style="padding: 6px 0 6px 6px;">Cimetière canadien de Reviers-Bény-sur-mer. </li>
          </ul>
          <br />
          <p>Une fois devant un kiosque, avec votre smartphone ou votre tablette, <strong>scannez le QR Code</strong> de l’application ou accédez directement à l’application en tapant dans votre navigateur <strong>« dansleurspas.com »</strong>. Vous pouvez aussi choisir le <strong>wifi</strong> « dansleurspas » dans les paramètres de votre smartphone ou de votre tablette.</p>
          <br />
          <p>Entrez votre adresse e-mail puis répondez à chaque question, uniquement accessible depuis l’application : <strong>Quel est l’âge du soldat ou bien la moyenne d’âge des soldats présentés sur chaque kiosque</strong>. La/les réponse(s) se trouve(nt) systématiquement sur le kiosque. </p>
          <br />
          <p><strong>Il est possible que votre adresse e-mail vous soit demandée plusieurs fois lors du jeu. Pas de panique, vos précédentes réponses sont bien sauvegardées !</strong></p>
          <br />
          <p>Une fois les 7 réponses enregistrées, un calcul automatique déterminera l’<strong>âge moyen des soldats, en juin 1944, présentés dans le circuit-expo plein air Dans leurs Pas</strong>. Une fois le résultat validé :</p>
          <br />
          <ul style="margin: 0 0 0 12px; list-style: square;">
            <li style="padding: 6px 0 6px 6px;">Si vous avez + de 18 ans : vous serez sélectionné(e) pour le tirage au sort pour gagner un voyage au Canada.</li>
            <li style="padding: 6px 0 6px 6px;">Si vous avez – de 18 ans : vous pourrez retirer votre petit cadeau à l’accueil de l’un des offices de tourisme.</li>
          </ul>

          <!--
          <p>Profitez d'un auto tour Canada Côte Est de Montréal à Québec en 8 nuits pour deux personnes majeures pour découvrir les villes emblématiques, les chutes du Niagara et la nature québécoise. (candidature réservée aux personnes majeures).</p>
          <p>Vous avez moins de 18 ans ? Tentez de gagner un petit cadeau souvenir de votre séjour en Normandie.</p>
          <br />
          <p>Comment participer ?</p>
          <br />
          <p>Pour participer, rendez-vous devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec votre smartphone, scannez le QR Code de l’application web ou accédez connectez-vous au réseau wifi du kiosque.</p>
          <p>Répondez simplement à la question située dans la rubrique "kiosque à histoire" de la ville concernée.</p>
          <p>Une fois les 7 réponses enregistrées par vos soins dans l’application, votre participation sera prise en compte</p>
          <p>Vous avez + de 18 ans : vous serez sélectionné(e) pour le tirage au sort pour gagner un voyage au Canada.</p>
          <p>Vous avez – de 18 ans : vous pourrez retirer votre petit cadeau à l’accueil de l’un des offices de tourisme.</p>
          -->
           `,
        mailLabel: 'Votre adresse mail',
        mailPlaceholder: 'me@me.fr',
        sendMailLabel: 'Confirmer mon adresse mail'
      },
      conditions: {
        title: 'Conditions de participation',
        text: `
          <h3>Règlement du jeu-concours « Gagnez un voyage de rêve au Canada, Côte Est »</h3>
          <p><strong>ARTICLE 1 : ORGANISATION DU JEU-CONCOURS</strong></p>
          <br />
          <p>1.1 Le Centre Juno Beach (ci-après la Société Organisatrice) situé Voie des Français Libres 14470 Courseulles-sur-mer organise un jeu-concours gratuit sans obligation d’achat du 08/04/2019 de 10h (heure française) au 11/11/2019 (minuit, heure française), intitulé :</p>
          <br />
          <p>Jeu-concours « Gagnez un voyage de rêve au Canada, Côte Est » (ci-après le jeu-concours), selon les modalités décrites dans le présent règlement.</p>
          <p>Jeu-concours accessible uniquement sur l’application web dansleurspas.com dans le cadre du circuit-expo plein-air Dans leurs Pas.</p>
          <br />
          <p><strong>ARTICLE 2 : CONDITIONS D’ACCES AU JEU-CONCOURS</strong></p>
          <br />
          <p>2.1. La participation au jeu-concours est gratuite, ouverte à toute personne physique majeure capable âgée d’au moins 18 ans, disposant, à la date de début du jeu-concours, d’un accès à l’application web et d’une adresse électronique personnelle (e-mail) à laquelle elle pourra être contactée pour les besoins de la gestion du jeu-concours (ci-après le « Participant »).</p>
          <br />
          <p>Sont exclues de toute participation à ce jeu-concours, les personnes ayant directement ou indirectement participé à l’élaboration du jeu-concours, de même que les membres de leur famille (même nom, même adresse postale), les employés et administrateurs du Centre Juno Beach, les employés et administrateurs de l’office de tourisme Terres de Nacre, y compris les personnes travaillant pour le compte du Centre Juno Beach et pour le compte de l’office de tourisme Terres de Nacre.</p>
          <p>Seules seront retenues les participations conformes à l’ensemble des stipulations du présent article. La Société Organisatrice se réserve le droit de procéder à toute vérification qu’elle jugera utile concernant notamment l’identité, l’âge et l’adresse de chaque Participant, en vue de faire respecter les stipulations du présent article. A cet égard, toute indication portée dans le formulaire de participation de l’application web, qui serait incomplète, erronée, falsifiée, ne permettrait pas d’identifier un Participant ou ses coordonnées, ou contreviendrait à l’une quelconque des stipulations du présent règlement entraînera l’annulation de sa participation.</p>
          <br />
          <p>2.3. La participation au jeu-concours implique l’acceptation expresse et sans réserve du présent règlement dans son intégralité, des règles de déontologie en vigueur, ainsi que des lois et règlements applicables aux jeux-concours en vigueur en France.</p>
          <br />
          <p>2.4 Une seule participation maximum par personne sera acceptée pendant toute la durée du jeu-concours.</p>
          <br />
          <p><strong>ARTICLE 3 : MODALITÉS DE PARTICIPATION</strong></p>
          <br />
          <p>3.1 Ce jeu-concours se déroule exclusivement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, aux dates indiquées dans l’article 1.1.</p>
          <br />
          <p>3.2 La participation s’effectue de la manière suivante : Le Participant doit se rendre devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec son smartphone ou sa tablette, il doit scanner le QR Code de l’application web ou accédez directement à l’application en tapant dans le navigateur « dansleurspas.com ». Le Participant doit répondre à une question par kiosque. Une fois qu’il a répondu à l’ensemble des 7 questions, il pourra répondre à la question finale : quel est l’âge moyen des soldats, présentés sur l’ensemble des kiosques de l’application ? Une fois la réponse finale donnée, le Participant pourra valider sa participation au tirage au sort pour tenter de gagner un voyage au Canada, Côte Est.</p>
          <br />
          <p>Seuls les Participants ayant correctement suivis ces étapes, et ayant répondus correctement aux questions posées, verront leur candidature validée.</p>
          <p>Les Participants qui ne répondent pas à l’ensemble des 7 questions ne peuvent prétendre être sélectionnés pour le tirage au sort.</p>
          <br />
          <p>3.3 Il n’est autorisé qu’une seule participation par personne - même nom, même prénom, même adresse électronique ou quel que soit l’adresse électronique pendant toute la période du jeu-concours.</p>
          <br />
          <p><strong>ARTICLE 4 – DESIGNATION DU/DE LA GAGNANT(E)</strong></p>
          <br />
          <p>4.1 Un tirage au sort sera effectué le samedi 16 novembre 2019 parmi toutes les participations complètes (telle que décrite à l’article 3) pour désigner le gagnant du jeu-concours.</p>
          <br />
          <p>4.2 Ce tirage au sort sera effectué par un algorithme informatique, réalisé par la société Twelve Solutions 149 Rue de la Délivrande, 14000 Caen. La participation tirée au sort désignera le/la Participant(e) gagnant(e) du jeu-concours (ci-après le/la « Gagnant(e) »).</p>
          <br />
          <p>4.3 Le/la Gagnant(e) sera averti par voie électronique (e-mail).</p>
          <br />
          <p>4.4 Le/la Gagnant(e) dispose d’un délai de 7 jours pour se faire connaître auprès de la Société Organisatrice. Le/la Gagnant(e) devra alors confirmer l’acceptation de son gain en répondant à l'adresse e-mail expéditrice et en précisant ses coordonnées : nom, prénom, adresse postale. Le/la Gagnant(e) ne donnant pas de réponse dans un délai de 7 jours à compter de l’envoi d’avis de son gain ne pourra pas en bénéficier. Le lot sera alors attribué à un nouveau contact tiré au sort et ainsi de suite jusqu’à ce qu’un(e) Gagnant(e) se fasse connaître auprès de la Société Organisatrice.</p>
          <br />
          <p>4.5 Toute coordonnée incomplète ou inexacte sera considérée comme nulle et ne permettra pas au/à la Gagnant(e) désigné par tirage au sort d’obtenir sa dotation. De manière générale, les participations au jeu-concours seront annulées si elles sont incomplètes, erronées, contrefaites ou réalisées de manière contrevenante au présent règlement.</p>
          <br />
          <p>4.6 Les Participants qui n’auront pas été tirés au sort n’en seront pas informés par e-mail ni par quelque autre moyen que ce soit. Aucun lot ne sera accordé aux Participants qui n’auront pas été tirés au sort.</p>
          <br />
          <p><strong>ARTICLE 5 – DOTATION</strong></p>
          <br />
          <p>5.1 Le voyage à gagner est financé par le Centre Juno Beach et est organisé par l’agence de voyage PLANET_REVE Village des Sports Décathlon Les Carandes 14120 Mondeville.</p>
          <br />
          <p>5.2 Le/la Gagnant(e) remportera le lot suivant : Un auto tour Canada Côte Est de Montréal à Québec en 8 nuits pour deux personnes majeures pour découvrir les villes emblématiques, les chutes du Niagara et la nature québécoise.</p>
          <br />
          <p>Ce circuit de 8 jours concocté par PLANET_REVE se déroulera hors vacances et ponts entre le 02 avril et le 10 juin 2020.</p>
          <p>La valeur totale du lot est de 2 200€/personne, soit 4 400€ pour deux personnes.</p>
          <p>Ces prix comprennent :</p>
          <ul>
            <li>· Les vols Paris – Montréal, Québec – Paris,</li>
            <li>· L’hébergement en hôtel en chambre standard avec petit déjeuner,</li>
            <li>· Les prestations offertes dans le programme, soumises à conditions,</li>
            <li>· La location de voiture livrée à l’aéroport de Montréal le jour 1 et restituée à l'aéroport de Québec le jour 8 pour une durée de 8x24h, incluant le kilométrage illimité, les frais d'abandon, GPS inclus, taxes locales, 1 conducteur additionnel inclus, 1 plein d’essence offert, surcharge aéroport, réduction totale de la responsabilité financière si accident ou vol du véhicule loué (LDW), assurance tierce personne jusqu’à 1 million de dollars (TPL = Third Party Liability),</li>
            <li>· L’assurance rapatriement de base.</li>
          </ul>
          <p>Ces prix ne comprennent pas :</p>
          <ul>
            <li>· Le transport jusqu’à l’aéroport de Paris,</li>
            <li>· Les repas en dehors des petits déjeuners,</li>
            <li>· Les boissons et les dépenses personnelles,</li>
            <li>· Les visites, entrées et soirées suggérées dans le programme sauf les prestations offertes,</li>
            <li>· Obtention et frais de l'autorisation de voyage électronique (AVE) pour le Canada (9 Dollars à ce jour),</li>
            <li>· Pour la location de voiture : le carburant ; les équipements complémentaires à réserver et à régler sur place tels que : siège bébé ou rehausseur enfant (13 CAD/jour) ; les assurances complémentaires à réserver et à régler sur place telles que : conducteur additionnel au-delà du 1er conducteur additionnel inclus (10 à 25 CAD/jour), jeune conducteur moins de 25 ans (de 25 à 35 CAD/jour), la « surcharge pneus » au Québec : de 3.24 CAD à 5,25 CAD/jour, assurance du conducteur (PAI) : 5 CAD/ jour, assurance protection des effets personnels (PEP), : 3 CAD/jour, l’option « assurance routière étendue » RSN (Roadside Safety Network) : 9 CAD/jour ; frais de parking/garage (hôtels, villes et sites...), frais de livraison du véhicule en dehors des horaires d'ouverture de l'agence du loueur, péages d'autoroutes, ponts..., contraventions éventuelles (+frais administratifs de traitement env. 50 €)</li>
            <li>· L’assurance multirisque annulation à 3%,</li>
            <li>· Les variations possibles de tarif liés à la possible hausse du prix du carburant, à la possible hausse des tarifs d’hébergement et la possible hausse des tarifs aériens.</li>
          </ul>
          <br />
          <p>5.3 Le Centre Juno Beach et PLANET_REVE se réservent le droit de procéder à la vérification de l’âge du/de la gagnant(e) et de la personne qui l’accompagne avant remise de son lot. </p>
          <br />
          <p>5.4 La dotation ne pourra faire l’objet d’aucune contestation de la part du/dela Gagnant(e). Le lot attribué est incessible, intransmissible, ne peut être vendu ou être échangée contre sa valeur en espèces ou contre toute autre dotation. </p>
          <br />
          <p>5.5 Le Centre Juno Beach ne saurait être tenu pour responsable de l’utilisation ou de la non utilisation, voire du négoce, du lot par le/la Gagnant(e). </p>
          <br />
          <p>5.6 En cas de force majeure, PLANET_REVE se réserve le droit de remplacer le lot gagné par un lot de nature et de valeur équivalente. </p>
          <br />
          <p>5.7 Le participant est informé que la vente et l'échange du lot sont strictement interdits.</p>
          <br />
          <p>5.8 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot.</p>
          <br />
          <p>5.9 La valeur indiquée pour le lot correspond au prix TTC couramment pratiqué ou estimé à la date de rédaction du règlement, elle est donnée à titre de simple indication et est susceptible de variation.</p>
          <br />
          <p>5.10 PLANET_REVE sera informé du nom du/de la gagnant(e) par la Société Organisatrice. Le/la Gagnant(e) devra prendre rendez-vous avec un agent de voyage de PLANET_REVE dans les 30 jours qui suivent la date du tirage au sort. </p>
          <br />
          <p>5.11 Si le lot n'a pu être retiré par Le/la gagnant(e) pour quelque raison que ce soit, indépendamment de la volonté de l'organisateur du jeu-concours, restera définitivement la propriété de la Société Organisatrice.</p>
          <br />
          <p>5.12 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot. </p>
          <br />
          <p><strong>ARTICLE 6 : Modification du Jeu-CONCOURS et du règlement </strong></p>
          <br />
          <p>6.1 La Société Organisatrice se réserve le droit d’annuler, de reporter, de prolonger ou d’écourter le jeu-concours, ainsi que de modifier tout ou partie des conditions d’accès et/ou des modalités de mise en œuvre du jeu-concours, si les circonstances l’y obligent sans avoir à justifier de cette décision et sans que sa responsabilité puisse être engagée en aucune manière de ce fait. </p>
          <p><strong>ARTICLE 7 : Dépôt et consultation du règlement </strong></p>
          <p>7.1 La participation au jeu-concours implique l’acceptation entière et sans réserve du présent règlement dans son intégralité. Le présent règlement est disponible gratuitement et dans son intégralité sur l’application web dansleurspas.com pendant toute la durée du jeu-concours.</p>
          <br />
          <p>7.2 Le présent règlement est déposé chez la SELARL ACTOJURIS, Huissier de Justice, à CAEN.</p>
          <br />
          <p><strong>ARTICLE 8 : LIMITATION DE RESPONSABILITE</strong></p>
          <br />
          <p>8.1 La participation au jeu-concours implique la connaissance et l'acceptation des caractéristiques et des limites de l'Internet, notamment en ce qui concerne les performances techniques, les temps de réponse pour consulter, interroger ou transférer des informations, les risques d'interruption, et plus généralement, les risques inhérents à toute connexion et transmission sur Internet, l'absence de protection de certaines données contre des détournements éventuels et les risques de contamination par des éventuels virus circulant sur le réseau. En conséquence, la Société Organisatrice ne saurait en aucune circonstance être tenue responsable, sans que cette liste soit limitative :</p>
          <br />
          <ul>
            <li>· de la transmission et/ou de la réception de toute donnée et/ou information sur Internet ;</li>
            <li>· de tout dysfonctionnement du réseau Internet empêchant le bon déroulement/fonctionnement du jeu-concours;</li>
            <li>· de défaillance de tout matériel de réception ou des lignes de communication ;</li>
            <li>· de perte de tout courrier papier ou électronique et, plus généralement, de perte de toute donnée ;</li>
            <li>· des problèmes d'acheminement ;</li>
            <li>· du fonctionnement de tout logiciel ;</li>
            <li>· des conséquences de tout virus, bogue informatique, anomalie, défaillance technique ;</li>
            <li>· de tout dommage causé à l'ordinateur d'un Participant ;</li>
            <li>· de toute défaillance technique, matérielle et logicielle de quelque nature, ayant empêché ou limité la possibilité de participer au jeu-concours ou ayant endommagé le système d'un Participant ;</li>
          </ul>
          <br />
          <p>8.2 La Société Organisatrice pourra annuler tout ou partie du jeu-concours s'il apparaît que des fraudes sont intervenues sous quelque forme que ce soit. Dans cette hypothèse, la Société Organisatrice aura le droit de ne pas attribuer le lot aux fraudeurs et/ou de poursuivre devant les juridictions compétentes les auteurs de ces fraudes. Elle ne saurait toutefois encourir aucune responsabilité d'aucune sorte vis-à-vis des participants du fait des fraudes éventuellement commises. Sera notamment considérée comme fraude le fait pour un participant d’utiliser un ou des prête-noms fictifs ou empruntés à une ou plusieurs tierces personnes, chaque participant devant participer au jeu-concours sous son propre et unique nom. Toute fraude entraîne l'élimination du participant.</p>
          <br />
          <p>8.3 La Société Organisatrice se réserve le droit pour quelque raison que ce soit, d'annuler, reporter, interrompre ou proroger le jeu-concours ou de modifier tout ou partie des modalités du présent règlement, dans le respect de celui-ci. Si, par suite d'un événement indépendant de sa volonté, elle était contrainte d'appliquer ce droit, sa responsabilité ne saurait être engagée.</p>
          <br />
          <p>8.4 La Société Organisatrice se réserve le droit d’exclure définitivement toute personne qui, par son comportement frauduleux, nuirait au bon déroulement du jeu-concours. </p>
          <br />
          <p><strong>ARTICLE 9 : LOI INFORMATIQUE ET LIBERTE ET Règlement général sur la protection des données</strong></p>
          <br />
          <p>9.1 Conformément à la loi Informatique et Liberté du 6 janvier 1978 modifiée, les participants disposent d’un droit d’accès, d’interrogation, de rectification, d’effacement des données personnelles les concernant, en écrivant au Centre Juno Beach Voie des Français Libres (14470) Courseulles-sur-mer (remboursement du timbre au tarif lent en vigueur).
          <br />
          <p>9.2 RGPD (Règlement Général sur la Protection des Données) :
          <br />
          <p>Les coordonnées des participants seront collectées et traitées informatiquement. Conformément au Règlement Général sur la Protection des Données, chaque participant dispose d’un droit d’accès, de rectification ou même de radiation des informations nominatives le concernant en écrivant à l’adresse précisée à l’article 9.1.</p>
          <p>Toutes les informations recueillies via ce jeu concours sont destinées à la gestion du jeu concours, aux statistiques, à satisfaire aux obligations légales ou réglementaires et à nous permettre également d’adresser des newsletters et des informations sur l’actualité du Centre Juno Beach et de l’office de tourisme Terres de Nacre par messagerie électronique.</p>
          <p>Ces informations sont exploitées par le Service communication du Centre Juno Beach et le pôle promotion-communication de l’office de tourisme Terres de Nacre. Vos données personnelles ne seront transmises à aucun autre organisme. Les données personnelles sont conservées pour une durée de 10 ans.</p>
          <p>Conformément au Règlement Général pour la Protection des Données personnelles (RGPD) vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous concernant.</p>
          <p>Vous pouvez à tout moment accéder à la modification ou la suppression de vos données en vous adressant à : Centre Juno Beach Voie des Français Libres (14470) Courseulles-sur-mer et à office de tourisme Terres de Nacre 72 Bis, Rue pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur).</p>
          <br />
          <p>Courseulles-sur-mer, le 10/04/2019</p>
          <br />
          <hr />
          <br />
          <h3>Règlement du concours « Gagnez un petit cadeau souvenir »</h3>
          <p><strong>ARTICLE 1 : ORGANISATION DU CONCOURS</strong></p>
          <br />
          <p>1.1 L’office de tourisme Terres de Nacre (ci-après la Société Organisatrice) situé 72 bis rue Pasteur 14750 Saint-Aubin-sur-mer organise un concours gratuit sans obligation d’achat du 08/04/2019 de 10h (heure française) au 11/11/2019 minuit (heure française), intitulé (ci-après le concours) : « Gagnez un petit cadeau souvenir » (ci-après le lot), selon les modalités décrites dans le présent règlement. Concours accessible uniquement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, pour les personnes mineurs à la date de début du concours.</p>
          <br />
          <p><strong>ARTICLE 2 : CONDITIONS D’ACCÈS AU CONCOURS</strong></p>
          <br />
          <p>2.1. La participation au concours est gratuite, ouverte à toute personne physique mineure capable âgée de 4 à 17 ans à la date de début du concours, disposant, à la date de début du concours et par l’intermédiaire de son représentant légal, d’un accès à l’application web et d’une adresse électronique personnelle (e-mail de son représentant légal) à laquelle elle pourra être contactée pour les besoins de la gestion du concours (ci-après le « Participant »). Il est impératif qu’un mineur qui souhaite participer au concours, participe par l’intermédiaire de son représentant légal. On entend par « représentant légal » : la ou les personnes titulaires de l’autorité parentale à l’égard du mineur (père et/ou mère, ou représentant légal). Toute participation d’une personne mineure est donc effectuée sous l’entière responsabilité du (des) titulaire(s) de l’autorité parentale. La Société Organisatrice se réserve le droit d’en demander la justification écrite à tout moment, a fortiori lors de l’attribution des lots. La société organisatrice serait contrainte de disqualifier tout mineur qui serait dans l’incapacité de fournir ce justificatif dans les délais qui lui seraient impartis. Dans ce cas, tout gain potentiellement obtenu durant le concours serait immédiatement annulé.</p>
          <br />
          <p>Sont exclus de toute participation à ce concours, les enfants des personnes et les personnes ayant directement ou indirectement participé à l’élaboration du concours, de même que les enfants des membres et les membres de leur famille (même nom, même adresse postale), les enfants des employés et les employés et administrateurs du Centre Juno Beach, les enfants des employés et les employés et administrateurs de l’office de tourisme Terres de Nacre, y compris les personnes travaillant pour le compte du Centre Juno Beach et pour le compte de l’office de tourisme Terres de Nacre.</p>
          <br />
          <p>Seules seront retenues les participations conformes à l’ensemble des stipulations du présent article. La Société Organisatrice se réserve le droit de procéder à toute vérification qu’elle jugera utile concernant notamment l’identité, l’âge et l’adresse de chaque Participant, en vue de faire respecter les stipulations du présent article. </p>
          <br />
          <p>2.3. La participation au concours implique l’acceptation expresse et sans réserve du présent règlement dans son intégralité, des règles de déontologie en vigueur, ainsi que des lois et règlements applicables aux concours en vigueur en France.</p>
          <br />
          <p>2.4 Une seule participation maximum par personne sera acceptée pendant toute la durée du concours.</p>
          <br />
          <p><strong>ARTICLE 3 : MODALITÉS DE PARTICIPATION ET DESIGNATION DU/DE LA GAGNANT(E)</strong></p>
          <br />
          <p>3.1 Ce concours se déroule exclusivement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, aux dates indiquées dans l’article 1.1.</p>
          <br />
          <p>3.2 La participation s’effectue de la manière suivante : Le Participant doit se rendre devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec le smartphone ou la tablette du représentant légal, il doit scanner le QR Code de l’application web ou accédez directement à l’application en tapant dans le navigateur « dansleurspas.com ». Le Participant doit répondre à une question par kiosque. Une fois qu’il a répondu à l’ensemble des 7 questions, il pourra répondre à la question finale : quel est l’âge moyen des soldats, présentés sur l’ensemble des kiosques de l’application ? S’il trouve la bonne réponse, il reçoit sur l’application un mot de passe. Le participant a jusqu’au 11 novembre 2019 pour donner ce mot de passe à l’accueil d’un des offices de tourisme de Courseulles-sur-mer, Bernières-sur-mer, Luc-sur-mer ou Saint-Aubin-sur-mer, uniquement durant les périodes et horaires d’ouverture disponibles sur www.terresdenacre.com/-nos-horaires-d-ouverture-101-.html. Si le mot de passe est bon, le participant pourra retirer son lot, dans la limite des stocks disponibles. Passée le 11 novembre, aucun lot ne pourra être accordé.</p>
          <br />
          <p>Seuls les Participants ayant correctement suivis ces étapes, et ayant répondu correctement aux questions posées, pourront prétendre recevoir le petit cadeau souvenir.</p>
          <br />
          <p>Si les Participants n’ont pas répondu à l’ensemble des questions, ni à la question finale, il ne pourra pas obtenir de lot. </p>
          <br />
          <p>3.3 Il n’est autorisé qu’une seule participation par personne - même nom, même prénom, même adresse électronique ou quel que soit l’adresse électronique pendant toute la période du concours.</p>
          <br />
          <p>3.4 Il n’y aura qu’un lot par participant, dans la limite des stocks disponibles. En aucun cas, le participant tentera d’obtenir plusieurs lots.</p>
          <br />
          <p>3.5 Le participant n’est autorisé à se rendre que dans l’un des 4 offices de tourisme cités en 3.2.</p>
          <br />
          <p>3.6 Toute coordonnée incomplète ou inexacte sera considérée comme nulle et ne permettra pas au/à la Gagnant(e) d’obtenir son petit cadeau souvenir. De manière générale, les participations au concours seront annulées si elles sont incomplètes, erronées, contrefaites ou réalisées de manière contrevenante au présent règlement.</p>
          <br />
          <p>3.7 Les données récupérées lors du concours ne seront en aucun cas stockées sur des serveurs ou utilisées par l’office de tourisme Terres de Nacre. Elles ne serviront qu’au concours dans le cadre de l’application Dans leurs Pas.</p>
          <br />
          <p><strong>ARTICLE 4 : DOTATION</strong></p>
          <br />
          <p>4.1 Le lot peut varier selon les stocks dont disposent les offices de tourisme : Le lot pourra être une casquette (valeur 2€) ou un T-shirt (valeur 4€) ou un frisbee (valeur 2€) ou des crayons de couleur (valeur 1€).</p>
          <br />
          <p>4.2 La Société Organisatrice se réserve le droit de remplacer le lot gagné par un lot de nature et de valeur équivalente.</p>
          <br />
          <p>4.3 Le/la conseiller(e) en séjour de l’office de tourisme se réserve le droit de procéder à la vérification des coordonnées et de l’âge du participant, avant remise de son lot.</p>
          <br />
          <p>4.4 La dotation ne pourra faire l’objet d’aucune contestation de la part du participant. Le lot attribué est incessible, intransmissible, ne peut être vendu ou être échangée contre sa valeur en espèces ou contre toute autre dotation.</p>
          <br />
          <p>4.5 Le participant est informé que la vente et l'échange du lot sont strictement interdits.</p>
          <br />
          <p>4.6 La Société Organisatrice ne saurait être tenu pour responsable de l’utilisation ou de la non utilisation, voire du négoce, du lot par le participant.</p>
          <br />
          <p>4.7 Si le lot n'a pu être retiré par le participant pour quelque raison que ce soit, indépendamment de la volonté de l'organisateur du concours, il restera définitivement la propriété de la Société Organisatrice.</p>
          <br />
          <p>4.8 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot.</p>
          <br />
          <p><strong>ARTICLE 5 : Modification du concours et du règlement</strong></p>
          <br />
          <p>5.1 La Société Organisatrice se réserve le droit d’annuler, de reporter, de prolonger ou d’écourter le concours, ainsi que de modifier tout ou partie des conditions d’accès et/ou des modalités de mise en œuvre du concours, si les circonstances l’y obligent sans avoir à justifier de cette décision et sans que sa responsabilité puisse être engagée en aucune manière de ce fait.</p>
          <br />
          <p><strong>ARTICLE 6 : Dépôt et consultation du règlement</strong></p>
          <br />
          <p>6.1 La participation au concours implique l’acceptation entière et sans réserve du présent règlement dans son intégralité. Le présent règlement est disponible gratuitement et dans son intégralité sur l’application web dansleurspas.com pendant toute la durée du concours.</p>
          <br />
          <p>6.2 Le présent règlement est déposé chez la SELARL ACTOJURIS, Huissier de Justice, à CAEN.</p>
          <br />
          <p><strong>ARTICLE 7 : LIMITATION DE RESPONSABILITÉ</strong></p>
          <br />
          <p>7.1 La participation au concours implique la connaissance et l'acceptation des caractéristiques et des limites de l'Internet, notamment en ce qui concerne les performances techniques, les temps de réponse pour consulter, interroger ou transférer des informations, les risques d'interruption, et plus généralement, les risques inhérents à toute connexion et transmission sur Internet, l'absence de protection de certaines données contre des détournements éventuels et les risques de contamination par des éventuels virus circulant sur le réseau. En conséquence, la Société Organisatrice ne saurait en aucune circonstance être tenue responsable, sans que cette liste soit limitative :
          <br />
          <ul>
            <li>· de la transmission et/ou de la réception de toute donnée et/ou information sur Internet ;</li>
            <li>· de tout dysfonctionnement du réseau Internet empêchant le bon déroulement/fonctionnement du concours ;</li>
            <li>· de défaillance de tout matériel de réception ou des lignes de communication ;</li>
            <li>· de perte de tout courrier papier ou électronique et, plus généralement, de perte de toute donnée ;</li>
            <li>· des problèmes d'acheminement ;</li>
            <li>· du fonctionnement de tout logiciel ;</li>
            <li>· des conséquences de tout virus, bogue informatique, anomalie, défaillance technique ;</li>
            <li>· de tout dommage causé à l'ordinateur d'un Participant ;</li>
            <li>· de toute défaillance technique, matérielle et logicielle de quelque nature, ayant empêché ou limité la possibilité de participer au concours ou ayant endommagé le système d'un Participant ;</li>
          </ul>
          <br />
          <p>7.2 La Société Organisatrice pourra annuler tout ou partie du concours s'il apparaît que des fraudes sont intervenues sous quelque forme que ce soit. Dans cette hypothèse, la Société Organisatrice aura le droit de ne pas attribuer le lot aux fraudeurs et/ou de poursuivre devant les juridictions compétentes les auteurs de ces fraudes. Elle ne saurait toutefois encourir aucune responsabilité d'aucune sorte vis-à-vis des participants du fait des fraudes éventuellement commises. Sera notamment considérée comme fraude le fait pour un participant d’utiliser un ou des prête-noms fictifs ou empruntés à une ou plusieurs tierces personnes, chaque participant devant participer au concours sous son propre et unique nom. Toute fraude entraîne l'élimination du participant.</p>
          <br />
          <p>7.3 La Société Organisatrice se réserve le droit pour quelque raison que ce soit, d'annuler, reporter, interrompre ou proroger le concours ou de modifier tout ou partie des modalités du présent règlement, dans le respect de celui-ci. Si, par suite d'un événement indépendant de sa volonté, elle était contrainte d'appliquer ce droit, sa responsabilité ne saurait être engagée.</p>
          <br />
          <p>7.4 La Société Organisatrice se réserve le droit d’exclure définitivement toute personne qui, par son comportement frauduleux, nuirait au bon déroulement du concours.</p>
          <br />
          <p><strong>ARTICLE 8 : RÈGLEMENT SUR LA LOI INFORMATIQUE ET LIBERTÉ ET Règlement Général sur la Protection des Données</strong></p>
          <br />
          <p>8.1 Conformément à la loi Informatique et Liberté du 6 janvier 1978 modifiée, les participants disposent d’un droit d’accès, d’interrogation, de rectification, d’effacement des données personnelles les concernant, en écrivant à l’Office de Tourisme Terres de Nacre, 72 Bis, Rue Pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur). </p>
          <br />
          <p>8.2 RGPD (Règlement Général sur la Protection des Données) :</p>
          <br />
          <p>Les coordonnées des participants seront collectées et traitées informatiquement. Conformément au Règlement Général sur la Protection des Données, chaque participant dispose d’un droit d’accès, de rectification ou même de radiation des informations nominatives le concernant en écrivant à l’adresse précisée à l’article 8.1.</p>
          <br />
          <p>Toutes les informations recueillies via ce jeu-concours sont destinées à la gestion du concours, aux statistiques, à satisfaire aux obligations légales ou réglementaires et à nous permettre également d’adresser des newsletters et des informations sur l’actualité du Centre Juno Beach et de l’office de tourisme Terres de Nacre par messagerie électronique.</p>
          <br />
          <p>Ces informations sont exploitées par le Service communication du Centre Juno Beach et le pôle promotion-communication de l’office de tourisme Terres de Nacre. Vos données personnelles ne seront transmises à aucun autre organisme. Les données personnelles sont conservées pour une durée de 10 ans.</p>
          <br />
          <p>Conformément au Règlement Général pour la Protection des Données personnelles (RGPD) vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous concernant.</p>
          <br />
          <p>Vous pouvez à tout moment accéder à la modification ou la suppression de vos données en vous adressant à : Centre Juno Beach Voie des Français Libres (14470) COURSEULLES-SUR-MER et à office de tourisme Terres de Nacre 72 Bis, Rue pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur).</p>
          <br />
          <p>Saint-Aubin-sur-mer, le 10/04/2019</p>
        `,
        acceptConditionLabel: 'J\'accepte les termes et les conditions du jeu-concours',
        imMajorLabel: 'Je certifie avoir plus de 18 ans, je joue pour tenter de gagner un voyage de rêve au Canada pour 2 personnes.',
        imNotMajorLabel: 'J\'ai moins de 18 ans, je joue pour tenter de gagner un petit cadeau souvenir à retirer à l’office de tourisme de Courseulles-sur-mer, de Saint-Aubin-sur-mer, de Luc-sur-mer ou de Bernières-sur-mer',
        send: 'Envoyer'
      },
      congrats: {
        title: 'Bravo !',
        text: `
          <p>Vous venez d’enregistrer l’ensemble des âges des soldats présentés sur les 7 kiosques.</p>
          <br />
          <p>Validez-vous le quizz ?</p>`
      },
      majorForm: {
        emailLabel: 'Votre email',
        lastnameLabel: 'Votre prénom',
        firstnameLabel: 'Votre nom',
        birthdateLabel: 'Votre date de naissance',
        addressLabel: 'Votre adresse',
        countryLabel: 'Votre département',
        stateLabel: 'Votre pays',
        cityLabel: 'Votre ville',
        zipCodeLabel: 'Votre code postal',
        phoneLabel: 'Votre numéro de téléphone',
        maleLabel: 'Homme',
        femaleLabel: 'Femme',
        sendError: 'Il semble que les coordonnées renseignées ne sont pas valides, veuillez les corriger et renvoyer le formulaire',
        send: 'Envoyer',
        resetLabel: 'Jouer avec un autre compte'
      }
    }
  },
  en: {
    pageName: 'Single point of interest',
    questionAvailableType: 'question | questions',
    questionAvailableLabel: 'available on this point of interest | available on this point of interest',
    reply: 'reply',
    survey: 'Survey',
    speechSynthesis : {
      start: 'Read this page',
      play: 'Resume',
      pause: 'Pause'
    },
    contest: {
      title: 'Dans leurs pas - contest',
      realm: {
        ok: 'Ok',
        noRealm: 'It seems that you\'re not near one of the history kiosk. Please head to one of the 7 town hosting a kiosk to enter the contest.'
      },
      welcome: {
        title: 'Enter the contest!',
        text: `
          <p><strong>Tentez de remporter un voyage de rêve au Canada pour deux personnes offert par le centre Juno Beach!</strong></p>
          <br />
          <p>Profitez d'un auto tour Canada Côte Est de Montréal à Québec en 8 nuits pour deux personnes majeures pour découvrir les villes emblématiques, les chutes du Niagara et la nature québécoise. (candidature réservée aux personnes majeures).</p>
          <p>Vous avez moins de 18 ans ? Tentez de gagner un petit cadeau souvenir de votre séjour en Normandie.</p>
          <br />
          <p>Comment participer ?</p>
          <br />
          <p>Pour participer, rendez-vous devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec votre smartphone, scannez le QR Code de l’application web ou accédez connectez-vous au réseau wifi du kiosque.</p>
          <p>Répondez simplement à la question située dans la rubrique "kiosque à histoire" de la ville concernée.</p>
          <p>Une fois les 7 réponses enregistrées par vos soins dans l’application, votre participation sera prise en compte</p>
          <p>Vous avez + de 18 ans : vous serez sélectionné(e) pour le tirage au sort pour gagner un voyage au Canada.</p>
          <p>Vous avez – de 18 ans : vous pourrez retirer votre petit cadeau à l’accueil de l’un des offices de tourisme.</p> `,
        mailLabel: 'Your email address',
        mailPlaceholder: 'me@me.com',
        sendMailLabel: 'Confirm my mail'
      },
      conditions: {
        title: 'Privacy policy',
        text: `
          <h3>Règlement du jeu-concours « Gagnez un voyage de rêve au Canada, Côte Est »</h3>
          <p><strong>ARTICLE 1 : ORGANISATION DU JEU-CONCOURS</strong></p>
          <br />
          <p>1.1 Le Centre Juno Beach (ci-après la Société Organisatrice) situé Voie des Français Libres 14470 Courseulles-sur-mer organise un jeu-concours gratuit sans obligation d’achat du 08/04/2019 de 10h (heure française) au 11/11/2019 (minuit, heure française), intitulé :</p>
          <br />
          <p>Jeu-concours « Gagnez un voyage de rêve au Canada, Côte Est » (ci-après le jeu-concours), selon les modalités décrites dans le présent règlement.</p>
          <p>Jeu-concours accessible uniquement sur l’application web dansleurspas.com dans le cadre du circuit-expo plein-air Dans leurs Pas.</p>
          <br />
          <p><strong>ARTICLE 2 : CONDITIONS D’ACCES AU JEU-CONCOURS</strong></p>
          <br />
          <p>2.1. La participation au jeu-concours est gratuite, ouverte à toute personne physique majeure capable âgée d’au moins 18 ans, disposant, à la date de début du jeu-concours, d’un accès à l’application web et d’une adresse électronique personnelle (e-mail) à laquelle elle pourra être contactée pour les besoins de la gestion du jeu-concours (ci-après le « Participant »).</p>
          <br />
          <p>Sont exclues de toute participation à ce jeu-concours, les personnes ayant directement ou indirectement participé à l’élaboration du jeu-concours, de même que les membres de leur famille (même nom, même adresse postale), les employés et administrateurs du Centre Juno Beach, les employés et administrateurs de l’office de tourisme Terres de Nacre, y compris les personnes travaillant pour le compte du Centre Juno Beach et pour le compte de l’office de tourisme Terres de Nacre.</p>
          <p>Seules seront retenues les participations conformes à l’ensemble des stipulations du présent article. La Société Organisatrice se réserve le droit de procéder à toute vérification qu’elle jugera utile concernant notamment l’identité, l’âge et l’adresse de chaque Participant, en vue de faire respecter les stipulations du présent article. A cet égard, toute indication portée dans le formulaire de participation de l’application web, qui serait incomplète, erronée, falsifiée, ne permettrait pas d’identifier un Participant ou ses coordonnées, ou contreviendrait à l’une quelconque des stipulations du présent règlement entraînera l’annulation de sa participation.</p>
          <br />
          <p>2.3. La participation au jeu-concours implique l’acceptation expresse et sans réserve du présent règlement dans son intégralité, des règles de déontologie en vigueur, ainsi que des lois et règlements applicables aux jeux-concours en vigueur en France.</p>
          <br />
          <p>2.4 Une seule participation maximum par personne sera acceptée pendant toute la durée du jeu-concours.</p>
          <br />
          <p><strong>ARTICLE 3 : MODALITÉS DE PARTICIPATION</strong></p>
          <br />
          <p>3.1 Ce jeu-concours se déroule exclusivement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, aux dates indiquées dans l’article 1.1.</p>
          <br />
          <p>3.2 La participation s’effectue de la manière suivante : Le Participant doit se rendre devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec son smartphone ou sa tablette, il doit scanner le QR Code de l’application web ou accédez directement à l’application en tapant dans le navigateur « dansleurspas.com ». Le Participant doit répondre à une question par kiosque. Une fois qu’il a répondu à l’ensemble des 7 questions, il pourra répondre à la question finale : quel est l’âge moyen des soldats, présentés sur l’ensemble des kiosques de l’application ? Une fois la réponse finale donnée, le Participant pourra valider sa participation au tirage au sort pour tenter de gagner un voyage au Canada, Côte Est.</p>
          <br />
          <p>Seuls les Participants ayant correctement suivis ces étapes, et ayant répondus correctement aux questions posées, verront leur candidature validée.</p>
          <p>Les Participants qui ne répondent pas à l’ensemble des 7 questions ne peuvent prétendre être sélectionnés pour le tirage au sort.</p>
          <br />
          <p>3.3 Il n’est autorisé qu’une seule participation par personne - même nom, même prénom, même adresse électronique ou quel que soit l’adresse électronique pendant toute la période du jeu-concours.</p>
          <br />
          <p><strong>ARTICLE 4 – DESIGNATION DU/DE LA GAGNANT(E)</strong></p>
          <br />
          <p>4.1 Un tirage au sort sera effectué le samedi 16 novembre 2019 parmi toutes les participations complètes (telle que décrite à l’article 3) pour désigner le gagnant du jeu-concours.</p>
          <br />
          <p>4.2 Ce tirage au sort sera effectué par un algorithme informatique, réalisé par la société Twelve Solutions 149 Rue de la Délivrande, 14000 Caen. La participation tirée au sort désignera le/la Participant(e) gagnant(e) du jeu-concours (ci-après le/la « Gagnant(e) »).</p>
          <br />
          <p>4.3 Le/la Gagnant(e) sera averti par voie électronique (e-mail).</p>
          <br />
          <p>4.4 Le/la Gagnant(e) dispose d’un délai de 7 jours pour se faire connaître auprès de la Société Organisatrice. Le/la Gagnant(e) devra alors confirmer l’acceptation de son gain en répondant à l'adresse e-mail expéditrice et en précisant ses coordonnées : nom, prénom, adresse postale. Le/la Gagnant(e) ne donnant pas de réponse dans un délai de 7 jours à compter de l’envoi d’avis de son gain ne pourra pas en bénéficier. Le lot sera alors attribué à un nouveau contact tiré au sort et ainsi de suite jusqu’à ce qu’un(e) Gagnant(e) se fasse connaître auprès de la Société Organisatrice.</p>
          <br />
          <p>4.5 Toute coordonnée incomplète ou inexacte sera considérée comme nulle et ne permettra pas au/à la Gagnant(e) désigné par tirage au sort d’obtenir sa dotation. De manière générale, les participations au jeu-concours seront annulées si elles sont incomplètes, erronées, contrefaites ou réalisées de manière contrevenante au présent règlement.</p>
          <br />
          <p>4.6 Les Participants qui n’auront pas été tirés au sort n’en seront pas informés par e-mail ni par quelque autre moyen que ce soit. Aucun lot ne sera accordé aux Participants qui n’auront pas été tirés au sort.</p>
          <br />
          <p><strong>ARTICLE 5 – DOTATION</strong></p>
          <br />
          <p>5.1 Le voyage à gagner est financé par le Centre Juno Beach et est organisé par l’agence de voyage PLANET_REVE Village des Sports Décathlon Les Carandes 14120 Mondeville.</p>
          <br />
          <p>5.2 Le/la Gagnant(e) remportera le lot suivant : Un auto tour Canada Côte Est de Montréal à Québec en 8 nuits pour deux personnes majeures pour découvrir les villes emblématiques, les chutes du Niagara et la nature québécoise.</p>
          <br />
          <p>Ce circuit de 8 jours concocté par PLANET_REVE se déroulera hors vacances et ponts entre le 02 avril et le 10 juin 2020.</p>
          <p>La valeur totale du lot est de 2 200€/personne, soit 4 400€ pour deux personnes.</p>
          <p>Ces prix comprennent :</p>
          <ul>
            <li>· Les vols Paris – Montréal, Québec – Paris,</li>
            <li>· L’hébergement en hôtel en chambre standard avec petit déjeuner,</li>
            <li>· Les prestations offertes dans le programme, soumises à conditions,</li>
            <li>· La location de voiture livrée à l’aéroport de Montréal le jour 1 et restituée à l'aéroport de Québec le jour 8 pour une durée de 8x24h, incluant le kilométrage illimité, les frais d'abandon, GPS inclus, taxes locales, 1 conducteur additionnel inclus, 1 plein d’essence offert, surcharge aéroport, réduction totale de la responsabilité financière si accident ou vol du véhicule loué (LDW), assurance tierce personne jusqu’à 1 million de dollars (TPL = Third Party Liability),</li>
            <li>· L’assurance rapatriement de base.</li>
          </ul>
          <p>Ces prix ne comprennent pas :</p>
          <ul>
            <li>· Le transport jusqu’à l’aéroport de Paris,</li>
            <li>· Les repas en dehors des petits déjeuners,</li>
            <li>· Les boissons et les dépenses personnelles,</li>
            <li>· Les visites, entrées et soirées suggérées dans le programme sauf les prestations offertes,</li>
            <li>· Obtention et frais de l'autorisation de voyage électronique (AVE) pour le Canada (9 Dollars à ce jour),</li>
            <li>· Pour la location de voiture : le carburant ; les équipements complémentaires à réserver et à régler sur place tels que : siège bébé ou rehausseur enfant (13 CAD/jour) ; les assurances complémentaires à réserver et à régler sur place telles que : conducteur additionnel au-delà du 1er conducteur additionnel inclus (10 à 25 CAD/jour), jeune conducteur moins de 25 ans (de 25 à 35 CAD/jour), la « surcharge pneus » au Québec : de 3.24 CAD à 5,25 CAD/jour, assurance du conducteur (PAI) : 5 CAD/ jour, assurance protection des effets personnels (PEP), : 3 CAD/jour, l’option « assurance routière étendue » RSN (Roadside Safety Network) : 9 CAD/jour ; frais de parking/garage (hôtels, villes et sites...), frais de livraison du véhicule en dehors des horaires d'ouverture de l'agence du loueur, péages d'autoroutes, ponts..., contraventions éventuelles (+frais administratifs de traitement env. 50 €)</li>
            <li>· L’assurance multirisque annulation à 3%,</li>
            <li>· Les variations possibles de tarif liés à la possible hausse du prix du carburant, à la possible hausse des tarifs d’hébergement et la possible hausse des tarifs aériens.</li>
          </ul>
          <br />
          <p>5.3 Le Centre Juno Beach et PLANET_REVE se réservent le droit de procéder à la vérification de l’âge du/de la gagnant(e) et de la personne qui l’accompagne avant remise de son lot. </p>
          <br />
          <p>5.4 La dotation ne pourra faire l’objet d’aucune contestation de la part du/dela Gagnant(e). Le lot attribué est incessible, intransmissible, ne peut être vendu ou être échangée contre sa valeur en espèces ou contre toute autre dotation. </p>
          <br />
          <p>5.5 Le Centre Juno Beach ne saurait être tenu pour responsable de l’utilisation ou de la non utilisation, voire du négoce, du lot par le/la Gagnant(e). </p>
          <br />
          <p>5.6 En cas de force majeure, PLANET_REVE se réserve le droit de remplacer le lot gagné par un lot de nature et de valeur équivalente. </p>
          <br />
          <p>5.7 Le participant est informé que la vente et l'échange du lot sont strictement interdits.</p>
          <br />
          <p>5.8 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot.</p>
          <br />
          <p>5.9 La valeur indiquée pour le lot correspond au prix TTC couramment pratiqué ou estimé à la date de rédaction du règlement, elle est donnée à titre de simple indication et est susceptible de variation.</p>
          <br />
          <p>5.10 PLANET_REVE sera informé du nom du/de la gagnant(e) par la Société Organisatrice. Le/la Gagnant(e) devra prendre rendez-vous avec un agent de voyage de PLANET_REVE dans les 30 jours qui suivent la date du tirage au sort. </p>
          <br />
          <p>5.11 Si le lot n'a pu être retiré par Le/la gagnant(e) pour quelque raison que ce soit, indépendamment de la volonté de l'organisateur du jeu-concours, restera définitivement la propriété de la Société Organisatrice.</p>
          <br />
          <p>5.12 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot. </p>
          <br />
          <p><strong>ARTICLE 6 : Modification du Jeu-CONCOURS et du règlement </strong></p>
          <br />
          <p>6.1 La Société Organisatrice se réserve le droit d’annuler, de reporter, de prolonger ou d’écourter le jeu-concours, ainsi que de modifier tout ou partie des conditions d’accès et/ou des modalités de mise en œuvre du jeu-concours, si les circonstances l’y obligent sans avoir à justifier de cette décision et sans que sa responsabilité puisse être engagée en aucune manière de ce fait. </p>
          <p><strong>ARTICLE 7 : Dépôt et consultation du règlement </strong></p>
          <p>7.1 La participation au jeu-concours implique l’acceptation entière et sans réserve du présent règlement dans son intégralité. Le présent règlement est disponible gratuitement et dans son intégralité sur l’application web dansleurspas.com pendant toute la durée du jeu-concours.</p>
          <br />
          <p>7.2 Le présent règlement est déposé chez la SELARL ACTOJURIS, Huissier de Justice, à CAEN.</p>
          <br />
          <p><strong>ARTICLE 8 : LIMITATION DE RESPONSABILITE</strong></p>
          <br />
          <p>8.1 La participation au jeu-concours implique la connaissance et l'acceptation des caractéristiques et des limites de l'Internet, notamment en ce qui concerne les performances techniques, les temps de réponse pour consulter, interroger ou transférer des informations, les risques d'interruption, et plus généralement, les risques inhérents à toute connexion et transmission sur Internet, l'absence de protection de certaines données contre des détournements éventuels et les risques de contamination par des éventuels virus circulant sur le réseau. En conséquence, la Société Organisatrice ne saurait en aucune circonstance être tenue responsable, sans que cette liste soit limitative :</p>
          <br />
          <ul>
            <li>· de la transmission et/ou de la réception de toute donnée et/ou information sur Internet ;</li>
            <li>· de tout dysfonctionnement du réseau Internet empêchant le bon déroulement/fonctionnement du jeu-concours;</li>
            <li>· de défaillance de tout matériel de réception ou des lignes de communication ;</li>
            <li>· de perte de tout courrier papier ou électronique et, plus généralement, de perte de toute donnée ;</li>
            <li>· des problèmes d'acheminement ;</li>
            <li>· du fonctionnement de tout logiciel ;</li>
            <li>· des conséquences de tout virus, bogue informatique, anomalie, défaillance technique ;</li>
            <li>· de tout dommage causé à l'ordinateur d'un Participant ;</li>
            <li>· de toute défaillance technique, matérielle et logicielle de quelque nature, ayant empêché ou limité la possibilité de participer au jeu-concours ou ayant endommagé le système d'un Participant ;</li>
          </ul>
          <br />
          <p>8.2 La Société Organisatrice pourra annuler tout ou partie du jeu-concours s'il apparaît que des fraudes sont intervenues sous quelque forme que ce soit. Dans cette hypothèse, la Société Organisatrice aura le droit de ne pas attribuer le lot aux fraudeurs et/ou de poursuivre devant les juridictions compétentes les auteurs de ces fraudes. Elle ne saurait toutefois encourir aucune responsabilité d'aucune sorte vis-à-vis des participants du fait des fraudes éventuellement commises. Sera notamment considérée comme fraude le fait pour un participant d’utiliser un ou des prête-noms fictifs ou empruntés à une ou plusieurs tierces personnes, chaque participant devant participer au jeu-concours sous son propre et unique nom. Toute fraude entraîne l'élimination du participant.</p>
          <br />
          <p>8.3 La Société Organisatrice se réserve le droit pour quelque raison que ce soit, d'annuler, reporter, interrompre ou proroger le jeu-concours ou de modifier tout ou partie des modalités du présent règlement, dans le respect de celui-ci. Si, par suite d'un événement indépendant de sa volonté, elle était contrainte d'appliquer ce droit, sa responsabilité ne saurait être engagée.</p>
          <br />
          <p>8.4 La Société Organisatrice se réserve le droit d’exclure définitivement toute personne qui, par son comportement frauduleux, nuirait au bon déroulement du jeu-concours. </p>
          <br />
          <p><strong>ARTICLE 9 : LOI INFORMATIQUE ET LIBERTE ET Règlement général sur la protection des données</strong></p>
          <br />
          <p>9.1 Conformément à la loi Informatique et Liberté du 6 janvier 1978 modifiée, les participants disposent d’un droit d’accès, d’interrogation, de rectification, d’effacement des données personnelles les concernant, en écrivant au Centre Juno Beach Voie des Français Libres (14470) Courseulles-sur-mer (remboursement du timbre au tarif lent en vigueur).
          <br />
          <p>9.2 RGPD (Règlement Général sur la Protection des Données) :
          <br />
          <p>Les coordonnées des participants seront collectées et traitées informatiquement. Conformément au Règlement Général sur la Protection des Données, chaque participant dispose d’un droit d’accès, de rectification ou même de radiation des informations nominatives le concernant en écrivant à l’adresse précisée à l’article 9.1.</p>
          <p>Toutes les informations recueillies via ce jeu concours sont destinées à la gestion du jeu concours, aux statistiques, à satisfaire aux obligations légales ou réglementaires et à nous permettre également d’adresser des newsletters et des informations sur l’actualité du Centre Juno Beach et de l’office de tourisme Terres de Nacre par messagerie électronique.</p>
          <p>Ces informations sont exploitées par le Service communication du Centre Juno Beach et le pôle promotion-communication de l’office de tourisme Terres de Nacre. Vos données personnelles ne seront transmises à aucun autre organisme. Les données personnelles sont conservées pour une durée de 10 ans.</p>
          <p>Conformément au Règlement Général pour la Protection des Données personnelles (RGPD) vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous concernant.</p>
          <p>Vous pouvez à tout moment accéder à la modification ou la suppression de vos données en vous adressant à : Centre Juno Beach Voie des Français Libres (14470) Courseulles-sur-mer et à office de tourisme Terres de Nacre 72 Bis, Rue pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur).</p>
          <br />
          <p>Courseulles-sur-mer, le 10/04/2019</p>
          <br />
          <hr />
          <br />
          <h3>Règlement du concours « Gagnez un petit cadeau souvenir »</h3>
          <p><strong>ARTICLE 1 : ORGANISATION DU CONCOURS</strong></p>
          <br />
          <p>1.1 L’office de tourisme Terres de Nacre (ci-après la Société Organisatrice) situé 72 bis rue Pasteur 14750 Saint-Aubin-sur-mer organise un concours gratuit sans obligation d’achat du 08/04/2019 de 10h (heure française) au 11/11/2019 minuit (heure française), intitulé (ci-après le concours) : « Gagnez un petit cadeau souvenir » (ci-après le lot), selon les modalités décrites dans le présent règlement. Concours accessible uniquement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, pour les personnes mineurs à la date de début du concours.</p>
          <br />
          <p><strong>ARTICLE 2 : CONDITIONS D’ACCÈS AU CONCOURS</strong></p>
          <br />
          <p>2.1. La participation au concours est gratuite, ouverte à toute personne physique mineure capable âgée de 4 à 17 ans à la date de début du concours, disposant, à la date de début du concours et par l’intermédiaire de son représentant légal, d’un accès à l’application web et d’une adresse électronique personnelle (e-mail de son représentant légal) à laquelle elle pourra être contactée pour les besoins de la gestion du concours (ci-après le « Participant »). Il est impératif qu’un mineur qui souhaite participer au concours, participe par l’intermédiaire de son représentant légal. On entend par « représentant légal » : la ou les personnes titulaires de l’autorité parentale à l’égard du mineur (père et/ou mère, ou représentant légal). Toute participation d’une personne mineure est donc effectuée sous l’entière responsabilité du (des) titulaire(s) de l’autorité parentale. La Société Organisatrice se réserve le droit d’en demander la justification écrite à tout moment, a fortiori lors de l’attribution des lots. La société organisatrice serait contrainte de disqualifier tout mineur qui serait dans l’incapacité de fournir ce justificatif dans les délais qui lui seraient impartis. Dans ce cas, tout gain potentiellement obtenu durant le concours serait immédiatement annulé.</p>
          <br />
          <p>Sont exclus de toute participation à ce concours, les enfants des personnes et les personnes ayant directement ou indirectement participé à l’élaboration du concours, de même que les enfants des membres et les membres de leur famille (même nom, même adresse postale), les enfants des employés et les employés et administrateurs du Centre Juno Beach, les enfants des employés et les employés et administrateurs de l’office de tourisme Terres de Nacre, y compris les personnes travaillant pour le compte du Centre Juno Beach et pour le compte de l’office de tourisme Terres de Nacre.</p>
          <br />
          <p>Seules seront retenues les participations conformes à l’ensemble des stipulations du présent article. La Société Organisatrice se réserve le droit de procéder à toute vérification qu’elle jugera utile concernant notamment l’identité, l’âge et l’adresse de chaque Participant, en vue de faire respecter les stipulations du présent article. </p>
          <br />
          <p>2.3. La participation au concours implique l’acceptation expresse et sans réserve du présent règlement dans son intégralité, des règles de déontologie en vigueur, ainsi que des lois et règlements applicables aux concours en vigueur en France.</p>
          <br />
          <p>2.4 Une seule participation maximum par personne sera acceptée pendant toute la durée du concours.</p>
          <br />
          <p><strong>ARTICLE 3 : MODALITÉS DE PARTICIPATION ET DESIGNATION DU/DE LA GAGNANT(E)</strong></p>
          <br />
          <p>3.1 Ce concours se déroule exclusivement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, aux dates indiquées dans l’article 1.1.</p>
          <br />
          <p>3.2 La participation s’effectue de la manière suivante : Le Participant doit se rendre devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec le smartphone ou la tablette du représentant légal, il doit scanner le QR Code de l’application web ou accédez directement à l’application en tapant dans le navigateur « dansleurspas.com ». Le Participant doit répondre à une question par kiosque. Une fois qu’il a répondu à l’ensemble des 7 questions, il pourra répondre à la question finale : quel est l’âge moyen des soldats, présentés sur l’ensemble des kiosques de l’application ? S’il trouve la bonne réponse, il reçoit sur l’application un mot de passe. Le participant a jusqu’au 11 novembre 2019 pour donner ce mot de passe à l’accueil d’un des offices de tourisme de Courseulles-sur-mer, Bernières-sur-mer, Luc-sur-mer ou Saint-Aubin-sur-mer, uniquement durant les périodes et horaires d’ouverture disponibles sur www.terresdenacre.com/-nos-horaires-d-ouverture-101-.html. Si le mot de passe est bon, le participant pourra retirer son lot, dans la limite des stocks disponibles. Passée le 11 novembre, aucun lot ne pourra être accordé.</p>
          <br />
          <p>Seuls les Participants ayant correctement suivis ces étapes, et ayant répondu correctement aux questions posées, pourront prétendre recevoir le petit cadeau souvenir.</p>
          <br />
          <p>Si les Participants n’ont pas répondu à l’ensemble des questions, ni à la question finale, il ne pourra pas obtenir de lot. </p>
          <br />
          <p>3.3 Il n’est autorisé qu’une seule participation par personne - même nom, même prénom, même adresse électronique ou quel que soit l’adresse électronique pendant toute la période du concours.</p>
          <br />
          <p>3.4 Il n’y aura qu’un lot par participant, dans la limite des stocks disponibles. En aucun cas, le participant tentera d’obtenir plusieurs lots.</p>
          <br />
          <p>3.5 Le participant n’est autorisé à se rendre que dans l’un des 4 offices de tourisme cités en 3.2.</p>
          <br />
          <p>3.6 Toute coordonnée incomplète ou inexacte sera considérée comme nulle et ne permettra pas au/à la Gagnant(e) d’obtenir son petit cadeau souvenir. De manière générale, les participations au concours seront annulées si elles sont incomplètes, erronées, contrefaites ou réalisées de manière contrevenante au présent règlement.</p>
          <br />
          <p>3.7 Les données récupérées lors du concours ne seront en aucun cas stockées sur des serveurs ou utilisées par l’office de tourisme Terres de Nacre. Elles ne serviront qu’au concours dans le cadre de l’application Dans leurs Pas.</p>
          <br />
          <p><strong>ARTICLE 4 : DOTATION</strong></p>
          <br />
          <p>4.1 Le lot peut varier selon les stocks dont disposent les offices de tourisme : Le lot pourra être une casquette (valeur 2€) ou un T-shirt (valeur 4€) ou un frisbee (valeur 2€) ou des crayons de couleur (valeur 1€).</p>
          <br />
          <p>4.2 La Société Organisatrice se réserve le droit de remplacer le lot gagné par un lot de nature et de valeur équivalente.</p>
          <br />
          <p>4.3 Le/la conseiller(e) en séjour de l’office de tourisme se réserve le droit de procéder à la vérification des coordonnées et de l’âge du participant, avant remise de son lot.</p>
          <br />
          <p>4.4 La dotation ne pourra faire l’objet d’aucune contestation de la part du participant. Le lot attribué est incessible, intransmissible, ne peut être vendu ou être échangée contre sa valeur en espèces ou contre toute autre dotation.</p>
          <br />
          <p>4.5 Le participant est informé que la vente et l'échange du lot sont strictement interdits.</p>
          <br />
          <p>4.6 La Société Organisatrice ne saurait être tenu pour responsable de l’utilisation ou de la non utilisation, voire du négoce, du lot par le participant.</p>
          <br />
          <p>4.7 Si le lot n'a pu être retiré par le participant pour quelque raison que ce soit, indépendamment de la volonté de l'organisateur du concours, il restera définitivement la propriété de la Société Organisatrice.</p>
          <br />
          <p>4.8 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot.</p>
          <br />
          <p><strong>ARTICLE 5 : Modification du concours et du règlement</strong></p>
          <br />
          <p>5.1 La Société Organisatrice se réserve le droit d’annuler, de reporter, de prolonger ou d’écourter le concours, ainsi que de modifier tout ou partie des conditions d’accès et/ou des modalités de mise en œuvre du concours, si les circonstances l’y obligent sans avoir à justifier de cette décision et sans que sa responsabilité puisse être engagée en aucune manière de ce fait.</p>
          <br />
          <p><strong>ARTICLE 6 : Dépôt et consultation du règlement</strong></p>
          <br />
          <p>6.1 La participation au concours implique l’acceptation entière et sans réserve du présent règlement dans son intégralité. Le présent règlement est disponible gratuitement et dans son intégralité sur l’application web dansleurspas.com pendant toute la durée du concours.</p>
          <br />
          <p>6.2 Le présent règlement est déposé chez la SELARL ACTOJURIS, Huissier de Justice, à CAEN.</p>
          <br />
          <p><strong>ARTICLE 7 : LIMITATION DE RESPONSABILITÉ</strong></p>
          <br />
          <p>7.1 La participation au concours implique la connaissance et l'acceptation des caractéristiques et des limites de l'Internet, notamment en ce qui concerne les performances techniques, les temps de réponse pour consulter, interroger ou transférer des informations, les risques d'interruption, et plus généralement, les risques inhérents à toute connexion et transmission sur Internet, l'absence de protection de certaines données contre des détournements éventuels et les risques de contamination par des éventuels virus circulant sur le réseau. En conséquence, la Société Organisatrice ne saurait en aucune circonstance être tenue responsable, sans que cette liste soit limitative :
          <br />
          <ul>
            <li>· de la transmission et/ou de la réception de toute donnée et/ou information sur Internet ;</li>
            <li>· de tout dysfonctionnement du réseau Internet empêchant le bon déroulement/fonctionnement du concours ;</li>
            <li>· de défaillance de tout matériel de réception ou des lignes de communication ;</li>
            <li>· de perte de tout courrier papier ou électronique et, plus généralement, de perte de toute donnée ;</li>
            <li>· des problèmes d'acheminement ;</li>
            <li>· du fonctionnement de tout logiciel ;</li>
            <li>· des conséquences de tout virus, bogue informatique, anomalie, défaillance technique ;</li>
            <li>· de tout dommage causé à l'ordinateur d'un Participant ;</li>
            <li>· de toute défaillance technique, matérielle et logicielle de quelque nature, ayant empêché ou limité la possibilité de participer au concours ou ayant endommagé le système d'un Participant ;</li>
          </ul>
          <br />
          <p>7.2 La Société Organisatrice pourra annuler tout ou partie du concours s'il apparaît que des fraudes sont intervenues sous quelque forme que ce soit. Dans cette hypothèse, la Société Organisatrice aura le droit de ne pas attribuer le lot aux fraudeurs et/ou de poursuivre devant les juridictions compétentes les auteurs de ces fraudes. Elle ne saurait toutefois encourir aucune responsabilité d'aucune sorte vis-à-vis des participants du fait des fraudes éventuellement commises. Sera notamment considérée comme fraude le fait pour un participant d’utiliser un ou des prête-noms fictifs ou empruntés à une ou plusieurs tierces personnes, chaque participant devant participer au concours sous son propre et unique nom. Toute fraude entraîne l'élimination du participant.</p>
          <br />
          <p>7.3 La Société Organisatrice se réserve le droit pour quelque raison que ce soit, d'annuler, reporter, interrompre ou proroger le concours ou de modifier tout ou partie des modalités du présent règlement, dans le respect de celui-ci. Si, par suite d'un événement indépendant de sa volonté, elle était contrainte d'appliquer ce droit, sa responsabilité ne saurait être engagée.</p>
          <br />
          <p>7.4 La Société Organisatrice se réserve le droit d’exclure définitivement toute personne qui, par son comportement frauduleux, nuirait au bon déroulement du concours.</p>
          <br />
          <p><strong>ARTICLE 8 : RÈGLEMENT SUR LA LOI INFORMATIQUE ET LIBERTÉ ET Règlement Général sur la Protection des Données</strong></p>
          <br />
          <p>8.1 Conformément à la loi Informatique et Liberté du 6 janvier 1978 modifiée, les participants disposent d’un droit d’accès, d’interrogation, de rectification, d’effacement des données personnelles les concernant, en écrivant à l’Office de Tourisme Terres de Nacre, 72 Bis, Rue Pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur). </p>
          <br />
          <p>8.2 RGPD (Règlement Général sur la Protection des Données) :</p>
          <br />
          <p>Les coordonnées des participants seront collectées et traitées informatiquement. Conformément au Règlement Général sur la Protection des Données, chaque participant dispose d’un droit d’accès, de rectification ou même de radiation des informations nominatives le concernant en écrivant à l’adresse précisée à l’article 8.1.</p>
          <br />
          <p>Toutes les informations recueillies via ce jeu-concours sont destinées à la gestion du concours, aux statistiques, à satisfaire aux obligations légales ou réglementaires et à nous permettre également d’adresser des newsletters et des informations sur l’actualité du Centre Juno Beach et de l’office de tourisme Terres de Nacre par messagerie électronique.</p>
          <br />
          <p>Ces informations sont exploitées par le Service communication du Centre Juno Beach et le pôle promotion-communication de l’office de tourisme Terres de Nacre. Vos données personnelles ne seront transmises à aucun autre organisme. Les données personnelles sont conservées pour une durée de 10 ans.</p>
          <br />
          <p>Conformément au Règlement Général pour la Protection des Données personnelles (RGPD) vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous concernant.</p>
          <br />
          <p>Vous pouvez à tout moment accéder à la modification ou la suppression de vos données en vous adressant à : Centre Juno Beach Voie des Français Libres (14470) COURSEULLES-SUR-MER et à office de tourisme Terres de Nacre 72 Bis, Rue pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur).</p>
          <br />
          <p>Saint-Aubin-sur-mer, le 10/04/2019</p>
        `,
        acceptConditionLabel: 'I have read and I accept the current contest rules and regulations.',
        imMajorLabel: 'I certify being 18 and above and having read the rules of this contest.',
        imNotMajorLabel: 'Or I certify under 18 and above and having read the rules of this contest.',
        send: 'Send'
      },
      congrats: {
        title: 'Congratulations!',
        text: ' '
      },
      majorForm: {
        emailLabel: 'Your email',
        lastnameLabel: 'Your lastname',
        firstnameLabel: 'Your firstname',
        birthdateLabel: 'Your birthdate',
        addressLabel: 'Your address',
        countryLabel: 'Your country',
        stateLabel: 'Your state',
        cityLabel: 'Your city',
        zipCodeLabel: 'Your zip code',
        phoneLabel: 'Your phone',
        maleLabel: 'Male',
        femaleLabel: 'Female',
        sendError: 'It appears that the details are not valid, please correct them and return the form',
        send: 'Send',
        resetLabel: 'Play with another account'
      }
    }

  },
  us: {
    pageName: 'Single point of interest',
    questionAvailableType: 'question | questions',
    questionAvailableLabel: 'available on this point of interest | available on this point of interest',
    reply: 'reply',
    speechSynthesis : {
      start: 'Read this page',
      play: 'Resume',
      pause: 'Pause'
    },
  },
  da: {
    pageName: 'Single point of interest',
    questionAvailableType: 'question | questions',
    questionAvailableLabel: 'available on this point of interest | available on this point of interest',
    reply: 'reply',
    speechSynthesis : {
      start: 'Read this page',
      play: 'Resume',
      pause: 'Pause'
    },
  },
  es: {
    pageName: 'Single point of interest',
    questionAvailableType: 'question | questions',
    questionAvailableLabel: 'available on this point of interest | available on this point of interest',
    reply: 'reply',
    survey: 'Encuesta',
    speechSynthesis : {
      start: 'Leer esta página',
      play: 'Resume',
      pause: 'Pause'
    },
  },
  nl: {
    pageName: 'Single point of interest',
    survey: 'Onderzoek',
    questionAvailableType: 'question | questions',
    questionAvailableLabel: 'available on this point of interest | available on this point of interest',
    reply: 'reply',
    speechSynthesis : {
      start: 'Read this page',
      play: 'Resume',
      pause: 'Pause'
    },
    contest: {
      title: 'Dans leurs pas - contest',
      realm: {
        ok: 'Ok',
        noRealm: 'It seems that you\'re not near one of the history kiosk. Please head to one of the 7 town hosting a kiosk to enter the contest.'
      },
      welcome: {
        title: 'Enter the contest!',
        text: `
          <p><strong>Tentez de remporter un voyage de rêve au Canada pour deux personnes offert par le centre Juno Beach!</strong></p>
          <br />
          <p>Profitez d'un auto tour Canada Côte Est de Montréal à Québec en 8 nuits pour deux personnes majeures pour découvrir les villes emblématiques, les chutes du Niagara et la nature québécoise. (candidature réservée aux personnes majeures).</p>
          <p>Vous avez moins de 18 ans ? Tentez de gagner un petit cadeau souvenir de votre séjour en Normandie.</p>
          <br />
          <p>Comment participer ?</p>
          <br />
          <p>Pour participer, rendez-vous devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec votre smartphone, scannez le QR Code de l’application web ou accédez connectez-vous au réseau wifi du kiosque.</p>
          <p>Répondez simplement à la question située dans la rubrique "kiosque à histoire" de la ville concernée.</p>
          <p>Une fois les 7 réponses enregistrées par vos soins dans l’application, votre participation sera prise en compte</p>
          <p>Vous avez + de 18 ans : vous serez sélectionné(e) pour le tirage au sort pour gagner un voyage au Canada.</p>
          <p>Vous avez – de 18 ans : vous pourrez retirer votre petit cadeau à l’accueil de l’un des offices de tourisme.</p> `,
        mailLabel: 'Your email address',
        mailPlaceholder: 'me@me.com',
        sendMailLabel: 'Confirm my mail'
      },
      conditions: {
        title: 'Privacy policy',
        text: `
          <h3>Règlement du jeu-concours « Gagnez un voyage de rêve au Canada, Côte Est »</h3>
          <p><strong>ARTICLE 1 : ORGANISATION DU JEU-CONCOURS</strong></p>
          <br />
          <p>1.1 Le Centre Juno Beach (ci-après la Société Organisatrice) situé Voie des Français Libres 14470 Courseulles-sur-mer organise un jeu-concours gratuit sans obligation d’achat du 08/04/2019 de 10h (heure française) au 11/11/2019 (minuit, heure française), intitulé :</p>
          <br />
          <p>Jeu-concours « Gagnez un voyage de rêve au Canada, Côte Est » (ci-après le jeu-concours), selon les modalités décrites dans le présent règlement.</p>
          <p>Jeu-concours accessible uniquement sur l’application web dansleurspas.com dans le cadre du circuit-expo plein-air Dans leurs Pas.</p>
          <br />
          <p><strong>ARTICLE 2 : CONDITIONS D’ACCES AU JEU-CONCOURS</strong></p>
          <br />
          <p>2.1. La participation au jeu-concours est gratuite, ouverte à toute personne physique majeure capable âgée d’au moins 18 ans, disposant, à la date de début du jeu-concours, d’un accès à l’application web et d’une adresse électronique personnelle (e-mail) à laquelle elle pourra être contactée pour les besoins de la gestion du jeu-concours (ci-après le « Participant »).</p>
          <br />
          <p>Sont exclues de toute participation à ce jeu-concours, les personnes ayant directement ou indirectement participé à l’élaboration du jeu-concours, de même que les membres de leur famille (même nom, même adresse postale), les employés et administrateurs du Centre Juno Beach, les employés et administrateurs de l’office de tourisme Terres de Nacre, y compris les personnes travaillant pour le compte du Centre Juno Beach et pour le compte de l’office de tourisme Terres de Nacre.</p>
          <p>Seules seront retenues les participations conformes à l’ensemble des stipulations du présent article. La Société Organisatrice se réserve le droit de procéder à toute vérification qu’elle jugera utile concernant notamment l’identité, l’âge et l’adresse de chaque Participant, en vue de faire respecter les stipulations du présent article. A cet égard, toute indication portée dans le formulaire de participation de l’application web, qui serait incomplète, erronée, falsifiée, ne permettrait pas d’identifier un Participant ou ses coordonnées, ou contreviendrait à l’une quelconque des stipulations du présent règlement entraînera l’annulation de sa participation.</p>
          <br />
          <p>2.3. La participation au jeu-concours implique l’acceptation expresse et sans réserve du présent règlement dans son intégralité, des règles de déontologie en vigueur, ainsi que des lois et règlements applicables aux jeux-concours en vigueur en France.</p>
          <br />
          <p>2.4 Une seule participation maximum par personne sera acceptée pendant toute la durée du jeu-concours.</p>
          <br />
          <p><strong>ARTICLE 3 : MODALITÉS DE PARTICIPATION</strong></p>
          <br />
          <p>3.1 Ce jeu-concours se déroule exclusivement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, aux dates indiquées dans l’article 1.1.</p>
          <br />
          <p>3.2 La participation s’effectue de la manière suivante : Le Participant doit se rendre devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec son smartphone ou sa tablette, il doit scanner le QR Code de l’application web ou accédez directement à l’application en tapant dans le navigateur « dansleurspas.com ». Le Participant doit répondre à une question par kiosque. Une fois qu’il a répondu à l’ensemble des 7 questions, il pourra répondre à la question finale : quel est l’âge moyen des soldats, présentés sur l’ensemble des kiosques de l’application ? Une fois la réponse finale donnée, le Participant pourra valider sa participation au tirage au sort pour tenter de gagner un voyage au Canada, Côte Est.</p>
          <br />
          <p>Seuls les Participants ayant correctement suivis ces étapes, et ayant répondus correctement aux questions posées, verront leur candidature validée.</p>
          <p>Les Participants qui ne répondent pas à l’ensemble des 7 questions ne peuvent prétendre être sélectionnés pour le tirage au sort.</p>
          <br />
          <p>3.3 Il n’est autorisé qu’une seule participation par personne - même nom, même prénom, même adresse électronique ou quel que soit l’adresse électronique pendant toute la période du jeu-concours.</p>
          <br />
          <p><strong>ARTICLE 4 – DESIGNATION DU/DE LA GAGNANT(E)</strong></p>
          <br />
          <p>4.1 Un tirage au sort sera effectué le samedi 16 novembre 2019 parmi toutes les participations complètes (telle que décrite à l’article 3) pour désigner le gagnant du jeu-concours.</p>
          <br />
          <p>4.2 Ce tirage au sort sera effectué par un algorithme informatique, réalisé par la société Twelve Solutions 149 Rue de la Délivrande, 14000 Caen. La participation tirée au sort désignera le/la Participant(e) gagnant(e) du jeu-concours (ci-après le/la « Gagnant(e) »).</p>
          <br />
          <p>4.3 Le/la Gagnant(e) sera averti par voie électronique (e-mail).</p>
          <br />
          <p>4.4 Le/la Gagnant(e) dispose d’un délai de 7 jours pour se faire connaître auprès de la Société Organisatrice. Le/la Gagnant(e) devra alors confirmer l’acceptation de son gain en répondant à l'adresse e-mail expéditrice et en précisant ses coordonnées : nom, prénom, adresse postale. Le/la Gagnant(e) ne donnant pas de réponse dans un délai de 7 jours à compter de l’envoi d’avis de son gain ne pourra pas en bénéficier. Le lot sera alors attribué à un nouveau contact tiré au sort et ainsi de suite jusqu’à ce qu’un(e) Gagnant(e) se fasse connaître auprès de la Société Organisatrice.</p>
          <br />
          <p>4.5 Toute coordonnée incomplète ou inexacte sera considérée comme nulle et ne permettra pas au/à la Gagnant(e) désigné par tirage au sort d’obtenir sa dotation. De manière générale, les participations au jeu-concours seront annulées si elles sont incomplètes, erronées, contrefaites ou réalisées de manière contrevenante au présent règlement.</p>
          <br />
          <p>4.6 Les Participants qui n’auront pas été tirés au sort n’en seront pas informés par e-mail ni par quelque autre moyen que ce soit. Aucun lot ne sera accordé aux Participants qui n’auront pas été tirés au sort.</p>
          <br />
          <p><strong>ARTICLE 5 – DOTATION</strong></p>
          <br />
          <p>5.1 Le voyage à gagner est financé par le Centre Juno Beach et est organisé par l’agence de voyage PLANET_REVE Village des Sports Décathlon Les Carandes 14120 Mondeville.</p>
          <br />
          <p>5.2 Le/la Gagnant(e) remportera le lot suivant : Un auto tour Canada Côte Est de Montréal à Québec en 8 nuits pour deux personnes majeures pour découvrir les villes emblématiques, les chutes du Niagara et la nature québécoise.</p>
          <br />
          <p>Ce circuit de 8 jours concocté par PLANET_REVE se déroulera hors vacances et ponts entre le 02 avril et le 10 juin 2020.</p>
          <p>La valeur totale du lot est de 2 200€/personne, soit 4 400€ pour deux personnes.</p>
          <p>Ces prix comprennent :</p>
          <ul>
            <li>· Les vols Paris – Montréal, Québec – Paris,</li>
            <li>· L’hébergement en hôtel en chambre standard avec petit déjeuner,</li>
            <li>· Les prestations offertes dans le programme, soumises à conditions,</li>
            <li>· La location de voiture livrée à l’aéroport de Montréal le jour 1 et restituée à l'aéroport de Québec le jour 8 pour une durée de 8x24h, incluant le kilométrage illimité, les frais d'abandon, GPS inclus, taxes locales, 1 conducteur additionnel inclus, 1 plein d’essence offert, surcharge aéroport, réduction totale de la responsabilité financière si accident ou vol du véhicule loué (LDW), assurance tierce personne jusqu’à 1 million de dollars (TPL = Third Party Liability),</li>
            <li>· L’assurance rapatriement de base.</li>
          </ul>
          <p>Ces prix ne comprennent pas :</p>
          <ul>
            <li>· Le transport jusqu’à l’aéroport de Paris,</li>
            <li>· Les repas en dehors des petits déjeuners,</li>
            <li>· Les boissons et les dépenses personnelles,</li>
            <li>· Les visites, entrées et soirées suggérées dans le programme sauf les prestations offertes,</li>
            <li>· Obtention et frais de l'autorisation de voyage électronique (AVE) pour le Canada (9 Dollars à ce jour),</li>
            <li>· Pour la location de voiture : le carburant ; les équipements complémentaires à réserver et à régler sur place tels que : siège bébé ou rehausseur enfant (13 CAD/jour) ; les assurances complémentaires à réserver et à régler sur place telles que : conducteur additionnel au-delà du 1er conducteur additionnel inclus (10 à 25 CAD/jour), jeune conducteur moins de 25 ans (de 25 à 35 CAD/jour), la « surcharge pneus » au Québec : de 3.24 CAD à 5,25 CAD/jour, assurance du conducteur (PAI) : 5 CAD/ jour, assurance protection des effets personnels (PEP), : 3 CAD/jour, l’option « assurance routière étendue » RSN (Roadside Safety Network) : 9 CAD/jour ; frais de parking/garage (hôtels, villes et sites...), frais de livraison du véhicule en dehors des horaires d'ouverture de l'agence du loueur, péages d'autoroutes, ponts..., contraventions éventuelles (+frais administratifs de traitement env. 50 €)</li>
            <li>· L’assurance multirisque annulation à 3%,</li>
            <li>· Les variations possibles de tarif liés à la possible hausse du prix du carburant, à la possible hausse des tarifs d’hébergement et la possible hausse des tarifs aériens.</li>
          </ul>
          <br />
          <p>5.3 Le Centre Juno Beach et PLANET_REVE se réservent le droit de procéder à la vérification de l’âge du/de la gagnant(e) et de la personne qui l’accompagne avant remise de son lot. </p>
          <br />
          <p>5.4 La dotation ne pourra faire l’objet d’aucune contestation de la part du/dela Gagnant(e). Le lot attribué est incessible, intransmissible, ne peut être vendu ou être échangée contre sa valeur en espèces ou contre toute autre dotation. </p>
          <br />
          <p>5.5 Le Centre Juno Beach ne saurait être tenu pour responsable de l’utilisation ou de la non utilisation, voire du négoce, du lot par le/la Gagnant(e). </p>
          <br />
          <p>5.6 En cas de force majeure, PLANET_REVE se réserve le droit de remplacer le lot gagné par un lot de nature et de valeur équivalente. </p>
          <br />
          <p>5.7 Le participant est informé que la vente et l'échange du lot sont strictement interdits.</p>
          <br />
          <p>5.8 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot.</p>
          <br />
          <p>5.9 La valeur indiquée pour le lot correspond au prix TTC couramment pratiqué ou estimé à la date de rédaction du règlement, elle est donnée à titre de simple indication et est susceptible de variation.</p>
          <br />
          <p>5.10 PLANET_REVE sera informé du nom du/de la gagnant(e) par la Société Organisatrice. Le/la Gagnant(e) devra prendre rendez-vous avec un agent de voyage de PLANET_REVE dans les 30 jours qui suivent la date du tirage au sort. </p>
          <br />
          <p>5.11 Si le lot n'a pu être retiré par Le/la gagnant(e) pour quelque raison que ce soit, indépendamment de la volonté de l'organisateur du jeu-concours, restera définitivement la propriété de la Société Organisatrice.</p>
          <br />
          <p>5.12 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot. </p>
          <br />
          <p><strong>ARTICLE 6 : Modification du Jeu-CONCOURS et du règlement </strong></p>
          <br />
          <p>6.1 La Société Organisatrice se réserve le droit d’annuler, de reporter, de prolonger ou d’écourter le jeu-concours, ainsi que de modifier tout ou partie des conditions d’accès et/ou des modalités de mise en œuvre du jeu-concours, si les circonstances l’y obligent sans avoir à justifier de cette décision et sans que sa responsabilité puisse être engagée en aucune manière de ce fait. </p>
          <p><strong>ARTICLE 7 : Dépôt et consultation du règlement </strong></p>
          <p>7.1 La participation au jeu-concours implique l’acceptation entière et sans réserve du présent règlement dans son intégralité. Le présent règlement est disponible gratuitement et dans son intégralité sur l’application web dansleurspas.com pendant toute la durée du jeu-concours.</p>
          <br />
          <p>7.2 Le présent règlement est déposé chez la SELARL ACTOJURIS, Huissier de Justice, à CAEN.</p>
          <br />
          <p><strong>ARTICLE 8 : LIMITATION DE RESPONSABILITE</strong></p>
          <br />
          <p>8.1 La participation au jeu-concours implique la connaissance et l'acceptation des caractéristiques et des limites de l'Internet, notamment en ce qui concerne les performances techniques, les temps de réponse pour consulter, interroger ou transférer des informations, les risques d'interruption, et plus généralement, les risques inhérents à toute connexion et transmission sur Internet, l'absence de protection de certaines données contre des détournements éventuels et les risques de contamination par des éventuels virus circulant sur le réseau. En conséquence, la Société Organisatrice ne saurait en aucune circonstance être tenue responsable, sans que cette liste soit limitative :</p>
          <br />
          <ul>
            <li>· de la transmission et/ou de la réception de toute donnée et/ou information sur Internet ;</li>
            <li>· de tout dysfonctionnement du réseau Internet empêchant le bon déroulement/fonctionnement du jeu-concours;</li>
            <li>· de défaillance de tout matériel de réception ou des lignes de communication ;</li>
            <li>· de perte de tout courrier papier ou électronique et, plus généralement, de perte de toute donnée ;</li>
            <li>· des problèmes d'acheminement ;</li>
            <li>· du fonctionnement de tout logiciel ;</li>
            <li>· des conséquences de tout virus, bogue informatique, anomalie, défaillance technique ;</li>
            <li>· de tout dommage causé à l'ordinateur d'un Participant ;</li>
            <li>· de toute défaillance technique, matérielle et logicielle de quelque nature, ayant empêché ou limité la possibilité de participer au jeu-concours ou ayant endommagé le système d'un Participant ;</li>
          </ul>
          <br />
          <p>8.2 La Société Organisatrice pourra annuler tout ou partie du jeu-concours s'il apparaît que des fraudes sont intervenues sous quelque forme que ce soit. Dans cette hypothèse, la Société Organisatrice aura le droit de ne pas attribuer le lot aux fraudeurs et/ou de poursuivre devant les juridictions compétentes les auteurs de ces fraudes. Elle ne saurait toutefois encourir aucune responsabilité d'aucune sorte vis-à-vis des participants du fait des fraudes éventuellement commises. Sera notamment considérée comme fraude le fait pour un participant d’utiliser un ou des prête-noms fictifs ou empruntés à une ou plusieurs tierces personnes, chaque participant devant participer au jeu-concours sous son propre et unique nom. Toute fraude entraîne l'élimination du participant.</p>
          <br />
          <p>8.3 La Société Organisatrice se réserve le droit pour quelque raison que ce soit, d'annuler, reporter, interrompre ou proroger le jeu-concours ou de modifier tout ou partie des modalités du présent règlement, dans le respect de celui-ci. Si, par suite d'un événement indépendant de sa volonté, elle était contrainte d'appliquer ce droit, sa responsabilité ne saurait être engagée.</p>
          <br />
          <p>8.4 La Société Organisatrice se réserve le droit d’exclure définitivement toute personne qui, par son comportement frauduleux, nuirait au bon déroulement du jeu-concours. </p>
          <br />
          <p><strong>ARTICLE 9 : LOI INFORMATIQUE ET LIBERTE ET Règlement général sur la protection des données</strong></p>
          <br />
          <p>9.1 Conformément à la loi Informatique et Liberté du 6 janvier 1978 modifiée, les participants disposent d’un droit d’accès, d’interrogation, de rectification, d’effacement des données personnelles les concernant, en écrivant au Centre Juno Beach Voie des Français Libres (14470) Courseulles-sur-mer (remboursement du timbre au tarif lent en vigueur).
          <br />
          <p>9.2 RGPD (Règlement Général sur la Protection des Données) :
          <br />
          <p>Les coordonnées des participants seront collectées et traitées informatiquement. Conformément au Règlement Général sur la Protection des Données, chaque participant dispose d’un droit d’accès, de rectification ou même de radiation des informations nominatives le concernant en écrivant à l’adresse précisée à l’article 9.1.</p>
          <p>Toutes les informations recueillies via ce jeu concours sont destinées à la gestion du jeu concours, aux statistiques, à satisfaire aux obligations légales ou réglementaires et à nous permettre également d’adresser des newsletters et des informations sur l’actualité du Centre Juno Beach et de l’office de tourisme Terres de Nacre par messagerie électronique.</p>
          <p>Ces informations sont exploitées par le Service communication du Centre Juno Beach et le pôle promotion-communication de l’office de tourisme Terres de Nacre. Vos données personnelles ne seront transmises à aucun autre organisme. Les données personnelles sont conservées pour une durée de 10 ans.</p>
          <p>Conformément au Règlement Général pour la Protection des Données personnelles (RGPD) vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous concernant.</p>
          <p>Vous pouvez à tout moment accéder à la modification ou la suppression de vos données en vous adressant à : Centre Juno Beach Voie des Français Libres (14470) Courseulles-sur-mer et à office de tourisme Terres de Nacre 72 Bis, Rue pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur).</p>
          <br />
          <p>Courseulles-sur-mer, le 10/04/2019</p>
          <br />
          <hr />
          <br />
          <h3>Règlement du concours « Gagnez un petit cadeau souvenir »</h3>
          <p><strong>ARTICLE 1 : ORGANISATION DU CONCOURS</strong></p>
          <br />
          <p>1.1 L’office de tourisme Terres de Nacre (ci-après la Société Organisatrice) situé 72 bis rue Pasteur 14750 Saint-Aubin-sur-mer organise un concours gratuit sans obligation d’achat du 08/04/2019 de 10h (heure française) au 11/11/2019 minuit (heure française), intitulé (ci-après le concours) : « Gagnez un petit cadeau souvenir » (ci-après le lot), selon les modalités décrites dans le présent règlement. Concours accessible uniquement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, pour les personnes mineurs à la date de début du concours.</p>
          <br />
          <p><strong>ARTICLE 2 : CONDITIONS D’ACCÈS AU CONCOURS</strong></p>
          <br />
          <p>2.1. La participation au concours est gratuite, ouverte à toute personne physique mineure capable âgée de 4 à 17 ans à la date de début du concours, disposant, à la date de début du concours et par l’intermédiaire de son représentant légal, d’un accès à l’application web et d’une adresse électronique personnelle (e-mail de son représentant légal) à laquelle elle pourra être contactée pour les besoins de la gestion du concours (ci-après le « Participant »). Il est impératif qu’un mineur qui souhaite participer au concours, participe par l’intermédiaire de son représentant légal. On entend par « représentant légal » : la ou les personnes titulaires de l’autorité parentale à l’égard du mineur (père et/ou mère, ou représentant légal). Toute participation d’une personne mineure est donc effectuée sous l’entière responsabilité du (des) titulaire(s) de l’autorité parentale. La Société Organisatrice se réserve le droit d’en demander la justification écrite à tout moment, a fortiori lors de l’attribution des lots. La société organisatrice serait contrainte de disqualifier tout mineur qui serait dans l’incapacité de fournir ce justificatif dans les délais qui lui seraient impartis. Dans ce cas, tout gain potentiellement obtenu durant le concours serait immédiatement annulé.</p>
          <br />
          <p>Sont exclus de toute participation à ce concours, les enfants des personnes et les personnes ayant directement ou indirectement participé à l’élaboration du concours, de même que les enfants des membres et les membres de leur famille (même nom, même adresse postale), les enfants des employés et les employés et administrateurs du Centre Juno Beach, les enfants des employés et les employés et administrateurs de l’office de tourisme Terres de Nacre, y compris les personnes travaillant pour le compte du Centre Juno Beach et pour le compte de l’office de tourisme Terres de Nacre.</p>
          <br />
          <p>Seules seront retenues les participations conformes à l’ensemble des stipulations du présent article. La Société Organisatrice se réserve le droit de procéder à toute vérification qu’elle jugera utile concernant notamment l’identité, l’âge et l’adresse de chaque Participant, en vue de faire respecter les stipulations du présent article. </p>
          <br />
          <p>2.3. La participation au concours implique l’acceptation expresse et sans réserve du présent règlement dans son intégralité, des règles de déontologie en vigueur, ainsi que des lois et règlements applicables aux concours en vigueur en France.</p>
          <br />
          <p>2.4 Une seule participation maximum par personne sera acceptée pendant toute la durée du concours.</p>
          <br />
          <p><strong>ARTICLE 3 : MODALITÉS DE PARTICIPATION ET DESIGNATION DU/DE LA GAGNANT(E)</strong></p>
          <br />
          <p>3.1 Ce concours se déroule exclusivement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, aux dates indiquées dans l’article 1.1.</p>
          <br />
          <p>3.2 La participation s’effectue de la manière suivante : Le Participant doit se rendre devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec le smartphone ou la tablette du représentant légal, il doit scanner le QR Code de l’application web ou accédez directement à l’application en tapant dans le navigateur « dansleurspas.com ». Le Participant doit répondre à une question par kiosque. Une fois qu’il a répondu à l’ensemble des 7 questions, il pourra répondre à la question finale : quel est l’âge moyen des soldats, présentés sur l’ensemble des kiosques de l’application ? S’il trouve la bonne réponse, il reçoit sur l’application un mot de passe. Le participant a jusqu’au 11 novembre 2019 pour donner ce mot de passe à l’accueil d’un des offices de tourisme de Courseulles-sur-mer, Bernières-sur-mer, Luc-sur-mer ou Saint-Aubin-sur-mer, uniquement durant les périodes et horaires d’ouverture disponibles sur www.terresdenacre.com/-nos-horaires-d-ouverture-101-.html. Si le mot de passe est bon, le participant pourra retirer son lot, dans la limite des stocks disponibles. Passée le 11 novembre, aucun lot ne pourra être accordé.</p>
          <br />
          <p>Seuls les Participants ayant correctement suivis ces étapes, et ayant répondu correctement aux questions posées, pourront prétendre recevoir le petit cadeau souvenir.</p>
          <br />
          <p>Si les Participants n’ont pas répondu à l’ensemble des questions, ni à la question finale, il ne pourra pas obtenir de lot. </p>
          <br />
          <p>3.3 Il n’est autorisé qu’une seule participation par personne - même nom, même prénom, même adresse électronique ou quel que soit l’adresse électronique pendant toute la période du concours.</p>
          <br />
          <p>3.4 Il n’y aura qu’un lot par participant, dans la limite des stocks disponibles. En aucun cas, le participant tentera d’obtenir plusieurs lots.</p>
          <br />
          <p>3.5 Le participant n’est autorisé à se rendre que dans l’un des 4 offices de tourisme cités en 3.2.</p>
          <br />
          <p>3.6 Toute coordonnée incomplète ou inexacte sera considérée comme nulle et ne permettra pas au/à la Gagnant(e) d’obtenir son petit cadeau souvenir. De manière générale, les participations au concours seront annulées si elles sont incomplètes, erronées, contrefaites ou réalisées de manière contrevenante au présent règlement.</p>
          <br />
          <p>3.7 Les données récupérées lors du concours ne seront en aucun cas stockées sur des serveurs ou utilisées par l’office de tourisme Terres de Nacre. Elles ne serviront qu’au concours dans le cadre de l’application Dans leurs Pas.</p>
          <br />
          <p><strong>ARTICLE 4 : DOTATION</strong></p>
          <br />
          <p>4.1 Le lot peut varier selon les stocks dont disposent les offices de tourisme : Le lot pourra être une casquette (valeur 2€) ou un T-shirt (valeur 4€) ou un frisbee (valeur 2€) ou des crayons de couleur (valeur 1€).</p>
          <br />
          <p>4.2 La Société Organisatrice se réserve le droit de remplacer le lot gagné par un lot de nature et de valeur équivalente.</p>
          <br />
          <p>4.3 Le/la conseiller(e) en séjour de l’office de tourisme se réserve le droit de procéder à la vérification des coordonnées et de l’âge du participant, avant remise de son lot.</p>
          <br />
          <p>4.4 La dotation ne pourra faire l’objet d’aucune contestation de la part du participant. Le lot attribué est incessible, intransmissible, ne peut être vendu ou être échangée contre sa valeur en espèces ou contre toute autre dotation.</p>
          <br />
          <p>4.5 Le participant est informé que la vente et l'échange du lot sont strictement interdits.</p>
          <br />
          <p>4.6 La Société Organisatrice ne saurait être tenu pour responsable de l’utilisation ou de la non utilisation, voire du négoce, du lot par le participant.</p>
          <br />
          <p>4.7 Si le lot n'a pu être retiré par le participant pour quelque raison que ce soit, indépendamment de la volonté de l'organisateur du concours, il restera définitivement la propriété de la Société Organisatrice.</p>
          <br />
          <p>4.8 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot.</p>
          <br />
          <p><strong>ARTICLE 5 : Modification du concours et du règlement</strong></p>
          <br />
          <p>5.1 La Société Organisatrice se réserve le droit d’annuler, de reporter, de prolonger ou d’écourter le concours, ainsi que de modifier tout ou partie des conditions d’accès et/ou des modalités de mise en œuvre du concours, si les circonstances l’y obligent sans avoir à justifier de cette décision et sans que sa responsabilité puisse être engagée en aucune manière de ce fait.</p>
          <br />
          <p><strong>ARTICLE 6 : Dépôt et consultation du règlement</strong></p>
          <br />
          <p>6.1 La participation au concours implique l’acceptation entière et sans réserve du présent règlement dans son intégralité. Le présent règlement est disponible gratuitement et dans son intégralité sur l’application web dansleurspas.com pendant toute la durée du concours.</p>
          <br />
          <p>6.2 Le présent règlement est déposé chez la SELARL ACTOJURIS, Huissier de Justice, à CAEN.</p>
          <br />
          <p><strong>ARTICLE 7 : LIMITATION DE RESPONSABILITÉ</strong></p>
          <br />
          <p>7.1 La participation au concours implique la connaissance et l'acceptation des caractéristiques et des limites de l'Internet, notamment en ce qui concerne les performances techniques, les temps de réponse pour consulter, interroger ou transférer des informations, les risques d'interruption, et plus généralement, les risques inhérents à toute connexion et transmission sur Internet, l'absence de protection de certaines données contre des détournements éventuels et les risques de contamination par des éventuels virus circulant sur le réseau. En conséquence, la Société Organisatrice ne saurait en aucune circonstance être tenue responsable, sans que cette liste soit limitative :
          <br />
          <ul>
            <li>· de la transmission et/ou de la réception de toute donnée et/ou information sur Internet ;</li>
            <li>· de tout dysfonctionnement du réseau Internet empêchant le bon déroulement/fonctionnement du concours ;</li>
            <li>· de défaillance de tout matériel de réception ou des lignes de communication ;</li>
            <li>· de perte de tout courrier papier ou électronique et, plus généralement, de perte de toute donnée ;</li>
            <li>· des problèmes d'acheminement ;</li>
            <li>· du fonctionnement de tout logiciel ;</li>
            <li>· des conséquences de tout virus, bogue informatique, anomalie, défaillance technique ;</li>
            <li>· de tout dommage causé à l'ordinateur d'un Participant ;</li>
            <li>· de toute défaillance technique, matérielle et logicielle de quelque nature, ayant empêché ou limité la possibilité de participer au concours ou ayant endommagé le système d'un Participant ;</li>
          </ul>
          <br />
          <p>7.2 La Société Organisatrice pourra annuler tout ou partie du concours s'il apparaît que des fraudes sont intervenues sous quelque forme que ce soit. Dans cette hypothèse, la Société Organisatrice aura le droit de ne pas attribuer le lot aux fraudeurs et/ou de poursuivre devant les juridictions compétentes les auteurs de ces fraudes. Elle ne saurait toutefois encourir aucune responsabilité d'aucune sorte vis-à-vis des participants du fait des fraudes éventuellement commises. Sera notamment considérée comme fraude le fait pour un participant d’utiliser un ou des prête-noms fictifs ou empruntés à une ou plusieurs tierces personnes, chaque participant devant participer au concours sous son propre et unique nom. Toute fraude entraîne l'élimination du participant.</p>
          <br />
          <p>7.3 La Société Organisatrice se réserve le droit pour quelque raison que ce soit, d'annuler, reporter, interrompre ou proroger le concours ou de modifier tout ou partie des modalités du présent règlement, dans le respect de celui-ci. Si, par suite d'un événement indépendant de sa volonté, elle était contrainte d'appliquer ce droit, sa responsabilité ne saurait être engagée.</p>
          <br />
          <p>7.4 La Société Organisatrice se réserve le droit d’exclure définitivement toute personne qui, par son comportement frauduleux, nuirait au bon déroulement du concours.</p>
          <br />
          <p><strong>ARTICLE 8 : RÈGLEMENT SUR LA LOI INFORMATIQUE ET LIBERTÉ ET Règlement Général sur la Protection des Données</strong></p>
          <br />
          <p>8.1 Conformément à la loi Informatique et Liberté du 6 janvier 1978 modifiée, les participants disposent d’un droit d’accès, d’interrogation, de rectification, d’effacement des données personnelles les concernant, en écrivant à l’Office de Tourisme Terres de Nacre, 72 Bis, Rue Pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur). </p>
          <br />
          <p>8.2 RGPD (Règlement Général sur la Protection des Données) :</p>
          <br />
          <p>Les coordonnées des participants seront collectées et traitées informatiquement. Conformément au Règlement Général sur la Protection des Données, chaque participant dispose d’un droit d’accès, de rectification ou même de radiation des informations nominatives le concernant en écrivant à l’adresse précisée à l’article 8.1.</p>
          <br />
          <p>Toutes les informations recueillies via ce jeu-concours sont destinées à la gestion du concours, aux statistiques, à satisfaire aux obligations légales ou réglementaires et à nous permettre également d’adresser des newsletters et des informations sur l’actualité du Centre Juno Beach et de l’office de tourisme Terres de Nacre par messagerie électronique.</p>
          <br />
          <p>Ces informations sont exploitées par le Service communication du Centre Juno Beach et le pôle promotion-communication de l’office de tourisme Terres de Nacre. Vos données personnelles ne seront transmises à aucun autre organisme. Les données personnelles sont conservées pour une durée de 10 ans.</p>
          <br />
          <p>Conformément au Règlement Général pour la Protection des Données personnelles (RGPD) vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous concernant.</p>
          <br />
          <p>Vous pouvez à tout moment accéder à la modification ou la suppression de vos données en vous adressant à : Centre Juno Beach Voie des Français Libres (14470) COURSEULLES-SUR-MER et à office de tourisme Terres de Nacre 72 Bis, Rue pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur).</p>
          <br />
          <p>Saint-Aubin-sur-mer, le 10/04/2019</p>
        `,
        acceptConditionLabel: 'I have read and I accept the current contest rules and regulations.',
        imMajorLabel: 'I certify being 18 and above and having read the rules of this contest.',
        imNotMajorLabel: 'Or I certify under 18 and above and having read the rules of this contest.',
        send: 'Send'
      },
      congrats: {
        title: 'Congratulations!',
        text: ''
      },
      majorForm: {
        emailLabel: 'Your email',
        lastnameLabel: 'Your lastname',
        firstnameLabel: 'Your firstname',
        birthdateLabel: 'Your birthdate',
        addressLabel: 'Your address',
        countryLabel: 'Your country',
        stateLabel: 'Your state',
        cityLabel: 'Your city',
        zipCodeLabel: 'Your zip code',
        phoneLabel: 'Your phone',
        maleLabel: 'Male',
        femaleLabel: 'Female',
        sendError: 'It appears that the details are not valid, please correct them and return the form',
        send: 'Send',
        resetLabel: 'Play with another account'
      }
    }
  },
  de: {
    pageName: 'Single point of interest',
    questionAvailableType: 'question | questions',
    questionAvailableLabel: 'available on this point of interest | available on this point of interest',
    reply: 'reply',
    survey: 'Umfrage',
    speechSynthesis : {
      start: 'Read this page',
      play: 'Resume',
      pause: 'Pause'
    },
    contest: {
      title: 'Dans leurs pas - contest',
      realm: {
        ok: 'Ok',
        noRealm: 'It seems that you\'re not near one of the history kiosk. Please head to one of the 7 town hosting a kiosk to enter the contest.'
      },
      welcome: {
        title: 'Enter the contest!',
        text: `
          <p><strong>Tentez de remporter un voyage de rêve au Canada pour deux personnes offert par le centre Juno Beach!</strong></p>
          <br />
          <p>Profitez d'un auto tour Canada Côte Est de Montréal à Québec en 8 nuits pour deux personnes majeures pour découvrir les villes emblématiques, les chutes du Niagara et la nature québécoise. (candidature réservée aux personnes majeures).</p>
          <p>Vous avez moins de 18 ans ? Tentez de gagner un petit cadeau souvenir de votre séjour en Normandie.</p>
          <br />
          <p>Comment participer ?</p>
          <br />
          <p>Pour participer, rendez-vous devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec votre smartphone, scannez le QR Code de l’application web ou accédez connectez-vous au réseau wifi du kiosque.</p>
          <p>Répondez simplement à la question située dans la rubrique "kiosque à histoire" de la ville concernée.</p>
          <p>Une fois les 7 réponses enregistrées par vos soins dans l’application, votre participation sera prise en compte</p>
          <p>Vous avez + de 18 ans : vous serez sélectionné(e) pour le tirage au sort pour gagner un voyage au Canada.</p>
          <p>Vous avez – de 18 ans : vous pourrez retirer votre petit cadeau à l’accueil de l’un des offices de tourisme.</p> `,
        mailLabel: 'Your email address',
        mailPlaceholder: 'me@me.com',
        sendMailLabel: 'Confirm my mail'
      },
      conditions: {
        title: 'Privacy policy',
        text: `
          <h3>Règlement du jeu-concours « Gagnez un voyage de rêve au Canada, Côte Est »</h3>
          <p><strong>ARTICLE 1 : ORGANISATION DU JEU-CONCOURS</strong></p>
          <br />
          <p>1.1 Le Centre Juno Beach (ci-après la Société Organisatrice) situé Voie des Français Libres 14470 Courseulles-sur-mer organise un jeu-concours gratuit sans obligation d’achat du 08/04/2019 de 10h (heure française) au 11/11/2019 (minuit, heure française), intitulé :</p>
          <br />
          <p>Jeu-concours « Gagnez un voyage de rêve au Canada, Côte Est » (ci-après le jeu-concours), selon les modalités décrites dans le présent règlement.</p>
          <p>Jeu-concours accessible uniquement sur l’application web dansleurspas.com dans le cadre du circuit-expo plein-air Dans leurs Pas.</p>
          <br />
          <p><strong>ARTICLE 2 : CONDITIONS D’ACCES AU JEU-CONCOURS</strong></p>
          <br />
          <p>2.1. La participation au jeu-concours est gratuite, ouverte à toute personne physique majeure capable âgée d’au moins 18 ans, disposant, à la date de début du jeu-concours, d’un accès à l’application web et d’une adresse électronique personnelle (e-mail) à laquelle elle pourra être contactée pour les besoins de la gestion du jeu-concours (ci-après le « Participant »).</p>
          <br />
          <p>Sont exclues de toute participation à ce jeu-concours, les personnes ayant directement ou indirectement participé à l’élaboration du jeu-concours, de même que les membres de leur famille (même nom, même adresse postale), les employés et administrateurs du Centre Juno Beach, les employés et administrateurs de l’office de tourisme Terres de Nacre, y compris les personnes travaillant pour le compte du Centre Juno Beach et pour le compte de l’office de tourisme Terres de Nacre.</p>
          <p>Seules seront retenues les participations conformes à l’ensemble des stipulations du présent article. La Société Organisatrice se réserve le droit de procéder à toute vérification qu’elle jugera utile concernant notamment l’identité, l’âge et l’adresse de chaque Participant, en vue de faire respecter les stipulations du présent article. A cet égard, toute indication portée dans le formulaire de participation de l’application web, qui serait incomplète, erronée, falsifiée, ne permettrait pas d’identifier un Participant ou ses coordonnées, ou contreviendrait à l’une quelconque des stipulations du présent règlement entraînera l’annulation de sa participation.</p>
          <br />
          <p>2.3. La participation au jeu-concours implique l’acceptation expresse et sans réserve du présent règlement dans son intégralité, des règles de déontologie en vigueur, ainsi que des lois et règlements applicables aux jeux-concours en vigueur en France.</p>
          <br />
          <p>2.4 Une seule participation maximum par personne sera acceptée pendant toute la durée du jeu-concours.</p>
          <br />
          <p><strong>ARTICLE 3 : MODALITÉS DE PARTICIPATION</strong></p>
          <br />
          <p>3.1 Ce jeu-concours se déroule exclusivement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, aux dates indiquées dans l’article 1.1.</p>
          <br />
          <p>3.2 La participation s’effectue de la manière suivante : Le Participant doit se rendre devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec son smartphone ou sa tablette, il doit scanner le QR Code de l’application web ou accédez directement à l’application en tapant dans le navigateur « dansleurspas.com ». Le Participant doit répondre à une question par kiosque. Une fois qu’il a répondu à l’ensemble des 7 questions, il pourra répondre à la question finale : quel est l’âge moyen des soldats, présentés sur l’ensemble des kiosques de l’application ? Une fois la réponse finale donnée, le Participant pourra valider sa participation au tirage au sort pour tenter de gagner un voyage au Canada, Côte Est.</p>
          <br />
          <p>Seuls les Participants ayant correctement suivis ces étapes, et ayant répondus correctement aux questions posées, verront leur candidature validée.</p>
          <p>Les Participants qui ne répondent pas à l’ensemble des 7 questions ne peuvent prétendre être sélectionnés pour le tirage au sort.</p>
          <br />
          <p>3.3 Il n’est autorisé qu’une seule participation par personne - même nom, même prénom, même adresse électronique ou quel que soit l’adresse électronique pendant toute la période du jeu-concours.</p>
          <br />
          <p><strong>ARTICLE 4 – DESIGNATION DU/DE LA GAGNANT(E)</strong></p>
          <br />
          <p>4.1 Un tirage au sort sera effectué le samedi 16 novembre 2019 parmi toutes les participations complètes (telle que décrite à l’article 3) pour désigner le gagnant du jeu-concours.</p>
          <br />
          <p>4.2 Ce tirage au sort sera effectué par un algorithme informatique, réalisé par la société Twelve Solutions 149 Rue de la Délivrande, 14000 Caen. La participation tirée au sort désignera le/la Participant(e) gagnant(e) du jeu-concours (ci-après le/la « Gagnant(e) »).</p>
          <br />
          <p>4.3 Le/la Gagnant(e) sera averti par voie électronique (e-mail).</p>
          <br />
          <p>4.4 Le/la Gagnant(e) dispose d’un délai de 7 jours pour se faire connaître auprès de la Société Organisatrice. Le/la Gagnant(e) devra alors confirmer l’acceptation de son gain en répondant à l'adresse e-mail expéditrice et en précisant ses coordonnées : nom, prénom, adresse postale. Le/la Gagnant(e) ne donnant pas de réponse dans un délai de 7 jours à compter de l’envoi d’avis de son gain ne pourra pas en bénéficier. Le lot sera alors attribué à un nouveau contact tiré au sort et ainsi de suite jusqu’à ce qu’un(e) Gagnant(e) se fasse connaître auprès de la Société Organisatrice.</p>
          <br />
          <p>4.5 Toute coordonnée incomplète ou inexacte sera considérée comme nulle et ne permettra pas au/à la Gagnant(e) désigné par tirage au sort d’obtenir sa dotation. De manière générale, les participations au jeu-concours seront annulées si elles sont incomplètes, erronées, contrefaites ou réalisées de manière contrevenante au présent règlement.</p>
          <br />
          <p>4.6 Les Participants qui n’auront pas été tirés au sort n’en seront pas informés par e-mail ni par quelque autre moyen que ce soit. Aucun lot ne sera accordé aux Participants qui n’auront pas été tirés au sort.</p>
          <br />
          <p><strong>ARTICLE 5 – DOTATION</strong></p>
          <br />
          <p>5.1 Le voyage à gagner est financé par le Centre Juno Beach et est organisé par l’agence de voyage PLANET_REVE Village des Sports Décathlon Les Carandes 14120 Mondeville.</p>
          <br />
          <p>5.2 Le/la Gagnant(e) remportera le lot suivant : Un auto tour Canada Côte Est de Montréal à Québec en 8 nuits pour deux personnes majeures pour découvrir les villes emblématiques, les chutes du Niagara et la nature québécoise.</p>
          <br />
          <p>Ce circuit de 8 jours concocté par PLANET_REVE se déroulera hors vacances et ponts entre le 02 avril et le 10 juin 2020.</p>
          <p>La valeur totale du lot est de 2 200€/personne, soit 4 400€ pour deux personnes.</p>
          <p>Ces prix comprennent :</p>
          <ul>
            <li>· Les vols Paris – Montréal, Québec – Paris,</li>
            <li>· L’hébergement en hôtel en chambre standard avec petit déjeuner,</li>
            <li>· Les prestations offertes dans le programme, soumises à conditions,</li>
            <li>· La location de voiture livrée à l’aéroport de Montréal le jour 1 et restituée à l'aéroport de Québec le jour 8 pour une durée de 8x24h, incluant le kilométrage illimité, les frais d'abandon, GPS inclus, taxes locales, 1 conducteur additionnel inclus, 1 plein d’essence offert, surcharge aéroport, réduction totale de la responsabilité financière si accident ou vol du véhicule loué (LDW), assurance tierce personne jusqu’à 1 million de dollars (TPL = Third Party Liability),</li>
            <li>· L’assurance rapatriement de base.</li>
          </ul>
          <p>Ces prix ne comprennent pas :</p>
          <ul>
            <li>· Le transport jusqu’à l’aéroport de Paris,</li>
            <li>· Les repas en dehors des petits déjeuners,</li>
            <li>· Les boissons et les dépenses personnelles,</li>
            <li>· Les visites, entrées et soirées suggérées dans le programme sauf les prestations offertes,</li>
            <li>· Obtention et frais de l'autorisation de voyage électronique (AVE) pour le Canada (9 Dollars à ce jour),</li>
            <li>· Pour la location de voiture : le carburant ; les équipements complémentaires à réserver et à régler sur place tels que : siège bébé ou rehausseur enfant (13 CAD/jour) ; les assurances complémentaires à réserver et à régler sur place telles que : conducteur additionnel au-delà du 1er conducteur additionnel inclus (10 à 25 CAD/jour), jeune conducteur moins de 25 ans (de 25 à 35 CAD/jour), la « surcharge pneus » au Québec : de 3.24 CAD à 5,25 CAD/jour, assurance du conducteur (PAI) : 5 CAD/ jour, assurance protection des effets personnels (PEP), : 3 CAD/jour, l’option « assurance routière étendue » RSN (Roadside Safety Network) : 9 CAD/jour ; frais de parking/garage (hôtels, villes et sites...), frais de livraison du véhicule en dehors des horaires d'ouverture de l'agence du loueur, péages d'autoroutes, ponts..., contraventions éventuelles (+frais administratifs de traitement env. 50 €)</li>
            <li>· L’assurance multirisque annulation à 3%,</li>
            <li>· Les variations possibles de tarif liés à la possible hausse du prix du carburant, à la possible hausse des tarifs d’hébergement et la possible hausse des tarifs aériens.</li>
          </ul>
          <br />
          <p>5.3 Le Centre Juno Beach et PLANET_REVE se réservent le droit de procéder à la vérification de l’âge du/de la gagnant(e) et de la personne qui l’accompagne avant remise de son lot. </p>
          <br />
          <p>5.4 La dotation ne pourra faire l’objet d’aucune contestation de la part du/dela Gagnant(e). Le lot attribué est incessible, intransmissible, ne peut être vendu ou être échangée contre sa valeur en espèces ou contre toute autre dotation. </p>
          <br />
          <p>5.5 Le Centre Juno Beach ne saurait être tenu pour responsable de l’utilisation ou de la non utilisation, voire du négoce, du lot par le/la Gagnant(e). </p>
          <br />
          <p>5.6 En cas de force majeure, PLANET_REVE se réserve le droit de remplacer le lot gagné par un lot de nature et de valeur équivalente. </p>
          <br />
          <p>5.7 Le participant est informé que la vente et l'échange du lot sont strictement interdits.</p>
          <br />
          <p>5.8 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot.</p>
          <br />
          <p>5.9 La valeur indiquée pour le lot correspond au prix TTC couramment pratiqué ou estimé à la date de rédaction du règlement, elle est donnée à titre de simple indication et est susceptible de variation.</p>
          <br />
          <p>5.10 PLANET_REVE sera informé du nom du/de la gagnant(e) par la Société Organisatrice. Le/la Gagnant(e) devra prendre rendez-vous avec un agent de voyage de PLANET_REVE dans les 30 jours qui suivent la date du tirage au sort. </p>
          <br />
          <p>5.11 Si le lot n'a pu être retiré par Le/la gagnant(e) pour quelque raison que ce soit, indépendamment de la volonté de l'organisateur du jeu-concours, restera définitivement la propriété de la Société Organisatrice.</p>
          <br />
          <p>5.12 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot. </p>
          <br />
          <p><strong>ARTICLE 6 : Modification du Jeu-CONCOURS et du règlement </strong></p>
          <br />
          <p>6.1 La Société Organisatrice se réserve le droit d’annuler, de reporter, de prolonger ou d’écourter le jeu-concours, ainsi que de modifier tout ou partie des conditions d’accès et/ou des modalités de mise en œuvre du jeu-concours, si les circonstances l’y obligent sans avoir à justifier de cette décision et sans que sa responsabilité puisse être engagée en aucune manière de ce fait. </p>
          <p><strong>ARTICLE 7 : Dépôt et consultation du règlement </strong></p>
          <p>7.1 La participation au jeu-concours implique l’acceptation entière et sans réserve du présent règlement dans son intégralité. Le présent règlement est disponible gratuitement et dans son intégralité sur l’application web dansleurspas.com pendant toute la durée du jeu-concours.</p>
          <br />
          <p>7.2 Le présent règlement est déposé chez la SELARL ACTOJURIS, Huissier de Justice, à CAEN.</p>
          <br />
          <p><strong>ARTICLE 8 : LIMITATION DE RESPONSABILITE</strong></p>
          <br />
          <p>8.1 La participation au jeu-concours implique la connaissance et l'acceptation des caractéristiques et des limites de l'Internet, notamment en ce qui concerne les performances techniques, les temps de réponse pour consulter, interroger ou transférer des informations, les risques d'interruption, et plus généralement, les risques inhérents à toute connexion et transmission sur Internet, l'absence de protection de certaines données contre des détournements éventuels et les risques de contamination par des éventuels virus circulant sur le réseau. En conséquence, la Société Organisatrice ne saurait en aucune circonstance être tenue responsable, sans que cette liste soit limitative :</p>
          <br />
          <ul>
            <li>· de la transmission et/ou de la réception de toute donnée et/ou information sur Internet ;</li>
            <li>· de tout dysfonctionnement du réseau Internet empêchant le bon déroulement/fonctionnement du jeu-concours;</li>
            <li>· de défaillance de tout matériel de réception ou des lignes de communication ;</li>
            <li>· de perte de tout courrier papier ou électronique et, plus généralement, de perte de toute donnée ;</li>
            <li>· des problèmes d'acheminement ;</li>
            <li>· du fonctionnement de tout logiciel ;</li>
            <li>· des conséquences de tout virus, bogue informatique, anomalie, défaillance technique ;</li>
            <li>· de tout dommage causé à l'ordinateur d'un Participant ;</li>
            <li>· de toute défaillance technique, matérielle et logicielle de quelque nature, ayant empêché ou limité la possibilité de participer au jeu-concours ou ayant endommagé le système d'un Participant ;</li>
          </ul>
          <br />
          <p>8.2 La Société Organisatrice pourra annuler tout ou partie du jeu-concours s'il apparaît que des fraudes sont intervenues sous quelque forme que ce soit. Dans cette hypothèse, la Société Organisatrice aura le droit de ne pas attribuer le lot aux fraudeurs et/ou de poursuivre devant les juridictions compétentes les auteurs de ces fraudes. Elle ne saurait toutefois encourir aucune responsabilité d'aucune sorte vis-à-vis des participants du fait des fraudes éventuellement commises. Sera notamment considérée comme fraude le fait pour un participant d’utiliser un ou des prête-noms fictifs ou empruntés à une ou plusieurs tierces personnes, chaque participant devant participer au jeu-concours sous son propre et unique nom. Toute fraude entraîne l'élimination du participant.</p>
          <br />
          <p>8.3 La Société Organisatrice se réserve le droit pour quelque raison que ce soit, d'annuler, reporter, interrompre ou proroger le jeu-concours ou de modifier tout ou partie des modalités du présent règlement, dans le respect de celui-ci. Si, par suite d'un événement indépendant de sa volonté, elle était contrainte d'appliquer ce droit, sa responsabilité ne saurait être engagée.</p>
          <br />
          <p>8.4 La Société Organisatrice se réserve le droit d’exclure définitivement toute personne qui, par son comportement frauduleux, nuirait au bon déroulement du jeu-concours. </p>
          <br />
          <p><strong>ARTICLE 9 : LOI INFORMATIQUE ET LIBERTE ET Règlement général sur la protection des données</strong></p>
          <br />
          <p>9.1 Conformément à la loi Informatique et Liberté du 6 janvier 1978 modifiée, les participants disposent d’un droit d’accès, d’interrogation, de rectification, d’effacement des données personnelles les concernant, en écrivant au Centre Juno Beach Voie des Français Libres (14470) Courseulles-sur-mer (remboursement du timbre au tarif lent en vigueur).
          <br />
          <p>9.2 RGPD (Règlement Général sur la Protection des Données) :
          <br />
          <p>Les coordonnées des participants seront collectées et traitées informatiquement. Conformément au Règlement Général sur la Protection des Données, chaque participant dispose d’un droit d’accès, de rectification ou même de radiation des informations nominatives le concernant en écrivant à l’adresse précisée à l’article 9.1.</p>
          <p>Toutes les informations recueillies via ce jeu concours sont destinées à la gestion du jeu concours, aux statistiques, à satisfaire aux obligations légales ou réglementaires et à nous permettre également d’adresser des newsletters et des informations sur l’actualité du Centre Juno Beach et de l’office de tourisme Terres de Nacre par messagerie électronique.</p>
          <p>Ces informations sont exploitées par le Service communication du Centre Juno Beach et le pôle promotion-communication de l’office de tourisme Terres de Nacre. Vos données personnelles ne seront transmises à aucun autre organisme. Les données personnelles sont conservées pour une durée de 10 ans.</p>
          <p>Conformément au Règlement Général pour la Protection des Données personnelles (RGPD) vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous concernant.</p>
          <p>Vous pouvez à tout moment accéder à la modification ou la suppression de vos données en vous adressant à : Centre Juno Beach Voie des Français Libres (14470) Courseulles-sur-mer et à office de tourisme Terres de Nacre 72 Bis, Rue pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur).</p>
          <br />
          <p>Courseulles-sur-mer, le 10/04/2019</p>
          <br />
          <hr />
          <br />
          <h3>Règlement du concours « Gagnez un petit cadeau souvenir »</h3>
          <p><strong>ARTICLE 1 : ORGANISATION DU CONCOURS</strong></p>
          <br />
          <p>1.1 L’office de tourisme Terres de Nacre (ci-après la Société Organisatrice) situé 72 bis rue Pasteur 14750 Saint-Aubin-sur-mer organise un concours gratuit sans obligation d’achat du 08/04/2019 de 10h (heure française) au 11/11/2019 minuit (heure française), intitulé (ci-après le concours) : « Gagnez un petit cadeau souvenir » (ci-après le lot), selon les modalités décrites dans le présent règlement. Concours accessible uniquement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, pour les personnes mineurs à la date de début du concours.</p>
          <br />
          <p><strong>ARTICLE 2 : CONDITIONS D’ACCÈS AU CONCOURS</strong></p>
          <br />
          <p>2.1. La participation au concours est gratuite, ouverte à toute personne physique mineure capable âgée de 4 à 17 ans à la date de début du concours, disposant, à la date de début du concours et par l’intermédiaire de son représentant légal, d’un accès à l’application web et d’une adresse électronique personnelle (e-mail de son représentant légal) à laquelle elle pourra être contactée pour les besoins de la gestion du concours (ci-après le « Participant »). Il est impératif qu’un mineur qui souhaite participer au concours, participe par l’intermédiaire de son représentant légal. On entend par « représentant légal » : la ou les personnes titulaires de l’autorité parentale à l’égard du mineur (père et/ou mère, ou représentant légal). Toute participation d’une personne mineure est donc effectuée sous l’entière responsabilité du (des) titulaire(s) de l’autorité parentale. La Société Organisatrice se réserve le droit d’en demander la justification écrite à tout moment, a fortiori lors de l’attribution des lots. La société organisatrice serait contrainte de disqualifier tout mineur qui serait dans l’incapacité de fournir ce justificatif dans les délais qui lui seraient impartis. Dans ce cas, tout gain potentiellement obtenu durant le concours serait immédiatement annulé.</p>
          <br />
          <p>Sont exclus de toute participation à ce concours, les enfants des personnes et les personnes ayant directement ou indirectement participé à l’élaboration du concours, de même que les enfants des membres et les membres de leur famille (même nom, même adresse postale), les enfants des employés et les employés et administrateurs du Centre Juno Beach, les enfants des employés et les employés et administrateurs de l’office de tourisme Terres de Nacre, y compris les personnes travaillant pour le compte du Centre Juno Beach et pour le compte de l’office de tourisme Terres de Nacre.</p>
          <br />
          <p>Seules seront retenues les participations conformes à l’ensemble des stipulations du présent article. La Société Organisatrice se réserve le droit de procéder à toute vérification qu’elle jugera utile concernant notamment l’identité, l’âge et l’adresse de chaque Participant, en vue de faire respecter les stipulations du présent article. </p>
          <br />
          <p>2.3. La participation au concours implique l’acceptation expresse et sans réserve du présent règlement dans son intégralité, des règles de déontologie en vigueur, ainsi que des lois et règlements applicables aux concours en vigueur en France.</p>
          <br />
          <p>2.4 Une seule participation maximum par personne sera acceptée pendant toute la durée du concours.</p>
          <br />
          <p><strong>ARTICLE 3 : MODALITÉS DE PARTICIPATION ET DESIGNATION DU/DE LA GAGNANT(E)</strong></p>
          <br />
          <p>3.1 Ce concours se déroule exclusivement sur l’application web dansleurspas.com, dans le cadre du circuit-expo plein-air Dans leurs Pas, aux dates indiquées dans l’article 1.1.</p>
          <br />
          <p>3.2 La participation s’effectue de la manière suivante : Le Participant doit se rendre devant chacun des 7 kiosques du circuit-expo plein-air Dans leurs Pas. Avec le smartphone ou la tablette du représentant légal, il doit scanner le QR Code de l’application web ou accédez directement à l’application en tapant dans le navigateur « dansleurspas.com ». Le Participant doit répondre à une question par kiosque. Une fois qu’il a répondu à l’ensemble des 7 questions, il pourra répondre à la question finale : quel est l’âge moyen des soldats, présentés sur l’ensemble des kiosques de l’application ? S’il trouve la bonne réponse, il reçoit sur l’application un mot de passe. Le participant a jusqu’au 11 novembre 2019 pour donner ce mot de passe à l’accueil d’un des offices de tourisme de Courseulles-sur-mer, Bernières-sur-mer, Luc-sur-mer ou Saint-Aubin-sur-mer, uniquement durant les périodes et horaires d’ouverture disponibles sur www.terresdenacre.com/-nos-horaires-d-ouverture-101-.html. Si le mot de passe est bon, le participant pourra retirer son lot, dans la limite des stocks disponibles. Passée le 11 novembre, aucun lot ne pourra être accordé.</p>
          <br />
          <p>Seuls les Participants ayant correctement suivis ces étapes, et ayant répondu correctement aux questions posées, pourront prétendre recevoir le petit cadeau souvenir.</p>
          <br />
          <p>Si les Participants n’ont pas répondu à l’ensemble des questions, ni à la question finale, il ne pourra pas obtenir de lot. </p>
          <br />
          <p>3.3 Il n’est autorisé qu’une seule participation par personne - même nom, même prénom, même adresse électronique ou quel que soit l’adresse électronique pendant toute la période du concours.</p>
          <br />
          <p>3.4 Il n’y aura qu’un lot par participant, dans la limite des stocks disponibles. En aucun cas, le participant tentera d’obtenir plusieurs lots.</p>
          <br />
          <p>3.5 Le participant n’est autorisé à se rendre que dans l’un des 4 offices de tourisme cités en 3.2.</p>
          <br />
          <p>3.6 Toute coordonnée incomplète ou inexacte sera considérée comme nulle et ne permettra pas au/à la Gagnant(e) d’obtenir son petit cadeau souvenir. De manière générale, les participations au concours seront annulées si elles sont incomplètes, erronées, contrefaites ou réalisées de manière contrevenante au présent règlement.</p>
          <br />
          <p>3.7 Les données récupérées lors du concours ne seront en aucun cas stockées sur des serveurs ou utilisées par l’office de tourisme Terres de Nacre. Elles ne serviront qu’au concours dans le cadre de l’application Dans leurs Pas.</p>
          <br />
          <p><strong>ARTICLE 4 : DOTATION</strong></p>
          <br />
          <p>4.1 Le lot peut varier selon les stocks dont disposent les offices de tourisme : Le lot pourra être une casquette (valeur 2€) ou un T-shirt (valeur 4€) ou un frisbee (valeur 2€) ou des crayons de couleur (valeur 1€).</p>
          <br />
          <p>4.2 La Société Organisatrice se réserve le droit de remplacer le lot gagné par un lot de nature et de valeur équivalente.</p>
          <br />
          <p>4.3 Le/la conseiller(e) en séjour de l’office de tourisme se réserve le droit de procéder à la vérification des coordonnées et de l’âge du participant, avant remise de son lot.</p>
          <br />
          <p>4.4 La dotation ne pourra faire l’objet d’aucune contestation de la part du participant. Le lot attribué est incessible, intransmissible, ne peut être vendu ou être échangée contre sa valeur en espèces ou contre toute autre dotation.</p>
          <br />
          <p>4.5 Le participant est informé que la vente et l'échange du lot sont strictement interdits.</p>
          <br />
          <p>4.6 La Société Organisatrice ne saurait être tenu pour responsable de l’utilisation ou de la non utilisation, voire du négoce, du lot par le participant.</p>
          <br />
          <p>4.7 Si le lot n'a pu être retiré par le participant pour quelque raison que ce soit, indépendamment de la volonté de l'organisateur du concours, il restera définitivement la propriété de la Société Organisatrice.</p>
          <br />
          <p>4.8 La Société Organisatrice ne peut être tenue pour responsable de tout incident/accident pouvant subvenir dans l'utilisation du lot.</p>
          <br />
          <p><strong>ARTICLE 5 : Modification du concours et du règlement</strong></p>
          <br />
          <p>5.1 La Société Organisatrice se réserve le droit d’annuler, de reporter, de prolonger ou d’écourter le concours, ainsi que de modifier tout ou partie des conditions d’accès et/ou des modalités de mise en œuvre du concours, si les circonstances l’y obligent sans avoir à justifier de cette décision et sans que sa responsabilité puisse être engagée en aucune manière de ce fait.</p>
          <br />
          <p><strong>ARTICLE 6 : Dépôt et consultation du règlement</strong></p>
          <br />
          <p>6.1 La participation au concours implique l’acceptation entière et sans réserve du présent règlement dans son intégralité. Le présent règlement est disponible gratuitement et dans son intégralité sur l’application web dansleurspas.com pendant toute la durée du concours.</p>
          <br />
          <p>6.2 Le présent règlement est déposé chez la SELARL ACTOJURIS, Huissier de Justice, à CAEN.</p>
          <br />
          <p><strong>ARTICLE 7 : LIMITATION DE RESPONSABILITÉ</strong></p>
          <br />
          <p>7.1 La participation au concours implique la connaissance et l'acceptation des caractéristiques et des limites de l'Internet, notamment en ce qui concerne les performances techniques, les temps de réponse pour consulter, interroger ou transférer des informations, les risques d'interruption, et plus généralement, les risques inhérents à toute connexion et transmission sur Internet, l'absence de protection de certaines données contre des détournements éventuels et les risques de contamination par des éventuels virus circulant sur le réseau. En conséquence, la Société Organisatrice ne saurait en aucune circonstance être tenue responsable, sans que cette liste soit limitative :
          <br />
          <ul>
            <li>· de la transmission et/ou de la réception de toute donnée et/ou information sur Internet ;</li>
            <li>· de tout dysfonctionnement du réseau Internet empêchant le bon déroulement/fonctionnement du concours ;</li>
            <li>· de défaillance de tout matériel de réception ou des lignes de communication ;</li>
            <li>· de perte de tout courrier papier ou électronique et, plus généralement, de perte de toute donnée ;</li>
            <li>· des problèmes d'acheminement ;</li>
            <li>· du fonctionnement de tout logiciel ;</li>
            <li>· des conséquences de tout virus, bogue informatique, anomalie, défaillance technique ;</li>
            <li>· de tout dommage causé à l'ordinateur d'un Participant ;</li>
            <li>· de toute défaillance technique, matérielle et logicielle de quelque nature, ayant empêché ou limité la possibilité de participer au concours ou ayant endommagé le système d'un Participant ;</li>
          </ul>
          <br />
          <p>7.2 La Société Organisatrice pourra annuler tout ou partie du concours s'il apparaît que des fraudes sont intervenues sous quelque forme que ce soit. Dans cette hypothèse, la Société Organisatrice aura le droit de ne pas attribuer le lot aux fraudeurs et/ou de poursuivre devant les juridictions compétentes les auteurs de ces fraudes. Elle ne saurait toutefois encourir aucune responsabilité d'aucune sorte vis-à-vis des participants du fait des fraudes éventuellement commises. Sera notamment considérée comme fraude le fait pour un participant d’utiliser un ou des prête-noms fictifs ou empruntés à une ou plusieurs tierces personnes, chaque participant devant participer au concours sous son propre et unique nom. Toute fraude entraîne l'élimination du participant.</p>
          <br />
          <p>7.3 La Société Organisatrice se réserve le droit pour quelque raison que ce soit, d'annuler, reporter, interrompre ou proroger le concours ou de modifier tout ou partie des modalités du présent règlement, dans le respect de celui-ci. Si, par suite d'un événement indépendant de sa volonté, elle était contrainte d'appliquer ce droit, sa responsabilité ne saurait être engagée.</p>
          <br />
          <p>7.4 La Société Organisatrice se réserve le droit d’exclure définitivement toute personne qui, par son comportement frauduleux, nuirait au bon déroulement du concours.</p>
          <br />
          <p><strong>ARTICLE 8 : RÈGLEMENT SUR LA LOI INFORMATIQUE ET LIBERTÉ ET Règlement Général sur la Protection des Données</strong></p>
          <br />
          <p>8.1 Conformément à la loi Informatique et Liberté du 6 janvier 1978 modifiée, les participants disposent d’un droit d’accès, d’interrogation, de rectification, d’effacement des données personnelles les concernant, en écrivant à l’Office de Tourisme Terres de Nacre, 72 Bis, Rue Pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur). </p>
          <br />
          <p>8.2 RGPD (Règlement Général sur la Protection des Données) :</p>
          <br />
          <p>Les coordonnées des participants seront collectées et traitées informatiquement. Conformément au Règlement Général sur la Protection des Données, chaque participant dispose d’un droit d’accès, de rectification ou même de radiation des informations nominatives le concernant en écrivant à l’adresse précisée à l’article 8.1.</p>
          <br />
          <p>Toutes les informations recueillies via ce jeu-concours sont destinées à la gestion du concours, aux statistiques, à satisfaire aux obligations légales ou réglementaires et à nous permettre également d’adresser des newsletters et des informations sur l’actualité du Centre Juno Beach et de l’office de tourisme Terres de Nacre par messagerie électronique.</p>
          <br />
          <p>Ces informations sont exploitées par le Service communication du Centre Juno Beach et le pôle promotion-communication de l’office de tourisme Terres de Nacre. Vos données personnelles ne seront transmises à aucun autre organisme. Les données personnelles sont conservées pour une durée de 10 ans.</p>
          <br />
          <p>Conformément au Règlement Général pour la Protection des Données personnelles (RGPD) vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous concernant.</p>
          <br />
          <p>Vous pouvez à tout moment accéder à la modification ou la suppression de vos données en vous adressant à : Centre Juno Beach Voie des Français Libres (14470) COURSEULLES-SUR-MER et à office de tourisme Terres de Nacre 72 Bis, Rue pasteur (14750) SAINT-AUBIN-SUR-MER (remboursement du timbre au tarif lent en vigueur).</p>
          <br />
          <p>Saint-Aubin-sur-mer, le 10/04/2019</p>
        `,
        acceptConditionLabel: 'I have read and I accept the current contest rules and regulations.',
        imMajorLabel: 'I certify being 18 and above and having read the rules of this contest.',
        imNotMajorLabel: 'Or I certify under 18 and above and having read the rules of this contest.',
        send: 'Send'
      },
      congrats: {
        title: 'Congratulations!',
        text: ' '
      },
      majorForm: {
        emailLabel: 'Your email',
        lastnameLabel: 'Your lastname',
        firstnameLabel: 'Your firstname',
        birthdateLabel: 'Your birthdate',
        addressLabel: 'Your address',
        countryLabel: 'Your country',
        stateLabel: 'Your state',
        cityLabel: 'Your city',
        zipCodeLabel: 'Your zip code',
        phoneLabel: 'Your phone',
        maleLabel: 'Male',
        femaleLabel: 'Female',
        sendError: 'It appears that the details are not valid, please correct them and return the form',
        send: 'Send',
        resetLabel: 'Play with another account'
      }
    }

  },
  it: {
    pageName: 'Single point of interest',
    questionAvailableType: 'question | questions',
    questionAvailableLabel: 'available on this point of interest | available on this point of interest',
    reply: 'reply',
    survey: 'Sondaggio',
    speechSynthesis : {
      start: 'Read this page',
      play: 'Resume',
      pause: 'Pause'
    },
  },
  pt: {
    pageName: 'Single point of interest',
    survey: 'Sondaggio',
    questionAvailableType: 'question | questions',
    questionAvailableLabel: 'available on this point of interest | available on this point of interest',
    reply: 'reply',
    speechSynthesis : {
      start: 'Read this page',
      play: 'Resume',
      pause: 'Pause'
    },
  },
  zh: {
    pageName: 'Single point of interest',
    questionAvailableType: 'question | questions',
    questionAvailableLabel: 'available on this point of interest | available on this point of interest',
    reply: 'reply',
    speechSynthesis : {
      start: 'Read this page',
      play: 'Resume',
      pause: 'Pause'
    },
  }
}
