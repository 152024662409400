
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const config = {
  name: 'c-image'
}

@Component(config)
export default class Image extends Vue {

  @Prop({ type: String, required: true }) src!: string
  @Prop({ type: String, required: false }) srcset!: string
  @Prop({ type: String, required: false }) sizes!: string
  @Prop({ type: String, required: false, default: '' }) alt!: string
  @Prop({ type: Number, required: false }) widthRatio!: number
  @Prop({ type: Number, required: false }) heightRatio!: number
  @Prop({ type: String, required: false }) width!: string
  @Prop({ type: String, required: false }) height!: string
  @Prop({ type: Boolean, required: false, default: false }) lazy!: boolean
  @Prop({ type: String, required: false }) background!: string
  @Prop({ type: Boolean, required: false }) cover!: boolean
  @Prop({ type: Boolean, required: false }) contain!: boolean
  @Prop({ type: Number, default: 0 }) delay!: number

  isLoading: boolean = true
  isLoaded: boolean = false
  error: boolean = false

  observer: IntersectionObserver | null = null
  lazySrc: string | null = null
  lazySrcset: string | null = null
  elWidth: number = 0

  @Watch('src')
  onSrcUpdate () {
    if (this.lazy) this.observer!.disconnect()
    this.isLoading = true
    this.isLoaded = false
    this.init()
  }

  init () {
    this.elWidth = this.$el.clientWidth
    if (this.lazy) this.initIntersectionObserver()
  }

  mounted () {
    this.init()
    // this.elWidth = this.$el.clientWidth
    // if (this.lazy) this.initIntersectionObserver()
  }

  beforeDestroy () {
    if (this.lazy) this.observer!.disconnect()
  }

  get containerRatio () {
    const { widthRatio, heightRatio } = this
    if (!widthRatio || !heightRatio) return
    return 100 / (widthRatio / heightRatio)
  }

  get containerInlineStyle () {
    return {
      width: this.width,
      height: this.height,
      paddingBottom: this.containerRatio ? `${this.containerRatio}%` : null,
      background: this.background
    }
  }

  get overlayInlineStyle () {
    return {
      background: this.background
    }
  }

  get containerClasses () {
    return {
      'with-ratio': !!this.containerRatio,
      'is-loaded': this.isLoaded,
    }
  }

  get imageElementInlineStyle () {
    return {
      objectFit: this.cover ? 'cover' : null ||
                 this.contain ? 'contain' : null,
    }
  }

  get containerWidth () {
    return `${this.elWidth}px`
  }

  initIntersectionObserver () {
    const options = { rootMargin: '0px', threshold: [0, 0, 0, 0] }
    this.observer = new IntersectionObserver(this.onEnterViewport as IntersectionObserverCallback,  options)
    this.observer.observe(this.$el)
  }

  onImageLoad () {
    setTimeout(() => {
      this.isLoading = false
      this.isLoaded = true
      this.error = false
      // disconnect observer
      if (this.lazy) this.observer!.disconnect()
    }, this.delay)
  }

  onImageError () {
    setTimeout(() => {
      this.isLoading = false
      this.isLoaded = false
      this.error = true
    }, this.delay)
  }

  onEnterViewport (entries: IntersectionObserverEntry[]) {
    if (entries[0].isIntersecting) {
      if (!this.isLoaded && !this.error) {
        this.lazySrc = this.src
        this.lazySrcset = this.srcset
      }
      if (this.error) {
        console.log(' !!!!!!!!!!!!!!! change src set on eror')
        this.lazySrc = `${this.src}?action=reload&date=${new Date().getTime()}`
        this.lazySrcset = this.srcset
      }
    }
  }

}
