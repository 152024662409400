
import {Vue, Component, Prop } from 'vue-property-decorator'
import CustomWrapper from '@/components/CustomWrapper.vue'

@Component({
  name: 'c-simple-card-content',
  components: { CustomWrapper }
})
export default class CSimpleCardContent extends Vue {
  @Prop({ type: String, default: 'div' }) tag!: string
}
