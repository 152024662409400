import { Vue, Component } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

import { Realm, Scalars } from '@/@types/graphql'
import * as REALMS from '@/@next/store/realms/types'

@Component({ name: 'realms-mixin' })
export default class Realms extends Vue {

  @Getter(REALMS.GET_ALL)
  _allRealms!: Realm[]
  @Getter(REALMS.GET_CURRENT)
  _currentRealm!: Realm

  @Mutation(REALMS.SET_ALL)
  _setAllRealms!: (realms: Realm[]) => void
  @Mutation(REALMS.SET_CURRENT)
  _setCurrentRealm!: (id: string) => void
}
