export const messages = {
  fr: {
    pageName: 'Media seul'
  },
  en: {
    pageName: 'Single media'
  },
  us: {
    pageName: 'Single media'
  },
  da: {
    pageName: 'Single media'
  },
  es: {
    pageName: 'Single media'
  },
  nl: {
    pageName: 'Single media'
  },
  de: {
    pageName: 'Single media'
  },
  it: {
    pageName: 'Single media'
  },
  pt: {
    pageName: 'Single media'
  },
  zh: {
    pageName: 'Single media'
  }
}
