import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class Input extends Vue {
  @Prop({ type: Boolean }) required!: boolean
  @Prop({ type: Boolean }) disabled!: boolean
  @Prop({ type: String }) name!: string
  @Prop() value!: any
  @Prop() nativeValue!: any

  newValue: any = this.value

  get computedValue () {
    return this.newValue
  }

  set computedValue (val: any) {
    this.newValue = val
    this.$emit('input', val)
  }

  @Watch('value')
  onValueChange (val: any) {
    this.newValue = val
  }
}
