export const messages = {
  fr: {
    pageName: 'Collection seule',
    letsGo: 'Allons-y !',
    learnMore: 'En savoir plus',
    goToMap: 'Voir sur le plan'
  },
  en: {
    pageName: 'Single collection',
    letsGo: 'Let\'s go !',
    learnMore: 'Learn more',
    goToMap: 'See on the map'
  },
  us: {
    pageName: 'Single collection',
    letsGo: 'Let\'s go !',
    learnMore: 'Learn more',
    goToMap: 'See on the map'
  },
  da: {
    pageName: 'Single collection',
    letsGo: 'Let\'s go !',
    learnMore: 'Learn more',
    goToMap: 'See on the map'
  },
  es: {
    pageName: 'Single collection',
    letsGo: 'Vamos',
    learnMore: 'Más información',
    goToMap: 'Ver en el mapa'
  },
  nl: {
    pageName: 'Single collection',
    letsGo: 'Let\'s go !',
    learnMore: 'Learn more',
    goToMap: 'Zie op de kaart'
  },
  de: {
    pageName: 'Single collection',
    letsGo: 'Let\'s go !',
    learnMore: 'Learn more',
    goToMap: 'Siehe auf der Karte'
  },
  it: {
    pageName: 'Single collection',
    letsGo: 'Let\'s go !',
    learnMore: 'Learn more',
    goToMap: 'Vedere sulla mappa'
  },
  pt: {
    pageName: 'Single collection',
    letsGo: 'Let\'s go !',
    learnMore: 'Learn more',
    goToMap: 'Ver no mapa'
  },
  zh: {
    pageName: 'Single collection',
    letsGo: 'Let\'s go !',
    learnMore: 'Learn more',
    goToMap: 'See on the map'
  }
}
