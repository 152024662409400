
import { Vue, Component, Prop } from 'vue-property-decorator'
import CustomWrapper from '@/components/CustomWrapper.vue'
import { messages } from '@/@next/i18n'

const config = {
  name: 'c-collection-card',
  i18n: { messages },
  components: { CustomWrapper }
}

@Component(config)
export default class CCollectionCard extends Vue {

  @Prop({ type: String, default: 'div' }) tag!: string
  @Prop({ type: Boolean, default: false }) rounded!: boolean
  @Prop({ type: Boolean, default: false }) shadow!: boolean
  @Prop({ type: Boolean, default: false }) noTextBackground!: boolean
  @Prop({ type: String }) lockedId!: string
  @Prop({ type: Boolean, default: false }) hasLocks!: boolean

  startAnimation: boolean = true;
  animTimeout!: any;
  messages = messages

  get CSSClasses () {
    return {
      '--Rounded': this.rounded,
      '--Shadow': this.shadow
    }
  }

  get unlocked () {
    return this.hasLocks && !this.lockedId
  }

  clickOnLockedContent () {
    if (!this.startAnimation) {
      this.startAnimation = true
      this.animTimeout = setTimeout(() => this.startAnimation = false, 12000)
    } else {
      this.startAnimation = false
      if (this.animTimeout) clearTimeout(this.animTimeout)
    }
  }

  enterTicket (e: any) {
    console.log('enterTicket')
    this.$emit('unlock')      
    e.stopPropagation()
    e.preventDefault()
  }

  get lockText () {
    if (this.$i18n.locale === 'en-US') {
      return this.messages.en.lockText
    }
    return this.$t('lockText')
  }

  get unLock () {
    if (this.$i18n.locale === 'en-US') {
      return this.messages.en.unLock
    }
    return this.$t('unLock')
  }
}
