
import { Vue, Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import * as SETTINGS from '@/store/settings/types'
import { SettingsMixin } from '@/@next/mixins'

import * as UI from '@/event-bus'

const config = {
  name: 'l-default-topbar',
  components: {}
}

@Component(config)
export default class LDefaultTopbar extends Mixins(SettingsMixin) {
  @Prop({ type: Object, required: true }) data!: any

  @Getter(SETTINGS.GET_ALL)
  allSettings!: any

  showMenu: boolean = false
  
  @Watch('$route', { immediate: true })
  async onRouteChange () {
    this.showMenu = this.$route.query.menu === 'force'
  }

  onToggleMenuBtnClick () {
    UI.EventBus.$emit(UI.SHOW_DEFAULT_MENU)
  }

  onQrCodeClick () {
    UI.EventBus.$emit(UI.SHOW_DEFAULT_QR_SCAN)
  }

  get routeIsSearch () {
    return this.$route.name === 'search'
  }

  get canPrev () {
    if (this.showMenu) return false
    if ((this.$route.name === 'home')) {
      return false
    }
    return true
  }

  get computedCSSClasses () {
    return {
      'is-Hidden': this.data.isTopbarHidden
    }
  }

  get computedTopbarLogoCSSClasses () {
    const qrCodeEnabled = this._settingsFeatures && Array.isArray(this._settingsFeatures)
      && this._settingsFeatures.find((item: string) => item === 'qrcode')
    if (qrCodeEnabled)
      return {
        '--PushLogo': true,
      }
  }

  get computedTopbarTitleStyle () {
    const qrCodeEnabled = this._settingsFeatures && Array.isArray(this._settingsFeatures)
      && this._settingsFeatures.find((item: string) => item === 'qrcode')
    if (qrCodeEnabled)
    return {
      'margin-right': '-40px',
      'padding':  '0 40px 0 0'
    }
  }

}
