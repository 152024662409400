
import { Component, Mixins, Prop } from 'vue-property-decorator'

import CustomTagMixin from '@/@next/mixins/CustomTag'

const config = {
  name: 'c-content-text'
}

@Component(config)
export default class Text extends Mixins(CustomTagMixin) {

  @Prop({ type: String, required: false }) id!: string
  @Prop({ type: String, required: true }) text!: string
  @Prop({ type: Array, required: false }) people!: any[]

}
