import CQuizz from './Quizz.vue'
import CQuizzMedia from './QuizzMedia.vue'
import CQuizzSentence from './QuizzSentence.vue'
import CQuizzChoices from './QuizzChoices.vue'
import CQuizzChoicesItem from './QuizzChoicesItem.vue'

export {
  CQuizz,
  CQuizzMedia,
  CQuizzSentence,
  CQuizzChoices,
  CQuizzChoicesItem
}

export default {
  CQuizz,
  CQuizzMedia,
  CQuizzSentence,
  CQuizzChoices,
  CQuizzChoicesItem
}
