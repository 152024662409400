
import { Component, Mixins, Watch } from 'vue-property-decorator'
import Matomo from 'matomo-ts'

import { messages } from './i18n'

import { Maybe, CollectionList, Collection, PointOfInterest, HtmlTranslation } from '@/@types/graphql'
import { MATOMO_URL, CLIENT, MAP_URL } from '@/constants'

import { CollectionsService, PointsOfInterestService, MediasService } from '@/@next/services'
import { SaveScrollMixin, SettingsMixin } from '@/@next/mixins'

import { CAsyncTask } from '@/@next/components/async-task'
import { CInfiniteScroll } from '@/@next/components/infinite-scroll'
import CCollectionCard from '@/components/cards/collection-card'
import CSimpleCard from '@/components/cards/simple-card'
import { CTopbarTitle } from '@/@next/components/topbar-title'
import { CImage } from '@/@next/components/image'
import { CLoader } from '@/components/loader'
import { CSection, CSectionTitle, CSectionContent } from '@/components/section'
import { CInput } from '@/components/form-controls/input'
import { CButton } from '@/components/form-controls/button'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'
import CLanguageSelector from '@/@next/components/LanguageSelector.vue'
import {
  ExternalRedirection,
  InternalRedirection,
  MenuAction,
  MenuItem,
  RouteWithoutParameterItem
} from '@/@types/menu'
import addProtocol from '@/utils/addProtocol'
import { filterPublishableItem } from '@/@next/helpers/publishable'
import { Getter, Mutation } from 'vuex-class'
import * as LANGUAGES from '@/@next/store/languages/types'
import { Language } from '@/shared/languages'
import { LanguageCode } from '@/@next/@types'
import { upperFirst } from 'lodash'
import * as AUTHORIZATION from '@/@next/store/authorization'

const config = {
  name: 'v-home',
  components: {
    CAsyncTask,
    CTopbarTitle,
    CInput,
    CButton,
    CSection,
    CSectionTitle,
    CSectionContent,
    ...CSimpleCard,
    ...CCollectionCard,
    CImage,
    CLoader,
    CInfiniteScroll,
    CLanguageSelector
  },
  i18n: { messages },
  head () {
    return {
      title: this.$t('pageName'),
      titleTemplate: '%s – Wivi'
    }
  }
}

type HomeItem = Collection | PointOfInterest

@Component(config)
export default class VHome extends Mixins!(
  SaveScrollMixin,
  CollectionsService,
  SettingsMixin,
  PointsOfInterestService,
  MediasService
) {

  @Getter(LANGUAGES.GET_CURRENT) getCurrentLanguageISO!: LanguageCode
  @Getter(LANGUAGES.GET_ALL)
  getLanguages!: Language[]
  @Mutation(AUTHORIZATION.SET_SHOW_TICKET_INPUT)
  setShowTicketInput!: (show: boolean) => void

  collectionList: Maybe<CollectionList> = null
  items: Array<Collection | PointOfInterest | any> = []
  newsletterEmailShow: boolean = true
  newsletterEmailVal: string | null = null
  capitalizeFirstLetter = capitalizeFirstLetter
  messages = messages
  languageModalIsOpen: boolean = false

  async init () {
    this.checkLogoTopBar()
    if (!this.homePageItems) return this.items = filterPublishableItem(await this.allCollectionsItems())
    const items: Array<HomeItem | null> = await Promise.all(this.homePageItems.map((i: any) => {
      switch (i.__typename) {
        case 'collection':
          return this.GET_SINGLE_COLLECTION(i.contentId)
        case 'pointOfInterest':
          return this.GET_SINGLE_POI(i.contentId)
        case 'custom':
          return this.formatCustomMenuItem(i)
        default:
          return null
      }
    }))
    this.items = filterPublishableItem(items)
    if (CLIENT === 'muma' || CLIENT === 'paleospace') {
      const titleFilter = (i: Collection | PointOfInterest) => {
        return i.i18n.title?.language === this.getCurrentLanguageISO
      }
      this.items = this.items.filter(titleFilter)
    }
  }

  async formatCustomMenuItem (i: any) {
    const i18n = {
      title: i.title ? this.findI18n(i.title) : null,
      abstract: i.subtitle ? this.findI18n(i.subtitle) : null
    }
    const fomatedItem = { ...i, i18n, shaking: false }
    try {
      if (i.imageId) fomatedItem.image = await this.GET_SINGLE_IMAGE(i.imageId)
    } catch (e) {
      console.warn('[Home] Error during fetching image card item', e)
    }
    return fomatedItem
  }

  get currentLanguage () {
    return this.getLanguages.find((language) => language.iso === this.getCurrentLanguageISO)
  }

  checkLogoTopBar () {
    try {
      const logoTopBar = new Image();
      logoTopBar.addEventListener('load', () => {
        const customElm = document.getElementById('logo-topbar-custom')
        const defaultElm = document.getElementById('logo-topbar-default')
        if (!customElm || !defaultElm) return
        customElm.style.display = 'block'
        defaultElm.style.display = 'none'
      }, false)
      logoTopBar.src = './logo-topbar.png';
    } catch (e) {
      console.warn('Error during logo topBar verification.', e)
    }
  }

  get homePageItems () {
    return this._settingsHomepage?.items || null
  }

  get settingsCardTitle () {
    const fallback = this.messages.fr.settingsLinkLabel.title
    if (!this.getCurrentLanguageISO) return fallback
    // @ts-ignore
    return this.messages[this.getCurrentLanguageISO]?.settingsLinkLabel?.title || fallback
  }

  get settingsCardSubTitle () {
    const fallback = this.messages.fr.settingsLinkLabel.subtitle
    if (!this.getCurrentLanguageISO) return fallback
    // @ts-ignore
    return this.messages[this.getCurrentLanguageISO]?.settingsLinkLabel?.subtitle || fallback
  }

  get languageCardTitle () {
    const fallback = this.messages.fr.languageLinkLabel.title
    if (!this.getCurrentLanguageISO) return fallback
    // @ts-ignore
    return this.messages[this.getCurrentLanguageISO]?.languageLinkLabel?.title || fallback
  }

  findI18n (text: HtmlTranslation[])  {
    if (!text || !text.length) return null
    const translation = text.find((t) => t.language === this.getCurrentLanguageISO)
    if (translation) return translation
    const fallback = text.find((t) => t.language === this.$i18n.fallbackLocale)
    if (fallback) return fallback
    return null
  }

  findTranslation (text: HtmlTranslation[]) {
    if (!text || !text.length) return null
    // TODO: ADD target filter
    const translation = text.find((t) => t.language === this.getCurrentLanguageISO)
    if (translation) return translation.value.html
    const fallback = text.find((t) => t.language === this.$i18n.fallbackLocale)
    if (fallback) return fallback.value.html
    return null
  }

  cardPath (action: InternalRedirection | ExternalRedirection) {
    if (action.__typename === MenuAction.externalRedirection) return addProtocol(action?.url)
    else if (action.__typename === MenuAction.internalRedirection) {
      const url = `${action.route.name}`
      if (action.route.__typename === 'RouteWithoutParameter') return url
      return `${url}/${action.route.param}`
    }
    return ''
  }

  get cards () {
    // if (!this._settingsHomepage?.cards) return null
    return this._settingsHomepage?.cards || null
  }

  getCardColor (style: any) {
    if (!style) return
    const { color } = style
    if (!color) return
    if (color === 'primaryColor' || color === 'secondaryColor') return `var(--${color})`
    return color
  }

  getCardTextColor (style: any) {
    if (!style) return
    const { color, textColor } = style
    if (!color && !textColor) return
    if (textColor) return textColor
    if (color === 'primaryColor' || color === 'secondaryColor') return `var(--on${upperFirst(color)})`
    return 
  }

  async allCollectionsItems () {
    const collections = await this.GET_ALL_COLLECTIONS()
    const list  = { ...collections, items: collections.items.sort((a: Collection, b: Collection) => {
      return new Date(a.publishedAt) < new Date(b.publishedAt) ? 1 : -1
    }) }
    if (CLIENT === 'juno' || CLIENT === 'webbvisit' || CLIENT === 'paleospace') {
      const hasTitle = (c: Collection) => c.i18n.title && c.i18n.title.value.text.length > 3
      const availableInCurrentLanguage = (c: Collection) => c.i18n.title?.language === this.getCurrentLanguageISO
      const poiHasTitle = (p: PointOfInterest) => p.i18n.title && p.i18n.title.value.text.length > 3
      const atLeastOnePoiHasTitle = (c: Collection) => c.pointsOfInterest.items.filter(poiHasTitle).length > 0
      return list?.items.filter(hasTitle).filter(availableInCurrentLanguage).filter(atLeastOnePoiHasTitle)
    }
    return list?.items
  }

  get collections () {
    if (CLIENT === 'juno' || CLIENT === 'webbvisit' || CLIENT === 'paleospace') {
      const hasTitle = (c: Collection) => c.i18n.title && c.i18n.title.value.text.length > 3
      const availableInCurrentLanguage = (c: Collection) => c.i18n.title?.language === this.getCurrentLanguageISO
      const poiHasTitle = (p: PointOfInterest) => p.i18n.title && p.i18n.title.value.text.length > 3
      const atLeastOnePoiHasTitle = (c: Collection) => c.pointsOfInterest.items.filter(poiHasTitle).length > 0
      return this.collectionList?.items
        .filter(hasTitle)
        .filter(availableInCurrentLanguage)
        .filter(atLeastOnePoiHasTitle)
    }
    return this.collectionList?.items
  }

  activated () {
    if (MATOMO_URL) {
      Matomo.default().trackPageView('home')
    }
  }

  async fetch () {
    return
  }

  get showJQCard () {
    const paleospace = CLIENT === 'paleospace'
    const french = this.getCurrentLanguageISO === 'fr'
    return paleospace && french
  }

  get showSelfieDinoCard () {
    return CLIENT === 'paleospace'
  }

  goToJQ () {
    // @ts-ignore
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage('GO_TO_JQ')
    } else {
      window.location.assign('http://jurassic-quest.wivi.fr')
    }
  }

  goToSelfieDino () {
    // @ts-ignore
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage('GO_TO_SELFIE')
    } else {
      window.location.assign('http://paleospace-selphie.wivi.fr')
    }
  }

  handleNewsletterFormSubmit () {
    this.newsletterEmailVal = null
    this.newsletterEmailShow = false
  }

  clickOnHomeCard (item: Collection | PointOfInterest | any) {
    if (item?.action?.locked) {
      item.shaking = true
      setTimeout(() => item.shaking = false, 400)
      return
    }
    switch (item.__typename) {
      case 'PointOfInterest':
        return this.$router.push({ name: 'single-poi', params: { uuid: item.id } })
      case 'Collection':
        return this.$router.push({ name: 'single-collection', params: { uuid: item.id } })
      case 'custom':
        return this.$router.push({ path: item.action.route.name })
    }
  }

  // computeItemRoute (item: Collection | PointOfInterest | any) {
  //   if (item.action.locked) return
  //   switch (item.__typename) {
  //     case 'PointOfInterest':
  //       return { name: 'single-poi', params: { uuid: item.id } }
  //     case 'Collection':
  //       return { name: 'single-collection', params: { uuid: item.id } }
  //     case 'custom':
  //       return { path: item.action.route.name }
  //   }
  // }

  get smallLanguageCard () {
    return CLIENT === 'caenmemorial' || CLIENT === 'tatihou'
  }

  openTicketModal () {
    this.setShowTicketInput(true)
  }

}
