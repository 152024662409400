








import { Vue, Component, Prop } from 'vue-property-decorator'

const config = {
  name: 'icon-drop-down',
  components: {}
}

@Component(config)
export default class IconDropDown extends Vue {}
