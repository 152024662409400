
import { Vue, Component, Prop } from 'vue-property-decorator'
import CustomWrapper from '@/components/CustomWrapper.vue'

@Component({
  name: 'c-section',
  components: { CustomWrapper }
})
export default class CSection extends Vue {
  @Prop({ default: 'section'}) tag!: string
}
