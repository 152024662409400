
import { Vue, Component, Prop } from 'vue-property-decorator'

import CustomWrapper from '@/components/CustomWrapper.vue'

const config = {
  name: 'c-audio-card-summary',
  components: { CustomWrapper }
}

@Component(config)
export default class CAudioCardSummary extends Vue {

  @Prop({ type: String, default: 'div' }) tag!: string

}
