import { MutationTree } from 'vuex'

import * as GAME from './types'
import { GameState } from '@/@next/store/game'

export const mutations: MutationTree<GameState> =  {

  [GAME.COMPLETE_GAME] (state, id: string) {
    const game = state.games.find((game: any) => game.id === id)
    if (game) {
      game.completed = true
    }
  },

  [GAME.CHANGE_USER_ANSWERS] (state, { id, answer, fail, completed }) {
    const game = state.games.find((game: any) => game.id === id)
    if (game) {
      game.userAnswer = answer
      game.fail = fail
      game.completed = completed || false
    }
  },

  [GAME.RESET_GAME] (state) {
    state.games = state.games.map((game: any) => {
      return {
        id: game.id,
        completed: false,
        userAnswer: null,
        fail: null
      }
    })
  },

  setAllGameFinishTest (state) {
    state.games = state.games.map((game: any) => {
      return {
        ...game,
        completed: true,
        fail: false
      }
    })
  }

}
