import CModal from './Modal.vue'
import CModalHeader from './ModalHeader.vue'
import CModalContent from './ModalContent.vue'
import CModalFooter from './ModalFooter.vue'

export {
  CModal,
  CModalHeader,
  CModalContent,
  CModalFooter
}

export default {
  CModal,
  CModalHeader,
  CModalContent,
  CModalFooter
}
