
import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import CustomWrapper from '@/components/CustomWrapper.vue'

const config = {
  name: 'c-lazy-loading-image',
  components: { CustomWrapper }
}

@Component(config)
export default class CLazyLoadingImage extends Vue {

  @Prop({ type: String, default: 'div' }) tag!: string
  @Prop({ type: String, required: true }) src!: string
  @Prop({ type: String,  default: '' }) alt!: string
  @Prop({ type: Number, default: 0 }) delay!: number
  @Prop({ type: Boolean, default: false }) cover!: boolean
  @Prop({ type: Boolean, default: false }) contain!: boolean
  @Prop({ type: Boolean, default: false }) relative!: boolean
  @Prop({ type: Boolean, default: false }) transparent!: boolean
  @Prop({ type: String }) width!: string
  @Prop({ type: String }) height!: string
  @Prop({ type: Function, default: () => null }) onLoaded!: any

  injectedSrc: string | null = null
  isLoading: boolean = true
  isLoaded: boolean = false
  error: boolean = false
  observer: IntersectionObserver | null = null

  mounted () {
    this.initIntersectionObserver()
  }

  beforeDestroy () {
    this.observer!.disconnect()
  }

  get loadedCssClass () {
    return {
      'is-Loaded': this.isLoaded,
      'is-Loading': this.isLoading,
      '--Cover': this.cover,
      '--Relative': this.relative,
      '--Transparent': this.transparent
    }
  }

  get inlineStyle () {
    return {
      'width': this.width,
      'height': this.height
    }
  }

  @Watch('src')
  onImageSrcChange (newSrc: string) {
    this.isLoading = true
    this.isLoaded = false
    this.error = false
    this.injectedSrc = newSrc
  }

  initIntersectionObserver () {
    const options = {
      rootMargin: '0px',
      threshold: [0, 0, 0, 0]
    }
    this.observer = new IntersectionObserver(this.onEnterViewport as IntersectionObserverCallback,  options)
    this.observer.observe(this.$el)
  }

  onImageLoad () {
    setTimeout(() => {
      this.isLoading = false
      this.isLoaded = true
      this.error = false
      // disconnect obersver
      this.observer!.disconnect()
    }, this.delay)
  }

  onImageError () {
    setTimeout(() => {
      this.isLoading = false
      this.isLoaded = false
      this.error = true
    }, this.delay)
  }

  onEnterViewport (entries: IntersectionObserverEntry[]) {
    if (entries[0].isIntersecting) {
      if (!this.isLoaded && !this.error) {
        this.injectedSrc = this.src
      }
      if (this.error) {
        this.injectedSrc = `${this.src}?action=reload&date=${ new Date().getTime() }`
      }
    }
  }

}
