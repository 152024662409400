import { Mixins, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'

import { LanguagesMixin } from '@/@next/mixins'
import { PaginationOptions, SortArgs, CollectionList, Collection, Scalars } from '@/@types/graphql'

import { MediaImageFragment } from './fragments'

@Component({ name: 'collections-service' })
export default class Collections extends Mixins(LanguagesMixin) {

  async GET_ALL_COLLECTIONS (
    pagination: PaginationOptions = {},
    sort: SortArgs = {}
  ): Promise<CollectionList> {
    const variables = { pagination, sort }
    const query = gql`

        ${ MediaImageFragment }

        query collections ($pagination: PaginationArgs, $sort: SortArgs) {
            collections (pagination: $pagination, sort: $sort) {
                itemsCount
                totalCount
                pagination {
                    skip
                    take
                }
                sort {
                    field,
                    order
                }
                items {
                    id
                    slug
                    shortCode
                    publishedAt
                    locks { 
                      slug 
                      data
                      # i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                      #   unlockingInformation { value { text html } language  }
                      # }
                    }
                    i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                        title { value { text html } language  }
                        abstract { value { text html } language  }
                        description { value { text html } language  }
                    }
                    image { ...MediaImageFragment }
                  pointsOfInterest {
                    items {
                      id
                      image { ...MediaImageFragment }
                      i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                        title { value { text html } language  }
                      }
                      publishedAt
                    }
                  }
                }
            }
        }
    `
    const { data } = await this.$apollo.query({ query, variables })
    return data.collections
  }

  async GET_SINGLE_COLLECTION (
    id: Scalars['UUID'],
    pointsOfInterestPagination: PaginationOptions = {}
  ): Promise<Collection> {
    const variables = { id, pointsOfInterestPagination }
    const query = gql`

      ${ MediaImageFragment }

      query collection($id: UUID!, $pointsOfInterestPagination: PaginationArgs) {
          collection (id: $id) {
              id
              slug
              shortCode
              languages
              publishedAt
              name
              i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                  title { value { text html } language  }
                  abstract { value { text html } language  }
                  description { value { text html } language  }
              }
              image { ...MediaImageFragment }
              placeholders {
                id
                floor { 
                  id
                  name 
                  i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                      title { value { text html } language  }
                      abstract { value { text html } language  }
                      description { value { text html } language  }
                  }
                }
                map {
                  id
                  name 
                  i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                      title { value { text html } language  }
                      abstract { value { text html } language  }
                      description { value { text html } language  }
                  }
                }
              }
              locks { 
                slug 
                data
                # i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                #   unlockingInformation { value { text html } language  }
                # }
              }
              pointsOfInterest (pagination: $pointsOfInterestPagination) {
                  itemsCount
                  totalCount
                  sort { field order}
                  items {
                      id
                      publishedAt
                      slug
                      shortCode
                      languages
                      audioGuideTracks {
                          id
                          shortCode
                          i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }, target: ${ this._languageTarget || null }) {
                            audio { target }
                            video { target }
                        }
                      }
                      i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                          title { value { text html } language  }
                          abstract { value { text html } language  }
                          description { value { text html } language  }
                      }
                      image { ...MediaImageFragment }
                      people {
                        id
                        name { value { text html } language }
                        bornAt
                        diedAt
                        role {
                          id
                          slug
                          name { language value }
                        }
                        image { ...MediaImageFragment }
                        i18n (languages: ${ JSON.stringify(this._allLanguageCodes) }) {
                          bio { value { text html } language }
                        }
                      }
                  }
              }
          }
      }
    `
    // audioGuideTracks { id }
    const { data } = await this.$apollo.query({ query, variables })
    return data.collection
  }
}
