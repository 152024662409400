
import { Vue, Component, Watch, Mixins } from 'vue-property-decorator'
import { QrcodeStream } from 'vue-qrcode-reader'
import { Carousel, Slide } from 'vue-carousel'
import {CLoader} from '@/components/loader'

import * as UI from '@/event-bus'
import addProtocol from '@/utils/addProtocol'
import { API_URL, CLIENT, REDIRECT_URL } from '@/constants'
import { SettingsMixin } from '@/@next/mixins'
import { DatasetService } from '@/@next/services'

const config = {
  name: 'l-default-qr-scan',
  components: {
    QrcodeStream,
    CCarousel: Carousel,
    CCarouselItem: Slide,
    CLoader
  }
}

@Component(config)
export default class LDefaultQrScan extends Mixins(SettingsMixin, DatasetService) {

  content: any = null
  qrCodeReaderReady: boolean = false
  tutorialOpen: boolean = true
  countSlide: number = 2
  currentSlide: number = 0
  manualQrCodeSearchTerm: string = ''
  identifiers: any = {}
  manualQrCodeSearchFail: boolean = false
  noCamera: boolean = false
  cameraNotAllowed: boolean = false
  showQRCodeWarning: boolean = false
  showQRCodeSearchButton: boolean = false

  @Watch('showQRCodeWarning')
  onShowQRCodeWarningUpdate () {
    setTimeout(() => {
      this.showQRCodeWarning = false
    }, 5000)
  }

  @Watch('showQRCodeSearchButton')
  onShowQRCodeSearchButtonUpdate () {
    setTimeout(() => {
      this.showQRCodeSearchButton = false
    }, 5000)
  }
  
  // async initPegasusQrcodeFetching () {
  //   const json = await this.GET_DATASET('qrcode')
  //   if (!json?.value?.identifier) return
  //   this._setSettingsQRCodes(json.value.identifier)
  // }

  get insideReactNativeWebview () {
    // @ts-ignore
    return window.ReactNativeWebView
  }

  initReactNativceContext () {
    if (!this.insideReactNativeWebview) return
    console.info('[QRCODE] React Native Context initialized - Set External Qrcode Reader on Webview')
    // @ts-ignore
    window.ReactNativeWebView.postMessage('OPEN_QRCODE_IN_ANDROID_APK')
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify({
      type: 'SCAN_QRCODE_WIVI_URL',
      // @ts-ignore
      function: 'onQrCodeDecoded'
    }))
    // @ts-ignore
    window.__WIVI_APP__ = {}
    // @ts-ignore
    window.__WIVI_APP__.onQrCodeDecoded = this.onQrCodeDecoded
    // @ts-ignore
    window.__WIVI_APP__.closeQrCodeReader = UI.EventBus.$emit(UI.HIDE_DEFAULT_QR_SCAN)
  }

  async initQRCodeRedirectValues () {
    try {
      let file = CLIENT
      // Change webbvisit project name to explorejuno
      if (file === 'webbvisit' || file === 'juno') file = 'explorejuno'
      // Change utah project name to utahinside
      else if (file === 'utah') file = 'utahinside'
      // fetch redirect json file
      const url = addProtocol(`${REDIRECT_URL}/namespaces/${file}`)
      const json = await this.$http.get(url, )
      console.log(CLIENT, json)
      // Set QRCODE Values
      if (json) this._setSettingsQRCodes(json.data)
    } catch (e) {
      console.warn('Error during fetch qrcodes in redirect.wivi.fr')
    }
  }

  async created () {
    this.initReactNativceContext()
    await this.initQRCodeRedirectValues()
    this.identifiers = this._settingsQRCodes
  }

  onCloseBtnClick () {
    UI.EventBus.$emit(UI.HIDE_DEFAULT_QR_SCAN)
  }

  @Watch('manualQrCodeSearchTerm')
  onManualQrCodeSearchTermChange (old: string, current: string) {
    if (old !== current) {
      this.manualQrCodeSearchFail = false
    }
  }

  async onInit (promise: Promise<any>) {
    try {
      await promise
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        // user denied camera access permisson
        this.cameraNotAllowed = true
      } else if (error.name === 'NotFoundError') {
        this.noCamera = true
        // no suitable camera device installed
      } else if (error.name === 'NotSupportedError') {
        // page is not served over HTTPS (or localhost)
      } else if (error.name === 'NotReadableError') {
        this.noCamera = true
        // maybe camera is already in use
      } else if (error.name === 'OverconstrainedError') {
        // passed constraints don't match any camera.
        // Did you requested the front camera although there is none?
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.noCamera = true
        // browser seems to be lacking features
      }
    } finally {
      // hide loading indicator
      this.qrCodeReaderReady = true
    }
  }

  redirectToPath (path: string) {
    this.$router.push(path)
    UI.EventBus.$emit(UI.HIDE_DEFAULT_QR_SCAN)
  }

  goToSearchView () {
    this.$router.push({ name: 'search' })
    UI.EventBus.$emit(UI.HIDE_DEFAULT_QR_SCAN)
  }

  changeSearchMethod () {
    console.warn('QR Code non reconnu')
    this.showQRCodeWarning = true
    this.showQRCodeSearchButton = true
  }

  // findPegasusRedirection (url: string) {
  //   const code = url.split('=').pop()
  //   if (!code) return this.changeSearchMethod()
  //   const identifier = this._settingsQRCodes[`poi${code}`]
  //   if (!identifier?.url) return this.changeSearchMethod()
  //   this.redirectToPath(identifier.url)
  // }

  findCodeInRedirectService (url: URL) {
    const code = url.pathname.split('/').pop() || url.search
    if (!code || !code.length) return this.changeSearchMethod()
    const identifier = this._settingsQRCodes[code]
    if (!identifier) return this.changeSearchMethod()
    const path = addProtocol(identifier.url).replace(/https?:\/\/[^\/]+/i, '')
    this.redirectToPath(path)
  }

  onQrCodeDecoded (decodedString: string) {
    const url = new URL(decodedString)
    if (!url.pathname || !url.href || !url.host) return this.changeSearchMethod()
    if (url.host === window.location.host) return this.redirectToPath(url.pathname + url.search)
    if (!this._settingsQRCodes) return this.changeSearchMethod()
    return this.findCodeInRedirectService(url)
    // this.currentSlide = 1
  }

  onManualQrCodeSearchSubmit () {
    if (!this._settingsQRCodes) return
    if (this.manualQrCodeSearchTerm in this._settingsQRCodes) {
      const identifier = this._settingsQRCodes[this.manualQrCodeSearchTerm]
      this.$router.push(identifier.url)
      UI.EventBus.$emit(UI.HIDE_DEFAULT_QR_SCAN)
    } else {
      this.manualQrCodeSearchFail = true
    }
  }

  authorizeCamera () {
    navigator.mediaDevices.getUserMedia({
      video: true
    }).then(() => {
      this.tutorialOpen = false
      this.cameraNotAllowed = false
    })
  }

  get QrScanOverlayCSSClasses () {
    return {
      '--Transparent': !this.noCamera
    }
  }

  get QrScanMenuCSSClasses () {
    return {
      '--Light': !this.noCamera
    }
  }

  get QrScanContentInlineStyles () {
    return {
      'width': `${this.countSlide * 100}%`,
      'transform': `translateX(-${(this.currentSlide * 100) / this.countSlide}%)`,
    }
  }

  get QrScanContentSlideInlineStyles () {
    return {
      'width': `${100 / this.countSlide}%`
    }
  }

  get searchQrCodeInputClass () {
    return {
      '--Danger': this.manualQrCodeSearchFail
    }
  }

  footerIsVisible () {
    return this.noCamera || this.cameraNotAllowed || this.showQRCodeSearchButton || this.showQRCodeWarning
  }

}
