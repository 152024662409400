
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "Content",
        "possibleTypes": [
          {
            "name": "AudioContent"
          },
          {
            "name": "AudioGuideContent"
          },
          {
            "name": "AudioGuideTrackContent"
          },
          {
            "name": "CollectionContent"
          },
          {
            "name": "ImageContent"
          },
          {
            "name": "ImageGalleryContent"
          },
          {
            "name": "PersonContent"
          },
          {
            "name": "PointOfInterestContent"
          },
          {
            "name": "PuzzleContent"
          },
          {
            "name": "QuizContent"
          },
          {
            "name": "QuestionContent"
          },
          {
            "name": "TextContent"
          },
          {
            "name": "VideoContent"
          },
          {
            "name": "SurveyContent"
          },
          {
            "name": "PopupContent"
          },
          {
            "name": "MemoryContent"
          },
          {
            "name": "TimelineContent"
          }
        ]
      }
    ]
  }
};
      export default result;
    