
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import CQuizz from '@/components/question'
import CCheckbox from '@/components/form-controls/checkbox/Checkbox.vue'
import Medias from '@/@next/services/Medias'
import {
  MultipleChoiceItem,
  AnswerType,
  MultipleChoiceImageItem,
  MultipleChoiceAudioItem,
  MultipleChoiceVideoItem
} from '@/@types/question'
import { MediaImage, MediaAudio, MediaVideo } from '@/@types/graphql'
import { CImage } from '@/@next/components/image'
import { CLoader } from '@/components/loader'
import Audio from '@/views/single-poi/contents/Audio.vue'
import addProtocol from '@/utils/addProtocol'
import { DomEvent } from 'leaflet'
import stopPropagation = DomEvent.stopPropagation

const config = {
  name: 'c-q-c-m-item',
  components: {
    ...CQuizz,
    CCheckbox,
    CImage,
    CLoader,
    Audio
  }
}
@Component(config)
export default class CQCMItem extends Mixins(Medias) {

  @Prop({ type: Object }) item!: MultipleChoiceItem
  @Prop({ type: Boolean }) isPlaying!: boolean
  @Prop({ type: Boolean }) isSelected!: boolean
  @Prop({ type: Boolean }) showResult!: boolean

  image: MediaImage | null = null
  audio: MediaAudio | null = null
  video: MediaVideo | null = null

  ready: boolean = false
  audioIsPlaying: boolean = false

  @Watch('isPlaying')
  isPlayingUpdate () {
    if (!this.audioId || !this.audioElement) return
    if (!this.isPlaying) this.audioElement.pause()
    this.audioIsPlaying = !this.audioElement.paused
  }

  async mounted () {
    if (this.imageId) this.image = await this.GET_SINGLE_IMAGE(this.imageId)
    if (this.audioId) {
      this.audio = await this.GET_SINGLE_AUDIO(this.audioId)
      // this.audioElement.addEventListener('ended', () => {
      //   console.log('end')
      //   this.audioIsPlaying = false
      // })
      // this.audioElement.addEventListener('timeupdate', () => {
      //   this.barElement.value = this.audioElement.currentTime * 100 / this.audioElement.duration
      // });
      //
      // this.progressElement.addEventListener('click',  (e) => {
      //   if (!e.target) return
      //   // @ts-ignore
      //   const percent = e.offsetX / e.target.offsetWidth
      //   this.barElement.value = percent * 100
      //   this.audioElement.currentTime = percent * this.audioElement.duration
      // })
      this.ready = true
    }
    if (this.videoId) this.video = await this.GET_SINGLE_VIDEO(this.videoId)
  }

  beforeDestroy () {
    if (!this.audioId) return
    this.audioElement?.removeEventListener('timeupdate', () => { return })
    this.audioElement?.removeEventListener('ended', () => { return })
    this.progressElement?.removeEventListener('click', () => { return })
  }

  get text () {
    // TODO : add target filter
    const body = this.item.body.find((el: any) => el.language === this.$i18n.locale)
    if (!body) return this.item.body[0]?.value.html || ''
    return body.value.html
  }

  get imageId () {
    // TODO : add target filter
    const { imageRefs } = this.item as MultipleChoiceImageItem
    if (!imageRefs) return
    const imageRef = imageRefs.find((el: any) => el.language === this.$i18n.locale)
    return imageRef ? imageRef.id : imageRefs[0]?.id || null
  }

  get audioId () {
    // TODO : add target filter
    const { audioRefs } = this.item as MultipleChoiceAudioItem
    if (!audioRefs) return
    const audioRef = audioRefs.find((el: any) => el.language === this.$i18n.locale)
    return audioRef ? audioRef.id : audioRefs[0]?.id || null
  }

  get audioUrl () {
    if (!this.audio || !this.audio.file) return null
    return addProtocol(this.audio.file.url)
  }

  get audioElement () {
    // if (!this.audioId) return
    return document.getElementById(`item-player-${this.audioId}`) as HTMLAudioElement
  }

  get progressElement () {
    // if (!this.audioId) return
    return document.getElementById(`item-progress-${this.audioId}`) as HTMLProgressElement
  }

  get barElement () {
    // if (!this.audioId) return
    return document.getElementById(`item-bar-${this.audioId}`) as HTMLProgressElement
  }

  get videoId () {
    // TODO : add target filter
    const { videoRefs } = this.item as MultipleChoiceVideoItem
    if (!videoRefs) return
    const videoRef = videoRefs.find((el: any) => el.language === this.$i18n.locale)
    return videoRef ? videoRef.id : videoRefs[0]?.id || null
  }

  onAudioPlayPauseClick (e: Event) {
    e.stopImmediatePropagation()
    e.stopPropagation()
    this.audioElement.paused ? this.audioElement.play() : this.audioElement.pause()
    if (!this.audioElement.paused) {
      this.audioElement.currentTime = 0
      this.audioElement.addEventListener('ended', () => {
        this.audioIsPlaying = false
        this.audioElement.removeEventListener('ended', () => { return })
      })
    } else {
      this.audioElement.removeEventListener('ended', () => { return })
    }
    this.audioIsPlaying = !this.audioElement.paused
    this.$emit('playPause')
  }

  get cssClasses () {
    return {
      '--Selected': !this.showResult && this.isSelected,
      '--Success': this.showResult && this.isSelected && this.item.correct,
      '--Error': this.showResult && this.isSelected && !this.item.correct,
      '--Response': this.showResult && !this.isSelected && this.item.correct
    }
  }

  select (e: Event) {
    e.preventDefault()
    e.stopImmediatePropagation()
    this.$emit('select')
  }

}
