export default function addProtocol (url: string) {
  if (!url) {
    throw TypeError('URL couldn\'t be undefined')
  }
  return /^http/.test(url) ? url : window.location.protocol + '//' + url
}

export function addSocketProtocol (url: string) {
  if (!url) {
    throw TypeError('URL couldn\'t be undefined')
  }
  if (/^ws/.test(url)) return url
  return window.location.protocol === 'https:' ? 'wss://' + url : 'ws://' + url
}
