
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
// @ts-ignore
import Puzzle from './Puzzle'

import { staticPath } from '@/utils/image'
import { Nullable } from '@/shared'

import CModal from '@/components/modal'
import { CLazyLoadingImage } from '@/components/images'
import { CLoader } from '@/components/loader'
import addProtocol from '@/utils/addProtocol';

const DEBUG = process.env.NODE_ENV !== 'production'

const config = {
  name: 'v-Single-poi-content-puzzle',
  components: {
    ...CModal,
    CLazyLoadingImage,
    CLoader
  }
}

@Component(config)
export default class VSinglePoiContentPuzzle extends Vue {

  @Prop({ type: Object, required: true }) content!: any

  puzzleModalIsOpen: boolean = false

  timer: number = 0
  startingDelay: number = 3
  puzzle: Nullable<Puzzle> = null
  // @ts-ignore
  // startingInter: number = setInterval(() => {/**/}) as any
  startingInter: any
  // @ts-ignore
  // timerInter: number = setInterval(() => {/**/}) as any
  timerInter: any

  savedScrollStop: number = 0

  @Watch('content')
  onContentChange () {
    // console.log('Puzzle Update !')
    this.destroyPuzzle()
    this.initPuzzle()
  }

  @Watch('puzzleModalIsOpen')
  onPuzzleModalStateChange () {
    const poiScrollContainer: Nullable<HTMLElement> = document.querySelector('.v-SinglePoi__Scrollable')
    if (this.puzzleModalIsOpen && poiScrollContainer) {
      this.savedScrollStop = poiScrollContainer.scrollTop
      poiScrollContainer.scrollTo(0, 0)
      poiScrollContainer.style.overflow = 'hidden'
      this.initPuzzle()
    } else if (!this.puzzleModalIsOpen && poiScrollContainer) {
      poiScrollContainer.style.overflow = ''
      poiScrollContainer.scrollTo(0, this.savedScrollStop)
      this.destroyPuzzle()
    }
  }

  initPuzzle () {
    this.timer = 0
    this.startingDelay = 3
    this.$nextTick(() => {
      this.puzzle = new Puzzle(this.$refs.canvas, staticPath(addProtocol(this.content.data.imageObj.image.url)), {
        xPieces: this.content.data.xPieces || 5,
        yPieces: this.content.data.yPieces || 6
      })
      this.puzzle.handleResize(true)
      this.puzzle.registerEndGameCallback(() => {
        console.log('game completed')
        // this.completeGame({ correct: true })
      })
      this.puzzle.registerAfterMoveCallback((info: string) => {
        console.log('after move =>', info)
      })
      this.startingInter = setInterval(() => {
        if (DEBUG) console.log('[puzzle] starting inter')
        this.startingDelay -= 1
        if (this.startingDelay === 0) {
          clearInterval(this.startingInter)
          this.puzzle.start()
          this.timerInter = setInterval(() => {
            if (DEBUG) console.log('[puzzle] timer inter')
            this.timer += 1
          }, 1000)
        }
      }, 1000)
    })
  }

  destroyPuzzle () {
    clearInterval(this.startingInter)
    clearInterval(this.timerInter)
    // this.puzzle.handleResize(false)
  }

}
