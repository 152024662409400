
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Nullable } from '@/@next/@types'

import { CustomTagMixin } from '@/@next/mixins'

const config = {
  name: 'c-person-card'
}

@Component(config)
export default class PersonCard extends Mixins(CustomTagMixin) {
  @Prop({ type: Boolean }) loading!: boolean
}
