import { Maybe, Dataset } from '@/@types/graphql'

// typescript
export interface SettingsDataset extends Omit<Dataset, 'value'> {
  value: Menu[]
}

export interface TextTranslation {
  [key: string]: {
    html: string
    text: string
  }
}

export interface Menu {
  items: MenuItem[]
  label: string
  realms: Maybe<any[]>
}

export interface MenuItem {
  content: any[]
  deletable: boolean
  enable: boolean
  icon: string
  label: string
  name: TextTranslation
  url: string
}

// getters
export const GET_MENU = 'settings2/GET_MENU'
export const GET_FEATURES = 'settings2/GET_FEATURES'
export const GET_QRCODES = 'settings2/GET_QRCODES'
export const GET_HOMEPAGE = 'settings2/GET_HOMEPAGE'
export const GET_MOVIES = 'settings2/GET_MOVIES'
// mutations
export const SET_MENU = 'settings2/SET_MENU'
export const SET_FEATURES = 'settings2/SET_FEATURES'
export const SET_QRCODES = 'settings2/SET_QRCODES'
export const SET_HOMEPAGE = 'settings2/SET_HOMEPAGE'
export const SET_MOVIES = 'settings2/SET_MOVIES'
