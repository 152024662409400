import gql from 'graphql-tag'
import { Language } from '@/shared/languages'

import MediaImageFragment from './../MediaImageFragment.graphql'
import ImageFileFragment from './../ImageFileFragment.graphql'
import PersonFragment from './../PersonFragment.graphql'
import MediaAudioFragment from './../MediaAudioFragment.graphql'
import AudioFileFragment from '../AudioFileFragment.graphql'
import MediaVideoFragment from './../MediaVideoFragment.graphql'
import { CLIENT } from '@/constants'

export default {
  getPoiById (languages: any[], fetchOptions: boolean = false) {
    const textTranslations = languages.map((lang) => lang.iso)
    const textTranslationsByKey = (content: string) => {
      const res = []
      for (const key in textTranslations) {
        if (textTranslations.hasOwnProperty(key)) {
          res.push(`${ textTranslations[key] } ${ content }`)
        }
      }
      return res.join('\n')
    }

    const optionsQuery = fetchOptions ? 'options {isAdditionalContent}' : ''
    const contentPuzzle = '... on ContentPuzzle {' +
      'imageId ' +
      'imageObj: image { ...MediaImageFragment } ' +
      'xPieces ' +
      'yPieces ' +
      'timer ' +
      'text { ' + textTranslationsByKey('{ text html }') + ' } }'

    return gql`
      
      # Fragments
      ${ MediaImageFragment }
      ${ ImageFileFragment }
      ${ PersonFragment }
      ${ MediaAudioFragment }
      ${ AudioFileFragment }
      ${ MediaVideoFragment }
      
      query pointOfInterest($id: String!) {
        pointOfInterest(id: $id) {
          id
          name {
            common
            ${ textTranslationsByKey(`{ text html }`) }
          }
          contents {
            id
            typeId
            ${optionsQuery}
            data {
              __typename
              ... on ContentQuestion {
                questionId
                question {
                  id
                  label
                  typeLabel
                  data {
                    __typename
                  }
                }
              }
              ... on ContentPoiAnchor {
                poiId
              }
             	... on ContentText {
              	body {
                  ${ textTranslationsByKey('{ text html }') }
                }
            	}
              ... on ContentAudio{
              	title {
                  common
                  ${ textTranslationsByKey('{ text html }') } 
                }
                description { 
                  common
                  ${ textTranslationsByKey('{ text html }') }
                }
                audio {
                  ${ textTranslationsByKey(`{
                    id
                    name
                    credits
                    audio {
                      ...AudioFileFragment
                    }
                    image {
                      ...MediaImageFragment
                    }
                  }`)}
                }
            	}
              ... on ContentImage {
                title {
                  common
                  ${ textTranslationsByKey('{ text html }') }
                }
                description {
                  common
                  ${ textTranslationsByKey('{ text html }') }
                }
                image {
                  ${ textTranslationsByKey('{ ...MediaImageFragment }') }
                }
              }

            
              ... on ContentImageGallery {
                title {
                  common
                  ${ textTranslationsByKey('{ text html }') }
                }
                description {
                  common
                  ${ textTranslationsByKey('{ text html }') }
                }
                images {
                  title {
                    common
                    ${ textTranslationsByKey('{ text html }') }
                  }
                  description {
                    common
                    ${ textTranslationsByKey('{ text html }') }
                  }
                  image {
                    ${ textTranslationsByKey('{ ...MediaImageFragment }') }
                  }
                }
              }
            
              ... on ContentVideo {
                title {
                  common
                  ${ textTranslationsByKey('{ text html }') }
                }
                description {
                  common
                  ${ textTranslationsByKey('{ text html }') }
                }
                video {
                  ${ textTranslationsByKey('{ ...MediaVideoFragment }') }
                }
              }
              ${contentPuzzle}
          }
          }
          tracks {
            id
            active
            label
            name {
              common
              ${ textTranslationsByKey(`{ text html }`) }
            }
            image { ...MediaImageFragment }
            audio {
              ${ textTranslationsByKey('{ ...MediaAudioFragment }') }
            }
          }
          people {
            id
            name
            bio {
              text
              html
            }
            bornAt
            diedAt
            image { ...MediaImageFragment }
          }
          image { ...MediaImageFragment }
        }
      }
    `
  },

  getPoiByIdWithoutContentsAndAudioguide (languages: any[]) {
    const textTranslations = languages.map((lang) => lang.iso)
    const textTranslationsByKey = (content: string) => {
      const res = []
      for (const key in textTranslations) {
        if (textTranslations.hasOwnProperty(key)) {
          res.push(`${ textTranslations[key] } ${ content }`)
        }
      }
      return res.join('\n')
    }
    return gql`

      # Fragments
      ${ MediaImageFragment }
      ${ ImageFileFragment }
      ${ PersonFragment }

      query pointOfInterestWithoutContentsAndAudioguide($id: String!) {
        pointOfInterest(id: $id) {
          id
          name {
            common
            ${ textTranslationsByKey(`{ text html }`) }
          }
          image { ...MediaImageFragment }
          people {
            id
            name
            bio {
              text
              html
            }
            bornAt
            diedAt
          }
        }
      }
    `
  }
}
