import { Mixins, Component } from 'vue-property-decorator'
import { CollectionsService, PointsOfInterestService } from '@/@next/services'
import { Collection, ImageFormat, PointOfInterest } from '@/@types/graphql'
import addProtocol from '@/utils/addProtocol'
import { CLIENT } from '@/constants';

@Component({ name: 'preload-service' })
export default class Preload extends Mixins(CollectionsService, PointsOfInterestService) {
    
    refreshing: boolean = false;

    created () {
        // Listen for our custom event from the SW registration
        if (CLIENT === 'tatihou') {
            document.addEventListener('swUpdated', this.PRELOAD_COLLECTIONS, { once: true })
        }

        // Prevent multiple refreshes
        // navigator.serviceWorker.addEventListener('controllerchange', () => {
        //     console.log('controllerchange event')
        //     if (this.refreshing) return
        //     this.refreshing = true
        //     // Here the actual reload of the page occurs
        //     window.location.reload()
        // })
    }

    async PRELOAD_COLLECTIONS (): Promise<string[]> {
        // alert('Prechargement des données en cache')
        const collections = await this.GET_ALL_COLLECTIONS({
            take: 100
          })
        if (collections?.itemsCount < 1) return []
        const collectionPromises: Array<Promise<Collection>> = []
        const pointOfInterestList: string[] = []
        const collectionImages: any[] = []
        const pointsOfInterestImages: any[] = []
        collections.items.forEach(({ id, pointsOfInterest, image }: Collection) => {
            collectionPromises.push(this.GET_SINGLE_COLLECTION(id))
            collectionPromises.push(this.GET_SINGLE_COLLECTION(id, { take: 100 }))
            collectionPromises.push(this.GET_SINGLE_COLLECTION(id, { take: 200 }))
            if (image?.formats?.length) {
                // collectionImages.push(image.formats[0].file.url)
                image.formats.forEach((i: ImageFormat) => {
                    collectionImages.push(i.file.url)
                })
                collectionImages.push(image.file.url)
            }
            pointsOfInterest.items.forEach(({ id, image }: PointOfInterest) => {
                pointOfInterestList.push(id)
                // if (image?.formats?.length) pointsOfInterestImages.push(image.formats[0].file.url)
                image?.formats?.forEach((i: ImageFormat) => {
                    pointsOfInterestImages.push(i.file.url)
                })
            })
        })
        await Promise.all(collectionPromises)
        pointOfInterestList.forEach(async (id: string) => {
            await this.GET_SINGLE_POI(id)
        })
        collectionImages.forEach((url: string) => {
            const img = new Image()
            img.src = addProtocol(url)
        })
        pointsOfInterestImages.forEach((url: string) => {
            const img = new Image()
            img.src = addProtocol(url)
        })
        // alert('Version offline prete')
        return []
    }
}
