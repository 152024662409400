import { Vue } from 'vue-property-decorator'
import { ActionTree } from 'vuex'

import { RootState } from '@/@next/store'
import { BotState } from '@/@next/store/bot'

import * as BOT from '@/@next/store/bot/types'
import * as botData from './bot.json'
import { reject } from 'q'

export const ENDPOINT = 'data/bot'

const isObject = (o: any) => {
  return o instanceof Object && o.constructor === Object
}
const formatMessageFromTranslatedText = (text: any) => {
  if (isObject(text)) {
    text = text.html
  }
  return {
    type: 'TEXT',
    text,
    date: new Date(),
    read: false,
    isBotMessage: true
  }
}

const formatUserChoicesFromTranslatedText = (choice: any, iso: string) => {
  return {
    text: choice.text[iso],
    action: choice.action
  }
}

const setBotNotificationStatusInLocalStorage = (notificationAreEnable: boolean) => {
  Vue.$cookies.set('WIVI_BOT_NOTIFICATION_ENABLED', notificationAreEnable.toString())
}

const getBotNotificationStatusInLocalStorage = () => {
  const notificationAreEnable = Vue.$cookies.get('WIVI_BOT_NOTIFICATION_ENABLED')
  return !!notificationAreEnable
}

const generateNewMessageFromContext = (contextLabel: string, contextData: any, iso: string) => {
  const context = contextData.find((el: any) => el.label === contextLabel)
  if (context) {
    const messages = []
    messages.push(...context.messages.map((message: any) => {
      return formatMessageFromTranslatedText(message[iso])
    }))
    if (context.userChoices && context.userChoices.length) {
      messages.push({
        choices: context.userChoices.map((choice: any) => {
          return formatUserChoicesFromTranslatedText(choice, iso)
        }),
        askUserChoices: true
      })
    }
    return messages
  }
  return []
}

const formatMessageFromImage = (imageData: any, iso: string) => {
  let title = imageData.title[iso]
  let titleAlt = ''
  if (isObject(title)) {
    title = title.html
    titleAlt = title.text
  }
  return {
    type: 'IMAGE',
    text: imageData.title[iso].html,
    textAlt: imageData.title[iso].text,
    description: imageData.description[iso].html,
    image: imageData.image[iso],
    date: new Date(),
    read: false,
    isBotMessage: true
  }
}

const formatMessageFromPoi = async (poiData: any, iso: string) => {
  // console.log('formatMessageFromPoi', poiData)
  const output = {
    type: 'POI_ANCHOR',
    text: 'POI ANCHOR',
    iso,
    date: new Date(),
    read: false,
    isBotMessage: true
  }
  // console.log(output)
  return output
}

const generateNewMessageFromAdditionalContent = (poi: any, iso: string) => {
  console.log('generateNewMessageFromAdditionalContent', poi, iso)
  if (poi.contents && poi.contents.length) {
    return poi.contents.filter((content: any) => {
      return content.__typename === 'TextContent' || content.typeId === 'ImageContent' || content.typeId === 'PointOfInterestContent'
    }).map((content: any) => {
        if (content.__typename === 'PointOfInterestContent') {
          // console.log('IS POI ANCHOR', content.data, iso)
          return {
            type: 'POI_ANCHOR',
            id: content.data.pointOfInterest.id,
            iso,
            date: new Date(),
            read: false,
            isBotMessage: true
          }
        } else {
          switch (content.__typename) {
            case 'TextContent':
              return formatMessageFromTranslatedText(content.data.i18n.body.value)
            case 'ImageContent':
              return formatMessageFromImage(content.data, iso)
            case 'PointOfInterestContent':
              return formatMessageFromPoi(content.data, iso)
          }
        }
      })
  }
}

export const actions: ActionTree<BotState, RootState> =  {

  async [BOT.FETCH] ({ state, commit }, data) {
    try {
      // const { data } = await Vue.axios.get(ENDPOINT)
      // console.log('online bot data', data)
      commit(BOT.FETCH, data)
      // console.log('local bot data', botData.default)
      // commit(BOT.FETCH, botData.default)
      // Check if bot notifications are enabled
      if (state.notificationAreEnable) {
        setBotNotificationStatusInLocalStorage(true)
      } else {
        commit(BOT.ENABLE_BOT_NOTIFICATION, getBotNotificationStatusInLocalStorage())
      }
      // Open Bot automatically
      // setTimeout(dispatch(BOT.OPENING_BOT), 5000)
    } catch (e) {
      throw new Error(`
        \nError in ${ BOT.FETCH } action.
        \nError message : ${e.message}
      `)
    }
  },

  [BOT.CLOSING_BOT] ({ commit }) {
    commit(BOT.IS_OPEN, false)
    commit(BOT.CLEAR_BUFFER)
  },

  [BOT.OPENING_BOT] ({ state, rootState, commit, dispatch }) {
    // console.log('opening bot 1')
    // TODO: replace with state machine
    const isFirstOpening = state.messages.length < 1
    // console.log('unread message', state.messages.filter((el: any) => el.read === false).length)
    const isNotNotified = state.messages.filter((el: any) => el.read === false).length === 0
    // @ts-ignore
    const iso = rootState.languages.current
    commit(BOT.IS_OPEN, true)
    commit(BOT.SET_ALL_MESSAGES_ARE_READ)
    let messages = []
    if (isFirstOpening) {
      commit(BOT.SET_BOT_STATE, 'firstMessage')
      messages = generateNewMessageFromContext(state.botState, state.content.context, iso)
      commit(BOT.ADD_MESSAGES_TO_BUFFER, messages)
    } else if (isNotNotified) {
      const notificationWereDisable = !state.notificationAreEnable
      commit(BOT.ENABLE_BOT_NOTIFICATION, true)
      commit(BOT.SET_BOT_STATE, 'openWithoutNotification')
      messages = generateNewMessageFromContext(state.botState, state.content.context, iso)
      commit(BOT.ADD_MESSAGES_TO_BUFFER, messages)
      if (notificationWereDisable) {
        commit(BOT.SET_BOT_STATE, 'reNotifyQuestion')
        messages = generateNewMessageFromContext(state.botState, state.content.context, iso)
        commit(BOT.ADD_MESSAGES_TO_BUFFER, messages)
      }
    } else {
      commit(BOT.SET_BOT_STATE, '')
    }
    // const messages = generateNewMessageFromContext(state.botState, state.content.context, iso)
    // console.log('messages', messages)
    // commit(BOT.ADD_MESSAGES_TO_BUFFER, messages)
    if (messages.length && state.buffer.length > 0) dispatch(BOT.READ_BUFFER)
  },

  [BOT.READ_BUFFER] ({ state, commit, dispatch }) {
    const defaultWritingTimer = 500
    if ((state.buffer.length > 0) && (!state.isWritingMessage)) {
      const nextBufferItem = state.buffer[0]
      if (nextBufferItem.isBotMessage) {
        commit(BOT.IS_WRITING_MESSAGE, true)
        let timeout = 500
        if (nextBufferItem.text && nextBufferItem.text.length) {
          const textMessage = nextBufferItem.text
          // Replace 1 by 170 for real speed humain writing
          timeout = textMessage.length ? textMessage.length * 20 : defaultWritingTimer
          if (timeout < defaultWritingTimer) timeout = defaultWritingTimer
          else if (timeout > 4000) timeout = 4000
        }
        setTimeout(() => {
          dispatch(BOT.DISPLAY_NEW_MESSAGE_FROM_BUFFER, nextBufferItem)
        }, timeout)
      } else if (nextBufferItem.askUserChoices) {
        // console.log('Read User choice')
        dispatch(BOT.ASK_USER_CHOICE, nextBufferItem.choices)
        commit(BOT.REMOVE_FIRST_BUFFER_MESSAGE)
      }
    }
  },

  [BOT.DISPLAY_NEW_MESSAGE_FROM_BUFFER] ({ state, commit, dispatch }, bufferItem) {
    // console.log('IS not WRITING')
    commit(BOT.IS_WRITING_MESSAGE, false)
    commit(BOT.ADD_NEW_MESSAGE, bufferItem)
    commit(BOT.REMOVE_FIRST_BUFFER_MESSAGE)
    if (state.isOpen) commit(BOT.SET_ALL_MESSAGES_ARE_READ)
    dispatch(BOT.READ_BUFFER)
  },

  [BOT.ASK_USER_CHOICE] ({ state, commit }, choices: any[]) {
    commit(BOT.SET_USER_CHOICES, choices)
  },

  [BOT.RECEIVE_USER_CHOICE] ({ state, commit, dispatch }, userChoice: any) {
    // console.log('Receive User Choice', userChoice)
    if (state.userChoices && state.userChoices.length) {
      // console.log('Les choix étaient les suivants', state.userChoices)
      commit(BOT.SET_USER_CHOICES, [])
      const userMessage = {
        text: userChoice.text,
        date: new Date(),
        read: true,
        isBotMessage: false
      }
      commit(BOT.ADD_NEW_MESSAGE, userMessage)
      switch (userChoice.action) {
        case 'enableBotNotification':
          dispatch(BOT.ENABLE_BOT_NOTIFICATION, true)
          break
        case 'disableBotNotification':
          dispatch(BOT.ENABLE_BOT_NOTIFICATION, false)
          break
        case 'acceptAdditionalContent':
          dispatch(BOT.DISPLAY_ADDITIONAL_CONTENT, true)
        case 'rejectAdditionalContent':
          dispatch(BOT.DISPLAY_ADDITIONAL_CONTENT, false)
        default:
          break
      }
    }
  },

  [BOT.ENABLE_BOT_NOTIFICATION] ({ state, rootState, commit, dispatch }, notificationAreEnable: boolean) {
    if (notificationAreEnable) commit(BOT.SET_BOT_STATE, 'acceptNotification')
    else commit(BOT.SET_BOT_STATE, 'rejectNotification')
    // @ts-ignore
    const iso = rootState.languages.current
    const messages = generateNewMessageFromContext(state.botState, state.content.context, iso)
    commit(BOT.ADD_MESSAGES_TO_BUFFER, messages)
    commit(BOT.ENABLE_BOT_NOTIFICATION, notificationAreEnable)
    setBotNotificationStatusInLocalStorage(notificationAreEnable)
    if (state.buffer.length > 0) dispatch(BOT.READ_BUFFER)
  },

  [BOT.ADD_POI_CONTENT] ({ state, rootState, commit, dispatch }, poi: any[]) {
    commit(BOT.SET_CURRENT_POI_CONTENT, poi)
    if (state.buffer.length > 0) commit(BOT.CLEAR_BUFFER)
    // console.log('check error', rootState)
    // @ts-ignore
    const iso = rootState.languages.current
    if (state.messages.length < 1) {
      commit(BOT.SET_BOT_STATE, 'firstMessage')
      const messages = generateNewMessageFromContext(state.botState, state.content.context, iso)
      commit(BOT.ADD_MESSAGES_TO_BUFFER, messages)
    }
    commit(BOT.SET_BOT_STATE, 'poiAdditionalContent')
    const messages = generateNewMessageFromContext(state.botState, state.content.context, iso)
    commit(BOT.ADD_MESSAGES_TO_BUFFER, messages)
    const additionalContentMessage = generateNewMessageFromAdditionalContent(poi, iso)
    // console.log('additionalContentMessage', additionalContentMessage, poi, iso)
    commit(BOT.ADD_MESSAGES_TO_BUFFER, additionalContentMessage)
    if (state.buffer.length > 0) dispatch(BOT.READ_BUFFER)
  },

  [BOT.DISPLAY_ADDITIONAL_CONTENT] ({ state, commit, dispatch }, displayAdditionalContent) {
    if (displayAdditionalContent) {
      // Display Additional Content
      if (state.buffer.length > 0) dispatch(BOT.READ_BUFFER)
    } else {
      // Remove Additional Content
      commit(BOT.CLEAR_BUFFER)
    }
  }
}
