import { Vue, Component } from 'vue-property-decorator'
import uuidv4 from 'uuid/v4'

// Polyfills
import 'intersection-observer'

// Plugins
import { apolloProvider } from '@/@next/plugins/apollo'
import '@/plugins/matomo'
import '@/plugins/vue-meta'
import '@/plugins/vue-touch'
import '@/plugins/portal-vue'
import '@/plugins/vue-axios'
import '@/plugins/vee-validate'
import '@/plugins/vue-cookies'
import '@/plugins/wivi/print'
import '@/plugins/vue-observe-visibility'
// import '@/plugins/vue-socket'

  // Constants
import { CLIENT, MATOMO_URL, GEOLOCATION, MAP_URL } from '@/constants'

// Utils
import addProtocol from '@/utils/addProtocol'
import sortByPositionKey from '@/utils/sortByPositionKey'
import reverse from '@/utils/reverse'
import minSecondDuration from '@/utils/minSecondDuration'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'

import '@/@next/filters'

// Translation, store and router
import i18n from '@/i18n'
import router from '@/router'
import store from '@/@next/store'

// Components
import CApp from '@/@next/App.vue'

// Configure Vue
Vue.config.productionTip = false

// Filters
Vue.filter('addProtocol', addProtocol)
Vue.filter('reverse', reverse)
Vue.filter('minSecondDuration', minSecondDuration)
Vue.filter('capitalizeFirstLetter', capitalizeFirstLetter)

// Global instance properties
Vue.mixin({
  data () {
    return {
      CLIENT,
      MATOMO_URL,
      MAP_URL
    }
  },
  methods: {
    sortByPositionKey,
    addProtocol
  }
})

// Register the custom hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

// Init Matomo
// initMatomo(MATOMO_URL, CLIENT)

// @ts-ignore
// window.goTo = (data: {uuid: string, type: string}) => {
//   if (data.type === 'pointOfInterest') {
//     void router.push({ name: 'single-poi', params: { uuid: data.uuid }})
//   }
//   if (data.type === 'collection') {
//     void router.push({ name: 'single-collection', params: { uuid: data.uuid }})
//   }
// }

new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  render: (h) => h(CApp)
}).$mount('#app')
