






























import { Vue, Component } from 'vue-property-decorator'
import { CModal } from '@/components/modal'
import { CButton } from '@/components/form-controls/button'
import { CRadio, CRadioButton, CRadioButtonIcon, CRadioButtonLabel } from '@/components/form-controls/radio'

const config = {
  name: 'c-welcome',
  components: {
    CModal,
    CRadioButton,
    CRadioButtonIcon,
    CRadioButtonLabel,
    CButton,
    CRadio
  }
}

@Component(config)
export default class CWelcome extends Vue {

  currentStep = 0
  itemCheck: boolean[] = []
  welcomContent = {
      configuration: {
          showStepper: true,
          closable: false
      },
      steps: [
          { 
              type: 'textArea',
              title: [
                  {
                      language: 'fr', target: null,
                      value: { html: 'l\'île Tatihou', text: 'l\'Ile Tatihou'}
                  }
              ],
              text: [
                  {
                      language: 'fr', target: null,
                      value: {
                          html: 'Bienvenue sur l\'île Tatihou ! </br> </br>Pour découvrir l’île Tatihou et profiter au mieux de l’application Tatihou Go, nous vous invitons à activer la géolocalisation de votre appareil. Ceci n\'est pas obligatoire. Nous vous garantissons que vos données personnelles ne seront ni exploitées, ni conservées. </br>Bonne visite !</br></br>',
                          text: 'Contenu dcrivant l\'ile'
                      }
                  }
              ],
              action: {
                  text: [
                      {
                          language: 'fr', target: null,
                          value: { html: 'Ok', text: 'Ok'}
                      }
                  ],
                  action: 'next'
              }
          },
          { 
              type: 'textArea',
              title: [
                  {
                      language: 'fr', target: null,
                      value: { html: 'l\'Appli Tatihou Go', text: 'l\'Appli Tatihou Go'}
                  }
              ],
              text: [
                  {
                      language: 'fr', target: null,
                      value: {
                          html: 'Bienvenue sur l’appli mobile Tatihou Go ! </br></br>Vous êtes là quelques heures ou bien toute la journée ? </br>Vous vous demandez par où commencer votre visite, où manger et pourquoi pas, dormir ici ? </br></br>Cette application est là pour vous accompagner en vous orientant sur le site, en vous fournissant toutes les informations pratiques dont vous avez besoin et en vous proposant des parcours thématiques et des jeux pour profiter au mieux de votre découverte de l’île.</br></br>',
                          text: 'Contenu dcrivant l\'appli'}
                  }
              ],
              action: {
                  text: [
                      {
                          language: 'fr', target: null,
                          value: { html: 'Ok, Je visite', text: 'Ok, je visite'}
                      }
                  ],
                  action: 'next'
              }
          },
          { 
              type: 'ckeckList',
              title: [
                  {
                      language: 'fr', target: null,
                      value: { html: 'La charte Tatihou', text: 'La charte Tatihou'}
                  }
              ],
              text: [
                  {
                      language: 'fr', target: null,
                      value: { html: 'Afin de préserver l’environnement de Tatihou, je m’engage à :', text: 'Contenu dcrivant la charte'}
                  }
              ],
              ckeckList: [
                  {
                      icon: '',
                      text: [{
                          language: 'fr', target: null,
                          value: { 
                              html: 'Remporter mes détritus car aucune poubelle n’a été installée sur l’île.',
                              text: 'Contenu dcrivant la charte'
                          }
                      }]
                  },
                  {
                      icon: '',
                      text: [{
                          language: 'fr', target: null,
                          value: { 
                              html: 'Ne pas déranger les oiseaux, surtout en période de nidification.',
                              text: 'Contenu dcrivant la charte'
                          }
                      }]
                  },
                  {
                      icon: '',
                      text: [{
                          language: 'fr', target: null,
                          value: { html: 'Rester sur les chemins clairement marqués lorsque je suis en dehors du lazaret.', text: 'text'}
                      }]
                  },
                  {
                      icon: '',
                      text: [{
                          language: 'fr', target: null,
                          value: { html: 'Ne pas cueillir les plantes sauvages et les fleurs des jardins.', text: 'Contenu décrivant la charte'}
                      }]
                  },
                  {
                      icon: '',
                      text: [{
                          language: 'fr', target: null,
                          value: { html: 'Ne pas jeter mes mégots de cigarette par terre.', text: 'Contenu décrivant la charte'}
                      }]
                  }
              ],
              action: {
                  text: [
                      {
                          language: 'fr', target: null,
                          value: { html: 'Je suis pret(e)', text: 'Je suis pret(e)'}
                      }
                  ],
                  action: 'end'
              }
          }
      ]
  }

  mounted () {
      if (this.$cookies.get('WIVI_WELCOME')) this.$emit('close')
  }

  get step () {
      return this.welcomContent.steps[this.currentStep]
  }
  get title () {
      const translation = this.step.title.find((el: any) => el.language === this.$i18n.locale)
      if (translation) return translation.value.html
      const french = this.step.title.find((el: any) => el.language === 'fr')
      if (french) return french?.value.html
      return '---'
  }
  
  get textArea () {
      if (!this.step.text) return null
      const translation = this.step.text.find((el: any) => el.language === this.$i18n.locale)
      if (translation) return translation.value.html
      const french = this.step.text.find((el: any) => el.language === 'fr')
      if (french) return french?.value.html
      return ''
  }

  get actionText () {
      if (!this.step?.action) return null
      const translation = this.step.action.text.find((el: any) => el.language === this.$i18n.locale)
      if (translation) return translation.value.html
      const french = this.step.action.text.find((el: any) => el.language === 'fr')
      if (french) return french?.value.html
      return 'suivant'
  }

  get showActionButton () {
      if (!this.step?.action) return false
      if (this.checkList)
          return this.itemCheck.filter((i: boolean) => i).length === this.checkList.length
      return true
  }

  close () {
      this.$cookies.set('WIVI_WELCOME', true)
      this.$emit('close')
  }

  startAction () {
      switch (this.step.action.action) {
          case 'next': this.currentStep ++
                       break;
          case 'end': this.close()
                      break;
          default: break;
      }
  }

  findTranslation (text: any) {
      if (!text?.length) return null
      const translation = text.find((el: any) => el.language === this.$i18n.locale)
      if (translation) return translation.value.html
      const french = text.find((el: any) => el.language === 'fr')
      if (french) return french?.value.html
      return null
  }

  get checkList () {
      if (!this.step.ckeckList) return null
      const checkList = this.step.ckeckList.map(({ icon, text}: any) => {
          return { icon, text: this.findTranslation(text) }
      })
      return checkList
  }
}
