
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import CustomWrapper from '@/components/CustomWrapper.vue'

const config = {
  name: 'c-collection-card-title',
  components: { CustomWrapper }
}

@Component(config)
export default class CCollectionCardTitle extends Vue {
  @Prop({ type: String, default: 'div' }) tag!: string
}
