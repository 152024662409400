
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const config = {
  name: 'c-AuthorModalContent'
}

@Component(config)
export default class CAuthorModalContent extends Vue {
}
