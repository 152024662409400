















































































import { Vue, Component } from 'vue-property-decorator'

const config = {
  name: 'icon-dyslexic',
  components: {}
}

@Component(config)
export default class IconDyslexic extends Vue {}
