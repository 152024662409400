import { GetterTree, Module } from 'vuex'
import { RootState } from '@/store'

import * as SETTINGS from './types'
import { actions } from './actions'
import { mutations } from './mutations'

import { Nullable } from '@/shared'

export class SettingsState {
  realm: Nullable <string> = null
  settings: any = {}
  availableSettings: any[] = []
}

const defaultMenu = {
  items: [
    {
      url: '/', icon: 'home', label: 'home', enable: true, deletable: false,
      name: { en: 'Home', fr: 'Accueil 12' }, content: []
    },
    {
      url: '/settings', icon: 'settings', label: 'settings', enable: true, deletable: false,
      name: { en: 'Settings', fr: 'Paramètres' }
    }
  ],
}

const state = new SettingsState()

const getters: GetterTree<SettingsState, RootState> = {
  [SETTINGS.GET_REALM]: (state): any => state.realm,
  [SETTINGS.GET_ALL]: (state): any => state.settings,
  [SETTINGS.GET_AVAILABLE]: (state): any[] => state.availableSettings,
  [SETTINGS.GET_MENU_ITEMS]: (state): any => {
    return defaultMenu.items
    // const menus = state.settings.menus
    // if (menus && menus.length) {
    //   const menu = menus.find((el: any) => (el.realms && (el.realms.indexOf(state.realm) !== -1)))
    //   if (menu) return menu.items.filter((el: any) => el.enable)
    //   return menus[0].items.filter((el: any) => el.enable)
    // } else {
    //   return defaultMenu.items
    // }
  },
}

export const settings: Module<SettingsState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
