import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { messages } from './globales'

Vue.use(VueI18n)

export const DEFAULT_LOCALE = navigator.language.split('-')[0] || 'fr'

const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  messages,
  fallbackLocale: 'fr',
  // to work with global and component based translations
  silentTranslationWarn: true
})

export default i18n
