import { MutationTree } from 'vuex'

import * as SETTINGS from './types'
import { SettingsState } from './'

export const mutations: MutationTree<SettingsState> =  {

  [SETTINGS.ADD_AVAILABLE] (state, value) {
    state.availableSettings = value
  },

  [SETTINGS.ADD] (state, { key, value }) {
    console.log('SETTINGS.ADD', { key, value })
    state.settings[key] = value
  },

  [SETTINGS.ADD_LOGO] (state, payload) {
    state.settings.theme.logo = payload
  },

  [SETTINGS.SET_REALM] (state, payload) {
    state.realm = payload
  }
}
