import { ActionTree } from 'vuex'

import * as AUDIOGUIDE from './types'
import * as LANGUAGE from '@/@next/store/languages/types'
import { RootState } from '@/@next/store'
import { AudioguideState } from '@/@next/store/audioguide'

export const actions: ActionTree<AudioguideState, RootState> =  {

  async [AUDIOGUIDE.SET_STATUS] ({ state, commit, dispatch }, status: boolean) {
    commit(AUDIOGUIDE.CHANGE_STATUS, status)
  },

  async [AUDIOGUIDE.SET_PLAYLIST] ({ state, commit, dispatch }, playlist: any) {
    commit(AUDIOGUIDE.CHANGE_PLAYLIST, playlist)
  },

  async [AUDIOGUIDE.SET_POI] ({ state, commit, dispatch }, poi: any) {
    commit(AUDIOGUIDE.CHANGE_POI, poi)
  },

  async [AUDIOGUIDE.SET_CURRENT_POI] ({ state, commit, dispatch, getters }, poi: any) {
    commit(AUDIOGUIDE.CHANGE_CURRENT_POI, poi)
    const target = getters[LANGUAGE.GET_TARGET]
    const language = getters[LANGUAGE.GET_CURRENT]
    if (!poi) return
    if (target === 'signLanguage') {
      const video = poi.audioGuideTracks
                    .map((t: any) => t.i18n.video)
                    .filter((v: any) => v?.value && v.language === language && v.target === target)
      if (video.length && video[0].value.file.url) {
        setTimeout(() => {
          dispatch(AUDIOGUIDE.SET_POI, poi)
          dispatch(AUDIOGUIDE.SET_PLAYLIST, poi.audioGuideTracks.filter((t: any) => t.i18n.video))
          dispatch(AUDIOGUIDE.SET_STATUS, true)
        }, 1000)
      }
    }    
  }

}
