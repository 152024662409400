
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { CLazyLoadingImage } from '@/components/images'
import { CLoader } from '@/components/loader'
import CModal from '@/components/modal'
import { CImage } from '@/@next/components/image'
import { CButton } from '@/components/form-controls/button'

const config = {
  name: 'c-content-popup',
  components: {
    CLazyLoadingImage,
    CLoader,
    CImage,
    ...CModal,
    CButton
  }
}

@Component(config)
export default class CContentPopup extends Vue {

  @Prop({ type: Object, required: true }) content!: any
  @Prop({ type: String, required: false, default: '' }) contextId!: string

  showPopupModal: boolean = false
  showPopupOnce: boolean = false
  ready: boolean = false

  get popup () {
    return this.content.data
  }

  get title () {
    return this.popup.i18n?.title?.value
  }

  get text () {
    return this.popup.i18n?.text?.value || null
  }

  get button () {
    return this.popup.i18n?.button?.value
  }

  get imageUrl () {
    if (!this.popup.image) return null
    return this.popup.image.value.file.url
  }

  get timer () {
    return (this.popup.timer || 0) * 1000
  }

  visibilityChanged (isVisible: boolean, entry: any) {
    // console.log(`visibilityChanged ${this.ready} ${isVisible} : ${this.text?.text}`)
    if (!this.ready || !isVisible || this.showPopupOnce) return
    this.showPopupModal = true
    this.showPopupOnce = true
  }

  setReady () {
    this.showPopupOnce = false
    setTimeout(() => this.ready = true, 1000)
  }

  mounted () {
    this.setReady()
  }

  desactivated () {
    this.ready = false
  }
}
