export const messages = {
  fr: {
    pageTitle: 'Toutes les collections'
  },
  en: {
    pageTitle: 'Collections'
  },
  us: {
    pageTitle: 'Collections'
  },
  da: {
    pageTitle: 'Collections'
  },
  es: {
    pageTitle: 'Collections'
  },
  nl: {
    pageTitle: 'Collections'
  },
  de: {
    pageTitle: 'Collections'
  },
  it: {
    pageTitle: 'Collections'
  },
  pt: {
    pageTitle: 'Collections'
  },
  zh: {
    pageTitle: 'Collections'
  }
}
