
import { Component, Mixins, Prop } from 'vue-property-decorator'

import CustomTagMixin from '@/@next/mixins/CustomTag'
import CVideoCard from '@/components/cards/video-card'
import { CLazyLoadingImage } from '@/components/images'
import { CLoader } from '@/components/loader'

const config = {
  name: 'c-content-video',
  components: {
    ...CVideoCard,
    CLazyLoadingImage,
    CLoader
  }
}

@Component(config)
export default class Video extends Mixins(CustomTagMixin) {
  @Prop({ type: Object, required: true }) content!: any
  @Prop({ type: String, required: false, default: '' }) contextId!: string

  get videoId () {
    if (!this.content) return null
    return this.content.data.i18n.video.value.id
  }

  get title () {
    if (!this.content) return null
    return this.content.data.i18n.title.value
  }

  get description () {
    if (!this.content) return null
    return this.content.data.i18n.description.value
  }

  get screenshot () {
    if (!this.content) return null
    const { screenshots } = this.content.data.i18n.video.value
    if (!screenshots || !screenshots.length) return null
    return screenshots[0].url
  }

  get duration () {
    if (!this.content) return null
    return this.content.data.i18n.video.value.file.duration
  }

}
