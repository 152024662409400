
import { Component, Vue, Prop } from 'vue-property-decorator'
import { CLoader } from '@/components/loader'
import { CImage } from '@/@next/components/image'
import { CCollectionCardSmall } from '@/@next/components/collection-card-small'
import { PointOfInterest } from '@/@next/@types'

const config = {
  name: 'c-content-collection',
  components: {
    CCollectionCardSmall,
    CImage,
    CLoader
  }
}

@Component(config)
export default class CContentCollection extends Vue {

  @Prop({ type: Object, required: true }) content!: any

  get collection () {
    return this.content.data.collection
  }

  get imageUrl () {
    if (!this.collection.image) return null
    return this.collection.image.file.url
  }

  get image () {
    return this.collection.image
  }

  get title () {
    if (!this.collection.i18n.title) return null
    return this.collection.i18n.title.value
  }

  get twoPois () {
    const { pointsOfInterest } = this.collection
    if (!pointsOfInterest || pointsOfInterest.totalCount < 2) return null
    const filterImage = (poi: PointOfInterest) => poi.image && poi.image.file.url
    const poiWithImage = this.collection.pointsOfInterest.items.filter(filterImage)
    if (poiWithImage.length < 2) return null
    return poiWithImage.slice(0, 2)
  }

  get poiCount () {
    if (!this.content) return null
    return this.content.data.collection.pointsOfInterest.totalCount
  }

}
