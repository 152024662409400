
import { Vue, Component, Watch, Mixins } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'

// import { messages } from './i18n'
import { messages } from '@/@next/i18n'
import * as LANGUAGES from '@/@next/store/languages/types'
import * as USER from '@/@next/store/user/types'

import { Nullable } from '@/shared'
import { Language } from '@/shared/languages'

import { CSection, CSectionTitle, CSectionContent } from '@/components/section'
import { CInput } from '@/components/form-controls/input'
import { CSwitch } from '@/components/form-controls/switch'
import { CRadioButton, CRadioButtonIcon, CRadioButtonLabel } from '@/components/form-controls/radio'
import { CButton, CButtonLabel, CButtonIcon } from '@/components/form-controls/button'
import { CTopbarTitle } from '@/@next/components/topbar-title'

import { CModal } from '@/components/modal'
import { CLIENT } from '@/constants'
import { cloneDeep } from 'lodash'
import CLanguageSelector from '@/@next/components/LanguageSelector.vue'
import { setSeconds } from 'date-fns'
import { LanguagesService, UsersService } from '@/@next/services'
import CLanguageAndTarget from '@/@next/components/LanguageAndTarget.vue'
import CAccessibility from '@/@next/components/Accessibility.vue'

const config = {
  name: 'v-settings',
  i18n: { messages },
  components: {
    CInput,
    CModal,
    CSection,
    CSectionTitle,
    CSectionContent,
    CSwitch,
    CRadioButton,
    CRadioButtonIcon,
    CRadioButtonLabel,
    CButton,
    CButtonLabel,
    CButtonIcon,
    CTopbarTitle,
    CLanguageSelector,
    CLanguageAndTarget,
    CAccessibility
  }
}

@Component(config)
export default class Settings extends Mixins(LanguagesService, UsersService) {

  languageModalIsOpen: boolean = false
  messages = messages

  setTarget (target: string) {
    this._setTarget(target)
    this.SAVE_CURRENT_USER_PREFERENCES()
    // const currentConfig = this.GET_USER_CONFIG()
    // this.SET_USER_CONFIG({...currentConfig, target})
  }

  // get pageName () {
  //   const fallback = this.messages.fr.pageName
  //   if (!this._currentLanguage) return fallback
  //   // @ts-ignore
  //   return this.messages[this._currentLanguage]?.pageName || fallback
  // }

  // get languagesSection () {
  //   const fallback = this.messages.fr.languagesSection
  //   if (!this._currentLanguage) return fallback
  //   // @ts-ignore
  //   return this.messages[this._currentLanguage]?.languagesSection || fallback
  // }

  get currentLanguage () {
    return this._allLanguages.find((language) => language.iso === this._currentLanguage)
  }

  // get accessibilitySection () {
  //   const fallback = this.messages.fr.accessibilitySection
  //   if (!this._currentLanguage) return fallback
  //   // @ts-ignore
  //   return this.messages[this._currentLanguage]?.accessibilitySection || fallback
  // }

  // get enableSignLanguage () {
  //   const fallback = this.messages.fr.enableSignLanguage
  //   if (!this._currentLanguage) return fallback
  //   // @ts-ignore
  //   return this.messages[this._currentLanguage]?.enableSignLanguage || fallback
  // }

}
