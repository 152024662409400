export const messages = {
  fr: {
    pageName: 'Embeds'
  },
  en: {
    pageName: 'Embeds'
  },
  us: {
    pageName: 'Embeds'
  },
  da: {
    pageName: 'Embeds'
  },
  es: {
    pageName: 'Embeds'
  },
  nl: {
    pageName: 'Embeds'
  },
  de: {
    pageName: 'Embeds'
  },
  it: {
    pageName: 'Embeds'
  },
  pt: {
    pageName: 'Embeds'
  },
  zh: {
    pageName: 'Embeds'
  }
}
