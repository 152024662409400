
import { Component, Vue, Prop } from 'vue-property-decorator'

const config = {
  name: 'c-content-not-found'
}

@Component(config)
export default class NotFound extends Vue {

  @Prop({ type: Object, required: true }) content!: any

  mounted () {
    console.error('Content Not Found', this.content)
  }

  render () {
    return
  }
}
