











































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import { CModal } from '@/components/modal'
import { CButton, CButtonIcon } from '@/components/form-controls/button'
import { CRadio, CRadioButton, CRadioButtonIcon, CRadioButtonLabel } from '@/components/form-controls/radio'
import CLanguageSelector from './LanguageSelector.vue'
import { Language } from '@/@types/graphql'
import { messages } from '@/@next/i18n'
import { LanguagesService, UsersService } from '../services'
import CLanguageList from '@/@next/components/LanguageList.vue'
import { SvgIcon, IconCheck, IconDropDown, IconDropUp, IconLanguage, IconChild, IconSignLanguage } from '@/@next/components/icons'

const config = {
  name: 'c-language-and-target',
  i18n: { messages },
  components: {
    CModal,
    CRadioButton,
    CRadioButtonIcon,
    CRadioButtonLabel,
    CButton,
    CButtonIcon,
    CRadio,
    CLanguageSelector,
    CLanguageList,
    SvgIcon, IconCheck, IconDropDown, IconDropUp, IconLanguage, IconChild, IconSignLanguage
  }
}

@Component(config)
export default class CLanguageAndTarget extends Mixins (LanguagesService, UsersService) {

  languageModalIsOpen: boolean = false

  @Prop({required: false, default: null}) title!: string | null
  
  mounted () {
      this.checkLogoTopBar()
  }

  close () {
    this.SAVE_CURRENT_USER_PREFERENCES()
    // this.SET_USER_CONFIG({ isoLanguage: this._currentLanguage, target: null })
    this.$emit('close')
  }

  findTranslation (text: any) {
      if (!text?.length) return null
      const translation = text.find((el: any) => el.language === this.$i18n.locale)
      if (translation) return translation.value.html
      const french = text.find((el: any) => el.language === 'fr')
      if (french) return french?.value.html
      return null
  }

  checkLogoTopBar () {
      try {
      const logoTopBar = new Image();
      logoTopBar.addEventListener('load', () => {
          const customElm = document.getElementById('configuration-logo-custom')
          const defaultElm = document.getElementById('configuration-logo-default')
          if (!customElm || !defaultElm) return
          customElm.style.display = 'block'
          defaultElm.style.display = 'none'
      }, false)
      logoTopBar.src = './logo-topbar.png';
      } catch (e) {
      console.warn('Error during logo topBar verification.', e)
      }
  }

  get language () {
      return this._allLanguages.find((l: Language) => l.iso === this._currentLanguage)
  }

  setTarget (input: string | null) {
    const target = this._languageTarget !== input ? input : null
    this._setTarget(target)
    this.SAVE_CURRENT_USER_PREFERENCES()
  }

}
