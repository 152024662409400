import Vue from 'vue'
export const EventBus = new Vue()

// TOPBAR
export const SHOW_DEFAULT_TOPBAR = 'SHOW_DEFAULT_TOPBAR'
export const HIDE_DEFAULT_TOPBAR = 'HIDE_DEFAULT_TOPBAR'
export const TOGGLE_DEFAULT_TOPBAR = 'TOGGLE_DEFAULT_TOPBAR'
export const SHOW_DEFAULT_TOPBAR_ON_SCROLL = 'SHOW_DEFAULT_TOPBAR_ON_SCROLL'
export const HIDE_DEFAULT_TOPBAR_ON_SCROLL = 'HIDE_DEFAULT_TOPBAR_ON_SCROLL'
export const ENABLE_DEFAULT_TOPBAR_TRANSPARENT = 'SET_DEFAULT_TOPBAR_TRANSPARENT'
export const DISABLE_DEFAULT_TOPBAR_TRANSPARENT = 'DISABLE_DEFAULT_TOPBAR_TRANSPARENT'
 // MENU
export const SHOW_DEFAULT_MENU = 'SHOW_DEFAULT_MENU'
export const HIDE_DEFAULT_MENU = 'HIDE_DEFAULT_MENU'
export const TOGGLE_DEFAULT_MENU = 'TOGGLE_DEFAULT_MENU'
 // QR SCAN
export const SHOW_DEFAULT_QR_SCAN = 'SHOW_DEFAULT_QR_SCAN'
export const HIDE_DEFAULT_QR_SCAN = 'HIDE_DEFAULT_QR_SCAN'
 // AUDIO GUIDE
export const SHOW_DEFAULT_AUDIO_GUIDE = 'SHOW_DEFAULT_AUDIO_GUIDE'
export const HIDE_DEFAULT_AUDIO_GUIDE = 'HIDE_DEFAULT_AUDIO_GUIDE'
export const TOGGLE_DEFAULT_AUDIO_GUIDE = 'TOGGLE_DEFAULT_AUDIO_GUIDE'
 // BOT
export const SHOW_DEFAULT_BOT = 'SHOW_DEFAULT_BOT'
export const HIDE_DEFAULT_BOT = 'HIDE_DEFAULT_BOT'
export const TOGGLE_DEFAULT_BOT = 'TOGGLE_DEFAULT_BOT'
