
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import _shuffle from 'lodash/shuffle'
import CQuizz from '@/components/question'
import CCheckbox from '@/components/form-controls/checkbox/Checkbox.vue'
import { MultipleChoiceAnswer } from '@/@types/question'
import CQCMItem from './QCMItem.vue'

const config = {
  name: 'c-q-c-m-answer',
  components: {
    ...CQuizz,
    CCheckbox,
    CQCMItem
  }
}

@Component(config)
export default class CQCMAnswer extends Vue {

  @Prop({ type: Object, required: true }) answer!: MultipleChoiceAnswer
  @Prop({ type: Boolean, required: true }) isFinished!: boolean

  selectedItems: any[] = []
  currentAudioPlayerIndex: number = 0

  @Watch('success')
  onSuccessUpdate () {
    this.$emit('success', this.success)
  }

  @Watch('selectedItems')
  onSelectedItemsUpdate () {
    this.$emit('selected', this.selectedItems)
  }

  get answerItems () {
    const items = this.answer.items
    return this.answer.randomDisplay ? _shuffle(items) : items
  }

  getMultipleChoiceItemsClasses (i: number) {
    if (!this.isFinished) return
    if (this.answerItems[i].correct) {
      if (this.selectedItems.find((el) => el.index === i)) return '--Success'
      return '--Response'
    }
    return '--Error'
  }

  getItemClasses (i: number) {
    const index = this.selectedItems.find((item) => item.i === i)
    return {
      '--test': true,
      '--Response': index > -1
    }
  }

  get correctAnswerLength () {
    return this.answerItems.filter((i) => i.correct).length
  }

  get correctAnswerSelected () {
    return this.selectedItems.filter((i) => i.correct).length
  }

  get wrongAnswerSelected () {
    return this.selectedItems.filter((i) => !i.correct).length
  }

  get success () {
    return this.wrongAnswerSelected === 0 && this.correctAnswerSelected === this.correctAnswerLength
  }

  selectItem (i: number, correct: boolean, id: string) {
    if (this.isFinished) return
    const index = this.selectedItems.findIndex((item) => {
      return item.i === i
    })
    if (index > -1) this.selectedItems.splice(index, 1)
    else this.selectedItems.push({ i, correct, id })
  }

  isItemSelected (i: number) {
    return  this.selectedItems.findIndex((item) => item.i === i) > -1
  }
}
