
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { CImage } from '@/@next/components/image'
import { CLoader } from '@/components/loader'
import CustomTagMixin from '@/@next/mixins/CustomTag'
import addProtocol from '@/@next/helpers/addProtocol'

const config = {
  name: 'c-content-image',
  components: {
    CImage,
    CLoader
  }
}

@Component(config)
export default class Image extends Mixins(CustomTagMixin) {
  @Prop({ type: Object, required: true }) content!: any
  @Prop({ type: String, required: false, default: '' }) contextId!: string

  get image () {
    if (!this.content) return null
    return this.content.data.i18n.image.value
  }

  get imageId () {
    if (!this.content) return null
    return this.content.data.i18n.image.value.id
  }

  get title () {
    if (!this.content) return null
    return this.content.data.i18n.title.value
  }

  get description () {
    if (!this.content) return null
    return this.content.data.i18n.description.value
  }

}
