import { Nullable } from '@/shared'
import { Person } from '@/shared/people'

export type Media = Audio | Image

export enum MediaTypes {
  audio = 'audio',
  video = 'video',
  image = 'image',
  imageGallery = 'image-gallery',
  panorama = 'panorama'
}

export interface Audio {
  id: string,
  name: string,
  imageId: Nullable <string>,
  audio: {
    [key: string]: string
  },
  people: [],
  createdAt: string,
  updatedAt: Nullable <string>
}

export interface Image {
  contentType: string
  formats: {}
  id: string
  image: {}
  imageId: string
  name: Nullable<string>
  people: [
    {
      personId: Nullable<string>
      roleId: Nullable<string>
    }
  ]
  ratio: string
  versions: []
}

export interface Video {
  id: string
}
