
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import POI from '@/graphql/pois'

import { CLazyLoadingImage } from '@/components/images'
import { CLoader } from '@/components/loader'
import { Nullable } from '@/shared'

import * as BOT from '@/store/bot/types'
import { Action } from 'vuex-class'

@Component({
  name: 'c-bot-poi-card',
  components: {
    CLazyLoadingImage,
    CLoader
  }
})
export default class CBotPoiCard extends Vue {

  @Prop({ type: String }) id!: string
  @Prop({ type: String }) iso!: string

  @Action(BOT.CLOSING_BOT) closeBot!: any

  pointOfInterestFetchFailed: boolean = false
  poi: Nullable<any> = null

  async fetchData () {
    await this.$apollo.addSmartQuery('contentToFetch', {
      manual: true,
      loadingKey: 'pointOfInterestIsLoading',
      query: POI.getPoiById([{ iso: this.iso }]),
      variables () {
        return {
          id: this.id,
        }
      },
      error (err) {
        // console.log('err poi', err)
        this.pointOfInterestFetchFailed = true
      },
      result (res) {
        this.poi = res.data.pointOfInterest
      }
    })
  }

  get poiTitle () {
    if (this.poi) {
      return this.poi.name[this.iso].html
    }
    return ''
  }

  mounted () {
    this.fetchData()
  }
}
