import { MutationTree } from 'vuex'

import { AppState } from '@/@next/store/app'
import * as APP from '@/@next/store/app/types'

import { NetworkStatus } from '@/@next/@types'

export const mutations: MutationTree<AppState> =  {

  [APP.SET_NETWORK] (state, network: NetworkStatus) {
    state.network = network
  },

  [APP.ADD_MODAL] (state, modal: any) {
    state.modals.push(modal)
  },

  [APP.REMOVE_CURRENT_MODAL] (state) {
    state.modals.splice(0, 1)
  },

  [APP.REMOVE_MODAL] (state, modal: any) {
    const index = state.modals.findIndex((m: any) => m === modal)
    if (index !== -1) {
      state.modals.splice(index, 1)
    }
  }

}
