import { render, staticRenderFns } from "./IconTextSize.vue?vue&type=template&id=ba509e72&"
import script from "./IconTextSize.vue?vue&type=script&lang=ts&"
export * from "./IconTextSize.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports