import { GetterTree, Module } from 'vuex'
import { RootState } from '@/@next/store'

import * as QUESTION from './types'
import { actions } from './actions'
import { mutations } from './mutations'

export class QuestionsState {
  finishedQuestions: any[] = []
}

const state = new QuestionsState()

const getters: GetterTree<QuestionsState, RootState> = {
  [QUESTION.GET_FINISHED_QUESTIONS]: (state): any => state.finishedQuestions,
  // @ts-ignore
  [QUESTION.GET_FINISHED_QUESTION]: (state): any => (id: string) => state.finishedQuestions.find((question: any) => {
    return question.id === id
  }),
}

export const questions: Module<QuestionsState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
