import { MutationTree } from 'vuex'

import { Language } from '@/@next/@types'

import * as LANGUAGE from './types'
import { LanguagesState } from '@/@next/store/languages'

export const mutations: MutationTree<LanguagesState> =  {

  [LANGUAGE.SET_ALL] (state, languages: Language[]) {
    state.all = languages
  },

  [LANGUAGE.SET_CURRENT] (state, iso: string) {
    state.current = iso
  },

  [LANGUAGE.SET_TARGET] (state, target: string | null) {
    state.target = target
  },

  [LANGUAGE.SET_DEFAULT] (state, iso: string) {
    state.default = iso
  },

  [LANGUAGE.SET_TRANSLATABLE_TARGETS] (state, tt: any) {
    state.translatableTargets = tt
  },

  [LANGUAGE.SET_ACCESSIBILITY] (state, props: any) {
    state.accessibility = { ...state.accessibility, ...props }
  }

}
